import { useEffect, useState } from 'react';
import { SmallConfirmationModal } from '../../SmallConfirmationModal/SmallConfirmationModal';
import { getEditableOrderById } from '../../../db/editableOrders';
import { getOrderPath } from '../../utils/orderPathsGenerator';
import { adjustEditableOrderToDisplayInOrdersList } from '../../utils/adjustEditableOrderToDisplayInOrdersList';
import { useRouter } from 'next/router';
import { OrderToDisplay } from '../../../types/order.types';
import { OrderStatus } from '../../../apiClient/generated';
import useOrderType from '../../../hooks/useOrderType';

interface Props {
    orderToDisplay: OrderToDisplay;
}

export const GoToEditModeModal: React.FC<Props> = ({ orderToDisplay }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { orderIdFromQuery } = useOrderType();
    const { push } = useRouter();

    useEffect(() => {
        // Only show if we're not on the editable order summary already
        if (typeof orderIdFromQuery !== 'number') {
            return;
        }

        // Only show for Awaiting Action orders
        if (orderToDisplay?.status !== OrderStatus.OrderForReview) {
            return;
        }

        // Only show if the order has an orderId
        if (!orderToDisplay?.orderId) {
            return;
        }

        // Only show if we have the editable order
        if (!orderToDisplay?.localOrderId) {
            return;
        }

        setIsOpen(true);
    }, [
        orderIdFromQuery,
        orderToDisplay?.status,
        orderToDisplay?.orderId,
        orderToDisplay?.localOrderId,
    ]);

    const goToEditableSummary = async () => {
        if (!orderToDisplay.orderId) {
            setIsOpen(false);

            return;
        }

        const editableOrder = await getEditableOrderById(
            Number(orderToDisplay.orderId),
        );

        if (!editableOrder) {
            setIsOpen(false);

            return;
        }

        push(
            getOrderPath(
                adjustEditableOrderToDisplayInOrdersList(editableOrder),
            ),
        );
    };

    return (
        <SmallConfirmationModal
            isOpen={isOpen}
            title="Awaiting Action Order"
            subtitle="It looks like you received an update to the current order."
            onConfirm={goToEditableSummary}
            onAbort={() => push('/order')}
            cancelBtnText="Back to orders"
            confirmBtnText="View update"
        />
    );
};
