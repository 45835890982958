import { currencyFormatter } from '../utils/numberFormat';
import styles from './ProductsList.module.css';
import Tooltip from '../Tooltips/Tooltip';
import { useAppLoading } from '../../context/AppLoadingContext';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';

interface PricePerPackProps {
    price: number;
    unitOfMeasure: string;
    isEstimatedPrice: boolean;
    currency?: string;
}

const PricePerPack: React.FC<PricePerPackProps> = ({
    unitOfMeasure,
    price,
    isEstimatedPrice,
    currency,
}) => {
    const { loadingPrices } = useAppLoading();

    return (
        <>
            {loadingPrices ? (
                <SkeletonLoader loaderWidth={60} />
            ) : (
                <>
                    {price === 0 && <p className={styles.primary}>-</p>}

                    {price > 0 &&
                        (isEstimatedPrice ? (
                            <Tooltip
                                tooltipTitle="Estimated price"
                                tooltipMessage="This is our best estimate based on historic price data.
                    Actual price may vary."
                            >
                                <p className={styles.primary}>
                                    ≈&nbsp;{currencyFormatter(price, currency)}
                                </p>
                            </Tooltip>
                        ) : (
                            <p className={styles.primary}>
                                {currencyFormatter(price, currency)}
                            </p>
                        ))}
                </>
            )}

            <p className={styles.secondary}>
                {unitOfMeasure ? `Per ${unitOfMeasure}` : ''}
            </p>
        </>
    );
};

export default PricePerPack;
