import React from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import modalStyles from '../Modal/Modal.module.css';
import styles from './SmallConfirmationModal.module.css';

interface Props {
    isOpen: boolean;
    onClose?: () => void;
    onAbort?: () => void;
    onConfirm?: () => void;
    confirmBtnText: string;
    cancelBtnText: string;
    title: string;
    subtitle?: string;
    additionalSubtitle?: string;
    confirmBtnDisabled?: boolean;
    redAbortButton?: boolean;
}

export const SmallConfirmationModal = ({
    isOpen,
    onClose,
    onAbort,
    onConfirm,
    confirmBtnText,
    cancelBtnText,
    title,
    subtitle,
    additionalSubtitle,
    confirmBtnDisabled,
    redAbortButton,
}: Props) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} small>
            <div className={styles.container}>
                <h1>{title}</h1>
                {subtitle && <p>{subtitle}</p>}
                {additionalSubtitle && <p>{additionalSubtitle}</p>}
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={cancelBtnText}
                        onClick={onAbort}
                        secondary={!redAbortButton}
                        red={redAbortButton}
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={confirmBtnText}
                        onClick={onConfirm}
                        disabled={confirmBtnDisabled}
                        primary
                    />
                </div>
            </div>
        </Modal>
    );
};
