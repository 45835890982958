import { OptionButton } from '../../Button/OptionButton';
import { UpdateItemIssue } from '../ClaimOrCondemnPreparationModal';
import { SelectableOption } from '../utils/selectableOptions';

interface SelectableIssueReasonProps {
    option: SelectableOption;
    isSelected: boolean;
    updateItemIssue: UpdateItemIssue;
}

const SelectableIssueReason: React.FC<SelectableIssueReasonProps> = ({
    option,
    isSelected,
    updateItemIssue,
}) => {
    return (
        <OptionButton
            isSelected={isSelected}
            onClick={() => {
                updateItemIssue('itemIssueProblem', option.itemIssueProblem);
            }}
        >
            {option.text}
        </OptionButton>
    );
};

export default SelectableIssueReason;
