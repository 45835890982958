import { useState } from 'react';
import { Product } from '../types/order.types';

const useFilters = () => {
    const [filters, setFilters] = useState<number[]>([]);

    const [filtersModalIsOpen, setFiltersModalIsOpen] = useState(false);

    const handleOpenFiltersModal = () => {
        setFiltersModalIsOpen(true);
    };

    const handleCloseFiltersModal = () => {
        setFiltersModalIsOpen(false);
    };

    const applyFilters = (localFilters: number[], silently?: boolean) => {
        setFilters(localFilters);
        if (!silently) {
            handleCloseFiltersModal();
        }
    };

    const filterFn = (product: Product) =>
        filters.length > 0
            ? filters.includes(product.categoryLevel3.number)
            : product;

    return {
        filters,
        filtersModalIsOpen,
        handleCloseFiltersModal,
        applyFilters,
        handleOpenFiltersModal,
        filterFn,
    };
};

export default useFilters;
