import { Product } from '../../../types/order.types';
import { getProductsSortedByCategories } from '../../ProductsList/utils/productsListParser';
import { StocktakingItem, StocktakingCondemnedItem } from '../../../types/stocktaking.types';
import { StocktakingAction } from '../StocktakingProductListModals/StocktakingProductModal';
import { db } from '../../../db/db';
import { ItemStatus } from '../../../types/itemStatuses.types';
import { apiClient } from '../../../apiClient/apiClient';
import { InitialStocktakingItemDto } from '../../../apiClient/generated';

export interface StocktakingProductCategoryToDisplay {
    categoryName: string;
    products: StocktakingItem[];
}

export enum CondemnReasons {
    Expired = 'Expired on board',
    Removed = 'Removed from stock',
    Damaged = 'Damaged on board',
}

export const getStocktakingProductsSortedByCategory = (
    stocktakingReportProducts?: StocktakingItem[],
) => {
    if (!stocktakingReportProducts) {
        return [];
    }

    const sortedCategoriesSet: Set<string> = new Set();

    for (const item of stocktakingReportProducts) {
        if (item.category) {
            sortedCategoriesSet.add(item.category);
        }
    }
    const sortedCategories: string[] = Array.from(sortedCategoriesSet);

    const productsPerCategoryArr = sortedCategories.map((category) => {
        const productsInCategory = stocktakingReportProducts.filter(
            (item) => item.category?.toLowerCase() === category?.toLowerCase(),
        );

        return {
            categoryName: category.toUpperCase(),
            products: productsInCategory,
        };
    });

    return productsPerCategoryArr;
};

export const adjustProductsToStocktaking = (
    /**
     * The assortment products are expected to be a subset of the assortment,
     * filtered by the user's search term during *global search*
     */
    assortmentProducts: Product[],
    stocktakingReportProducts: StocktakingItem[],
    filterOptions?: {
        isReportSearch?: boolean;
        isGlobalSearch?: boolean;
        filterCountedItems?: boolean;
        isReadOnly?: boolean;
    },
    /**
     * An array of item numbers of products, matching the user's search term.
     * This is used when searching within the *current report*
     */
    searchResultItemNumbers: string[] = [],
): StocktakingProductCategoryToDisplay[] => {
    let filteredStocktakingReportProducts = stocktakingReportProducts;

    if (!filterOptions?.isReadOnly) {
        filteredStocktakingReportProducts = stocktakingReportProducts.filter(
            (product) =>
                product.counted === true || product.counted === !filterOptions?.filterCountedItems,
        );
    }

    const getStocktakingProductsForCurrentOptions = (): {
        categoryName: string;
        products: Product[] | StocktakingItem[];
    }[] => {
        if (filterOptions?.isGlobalSearch) {
            const productsFilteredByStocktaking = assortmentProducts.filter((product) => {
                const foundProduct = filteredStocktakingReportProducts.find(
                    (stockProduct) => product.itemNumber === stockProduct.itemNumber,
                );

                if (!foundProduct) {
                    return true;
                }
            });

            return getProductsSortedByCategories(productsFilteredByStocktaking);
        }

        if (filterOptions?.isReportSearch) {
            return getStocktakingProductsSortedByCategory(
                filteredStocktakingReportProducts.filter((item) =>
                    searchResultItemNumbers.includes(item.itemNumber),
                ),
            );
        }

        return getStocktakingProductsSortedByCategory(filteredStocktakingReportProducts);
    };

    const stocktakingProductsSortedByCategories: {
        categoryName: string;
        products: Product[] | StocktakingItem[];
    }[] = getStocktakingProductsForCurrentOptions();

    const finalProducts = [];

    for (const category of stocktakingProductsSortedByCategories) {
        const parsedProducts: StocktakingItem[] = [];

        for (const product of category.products) {
            if (stocktakingReportProducts) {
                const stockProduct = stocktakingReportProducts.find(
                    (item) => item.itemNumber === product.itemNumber,
                );

                const parsedProduct: StocktakingItem = {
                    itemName: stockProduct?.itemName ?? product.itemName,
                    itemNumber: stockProduct?.itemNumber ?? product.itemNumber,
                    lastCount: stockProduct?.lastCount ?? 0,
                    counted: stockProduct?.counted,
                    quantity: stockProduct?.quantity ?? 0,
                    unitPrice: stockProduct?.unitPrice ?? 0,
                    unitOfMeasure: stockProduct?.unitOfMeasure ?? product.unitOfMeasure,
                    comment: stockProduct?.comment,
                    expectedCount: stockProduct?.expectedCount,
                    lastMonthPurchaseCount: stockProduct?.lastMonthPurchaseCount,
                    id: stockProduct?.id ?? undefined,
                    category: stockProduct?.category ?? category.categoryName,
                    condemned: stockProduct?.condemned ?? [],
                };

                parsedProducts.push(parsedProduct);
            }
        }

        if (parsedProducts.length === 0) {
            continue;
        }

        const parsedCategory: StocktakingProductCategoryToDisplay = {
            ...category,
            products: parsedProducts,
        };

        finalProducts.push(parsedCategory);
    }
    return finalProducts;
};

export const combineStocktakingActions = (actions: StocktakingAction[]): StocktakingAction[] => {
    const combinedActions: StocktakingAction[] = [];

    const inStockQuantitySum = actions.reduce(
        (acc, item) => (item.action === 'in-stock' ? acc + item.quantity : acc),
        0,
    );

    combinedActions.push({
        action: 'in-stock',
        quantity: inStockQuantitySum,
    });

    const condemnActions = actions.filter((item) => item.action === 'condemn');

    const uniqueReasons = new Set(
        condemnActions.map((item) => item.condemnReason).filter((item) => item !== undefined),
    );
    for (const reason of Array.from(uniqueReasons)) {
        const condemnReasonQuantitySum = condemnActions.reduce(
            (acc, item) => (item.condemnReason === reason ? acc + item.quantity : acc),
            0,
        );

        combinedActions.push({
            action: 'condemn',
            quantity: condemnReasonQuantitySum,
            condemnReason: reason,
        });
    }

    return combinedActions;
};

/**
 * The search logic expects products for searching.
 * This method "converts" stocktaking items to products,
 * for use with the existing search tools
 */
export const convertStocktakingItemsToSearchProducts = (
    stocktakingItems: StocktakingItem[],
): Product[] => {
    return stocktakingItems.map((item) => ({
        ...item,
        area: 'Searchresult',
        categoryLevel1: {
            number: 0,
            text: 'Searchresult',
        },
        categoryLevel2: {
            number: 0,
            text: 'Searchresult',
        },
        categoryLevel3: {
            number: 0,
            text: item.category,
        },
        estimatedPackSize: 0,
        estimatedPrice: 0,
        nutritionGroup: 0,
    }));
};

export const sortStocktakingProductsByCategory = (products: StocktakingItem[]) => {
    return products.sort((a, b) => (a.category.toLowerCase() > b.category.toLowerCase() ? 1 : -1));
};

export const mapToStocktakingItem = (item: InitialStocktakingItemDto): StocktakingItem => {
    return {
        itemNumber: item.itemNumber,
        quantity: 0,
        unitPrice: item.unitPrice,
        comment: '',
        counted: false,
        lastMonthPurchaseCount: item.lastMonthPurchaseCount,
        expectedCount: item.expectedCount,
        lastCount: item.lastCount,
        itemName: item.itemName,
        category: item.category,
        unitOfMeasure: item.unitOfMeasure,
        condemned: item?.condemned?.map(
            (condemn): StocktakingCondemnedItem => ({
                quantity: condemn.quantity,
                reason: condemn.reason,
                isCreated: true,
            }),
        ),
    };
};

export const updateStocktakingDraftMaxCount = async (): Promise<void> => {
    const stocktakingDraft = await db.stocktakingReports
        .where('state')
        .equals(ItemStatus.draft)
        .first();

    if (stocktakingDraft === undefined) {
        return;
    }

    const initialStock = await apiClient.getInitialStocktakingData(stocktakingDraft.reportMonth);
    for (let i = stocktakingDraft.items.length - 1; i >= 0; i--) {
        const draftItem = stocktakingDraft.items[i];
        const stockItem = initialStock.find((item) => item.itemNumber === draftItem.itemNumber);
        if (stockItem) {
            draftItem.expectedCount = stockItem.expectedCount;
        } else if (!Boolean(draftItem.counted)) {
            stocktakingDraft.items.splice(i, 1);
        }
    }

    const stockItemsToAdd = initialStock.filter(
        (item) => !stocktakingDraft.items.find((x) => x.itemNumber === item.itemNumber),
    );

    stocktakingDraft.items.push(...stockItemsToAdd.map(mapToStocktakingItem));

    await db.stocktakingReports.update(stocktakingDraft.reportMonth, {
        items: stocktakingDraft.items,
    });
};

export const handleArrowKeyDown = (
    event: string,
    focusedField: string | undefined,
    setFocusedField: (str: string) => void,
    navigatableLength: number,
) => {
    if (focusedField) {
        const [label, index] = focusedField.split('-');
        let newFocus = ``;

        switch (event) {
            case 'ArrowLeft':
                if (
                    !document.activeElement?.id.includes('navigatable-child') &&
                    !document.activeElement?.className.includes('leftmost')
                ) {
                    newFocus = `${label}-${Number(index) - 1}`;
                    setFocusedField(newFocus);
                    document.getElementById(newFocus)?.focus();
                }

                break;
            case 'ArrowRight':
                if (
                    !document.activeElement?.id.includes('navigatable-child') &&
                    !document.activeElement?.className.includes('rightmost')
                ) {
                    newFocus = `${label}-${Number(index) + 1}`;
                    setFocusedField(newFocus);
                    document.getElementById(newFocus)?.focus();
                }

                break;
            case 'ArrowUp':
                if (
                    !document.activeElement?.id.includes('navigatable-child') &&
                    Number(index) - 5 > 0
                ) {
                    newFocus = `${label}-${Number(index) - 5}`;
                    setFocusedField(newFocus);
                    document.getElementById(newFocus)?.focus();
                }

                break;
            case 'ArrowDown':
                if (
                    !document.activeElement?.id.includes('navigatable-child') &&
                    Number(index) + 5 < navigatableLength + 1
                ) {
                    newFocus = `${label}-${Number(index) + 5}`;
                    setFocusedField(newFocus);
                    document.getElementById(newFocus)?.focus();
                }

                break;
            case 'Enter':
            case 'NumpadEnter':
                if (document.activeElement?.id?.includes('navigatable-child')) {
                    newFocus = document.activeElement.id?.replace('-child', '');

                    document.getElementById(document.activeElement.id)?.blur();
                } else {
                    newFocus = `${document.activeElement?.id?.split('-')[0]}-child-${Number(
                        document.activeElement?.id?.split('-')[1],
                    )}`;

                    if (document.getElementById(newFocus)?.ariaDisabled === 'true') break;
                }

                setFocusedField(newFocus);
                document.getElementById(newFocus)?.focus();

                break;
            case 'Escape':
                if (
                    document.activeElement &&
                    document.activeElement.id?.includes('navigatable-child')
                ) {
                    newFocus = `${document.activeElement.id?.split('-')[0]}-${Number(
                        document.activeElement.id?.split('-')[2],
                    )}`;
                    document.getElementById(document.activeElement.id)?.blur();

                    setFocusedField(newFocus);
                    document.getElementById(newFocus)?.focus();
                }

                break;
            default:
                if (document.activeElement?.id?.includes('navigatable-child'))
                    newFocus = document.activeElement?.id;
                else
                    newFocus = `${document.activeElement?.id?.split('-')[0]}-child-${Number(
                        document.activeElement?.id?.split('-')[1],
                    )}`;

                setFocusedField(newFocus);
                document.getElementById(newFocus)?.focus();
                break;
        }
    }
};

export const handleArrowKeyDownReason = (
    event: string,
    focusedField: string | undefined,
    setFocusedField: (str: string) => void,
    setIsCondemnOptionsModalOpen: (bool: boolean) => void,
    handlePrevFocusedField: () => void,
) => {
    if (focusedField) {
        const [label, index] = focusedField.split('-');
        let newFocus = ``;

        switch (event) {
            case 'Escape':
                setIsCondemnOptionsModalOpen(false);
                handlePrevFocusedField();

                break;
            case 'ArrowUp':
                if (
                    !document.activeElement?.id.includes('navigatable-child') &&
                    Number(index) - 1 > 0
                ) {
                    newFocus = `${label}-${Number(index) - 1}`;
                    setFocusedField(newFocus);
                    document.getElementById(newFocus)?.focus();
                }

                break;
            case 'ArrowDown':
                if (
                    !document.activeElement?.id.includes('navigatable-child') &&
                    Number(index) + 1 < 4
                ) {
                    newFocus = `${label}-${Number(index) + 1}`;
                    setFocusedField(newFocus);
                    document.getElementById(newFocus)?.focus();
                }

                break;
            case 'Enter':
            case 'NumpadEnter':
                document.getElementById(focusedField)?.click();
                handlePrevFocusedField();

                break;
            default:
                if (document.activeElement?.id?.includes('navigatable-child')) {
                    newFocus = document.activeElement.id?.replace('-child', '');

                    document.getElementById(document.activeElement.id)?.blur();
                } else {
                    newFocus = `${document.activeElement?.id?.split('-')[0]}-child-${Number(
                        document.activeElement?.id?.split('-')[1],
                    )}`;

                    if (document.getElementById(newFocus)?.ariaDisabled === 'true') break;
                }
                break;
        }
    }
};
