/*eslint-disable @typescript-eslint/no-unused-vars*/
import React, { Component, ErrorInfo, ReactNode } from 'react';
import UnhandledErrorModal from './UnhandledErrorModal';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class UnhandledErrorBoundary extends Component<Props, State> {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <UnhandledErrorModal />;
        }

        return this.props.children;
    }
}

export default UnhandledErrorBoundary;
