import Dexie from 'dexie';
import { RequestData } from '../requestQueue/requestQueue';

export interface IStoredRequest {
    id?: number;
    requestData: RequestData;
    uuid: string;
    localId: string;
    status?: string;
    clientId?: string;
}

export class OfflineRequestsDatabase extends Dexie {
    requests!: Dexie.Table<IStoredRequest, number>;
    failedRequests!: Dexie.Table<IStoredRequest, number>;

    constructor() {
        super('OfflineRequestsDatabase');

        this.version(1).stores({
            requests: 'uuid,localId',
            failedRequests: 'uuid,localId',
        });
    }

    public deleteRequestByUuid(uuid: string) {
        this.requests.where('uuid').equals(uuid).delete();
    }
}

export const offlineRequestDatabase = new OfflineRequestsDatabase();
