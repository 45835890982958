import moment from 'moment';
import { Order, RFQ } from '../../types/order.types';

export const getLatestRfq = (order: Order | undefined): RFQ | null => {
    if (!order?.rfqs?.length) {
        return null;
    }

    let latestRfq = order.rfqs[0];
    for (const rfq of order.rfqs) {
        if (moment(rfq.created).isAfter(moment(latestRfq.created))) {
            latestRfq = rfq;
        }
    }

    return latestRfq;
};

export const getSortedRfqs = (order: Order | undefined): RFQ[] | null => {
    if (!order?.rfqs?.length) {
        return null;
    }

    return order.rfqs.sort((a, b) => moment(a.created).diff(moment(b.created)));
};