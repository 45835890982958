import { ExperienceKey } from '../../../../types/receivedOrder.types';

export const supplierExperienceSurveyAnswers: {
    key: ExperienceKey;
    description: string;
}[] = [
    {
        key: 'supplierRepresentative',
        description: 'A Supplier Representative accompanied the delivery',
    },
    {
        key: 'blockFrozen',
        description: 'The delivery was without block frozen products',
    },
    {
        key: 'labelsInEnglish',
        description: 'Provisions were labelled in English',
    },
    {
        key: 'supplyOnTime',
        description: 'Provisions were supplied on time',
    },
    {
        key: 'plasticReturned',
        description:
            'The supplier did not take back any plastic packaging used for delivery',
    },
];
