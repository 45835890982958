import moment from 'moment';
import { Order } from '../../../types/order.types';
import { getLatestRfq } from '../../../db/utils/getLatestRfq';
import { itemIssueReasonsList } from './selectableOptions';
import { ItemIssueDetails } from '../ClaimOrCondemnPreparationModal';

/**
 * This function returns a list of lineItems with the date of the last order containing the item.
 */
export const getLastOrderedDates = (allCompletedOrders: Order[]) => {
    const allCompletedRfqs = allCompletedOrders
        .map((order) => ({
            orderId: order.orderId,
            rfq: getLatestRfq(order),
            created: getLatestRfq(order)?.created ?? order.created,
        }))
        .filter((rfq) => rfq !== null);

    const lineItemsWithDate: Record<string, Date> = {};

    for (const order of allCompletedRfqs) {
        for (const lineItem of order.rfq?.lineItems ?? []) {
            if (
                !lineItemsWithDate[lineItem.itemNumber] ||
                moment(order.created).isAfter(
                    lineItemsWithDate[lineItem.itemNumber],
                )
            ) {
                lineItemsWithDate[lineItem.itemNumber] = order.created;
            }
        }
    }

    const lastOrderedLineItems: {
        itemNumber: string;
        created: Date | undefined;
    }[] = Object.keys(lineItemsWithDate).map((itemNumber) => ({
        itemNumber,
        created: lineItemsWithDate[itemNumber],
    }));

    return { lastOrderedLineItems };
};


export const getIssueNameHeaderText = (itemIssueDetails: ItemIssueDetails) => {
    return (
        itemIssueReasonsList.find(
            (reason) =>
                reason.itemIssueProblem.toString() ===
                (itemIssueDetails.itemIssueProblem
                    ? itemIssueDetails.itemIssueProblem.toString()
                    : ''),
        )?.text ?? ''
    );
};
