/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminRfqDto } from '../models/AdminRfqDto';
import type { AdminRfqDtoSingleResult } from '../models/AdminRfqDtoSingleResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminRfqsScope {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns AdminRfqDto Success
     * @throws ApiError
     */
    public apiOdataAdminRfqs(): CancelablePromise<Array<AdminRfqDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminRfqs',
        });
    }

    /**
     * @returns AdminRfqDto Success
     * @throws ApiError
     */
    public apiOdataAdminRfqsCount(): CancelablePromise<Array<AdminRfqDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminRfqs/$count',
        });
    }

    /**
     * @param key 
     * @returns AdminRfqDtoSingleResult Success
     * @throws ApiError
     */
    public apiOdataAdminRfqsKey(
key?: number,
): CancelablePromise<AdminRfqDtoSingleResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminRfqs({key})',
            query: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @returns AdminRfqDtoSingleResult Success
     * @throws ApiError
     */
    public apiOdataAdminRfqsKey1(
key?: number,
): CancelablePromise<AdminRfqDtoSingleResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminRfqs/{key}',
            query: {
                'key': key,
            },
        });
    }

}