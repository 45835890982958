import styles from './ArrowNavigator.module.css';
import React, { useMemo } from 'react';
import ArrowIcon from '../../../public/gfx/uiIcons/goBackArrowIcon.svg';

interface Props {
    left?: boolean;
    right?: boolean;
    overflowRight?: 'active' | undefined;
    overflowLeft?: 'active' | undefined;
    actionOnClick: () => void;
}

const ArrowNavigator: React.FC<Props> = ({
    overflowRight,
    overflowLeft,
    left,
    right,
    actionOnClick,
}) => {
    const leftArrowPic = useMemo(() => {
        if (overflowLeft) {
            return <ArrowIcon stroke="#020C16" width="6" height="10" />;
        }
        return <ArrowIcon stroke="#ACB4BB" width="6" height="10" />;
    }, [overflowLeft]);

    const rightArrowPic = useMemo(() => {
        if (!overflowRight) {
            return (
                <ArrowIcon
                    className={styles.arrowRotated}
                    stroke="#ACB4BB"
                    width="6"
                    height="10"
                />
            );
        }
        return (
            <ArrowIcon
                className={styles.arrowRotated}
                stroke="#020C16"
                width="6"
                height="10"
            />
        );
    }, [overflowRight]);

    return (
        <>
            {left && (
                <div className={styles.leftArrowContainer}>
                    <div
                        className={`${styles.arrowButton} ${
                            overflowLeft
                                ? styles.arrowButtonDark
                                : styles.arrowButtonLight
                        }`}
                        onClick={actionOnClick}
                    >
                        {leftArrowPic}
                    </div>
                </div>
            )}
            {right && (
                <div className={styles.rightArrowContainer}>
                    <div
                        className={`${styles.arrowButton} 
                        ${
                            !overflowRight
                                ? // || !contentBigger
                                  styles.arrowButtonLight
                                : styles.arrowButtonDark
                        } 
              `}
                        onClick={actionOnClick}
                    >
                        {rightArrowPic}
                    </div>
                </div>
            )}
        </>
    );
};

export default ArrowNavigator;
