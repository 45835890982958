import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from '../Modal/Modal';
import styles from './FiltersModal.module.css';
import modalStyles from '../Modal/Modal.module.css';
import Button from '../Button/Button';
import FilterButton from './FilterButton';
import ClearFiltersButton from './ClearFiltersButton';
import { parseProductsToCategories } from './utils/productsToCategoriesParser';
import { Product } from '../../types/order.types';

interface FiltersModalProps {
    isOpen: boolean;
    onClose: () => void;
    applyFilters: (localFilters: number[], silently?: boolean) => void;
    appliedFilters: number[];
    products: Product[];
}

export interface CategoryDetailsLevel3 {
    id: number;
    name: string;
}

const FiltersModal: React.FC<FiltersModalProps> = ({
    isOpen,
    onClose,
    applyFilters,
    appliedFilters,
    products,
}) => {
    const modalContentRef = useRef<HTMLDivElement>(null);

    const [localFilters, setLocalFilters] = useState<number[]>(appliedFilters);

    const syncLocalAndAppliedFilters = () => setLocalFilters(appliedFilters);

    useEffect(() => {
        if (isOpen) {
            syncLocalAndAppliedFilters();
        }

        if (!isOpen && modalContentRef.current) {
            modalContentRef.current.scrollTop = 0;
        }
    }, [isOpen]);

    const productsSortedByCategoriesLevel2 = useMemo(
        () => parseProductsToCategories(products),
        [products],
    );

    const handleClearFiltersForCategory = (categoryId: number) => {
        setLocalFilters((prev) => {
            const idsToRemoveArr = productsSortedByCategoriesLevel2
                .find(({ categoryIdLevel2 }) => categoryIdLevel2 === categoryId)
                ?.categoriesLevel3.map(({ id }) => id);

            return prev.filter((item) => !idsToRemoveArr?.includes(item));
        });
    };

    const clearAllFilters = () => {
        applyFilters([], true);
        setLocalFilters([]);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles.modalContainer} ref={modalContentRef}>
                {productsSortedByCategoriesLevel2.map(
                    ({
                        categoryIdLevel2,
                        categoryNameLevel2,
                        categoriesLevel3,
                    }) => (
                        <div key={categoryIdLevel2}>
                            <p className={styles.categoryName}>
                                {categoryNameLevel2}
                            </p>
                            <div className={styles.filterButtonsContainer}>
                                {categoriesLevel3.map((item) => (
                                    <FilterButton
                                        key={item.id}
                                        categoryDetails={item}
                                        setFilters={setLocalFilters}
                                        filters={localFilters}
                                    />
                                ))}
                                <ClearFiltersButton
                                    onClick={() =>
                                        handleClearFiltersForCategory(
                                            categoryIdLevel2,
                                        )
                                    }
                                />
                            </div>
                        </div>
                    ),
                )}
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Clear All"
                        onClick={clearAllFilters}
                        secondary
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Apply"
                        onClick={() => applyFilters(localFilters)}
                        primary
                    />
                </div>
            </div>
        </Modal>
    );
};

export default FiltersModal;
