// It's small context to handle expanding items in CashPurchaseItemsList.tsx and ClaimsSortedByOrderRow.tsx

import React, { createContext, useContext, useState } from 'react';

type ItemsListContextProviderProps = {
    children: React.ReactNode;
};

const ItemsListContext = createContext<{
    expandedRowsIds: number[];
    openRowIds: (ids: number[]) => void;
    toggleRowId: (id: number) => void;
}>({
    expandedRowsIds: [],
    openRowIds: () => {},
    toggleRowId: () => {},
});

const ItemsListContextProvider = ({
    children,
}: ItemsListContextProviderProps) => {
    const [expandedRowsIds, setExpandedRowsIds] = useState<number[]>([]);

    const openRowIds = (ids: number[]) => {
        setExpandedRowsIds((prev) => [...prev, ...ids]);
    };

    const toggleRowId = (id: number) => {
        if (expandedRowsIds.includes(id)) {
            setExpandedRowsIds((prev) =>
                prev.filter((expandedId) => expandedId !== id),
            );
            return;
        }
        setExpandedRowsIds((prev) => [...prev, id]);
    };

    return (
        <ItemsListContext.Provider
            value={{ expandedRowsIds, openRowIds, toggleRowId }}
        >
            {children}
        </ItemsListContext.Provider>
    );
};

export { ItemsListContextProvider };

export const useItemsListOrderContext = () => {
    const context = useContext(ItemsListContext);
    if (context === undefined) {
        throw new Error('cashPurchaseOrderContext is out of scope');
    }

    return context;
};
