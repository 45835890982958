import { PendingCashPurchaseOrder } from '../types/cashPurchaseOrders.types';
import { db } from './db';

export const getInitialCashPurchaseOrder = () => {
    const initialCashPurchaseOrder: PendingCashPurchaseOrder = {
        items: [],
    };
    return initialCashPurchaseOrder;
};

export const createInitialCashPurchaseOrder = async () => {
    const initialData = getInitialCashPurchaseOrder();

    const countOfPendingCashPurchaseOrders =
        await db.pendingCashPurchaseOrders.count();

    if (countOfPendingCashPurchaseOrders >= 1) {
        await db.pendingCashPurchaseOrders.clear();
    }
    await db.pendingCashPurchaseOrders.add(initialData);
};

export const getPendingCashPurchaseOrder = async () => {
    const pendingCashPurchaseOrders =
        await db.pendingCashPurchaseOrders.toArray();

    if (pendingCashPurchaseOrders.length < 1) {
        return;
    }
    return pendingCashPurchaseOrders[0];
};
