import { Dispatch, SetStateAction, useState } from 'react';
import { OrderTypes } from '../../context/OrderTypes';
import { OrderPreparationModalStep } from '../../types/order.types';

const stepsInOrder = [
    OrderPreparationModalStep.orderType,
    OrderPreparationModalStep.orderDetails,
    OrderPreparationModalStep.startingPoint,
];

export const useStepsNavigator = ({
    onComplete,
    shouldShowStartingPointStep,
    orderType,
    setIsOpenPreparationModal,
}: {
    onComplete: () => void;
    shouldShowStartingPointStep: boolean;
    orderType: OrderTypes | undefined;
    setIsOpenPreparationModal: Dispatch<SetStateAction<boolean>>;
}) => {
    const [step, setStep] = useState<OrderPreparationModalStep>(
        orderType
            ? OrderPreparationModalStep.orderDetails
            : OrderPreparationModalStep.orderType,
    );

    const steps = shouldShowStartingPointStep
        ? stepsInOrder
        : stepsInOrder.filter(
              (item) => item !== OrderPreparationModalStep.startingPoint,
          );

    const currentIndex = steps.indexOf(step);

    const isItLastStep = currentIndex === steps.length - 1;
    const isItFirstStep = currentIndex === 0;

    const next = isItLastStep ? steps[currentIndex] : steps[currentIndex + 1];

    const previous = isItFirstStep
        ? steps[currentIndex]
        : steps[currentIndex - 1];

    const nextStep = () => {
        if (isItLastStep) {
            onComplete?.();
            return;
        }
        setStep(next);
    };

    const previousStep = () => {
        if (isItFirstStep) {
            setIsOpenPreparationModal(false);
            return;
        }

        setStep(previous);
    };

    return {
        nextStep,
        previousStep,
        step,
        currentIndex,
        length: steps.length,
    };
};
