import { GenerateDexieClass } from '../db';
import { EditableOrder } from '../../types/order.types';
import { v4 } from 'uuid';

export const onUpgradeTo29 = async (db: GenerateDexieClass) => {
    const editableOrders = await db
        .table<EditableOrder>('editableOrders')
        .toArray();

    await db.editableOrders.clear();

    const updatedEditableOrders = editableOrders.map((item) => ({
        ...item,
        localOrderId: v4(),
    }));

    updatedEditableOrders.forEach((item) => db.editableOrders.add(item));
};
