import React from 'react';
import Tooltip from './Tooltip';
import styles from './GarretsContactTooltip.module.css';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';

interface IGarretsContactTooltip {
    additionalTooltipText?: string;
    isOpen?: boolean;
}

export const GarretsContactTooltip: React.FC<
    React.PropsWithChildren<IGarretsContactTooltip>
> = ({ additionalTooltipText, isOpen, children }) => {
    const {
        data: {
            seaStarContact,
            seaStarEmail,
            seaStarOfficeHours,
            seaStarPhone,
        },
    } = useVesselMetadata();

    return (
        <Tooltip
            tooltipTitle=""
            isOpen={isOpen}
            tooltipMessage={
                <div>
                    {additionalTooltipText && <p>{additionalTooltipText}</p>}
                    <div className={styles.rowContainer}>
                        <div
                            className={[styles.contactText, styles.left].join(
                                ' ',
                            )}
                        >
                            <p>Name</p>
                            <p>Phone</p>
                            <p>Email</p>
                            <p>Office hours</p>
                        </div>
                        <div className={styles.contactText}>
                            <p>{seaStarContact}</p>
                            <p className={styles.blueText}>
                                <a href={`tel:${seaStarPhone}`}>
                                    {seaStarPhone}
                                </a>
                            </p>
                            <p className={styles.blueText}>
                                <a href={`mailto:${seaStarEmail}`}>
                                    {seaStarEmail}
                                </a>
                            </p>
                            <p>{seaStarOfficeHours}</p>
                        </div>
                    </div>
                </div>
            }
        >
            {children}
        </Tooltip>
    );
};
