import Image from 'next/image';
import { useMemo, useEffect } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { OrderToDisplay } from '../../types/order.types';
import { OrderTypes } from '../../context/OrderTypes';
import useOrderType from '../../hooks/useOrderType';
import { useProducts } from '../../hooks/useProducts';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import { currencyFormatter } from '../utils/numberFormat';
import {
    calculateSummary,
    getLineItemsWithProductDetails,
} from '../utils/orderSummary';
import styles from './OrderSummaryModalContent.module.css';
import { SummaryRow } from '../CashPurchaseSummaryModal/SummaryRow';

import InfoIcon from '../../public/gfx/uiIcons/infoIcon.svg';
import Tooltip from '../Tooltips/Tooltip';
import { useSeasonalPeriods } from '../../hooks/useSeasonalPeriods';
import moment from 'moment';
import { SummaryRowSeasonalPeriods } from '../SeasonalPurchaseSummaryModal/SummaryRowSeasonalPeriods';
import { updateEditableOrderPeriods } from '../../db/editableOrders';
import { useFeatureFlags } from '../../context/useFeatureFlags';

interface INutritionIconLabelProps {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    index: number;
}

const nutritionIconLabel =
    (data: { name: string; value: number; icon: string }[]) =>
    ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        index,
    }: INutritionIconLabelProps) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const width = 30;
        const height = 26;

        if (!data[index]?.icon) {
            return null;
        }

        return (
            <image
                x={x - width / 2}
                y={y - height / 2}
                href={data[index].icon}
                height={width}
                width={height}
            />
        );
    };
const OrderSummaryModalContent = ({
    orderToDisplay,
}: {
    orderToDisplay: OrderToDisplay;
}) => {
    const { activeOrderType } = useOrderType();
    const isProvision = activeOrderType === OrderTypes.provision;
    const isSeason = activeOrderType === OrderTypes.season;

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const { data: products } = useProducts();
    const { featureFlags } = useFeatureFlags();

    const lineItemsWithProductDetails = getLineItemsWithProductDetails(
        orderToDisplay,
        products,
    );

    const { data: vesselMetadata } = useVesselMetadata();

    const editableRfq = orderToDisplay?.rfq;

    const summary = useMemo(() => {
        if (orderToDisplay && products) {
            const cs = calculateSummary(
                products,
                orderToDisplay,
                vesselMetadata,
            );
            return cs;
        }
        return {
            orderTotal: 0,
            nutritionsPerPersonPerDay: {
                meatAndSeafood: 0,
                fruitsAndVegetables: 0,
                pastaAndRice: 0,
            },
            nutritionsSum: {
                meatAndSeafood: 0,
                fruitsAndVegetables: 0,
                pastaAndRice: 0,
            },
            nutritionsMax: {
                meatAndSeafood: 0,
                fruitsAndVegetables: 0,
                pastaAndRice: 0,
            },
            orderBudget: 0,
            orderBudgetReservedForFreshTopUps: 0,
        };
    }, [products, orderToDisplay, vesselMetadata]);

    const meatAndSeafoodPercentage =
        (summary.nutritionsSum.meatAndSeafood /
            summary.nutritionsMax.meatAndSeafood) *
        100;
    const fruitsAndVegetablesPercentage =
        (summary.nutritionsSum.fruitsAndVegetables /
            summary.nutritionsMax.fruitsAndVegetables) *
        100;
    const pastaAndRicePercentage =
        (summary.nutritionsSum.pastaAndRice /
            summary.nutritionsMax.pastaAndRice) *
        100;

    const nutritionsChartData = [
        {
            name: 'Meat and Seafood',
            value: meatAndSeafoodPercentage,
            icon: '/gfx/foodIcons/protein.svg',
        },
        {
            name: 'Fruits and Vegetables',
            value: fruitsAndVegetablesPercentage,
            icon: '/gfx/foodIcons/eggplant.svg',
        },
        {
            name: 'Rice and Pasta',
            value: pastaAndRicePercentage,
            icon: '/gfx/foodIcons/carbs.svg',
        },
    ].filter((item) => item.value > 0);

    if (!nutritionsChartData.length) {
        // Empty "plate"
        nutritionsChartData.push({ name: '', value: 100, icon: '' });
    }

    const itemsWithoutPricesCount = useMemo(() => {
        let quantity = 0;
        lineItemsWithProductDetails.map((item) => {
            if (
                item.lineItem.quantity > 0 &&
                !Boolean(item.lineItem.estimatedPrice) &&
                !Boolean(item.lineItem.confirmedPrice)
            ) {
                quantity++;
            }
        });
        return quantity;
    }, [lineItemsWithProductDetails]);

    const { data: seasonPeriods } = useSeasonalPeriods();

    const formattedDeliveryDate = moment(
        orderToDisplay.rfq.deliveryDate,
    ).format('YYYY-MM-DD');

    const matchingPeriods = seasonPeriods?.filter((seasonalPeriod) => {
        const fromDate = moment(seasonalPeriod.fromDate).format('YYYY-MM-DD');
        const toDate = moment(seasonalPeriod.toDate).format('YYYY-MM-DD');
        return (
            formattedDeliveryDate >= fromDate && formattedDeliveryDate <= toDate
        );
    });

    useEffect(() => {
        const updatePeriods = async () => {
            if (matchingPeriods && matchingPeriods.length > 0) {
                await updateEditableOrderPeriods(
                    orderToDisplay,
                    matchingPeriods,
                );
            }
        };

        updatePeriods();
    }, [orderToDisplay.localOrderId, matchingPeriods]);

    if (isSeason) {
        return (
            <div className={styles.container}>
                <h2 className={styles.title}>Summary</h2>

                <SummaryRow
                    backgroundColor="grey"
                    label="Meat and seafood"
                    value={`${
                        summary?.nutritionsSum?.meatAndSeafood.toFixed(2) ?? '-'
                    } KG`}
                />
                <SummaryRow
                    backgroundColor="grey"
                    label="Fruits and vegetables"
                    value={`${
                        summary?.nutritionsSum?.fruitsAndVegetables.toFixed(
                            2,
                        ) ?? '-'
                    } KG`}
                />
                <SummaryRow
                    backgroundColor="grey"
                    label="Rice and pasta"
                    value={`${
                        summary?.nutritionsSum?.pastaAndRice.toFixed(2) ?? '-'
                    } KG`}
                    strongBottomBorder
                />
                <div className={styles.cashDiscountRow}></div>
                {!featureFlags?.hidePrice ? (
                    <>
                        <SummaryRow
                            backgroundColor="grey"
                            label="Season Allowance"
                            value={''}
                        />

                        {matchingPeriods?.map((seasonalPeriod, index) => {
                            const { comment, fromDate, toDate, budget } =
                                seasonalPeriod;

                            const fromDateFormatted =
                                moment(fromDate).format('DD-MM-YYYY');
                            const toDateFormatted =
                                moment(toDate).format('DD-MM-YYYY');
                            return (
                                <SummaryRowSeasonalPeriods
                                    key={index + 1}
                                    fromDate={fromDateFormatted}
                                    toDate={toDateFormatted}
                                    value={
                                        budget
                                            ? currencyFormatter(
                                                  budget,
                                                  vesselCurrency,
                                              )
                                            : 'N/A'
                                    }
                                    period={`${comment}`}
                                    strongBottomBorder={
                                        index === matchingPeriods.length - 1
                                    }
                                />
                            );
                        })}

                        <div style={{ width: '100%', height: '30px' }}></div>
                        <SummaryRow
                            label="Order Total"
                            labelHelper=" "
                            value={
                                summary.orderTotal
                                    ? currencyFormatter(
                                          summary.orderTotal,
                                          vesselCurrency,
                                      )
                                    : '0'
                            }
                            valueHelper={
                                <div className={styles.currencyInfo}>
                                    <Tooltip
                                        tooltipTitle="Estimated conversion"
                                        tooltipMessage={
                                            <>
                                                <p>
                                                    This is our best estimate
                                                    based on historic price
                                                    data. Actual price may vary.
                                                </p>
                                                <p>
                                                    Rate at the time of
                                                    purchase:
                                                </p>
                                            </>
                                        }
                                    >
                                        <InfoIcon width="14" height="14" />
                                    </Tooltip>
                                </div>
                            }
                        />
                    </>
                ) : null}
            </div>
        );
    }

    return (
        <>
            <h2 className={styles.summaryTitle}>Summary</h2>

            <div className={styles.keyValueRow}>
                <p className={styles.label}>Meat, fish and poultry</p>
                <p className={styles.label}>
                    {summary.nutritionsPerPersonPerDay.meatAndSeafood.toFixed(
                        2,
                    )}
                    &nbsp;Kg / Person / Day
                </p>
            </div>
            <div className={styles.keyValueRow}>
                <p className={styles.subLabel}>
                    {summary.nutritionsSum.meatAndSeafood.toFixed(2)} Kg
                </p>
                <p className={styles.subLabel}>
                    Recommended{' '}
                    {summary.nutritionsMax.meatAndSeafood.toFixed(2)} Kg
                </p>
            </div>
            <div className={styles.progress}>
                <div
                    className={[
                        styles.progress__bar,
                        meatAndSeafoodPercentage > 100
                            ? styles.progress__bar__exceeded
                            : '',
                    ].join(' ')}
                    style={{ width: `${meatAndSeafoodPercentage}%` }}
                />
            </div>
            <div
                className={`${styles.keyValueRow} ${styles.keyValueGroupSpacing}`}
            >
                <p className={styles.label}>Fruits and vegetables</p>
                <p className={styles.label}>
                    {summary.nutritionsPerPersonPerDay.fruitsAndVegetables.toFixed(
                        2,
                    )}
                    &nbsp;Kg / Person / Day
                </p>
            </div>
            <div className={styles.keyValueRow}>
                <p className={styles.subLabel}>
                    {summary.nutritionsSum.fruitsAndVegetables.toFixed(2)} Kg
                </p>
                <p className={styles.subLabel}>
                    {' '}
                    Recommended{' '}
                    {summary.nutritionsMax.fruitsAndVegetables.toFixed(2)} Kg
                </p>
            </div>
            <div className={styles.progress}>
                <div
                    className={[
                        styles.progress__bar,
                        fruitsAndVegetablesPercentage > 100
                            ? styles.progress__bar__exceeded
                            : '',
                    ].join(' ')}
                    style={{
                        width: `${fruitsAndVegetablesPercentage}%`,
                    }}
                />
            </div>
            <div
                className={`${styles.keyValueRow} ${styles.keyValueGroupSpacing}`}
            >
                <p className={styles.label}>Rice and pasta</p>
                <p className={styles.label}>
                    {summary.nutritionsPerPersonPerDay.pastaAndRice.toFixed(2)}
                    &nbsp;Kg / Person / Day
                </p>
            </div>
            <div className={styles.keyValueRow}>
                <p className={styles.subLabel}>
                    {summary.nutritionsSum.pastaAndRice.toFixed(2)} Kg
                </p>
                <p className={styles.subLabel}>
                    Recommended {summary.nutritionsMax.pastaAndRice.toFixed(2)}{' '}
                    Kg
                </p>
            </div>
            <div className={styles.progress}>
                <div
                    className={[
                        styles.progress__bar,
                        pastaAndRicePercentage > 100
                            ? styles.progress__bar__exceeded
                            : '',
                    ].join(' ')}
                    style={{ width: `${pastaAndRicePercentage}%` }}
                />
            </div>

            <div className={styles.chart}>
                <div className={styles.plateIcon}>
                    <Image
                        src="/gfx/foodIcons/fork.svg"
                        width={30}
                        height={160}
                        alt="fork icon"
                    />
                </div>
                <PieChart width={240} height={240}>
                    <Pie
                        data={nutritionsChartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        isAnimationActive={false}
                        label={nutritionIconLabel(nutritionsChartData)}
                        fill="#fff"
                        dataKey="value"
                    >
                        {nutritionsChartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} stroke="#DBDDE0" />
                        ))}
                    </Pie>
                </PieChart>
                <div className={styles.plateIcon}>
                    <Image
                        src="/gfx/foodIcons/knife.svg"
                        width={30}
                        height={160}
                        alt="knife icon"
                    />
                </div>
            </div>
            {!featureFlags?.hidePrice && (
                <div>
                    <div
                        className={`${styles.keyValueRow} ${styles.orderBudget}`}
                    >
                        <p className={styles.label}>
                            Order budget ({editableRfq?.coveringDays ?? 60}{' '}
                            days)
                        </p>
                        <p className={styles.label}>
                            {summary.orderBudget
                                ? currencyFormatter(
                                      summary.orderBudget,
                                      vesselCurrency,
                                  )
                                : 'N/A'}
                        </p>
                    </div>

                    <hr
                        className={`hr ${isProvision ? '' : 'hr--marginTop'}`}
                    />
                    <div
                        className={`${styles.keyValueRow} ${styles.orderTotal}`}
                    >
                        <p className={styles.label}>Estimated order total</p>
                        <p className={styles.label}>
                            {summary.orderTotal
                                ? currencyFormatter(
                                      summary.orderTotal,
                                      vesselCurrency,
                                  )
                                : 'N/A'}
                        </p>
                    </div>
                    <hr
                        className={`hr ${
                            isProvision ? 'hr--margin' : 'hr--marginTop'
                        }`}
                    />
                    <div
                        className={`${styles.keyValueRow} ${styles.orderTotal}`}
                    >
                        <p className={styles.label}>Items without prices</p>
                        <p className={styles.label}>
                            {itemsWithoutPricesCount}
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderSummaryModalContent;
