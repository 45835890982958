import React from 'react';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import modalStyles from '../../Modal/Modal.module.css';
import styles from './Receival.module.css';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import { ProductCategory } from '../../ProductsList/ProductsList';
import { Product } from '../../../types/order.types';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    category?: ProductCategory<Product>;
    confirm: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmAllProductsConfirmationModal = ({
    isOpen,
    onClose,
    category,
    onConfirm,
    onCancel,
    confirm,
}: Props) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} small>
            <h1 className={styles.confirmationModalTitle}>Please Confirm</h1>
            <p className={styles.confirmationModalText}>
                You are about to {`${confirm ? 'confirm' : 'un-confirm'}`} the
                receival of all "{''}
                <span className={styles.categoryNameCapitalized}>
                    {category ? category.categoryName.toLowerCase() : ''}
                </span>
                " items. Are you sure you wish to continue?
            </p>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Cancel" onClick={onCancel} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={`${confirm ? 'Confirm all' : 'Un-confirm all'}`}
                        onClick={onConfirm}
                        primary
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmAllProductsConfirmationModal;
