import { mutate } from 'swr';
import { OrderToDisplay, Product } from '../types/order.types';
import {
    dataFlowEventHub,
    SWRCacheKeys
} from '../events/dataFlowEvents';
import { getOrder } from '../db/orders';
import { CashPurchaseDto } from '../apiClient/generated';
import { PendingCashPurchaseOrder } from '../types/cashPurchaseOrders.types';
import { getShortWbPono } from '../components/utils/wbPono';

dataFlowEventHub.on('orderChanged', (orderId) =>
    mutate([SWRCacheKeys.order, orderId], undefined, { revalidate: true }),
);

const fetchDbData = (orderId: number) => getOrder(orderId);

export const usePrintHeaderDetails = ({ 
    orderToDisplayCash,
    orderToDisplay}:
    {
        orderToDisplayCash?: CashPurchaseDto | PendingCashPurchaseOrder | undefined,
        orderToDisplay?: OrderToDisplay 
    }

) => { 
    
    let 
        currency,
        shortWbPoNoOrTitle,
        displayDeliveryDate,
        numberOfProducts,
        products,
        orderType

    if (orderToDisplay) { 
        displayDeliveryDate = orderToDisplay?.rfq.deliveryDate
        numberOfProducts = orderToDisplay.rfq.lineItems.length 
        products = orderToDisplay.rfq.lineItems  
        shortWbPoNoOrTitle = orderToDisplay.draftName ??
            "#" + getShortWbPono(orderToDisplay.rfq.wbPono) 
        currency = orderToDisplay.rfq.contractCurrency
        orderType = orderToDisplay.type
        
    } else if (orderToDisplayCash) { 
        displayDeliveryDate = orderToDisplayCash.deliveryDate
        numberOfProducts = orderToDisplayCash.items.length
        products = orderToDisplayCash.items
        shortWbPoNoOrTitle = 'wbPoNo' in orderToDisplayCash ?
            "#" + getShortWbPono(orderToDisplayCash.wbPoNo) :
            'Cash Draft'
        currency = orderToDisplayCash.currency
        orderType = "Cash"
        
    } 
    return {
        currency,
        shortWbPoNoOrTitle,
        displayDeliveryDate,
        numberOfProducts,
        products,
        orderType 
    };
};
