import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface ContextValue {
    searchQuery: string;
    shouldShowResults: boolean;
    handleChangeQuery: (
        query: string,
        options?: { showInLayout?: boolean },
    ) => void;
}

type SearchProviderProps = {
    children: React.ReactNode;
};

const SearchContext = createContext<ContextValue | undefined>(undefined);

const SearchProvider = ({ children }: SearchProviderProps) => {
    const { asPath } = useRouter();
    const [searchQuery, setSearchQuery] = useState('');
    const [shouldShowResults, setShouldShowResults] = useState(false);

    useEffect(() => {
        setSearchQuery('');
        setShouldShowResults(false);
    }, [asPath]);

    const handleChangeQuery = (
        query: string,
        options?: { showInLayout?: boolean },
    ) => {
        //this restriction prevent regex errors
        const restricted = [
            ',',
            '.',
            ';',
            ':',
            '[',
            ']',
            '=',
            '-',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '0',
            "'",
            '"',
            '/',
            '<',
            '>',
            '?',
            '{',
            '}',
            '!',
            '@',
            '#',
            '$',
            '%',
            '^',
            '&',
            '*',
            '(',
            ')',
            '`',
            '~',
        ];

        if (restricted.includes(query)) {
            return;
        }
        setSearchQuery(query);

        let showResults = false;

        if (query.length >= 3 || searchQuery > query) {
            showResults = true;
        } else {
            showResults = false;
        }

        if (query.length === 0) {
            showResults = false;
        }

        setShouldShowResults(
            options?.showInLayout === false ? false : showResults,
        );
    };

    return (
        <SearchContext.Provider
            value={{
                searchQuery,
                shouldShowResults,
                handleChangeQuery,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export { SearchProvider };

export const useSearch = () => {
    const context = useContext(SearchContext);
    if (context === undefined) {
        throw new Error('useAbortController is out of scope');
    }

    return context;
};
