import React, { useEffect } from 'react';
import styles from './StepsProgressBar.module.css';

interface Props {
    currentStep: number;
    totalStepsLength: number;
    /**
     * If the parent remounts during navigation,
     * we animate progress by using a second CSS variable
     */
    parentRemounts?: boolean;
}

const StepsProgressBar: React.FC<Props> = ({
    currentStep,
    totalStepsLength,
    parentRemounts,
}) => {
    const progressBarWidth = ((currentStep - 1) / (totalStepsLength - 1)) * 100;

    const setPreviousProgressBarWidth = () => {
        if (!parentRemounts) {
            return;
        }
        document.documentElement.style.setProperty(
            '--previous-modal-progressbar-width',
            `${progressBarWidth}%`,
        );
    };

    const setCurrentProgressBarWidth = () => {
        document.documentElement.style.setProperty(
            '--current-modal-progressbar-width',
            `${progressBarWidth}%`,
        );
    };

    setCurrentProgressBarWidth();

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPreviousProgressBarWidth();
        }, 1000);
        return () => {
            timeout && clearTimeout(timeout);
            setPreviousProgressBarWidth();
        };
    }, [progressBarWidth]);

    return (
        <>
            <div className={styles.progressBar}>
                <div className={styles.bar} />
            </div>
            <div className={styles.stepsContainer}>
                <p className={styles.stepsText}>
                    Step {currentStep} of {totalStepsLength}
                </p>
            </div>
        </>
    );
};

export default StepsProgressBar;
