import { useEffect, useState } from 'react';
import { getRemovedLineItemsFromOrder } from '../components/ProductsList/utils/rfqFlowParser';
import { OrderToDisplay } from '../types/order.types';
import { useOrder } from './useOrder';

const useHiddenProducts = (orderToDisplay?: OrderToDisplay | null) => {
    const [hiddenProducts, setHiddenProducts] = useState<string[]>([]);
    const { data: order } = useOrder(orderToDisplay?.orderId);

    const getHiddenProducts = async () => {
        if (!orderToDisplay || !orderToDisplay.orderId || !order) {
            setHiddenProducts([]);
            return;
        }

        const productsRemovedByVAMDuringRfqFlow =
            getRemovedLineItemsFromOrder(order);

        setHiddenProducts(productsRemovedByVAMDuringRfqFlow);
    };

    useEffect(() => {
        getHiddenProducts();
    }, [orderToDisplay]);

    return { hiddenProducts };
};

export default useHiddenProducts;
