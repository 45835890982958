import React, { createContext, useContext, useEffect, useState } from 'react';
import { db } from '../db/db';
import { useVesselMetadata } from '../hooks/useVesselMetadata';
import { LineItem, OrderToDisplay, Product } from '../types/order.types';

interface ProductModalMetadata {
    orderToDisplay?: OrderToDisplay;
    product?: Product;
    lineItem?: LineItem;
    isOpen: boolean;
    isDefaultCommentView: boolean;
}

interface ContextValue {
    modal: ProductModalMetadata;
    openModal: (
        orderToDisplay: OrderToDisplay,
        itemNumber: string,
        isCommentView: boolean,
    ) => void;
    closeModal: () => void;
}

type ProductDetailsProviderProps = {
    children: React.ReactNode;
};

const ProductDetailsContext = createContext<ContextValue | undefined>(
    undefined,
);

const ProductDetailsProvider = ({ children }: ProductDetailsProviderProps) => {
    const [productModal, setProductModal] = useState<ProductModalMetadata>({
        isOpen: false,
        isDefaultCommentView: false,
    });

    const {
        data: { standardManning },
    } = useVesselMetadata();

    useEffect(() => {
        if (standardManning) {
            db.setDefaultsForVesselBasedOnMetadata({ standardManning });
        }
    }, [standardManning]);

    const openProductModal = async (
        orderToDisplay: OrderToDisplay,
        itemNumber: string,
        isDefaultCommentView: boolean,
    ) => {
        const product = await db.products.get(itemNumber);
        const lineItem = orderToDisplay?.rfq?.lineItems?.find(
            (item) => item.itemNumber === itemNumber,
        );

        setProductModal({
            orderToDisplay,
            isOpen: true,
            lineItem,
            isDefaultCommentView,
            product,
        });
    };

    const closeProductModal = () => {
        setProductModal({ isOpen: false, isDefaultCommentView: false });
    };

    return (
        <ProductDetailsContext.Provider
            value={{
                modal: productModal,
                openModal: openProductModal,
                closeModal: closeProductModal,
            }}
        >
            {children}
        </ProductDetailsContext.Provider>
    );
};

export { ProductDetailsProvider };

export const useProductDetails = () => {
    const context = useContext(ProductDetailsContext);
    if (context === undefined) {
        throw new Error('useProductDetails context is out of scope');
    }

    return context;
};
