import { apiClient } from '../apiClient/apiClient';
import { dataFlowEventHub } from '../events/dataFlowEvents';
import { db } from './db';

export const fetchAndUpdateAssortments = async () => {
    const assortments = await apiClient.getAssortments();

    await db.transaction('rw', db.assortments, async () => {
        await db.assortments.clear();
        await db.assortments.add(assortments);
    });

    dataFlowEventHub.emit('assortmentChanged');
};
