import moment from 'moment';
import { Order, OrderRfqOrigin, RFQ } from '../../../types/order.types';
import { getUniqesFromArray } from '../../utils/arrayMethods';

interface Iteration {
    iterationNumber: number;
    rfqs: {
        [OrderRfqOrigin.App]: RFQ[];
        [OrderRfqOrigin.WristIntegration]: RFQ[];
    };
    removedLineItems: string[];
}

interface IterationsParserStatus {
    lastOrigin: null | OrderRfqOrigin;
    iterationNumber: number;
}

const getAllLineItemsIdsFromRfqs = (rfqs: RFQ[]): string[] => {
    const allLineItemsIdsWithDuplicates: string[] = [];

    rfqs.forEach(({ lineItems }) => {
        const itemNumbers = lineItems.map(({ itemNumber }) => itemNumber);
        allLineItemsIdsWithDuplicates.push(...itemNumbers);
    });

    const uniqueLineItemsIdsFromRfqs = getUniqesFromArray(
        allLineItemsIdsWithDuplicates,
    );

    return uniqueLineItemsIdsFromRfqs;
};

const getRemovedLineItemsFromIteration = (iteration: Iteration): string[] => {
    const sentLineItems = getAllLineItemsIdsFromRfqs(
        iteration.rfqs[OrderRfqOrigin.App],
    );
    const receivedLineItems = getAllLineItemsIdsFromRfqs(
        iteration.rfqs[OrderRfqOrigin.WristIntegration],
    );

    const removedLineItemsIds = sentLineItems.filter(
        (item) => !receivedLineItems.includes(item),
    );

    return removedLineItemsIds;
};

const parseRfqsListToIterations = (rfqs: RFQ[]): Iteration[] => {
    const createInitialIterationDetails = () => ({
        rfqs: {
            [OrderRfqOrigin.App]: [],
            [OrderRfqOrigin.WristIntegration]: [],
        },
        removedLineItems: [],
    });

    const rfqsSortedByDate = rfqs.sort((a, b) =>
        moment(a.created).diff(b.created),
    );

    const iterationsParserStatus: IterationsParserStatus = {
        lastOrigin: null,
        iterationNumber: 1,
    };

    const iterations: Iteration[] = [
        {
            iterationNumber: 1,
            ...createInitialIterationDetails(),
        },
    ];

    const createAndPushNextIteration = () => {
        iterationsParserStatus.iterationNumber++;
        iterations.push({
            iterationNumber: iterationsParserStatus.iterationNumber,
            ...createInitialIterationDetails(),
        });
    };

    const pushRfqToCurrentIteration = (rfq: RFQ) => {
        const currentIteration = iterations.find(
            (item) =>
                item.iterationNumber === iterationsParserStatus.iterationNumber,
        );

        currentIteration?.rfqs[rfq.origin ?? OrderRfqOrigin.App].push(rfq);

        iterationsParserStatus.lastOrigin = rfq.origin ?? OrderRfqOrigin.App;
    };

    const createIterationsFromList = () =>
        rfqsSortedByDate.forEach((rfq) => {
            const isInitialIteration =
                iterationsParserStatus.lastOrigin === null;
            const isRfqSentByVessel = rfq.origin === OrderRfqOrigin.App;
            const hasOriginChanged =
                iterationsParserStatus.lastOrigin !== rfq.origin;

            const isSingleIterationFinished =
                !isInitialIteration && hasOriginChanged && isRfqSentByVessel;

            if (isSingleIterationFinished) {
                // What is this condition about?
                // isInitialIteration - initially we have the first iteration (so we want to skip this if its initial rfq)
                // isRfqSentByVessel - next iteration starts if rfq comes from the vessel
                // and basicaly that's it.. but:
                // hasOriginChanged - during my (locally) tests i discovered we can have multiple rfqs from the same source in a row.
                // and i guess we shouldn't treat this as a separate iteration so i added a third condition hasOriginChanged

                createAndPushNextIteration();
            }

            pushRfqToCurrentIteration(rfq);
        });

    const getRemovedItemsFromEachIteration = () => {
        iterations.forEach((iteration) => {
            iteration.removedLineItems =
                getRemovedLineItemsFromIteration(iteration);
        });
    };

    createIterationsFromList();
    getRemovedItemsFromEachIteration();

    return iterations;
};

const getRemovedLineItemsFromRfqFlow = (rfqs: RFQ[]): string[] => {
    const iterations = parseRfqsListToIterations(rfqs);

    const removedLineItems: string[] = [];
    iterations.forEach((iteration) =>
        removedLineItems.push(...iteration.removedLineItems),
    );

    return removedLineItems;
};

export const getRemovedLineItemsFromOrder = (order?: Order): string[] => {
    if (!order?.rfqs?.length) {
        return [];
    }

    const { rfqs } = order;

    const removedLineItems = getRemovedLineItemsFromRfqFlow(rfqs);

    return removedLineItems;
};
