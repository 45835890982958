/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErrorMetadata } from '../models/ErrorMetadata';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminScope {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Resets database in development mode
     * Requires admin privileges.
     * @returns any Success
     * @throws ApiError
     */
    public resetDatabase(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/DatabaseCleaner',
        });
    }

    /**
     * Resets database in development mode
     * Requires admin privileges.
     * @returns any Success
     * @throws ApiError
     */
    public resetStocktaking(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/DatabaseCleaner/stocktaking',
        });
    }

    /**
     * Resets database in development mode
     * Requires admin privileges.
     * @returns any Success
     * @throws ApiError
     */
    public resetManning(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/DatabaseCleaner/manning',
        });
    }

    /**
     * Returns list of users with invalid or missing data.
     * Requires admin privileges.
     * @returns ErrorMetadata Success
     * @throws ApiError
     */
    public getInvalidUsers(): CancelablePromise<Array<ErrorMetadata>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/integration/users/validate',
        });
    }

}