import useSWR from 'swr';
import { db } from '../db/db';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import { CashPurchaseDto } from '../apiClient/generated';
import { getCashPurchaseOrderById } from '../db/cashPurchaseOrders';

export const useCashPurchaseOrder = (
    cashPurchaseOrderId: CashPurchaseDto['id'],
) => {
    const {
        data: cashPurchaseOrder,
        isValidating: isCashPurchaseOrderValidating,
        mutate: revalidateCashPurchaseOrder,
    } = useSWR(
        [SWRCacheKeys.cashPurchaseOrder, cashPurchaseOrderId],
        ([, cashPurchaseOrderId]) =>
            getCashPurchaseOrderById(cashPurchaseOrderId),
        swrDefaultConfig,
    );

    const optimisticUpdate = async (orderClone: CashPurchaseDto) => {
        revalidateCashPurchaseOrder(orderClone, { revalidate: false });
        await db.cashPurchaseOrders.put(orderClone);
    };

    const updateCashPurchaseOrder = async (update: CashPurchaseDto) => {
        await optimisticUpdate(update);
    };

    return {
        cashPurchaseOrder,
        isCashPurchaseOrderValidating,
        revalidateCashPurchaseOrder,
        updateCashPurchaseOrder,
    };
};
