import { currencyFormatter } from '../utils/numberFormat';
import styles from './OrderPDF.module.css';


interface PricePerPackProps {
    price: number;
    unitOfMeasure: string;
    isEstimatedPrice: boolean;
    currency?: string;
}

const PricePerPackOrderPDF: React.FC<PricePerPackProps> = ({
    unitOfMeasure,
    price,
    isEstimatedPrice,
    currency,
}) => { 
    return (
        <>
            <div className={styles.pricePerPack}>
                <div>
                    {price === 0 && <div>-</div>}
                </div>
                <div>
                    {price > 0 &&
                        (isEstimatedPrice ? ( 
                            <div>
                                ≈&nbsp;{currencyFormatter(price, currency)}
                            </div> 
                        ) : (
                            <div>
                                {currencyFormatter(price, currency)}
                            </div>
                    ))}
                </div>
                <div>
                    {unitOfMeasure ? `Per ${unitOfMeasure}` : ''}
                </div>
            </div>
            
        </>
    );
};

export default PricePerPackOrderPDF;
