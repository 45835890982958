import { CashPurchaseDto } from '../apiClient/generated';
import { db } from './db';

export const updateCashPurchaseOrdersTable = async (
    cashPurchaseOrders: CashPurchaseDto[],
) => {
    const actualCashPurchaseOrders = await db.cashPurchaseOrders.toArray();

    cashPurchaseOrders.forEach((cashPurchaseOrderFromApi) => {
        const duplicateFromDb = actualCashPurchaseOrders.find(
            (cashPurchaseOrderFromDb) =>
                cashPurchaseOrderFromDb.wbPoNo ===
                cashPurchaseOrderFromApi.wbPoNo,
        );

        if (duplicateFromDb) {
            db.cashPurchaseOrders.delete(duplicateFromDb.id);
        }
    });

    await db.cashPurchaseOrders.bulkPut(cashPurchaseOrders);
};

export const getCashPurchaseOrderById = async (id: number) => {
    if (!id) {
        return;
    }
    return db.cashPurchaseOrders.where('id').equals(id).first();
};
