import React from 'react';
import styles from './OrderSummaryProductsTab.module.css'; 
import { OrderToDisplay, Product, Order } from '../../types/order.types';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { RFQChangelog } from '../../hooks/useChangelog';
import ProductsList from '../ProductsList/ProductsList';
import { ProductRowChangelogStatus } from '../ProductsList/SingleProductRow';
import { isUnavailableProduct } from '../utils/getProductsForOrderSummaryScreen'; 

export const OrderSummaryProductsTab: React.FC<{
    changelog?: RFQChangelog;
    products: Product[];
    orderToDisplay: OrderToDisplay;
    orders: Order[];
}> = ({ changelog, products, orderToDisplay }) => {
    const { isLocked } = parseOrderStatus(orderToDisplay);
      
    return (
        <div className={styles.printOrders}>
            
            <ProductsList
                compact
                products={products}
                orderToDisplay={orderToDisplay}
                getChangelogStatus={(product) => {
                    if (orderToDisplay.isSending || isLocked) {
                        return ProductRowChangelogStatus.locked;
                    }

                    const change = changelog?.changes?.find(
                        (change) => change.itemNumber === product.itemNumber,
                    );

                    if (change?.status === 'removed') {
                        return ProductRowChangelogStatus.removed;
                    } else if (change?.status === 'updated') {
                        return ProductRowChangelogStatus.updated;
                    } else if (isUnavailableProduct(product)) {
                        return ProductRowChangelogStatus.unavailable;
                    }

                    return ProductRowChangelogStatus.default;
                }}
            />
        </div>
    );
};

export default OrderSummaryProductsTab;
