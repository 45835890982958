/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AdminScope } from './services/AdminScope';
import { AdminOrdersScope } from './services/AdminOrdersScope';
import { AdminRfqsScope } from './services/AdminRfqsScope';
import { AdminUsersScope } from './services/AdminUsersScope';
import { FakerScope } from './services/FakerScope';
import { FakeReReceivalScope } from './services/FakeReReceivalScope';
import { GenerateAppScope } from './services/GenerateAppScope';
import { MetadataScope } from './services/MetadataScope';
import { ReceivalScope } from './services/ReceivalScope';
import { WristItScope } from './services/WristItScope';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class GeneratedApiClient {

    public readonly admin: AdminScope;
    public readonly adminOrders: AdminOrdersScope;
    public readonly adminRfqs: AdminRfqsScope;
    public readonly adminUsers: AdminUsersScope;
    public readonly faker: FakerScope;
    public readonly fakeReReceival: FakeReReceivalScope;
    public readonly generateApp: GenerateAppScope;
    public readonly metadata: MetadataScope;
    public readonly receival: ReceivalScope;
    public readonly wristIt: WristItScope;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.admin = new AdminScope(this.request);
        this.adminOrders = new AdminOrdersScope(this.request);
        this.adminRfqs = new AdminRfqsScope(this.request);
        this.adminUsers = new AdminUsersScope(this.request);
        this.faker = new FakerScope(this.request);
        this.fakeReReceival = new FakeReReceivalScope(this.request);
        this.generateApp = new GenerateAppScope(this.request);
        this.metadata = new MetadataScope(this.request);
        this.receival = new ReceivalScope(this.request);
        this.wristIt = new WristItScope(this.request);
    }
}