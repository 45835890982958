export const getCorrectIcon = (categoryName: string, withIcon: boolean) => {
    if (!withIcon) {
        return '';
    }

    switch (categoryName) {
        case 'Beef':
            return '/gfx/foodIcons/beef.svg';
        case 'Pork':
            return '/gfx/foodIcons/sausage.svg';
        case 'Poultry':
            return '/gfx/foodIcons/chicken.svg';
        default:
            return '';
    }
};
