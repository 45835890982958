import { useState } from 'react';
import { RemovedLineItemsChangelogModalMetadata } from '../components/RemovedLineItemsChangelogModal/RemovedLineItemsChangelogModal';
import { LineItemWithProductDetails } from '../db/utils/products';

/**
 * This hook is an abstraction to manage the state of RemovedLineItemsChangelogModal.
 * RemovedLineItemsChangelogModal is used when an existing order is selected as an entry point in createOrderModal.
 * In case the selected order contains a product that is not in the user's current assortment - then we display an information modal.
 */
export const useRemovedLineItemsChangelogModal = () => {
    const [
        removedLineItemsChangelogModalMetadata,
        setRemovedLineItemsChangelogModalMetadata,
    ] = useState<RemovedLineItemsChangelogModalMetadata>({
        isOpen: false,
        removedLineItemsWithProductDetails: [],
    });

    const openRemovedLineItemsChangelogModal = (
        removedLineItemsWithProductDetails: LineItemWithProductDetails[],
    ) => {
        setRemovedLineItemsChangelogModalMetadata({
            isOpen: true,
            removedLineItemsWithProductDetails,
        });
    };

    const handleCloseRemovedLineItemsChangelogModal = () => {
        setRemovedLineItemsChangelogModalMetadata({
            isOpen: false,
            removedLineItemsWithProductDetails: [],
        });
    };

    return {
        removedLineItemsChangelogModalMetadata,
        handleCloseRemovedLineItemsChangelogModal,
        openRemovedLineItemsChangelogModal,
    };
};
