import { UseComboboxStateChange } from 'downshift';
import React, { SetStateAction, useEffect, useState } from 'react';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import Autocomplete from '../../Input/Autocomplete';
import styles from './CashOrderDetailsStepContent.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import parsePort from '../../../db/utils/ports';
import { usePorts } from '../../../hooks/usePorts';
import { MissingDeliveryPortHelper } from '../../MissingDeliveryPortHelper/MissingDeliveryPortHelper';
import Button from '../../Button/Button';
import { usePendingCashPurchaseOrder } from '../../../hooks/usePendingCashPurchaseOrder';
import moment, { isDate } from 'moment';
import { CashPurchaseStatus } from '../../../apiClient/generated';
import { PendingCashPurchaseOrder } from '../../../types/cashPurchaseOrders.types';
import { CashDiscountInput } from './CashDiscountInput';
import { useExternalCurrenciesWithRates } from '../../../hooks/useExternalCurrenciesWithRates';
import { ExternalCurrencyWithRate } from '../../../types/externalCurrencies.types';

interface Props {
    onAction: () => void;
    onBack: () => void;
    editMode?: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

export const getSelectedCurrency = (
    selectedCurrencyCode: string,
    externalCurrenciesWithRates: ExternalCurrencyWithRate[],
) => {
    const currency = externalCurrenciesWithRates?.find(
        (currency) => currency.code === selectedCurrencyCode,
    );

    return (
        [
            selectedCurrencyCode,
            currency?.name ? ` (${currency.name})` : null,
        ].join('') ?? ''
    );
};

export const CashOrderDetailsStepContent: React.FC<Props> = ({
    onAction,
    onBack,
    editMode,
    setIsOpen,
}) => {
    const {
        pendingCashPurchaseOrder,
        updatePendingCashPurchaseOrder,
        isPendingCashPurchaseOrderValidating,
    } = usePendingCashPurchaseOrder();

    const { externalCurrenciesWithRates } = useExternalCurrenciesWithRates();

    const [initialCashPurchaseOrderData, setInitialCashPurchaseOrderData] =
        useState<PendingCashPurchaseOrder | undefined>(
            pendingCashPurchaseOrder,
        );

    useEffect(() => {
        if (
            !isPendingCashPurchaseOrderValidating &&
            pendingCashPurchaseOrder &&
            editMode
        ) {
            setInitialCashPurchaseOrderData(pendingCashPurchaseOrder);
        }
    }, []);

    const [errors, setErrors] = useState({
        deliveryPortIsEmpty: false,
        deliveryPortDoesntMatch: false,
        deliveryDateIsEmpty: false,
        currencyIsEmpty: false,
        contractCurrencyDoesntMatch: false,
        cashDiscountTooBig: false,
    });

    const validate = () => {
        const deliveryPortIsEmpty =
            !pendingCashPurchaseOrder?.deliveryPort?.portName ||
            pendingCashPurchaseOrder?.deliveryPort?.portName === '';

        const deliveryDateIsEmpty =
            !pendingCashPurchaseOrder?.deliveryDate ||
            !isDate(moment(pendingCashPurchaseOrder?.deliveryDate).toDate());
        const currencyIsEmpty =
            !pendingCashPurchaseOrder?.currency ||
            pendingCashPurchaseOrder?.currency === '';
        if (deliveryPortIsEmpty || deliveryDateIsEmpty || currencyIsEmpty) {
            setErrors((prev) => ({
                ...prev,
                deliveryPortIsEmpty,
                deliveryDateIsEmpty,
                currencyIsEmpty,
            }));
            return false;
        }
        return true;
    };

    const onCancelUpdatingCashPurchaseOrderDetails = async (
        initialPendingCashPurchaseOrder: PendingCashPurchaseOrder | undefined,
    ) => {
        if (!initialPendingCashPurchaseOrder) {
            return;
        }
        await updatePendingCashPurchaseOrder(initialPendingCashPurchaseOrder);
        setIsOpen(false);
    };

    const handleConfirm = () => {
        if (validate()) {
            if (editMode) {
                setIsOpen(false);
            } else {
                onAction();
            }
        }
    };

    const handleBack = async () => {
        if (editMode) {
            await onCancelUpdatingCashPurchaseOrderDetails(
                initialCashPurchaseOrderData,
            );
        } else {
            onBack();
        }
    };

    const ports = usePorts();

    const handleChangeDeliveryLocation = async ({
        selectedItem,
    }: UseComboboxStateChange<string>) => {
        const selectedPortDetails = ports?.find(
            (port) => port.portNameWithCodeAndCountry === selectedItem,
        );

        if (selectedItem && selectedPortDetails) {
            if (!pendingCashPurchaseOrder) {
                return;
            }

            await updatePendingCashPurchaseOrder({
                ...pendingCashPurchaseOrder,
                deliveryPort: selectedPortDetails,
            });

            setErrors((prev) => ({
                ...prev,
                deliveryPortIsEmpty: false,
                deliveryPortDoesntMatch: false,
            }));
        }
    };

    const handleChangeDeliveryDateTime = async (deliveryDate: Date | null) => {
        if (!deliveryDate || !pendingCashPurchaseOrder) {
            return;
        }

        await updatePendingCashPurchaseOrder({
            ...pendingCashPurchaseOrder,
            deliveryDate: deliveryDate,
        });

        setErrors((prev) => ({
            ...prev,
            deliveryDateIsEmpty: false,
            deliveryDateIsTooEarly: false,
        }));
    };

    const handleChangeCurrency = async ({
        selectedItem,
    }: UseComboboxStateChange<string>) => {
        const selectedCurrencyCode = selectedItem?.substring(0, 3);
        const selectedCurrency = externalCurrenciesWithRates?.find(
            (currency) => currency.code === selectedCurrencyCode,
        );

        if (selectedCurrencyCode && selectedCurrency) {
            if (pendingCashPurchaseOrder) {
                await updatePendingCashPurchaseOrder({
                    ...pendingCashPurchaseOrder,
                    currency: selectedCurrency.code,
                });
                setErrors((prev) => ({
                    ...prev,
                    currencyIsEmpty: false,
                    contractCurrencyDoesntMatch: false,
                }));
            }
        }
    };

    const onDeliveryPortInputClear = async () => {
        if (pendingCashPurchaseOrder) {
            await updatePendingCashPurchaseOrder({
                ...pendingCashPurchaseOrder,
                deliveryPort: undefined,
            });
        }

        setErrors((prev) => ({ ...prev, deliveryPortDoesntMatch: true }));
    };
    const onCurrencyInputClear = async () => {
        if (pendingCashPurchaseOrder) {
            await updatePendingCashPurchaseOrder({
                ...pendingCashPurchaseOrder,
                currency: undefined,
            });
            setErrors((prev) => ({
                ...prev,
                contractCurrencyDoesntMatch: true,
            }));
        }
    };

    const { portNameWithCodeAndCountry } = parsePort(
        pendingCashPurchaseOrder?.deliveryPort?.portName ?? '',
        pendingCashPurchaseOrder?.deliveryPort?.portCode ?? '',
    );

    const isButtonActive = Boolean(
        pendingCashPurchaseOrder?.deliveryPort &&
            pendingCashPurchaseOrder?.deliveryDate &&
            pendingCashPurchaseOrder?.currency &&
            portNameWithCodeAndCountry &&
            !errors.cashDiscountTooBig,
    );

    return (
        <>
            <div className={styles.contentContainer}>
                <div>
                    <Autocomplete
                        data-testid="cashOrderDetailsDeliveryPortInput"
                        helper={
                            pendingCashPurchaseOrder?.status !==
                            CashPurchaseStatus.Submitted ? (
                                <MissingDeliveryPortHelper />
                            ) : (
                                <></>
                            )
                        }
                        label="Delivery port"
                        placeholder="Type to search delivery port"
                        items={
                            ports?.map(
                                ({ portNameWithCodeAndCountry }) =>
                                    portNameWithCodeAndCountry,
                            ) || []
                        }
                        selectedItem={portNameWithCodeAndCountry}
                        handleSelectedItemChange={handleChangeDeliveryLocation}
                        error={
                            errors.deliveryPortIsEmpty
                                ? 'This field is required'
                                : errors.deliveryPortDoesntMatch
                                ? 'Select a delivery port from the list'
                                : undefined
                        }
                        onInputClear={onDeliveryPortInputClear}
                        strongLabel
                    />
                    <div className={styles.inputsInRowContainer}>
                        <div className={styles.inputContainer}>
                            <CustomDatePicker
                                selectedDate={
                                    pendingCashPurchaseOrder?.deliveryDate
                                }
                                setSelectedDate={handleChangeDeliveryDateTime}
                                label={'Delivery date'}
                                error={
                                    errors?.deliveryDateIsEmpty
                                        ? 'Delivery date is required'
                                        : undefined
                                }
                                strongLabel
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <Autocomplete
                                strongLabel
                                label="Currency"
                                optionalLabel=' (from the receipt)'
                                placeholder="Type to search currency"
                                items={
                                    externalCurrenciesWithRates?.map((item) => {
                                        return `${item.code} (${item.name})`;
                                    }) || []
                                }
                                hintsOnClickEnabled
                                error={
                                    errors?.currencyIsEmpty
                                        ? 'Contract currency is required'
                                        : errors?.contractCurrencyDoesntMatch
                                        ? 'Select a currency code from the list'
                                        : undefined
                                }
                                selectedItem={getSelectedCurrency(
                                    pendingCashPurchaseOrder?.currency ?? '',
                                    externalCurrenciesWithRates ?? [],
                                )}
                                handleSelectedItemChange={handleChangeCurrency}
                                onInputClear={onCurrencyInputClear}
                                dropDownHeight={260}
                            />
                        </div>
                    </div>
                    <div className={styles.inputsInRowContainer}>
                        <div className={styles.inputContainer}>
                            <CashDiscountInput
                                cashDiscountInputError={
                                    errors.cashDiscountTooBig
                                }
                                setCashDiscountInputError={(
                                    isDiscountTooBig: boolean,
                                ) => {
                                    setErrors((prev) => ({
                                        ...prev,
                                        cashDiscountTooBig: isDiscountTooBig,
                                    }));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={editMode ? 'Cancel' : 'Back'}
                        onClick={handleBack}
                        secondary
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={editMode ? 'Update' : 'Continue'}
                        onClick={handleConfirm}
                        primary={isButtonActive}
                        disabled={!isButtonActive}
                    />
                </div>
            </div>
        </>
    );
};
