import { EditableOrder, Order } from '../../types/order.types';
import { v4 } from 'uuid';
import { getLatestRfq } from '../../db/utils/getLatestRfq';
import { db } from '../../db/db';

export const adjustOrderForReviewToEditableOrder = (
    order: Order,
): EditableOrder => ({
    localOrderId: v4(),
    orderId: order.orderId,
    type: order.type,
    status: order.status,
    rfq: getLatestRfq(order) ?? db.createInitialRfq(db.defaults.currency),
});
