import { dataFlowEventHub } from './../events/dataFlowEvents';
import { MarketDto } from '../apiClient/generated';
import { db } from './db';
import { getEditableOrderById, updateEditableOrder } from './editableOrders';
import { EditableOrder, Product } from '../types/order.types';
import { getAvailableProducts } from './products';

const updateEstimatedPricesInEditableOrderOfType = async (
    marketResponse: MarketDto[],
    localOrderId: string,
) => {
    const editableOrder = await getEditableOrderById(localOrderId);
    if (!editableOrder) return;

    const updatedLineItems = [...editableOrder.rfq.lineItems].map(
        (lineItem) => {
            const { packSize, price } =
                marketResponse.find(
                    (item) => item.itemNumber === lineItem.itemNumber,
                ) ?? {};

            return {
                ...lineItem,
                estimatedPackSize: packSize ?? 0,
                estimatedPrice: price ?? 0,
            };
        },
    );

    await updateEditableOrder(editableOrder, {
        rfq: { ...editableOrder.rfq, lineItems: updatedLineItems },
    });
};

export const resetEstimatedPricesInEditableOrder = async (
    editableOrder: EditableOrder,
) => {
    if (!editableOrder) return;

    const updatedLineItems = [...editableOrder.rfq.lineItems].map(
        (lineItem) => {
            return {
                ...lineItem,
                estimatedPackSize: 0,
                estimatedPrice: 0,
            };
        },
    );

    await updateEditableOrder(editableOrder, {
        rfq: { ...editableOrder.rfq, lineItems: updatedLineItems },
    });
};

export const updatePrices = async (
    prices?: MarketDto[],
    editableOrder?: EditableOrder,
) => {
    if (!editableOrder || !prices) {
        return;
    }

    dataFlowEventHub.emit('loadingPrices', true);

    await updateEstimatedPricesInEditableOrderOfType(
        prices,
        editableOrder.localOrderId,
    );

    const allProducts = await getAvailableProducts(editableOrder.type, []);

    // We only send updated products to IndexedDb to improve write speed
    const updatedProducts: Product[] = [];
    [...(allProducts ?? [])].forEach((item) => {
        const newPricesForProduct = prices.find(
            (price) => price.itemNumber === item.itemNumber,
        );

        if (newPricesForProduct) {
            if (
                newPricesForProduct.price === item.estimatedPrice &&
                newPricesForProduct.packSize === item.estimatedPackSize
            ) {
                return item;
            }

            const update = {
                ...item,
                estimatedPrice: newPricesForProduct.price,
                estimatedPackSize: newPricesForProduct.packSize ?? 0,
            };

            updatedProducts.push(update);
            return update;
        }

        const emptyUpdate = {
            ...item,
            estimatedPackSize: 0,
            estimatedPrice: 0,
        };
        updatedProducts.push(emptyUpdate);
        return emptyUpdate;
    });

    await db.products.bulkPut(updatedProducts);

    dataFlowEventHub.emit('productsChanged');

    dataFlowEventHub.emit('loadingPrices', false);
};
