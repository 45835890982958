import useSWR from 'swr';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import { db } from '../db/db';
import { getPendingCashPurchaseOrder } from '../db/pendingCashPurchaseOrders';
import {
    PendingCashPurchaseItem,
    PendingCashPurchaseOrder,
} from '../types/cashPurchaseOrders.types';
import { Product } from '../types/order.types';

export const usePendingCashPurchaseOrder = () => {
    const {
        data: pendingCashPurchaseOrder,
        isValidating: isPendingCashPurchaseOrderValidating,
        mutate: revalidatePendingCashPurchaseOrder,
        isLoading,
    } = useSWR(
        SWRCacheKeys.pendingCashPurchaseOrder,
        () => getPendingCashPurchaseOrder(),
        swrDefaultConfig,
    );

    const optimisticUpdate = async (
        pendingCashPurchaseOrder: PendingCashPurchaseOrder,
    ) => {
        revalidatePendingCashPurchaseOrder(pendingCashPurchaseOrder, {
            revalidate: false,
        });
        await db.pendingCashPurchaseOrders.put(pendingCashPurchaseOrder);
    };

    const updatePendingCashPurchaseOrder = async (
        updatedOrder: PendingCashPurchaseOrder,
    ) => {
        await optimisticUpdate(updatedOrder);
    };

    const updateItem = async (
        currentItem: PendingCashPurchaseItem,
        update: Partial<PendingCashPurchaseItem>,
    ) => {
        if (!pendingCashPurchaseOrder) {
            return;
        }

        const getUpdatedItems = () => {
            return pendingCashPurchaseOrder.items.map((item) => {
                const isUpdatedItem =
                    item.itemNumber === currentItem.itemNumber;

                if (isUpdatedItem) {
                    const updatedItem = { ...currentItem, ...update };
                    return updatedItem;
                }

                return item;
            });
        };

        await optimisticUpdate({
            ...pendingCashPurchaseOrder,
            items: getUpdatedItems(),
        });
    };

    const addProductsToCashPurchaseOrder = (
        products: Product[],
        itemNumbers: string[],
    ) => {
        if (!pendingCashPurchaseOrder) {
            return;
        }

        const productsToAdd = products.filter((item) =>
            itemNumbers.includes(item.itemNumber),
        );

        updatePendingCashPurchaseOrder({
            ...pendingCashPurchaseOrder,
            items: [
                ...pendingCashPurchaseOrder.items,
                ...productsToAdd.map((item) => ({
                    ...item,
                    price: 0,
                    quantity: 0,
                    comment: '',
                })),
            ],
        });

        return productsToAdd;
    };

    const deleteItem = async (currentItem: PendingCashPurchaseItem) => {
        if (!pendingCashPurchaseOrder) {
            return;
        }

        const updatedItems = pendingCashPurchaseOrder.items.filter(
            (item) => currentItem.itemNumber !== item.itemNumber,
        );

        await optimisticUpdate({
            ...pendingCashPurchaseOrder,
            items: updatedItems,
        });
    };

    const deletePendingCashPurchaseOrder = async () => {
        await db.pendingCashPurchaseOrders.clear();
        await revalidatePendingCashPurchaseOrder();
    };

    return {
        pendingCashPurchaseOrder,
        isPendingCashPurchaseOrderValidating,
        revalidatePendingCashPurchaseOrder,
        updatePendingCashPurchaseOrder,
        deleteItem,
        addProductsToCashPurchaseOrder,
        updateItem,
        deletePendingCashPurchaseOrder,
        isLoading,
    };
};
