import { useRouter } from 'next/router';

import { adjustAmountToUnitOfMeasure } from '../../utils/adjustAmountToUnitOfMeasure';
import PricePerPack from '../ProductsList/PricePerPack';
import productListStyles from '../ProductsList/ProductsList.module.css';
import { currencyFormatter } from '../utils/numberFormat';
import tableHeadStyles from '../../components/CashPurchaseItemsOverview/CashPurchaseItemsList/CashPurchaseItemsList.module.css';
import { useCashPurchaseOrder } from '../../hooks/useCashPurchaseOrder';
import { useEffect, useMemo, useState } from 'react';

import {
    isCashPurchaseInViewOnly,
    OrderItemCategoriesReMapped,
} from '../ProductsList/utils/productsListParser';
import styles from './OrderPDF.module.css';
import { LineItem, OrderToDisplay, Product } from '../../types/order.types';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import { useProductDetails } from '../../context/ProductDetailsContext';
import { adjustLineItemValueToPackSizeAndUnit } from '../utils/lineItemUtils';
import { useAppLoading } from '../../context/AppLoadingContext';
import { useEditableOrder } from '../../hooks/useEditableOrder';
import PricePerPackOrderPDF from './PricePerPackOrderPDF';

interface SingleProductRowProps {
    product: Product;
    orderToDisplay: OrderToDisplay;
    lineItem?: LineItem;
    rowNumber?: number;
    sortedProductsLength?: number;
    categoryRowNumber: number;
    showPrice?: boolean;
}

export const OrderPDFSingleProductRow: React.FC<SingleProductRowProps> = ({
    product,
    orderToDisplay,
    lineItem,
    rowNumber,
    sortedProductsLength,
    categoryRowNumber,
    showPrice,
}) => {
    const productRowDetails = {
        itemName: product.itemName || lineItem?.itemName,
        itemNumber: product.itemNumber || lineItem?.itemNumber,
        isEstimatedPrice: !lineItem?.confirmedPrice,
        price: lineItem?.confirmedPrice ?? lineItem?.estimatedPrice ?? product.estimatedPrice,
        packSize:
            lineItem?.confirmedPackSize ?? lineItem?.estimatedPackSize ?? product.estimatedPackSize,
        unitOfMeasure: lineItem?.unitOfMeasure ?? product.unitOfMeasure,
    };

    const { itemName, itemNumber, isEstimatedPrice, price, packSize, unitOfMeasure } =
        productRowDetails;

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const { pathname } = useRouter();
    const { loadingPrices } = useAppLoading();
    const productDetails = useProductDetails();
    const { data: editableOrder } = useEditableOrder(orderToDisplay.localOrderId);

    const shouldRemoveZeroQuantityProduct = !pathname.includes('/summary');

    const itemQuantityFromDb =
        orderToDisplay.rfq.lineItems.find((item) => item.itemNumber === itemNumber)?.quantity ?? 0;

    const [lineItemAmount, setLineItemAmount] = useState<number>(itemQuantityFromDb);

    const openCommentModal = () => {
        productDetails.openModal(orderToDisplay, `${itemNumber}`, true);
    };

    const getTotalPrice = () => {
        if (!itemQuantityFromDb) {
            return <></>;
        }

        const roundedPrice = Math.round(price * 100) / 100;
        const totalPrice = Math.round(roundedPrice * itemQuantityFromDb * 100) / 100;

        if (totalPrice === 0) {
            return <></>;
        }

        return (
            <>{`${isEstimatedPrice ? '≈' : ''} ${currencyFormatter(totalPrice, vesselCurrency)}`}</>
        );
    };

    return (
        <>
            <div className={styles.tableRow}>
                <div className={[styles.tableCell, styles.s1].join(' ')}>{rowNumber}</div>

                <div className={styles.tableCell}>
                    <div className={styles.badge}>{itemNumber}</div>
                    {itemName || lineItem?.itemName}
                </div>

                <div className={[styles.tableCell, styles.s2].join(' ')}>{unitOfMeasure}</div>
                <div className={[styles.tableCell, styles.s2].join(' ')}>
                    {' '}
                    {itemQuantityFromDb} &nbsp;{unitOfMeasure ?? ''}
                </div>
                {showPrice && (
                    <div className={[styles.tableCell, styles.s2].join(' ')}>
                        <PricePerPackOrderPDF
                            unitOfMeasure={unitOfMeasure}
                            price={price}
                            isEstimatedPrice={isEstimatedPrice}
                            currency={vesselCurrency}
                        />
                    </div>
                )}
                {showPrice && (
                    <div className={[styles.tableCell, styles.s2].join(' ')}>
                        {' '}
                        {getTotalPrice()}{' '}
                    </div>
                )}
                <div className={styles.tableCell}>
                    {packSize || ''}
                    {lineItem?.comment}
                </div>
            </div>
        </>
    );
};
