import Head from 'next/head';
import React from 'react';

const GenerateHead: React.FC = () => {
    return (
        <Head>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
            />
            <meta name="description" content="Description" />
            <meta name="keywords" content="Keywords" />
            <title>Gateway</title>

            <link rel="manifest" href="/manifest.json" />
            <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            <link
                rel="apple-touch-icon"
                href="images/appIcons/apple-icon.png"
            />
            <meta name="theme-color" content="#317EFB" />
        </Head>
    );
};

export default GenerateHead;
