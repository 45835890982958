import styles from './SearchProductsWithIssuesStep.module.css';

interface PlaceholderProps {
    title: string;
    subtitle: string;
}

export const Placeholder: React.FC<PlaceholderProps> = ({
    title,
    subtitle,
}) => {
    return (
        <div className={styles.centeredTextArea}>
            <span className={styles.title}>{title}</span>
            <p className={styles.subtitle}>{subtitle}</p>
        </div>
    );
};
