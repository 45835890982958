import { ModalHeader } from '../../ModalHeader/ModalHeader';
import DetailsDataTable from '../../DetailsDataTable/DetailsDataTable';
import DetailsDataRow from '../../DetailsDataTable/DetailsDataRow';
import modalStyles from '../../Modal/Modal.module.css';
import styles from './Receival.module.css';
import Button from '../../Button/Button';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import { useReceivedOrder } from '../../../hooks/useReceivedOrder';
import { ReceivedOrder } from '../../../types/receivedOrder.types';
import StepsProgressBar from '../../StepsProgressBar/StepsProgressBar';
import React from 'react';

interface AdditionalNotesProps {
    selectedOrderId: number;
    steps: number;
    onContinue: () => void;
    onBack: () => void;
}

const AdditionalNotes: React.FC<AdditionalNotesProps> = ({
    selectedOrderId,
    steps,
    onBack,
    onContinue,
}) => {
    const { data: receivedOrder, upsertReceivedOrder } =
        useReceivedOrder(selectedOrderId);

    const updateComment = async (
        remarks: string,
        receivedOrder: ReceivedOrder,
    ) => {
        await upsertReceivedOrder({
            ...receivedOrder,
            remarks,
        });
    };

    return (
        <>
            <ModalHeader
                subTitle="Additional notes"
                title={
                    <div className={styles.stepsProgressBar}>
                        <StepsProgressBar
                            currentStep={9}
                            totalStepsLength={steps}
                            parentRemounts
                        />
                    </div>
                }
            />
            <DetailsDataTable>
                <label className={modalStyles.commentInputLabel}>
                    <DetailsDataRow
                        title="Optional comment"
                        uppercaseTitle
                        boldTitle
                    />
                    <textarea
                        name="commentInput"
                        className={modalStyles.commentInput}
                        placeholder="Anything else you want to add about the order, you can put here."
                        value={receivedOrder?.remarks}
                        onChange={async (e) => {
                            if (receivedOrder)
                                await updateComment(
                                    e.target.value.toString(),
                                    receivedOrder,
                                );
                        }}
                    />
                </label>
            </DetailsDataTable>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Continue" onClick={onContinue} primary />
                </div>
            </div>
        </>
    );
};

export default AdditionalNotes;
