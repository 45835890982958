import styles from './AppSettingsModal.module.css';

const SettingRow: React.FC<{
    name: string;
    value: string;
    action?: () => void;
    disabled?: boolean;
}> = ({ name, value, action, disabled }) => (
    <div className={styles.settingRow}>
        <p className={styles.rowName}>{name}</p>
        {action && !disabled ? (
            <p className={styles.textButton} onClick={action}>
                {value}
            </p>
        ) : (
            <p className={disabled ? styles.disabled : ''}>{value}</p>
        )}
    </div>
);

export default SettingRow;
