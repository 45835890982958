import { useRef } from 'react';

/**
 * When the key in an SWR hook changes, the data becomes `undefined` until
 * new data has arrived. When we're doing a heavy operation, like searching or fetching,
 * we may want to display the old results until the new results have arrived.
 *
 * This hook solves that problem by keeping the last value until a new one arrives.
 *
 * Adapted from https://github.com/vercel/swr/issues/192#issuecomment-568944696
 *
 * @param value The value SWR returns
 * @returns A stable populated value, skipping undefined values
 */
export const useSWRCachedResult = <ValueType>(value?: ValueType[]) => {
    const val = useRef<ValueType[]>([]);
    if (value !== undefined) {
        val.current = value;
    }
    return val.current;
};
