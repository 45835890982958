import React, { useEffect, useState } from 'react';
import styles from './Loader.module.css';
import GatewayLogo from '../../public/gfx/gradientGatewayLogo.svg';
import { useAppLoading } from '../../context/AppLoadingContext';
import { Wave } from './Wave';

const Loader = () => {
    const { isAppLoading, setIsLoaderDisplayed } = useAppLoading();
    const [isWaveAnimationVisible, setIsWaveAnimationVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isAppLoading) {
                setIsLoaderDisplayed(false);
            }
        }, 1000);

        const waveAnimationTimer = setTimeout(
            () => setIsWaveAnimationVisible(true),
            2000,
        );

        return () => {
            clearTimeout(timer);
            clearTimeout(waveAnimationTimer);
        };
    }, [isAppLoading]);

    return (
        <>
            {isWaveAnimationVisible && (
                <div className={styles.waveAnimation}>
                    <Wave />
                </div>
            )}
            <div className={styles.container}>
                <div className={styles.slider}>
                    <div
                        className={[
                            styles.logoContainer,
                            styles.logoLoadingAnimation,
                        ].join(' ')}
                    >
                        <div className={styles.logoAnimation}>
                            <GatewayLogo width="inherit" height="inherit" />
                        </div>
                    </div>
                    <p
                        className={[
                            styles.text,
                            styles.gatewayTextContainer,
                        ].join(' ')}
                    >
                        Gateway &#174;
                    </p>
                    <p
                        className={[
                            styles.text,
                            styles.versionTextContainer,
                        ].join(' ')}
                    >
                        App Version {process.env.NEXT_PUBLIC_REACT_APP_VERSION}
                    </p>
                </div>
                <div className={styles.loaderContainer}>
                    <div className={styles.loader}>
                        <div className={styles.loaderBar} />
                    </div>
                </div>
                <div>
                    <div className={styles.loadingTextContainer}>
                        <p className={styles.loadingText}>Loading</p>
                    </div>
                    <div className={styles.loadedDataContainer}>
                        <p>Products</p>
                        <p>Orders</p>
                        <p>Claims</p>
                        <p>Metadata</p>
                        <p>Images</p>
                        <p>Entering Gateway</p>
                    </div>
                </div>
                {!isAppLoading && <div className={styles.finalSlider}></div>}
            </div>
        </>
    );
};

export default Loader;
