import { Portal } from '@radix-ui/react-portal';
import styles from './DropArea.module.css';
import { createRef, useEffect, useState } from 'react';

interface FullscreenDropAreaProps {
    onFilesDrop: (files: FileList) => void | Promise<void>;
    captionText: string;
    icon?: React.ReactElement;
}

export const FullscreenDropArea: React.FC<FullscreenDropAreaProps> = ({
    onFilesDrop,
    icon,
    captionText,
}) => {
    const [dropping, setDropping] = useState(false);
    const dropBannerRef = createRef<HTMLDivElement>();

    const activateDropBanner = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setDropping(true);
    };
    const deactivateDropBanner = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setDropping(false);
    };

    const onDrop = (e: DragEvent) => {
        const dt = e.dataTransfer;
        if (!dt?.files) {
            return;
        }

        const files = dt.files;
        onFilesDrop(files);
    };

    useEffect(() => {
        document.addEventListener('dragover', activateDropBanner);
        document.addEventListener('dragenter', activateDropBanner);

        return () => {
            document.removeEventListener('dragover', activateDropBanner);
            document.removeEventListener('dragenter', activateDropBanner);
        };
    }, []);

    useEffect(() => {
        const ref = dropBannerRef?.current;
        ref?.addEventListener('dragleave', deactivateDropBanner);
        ref?.addEventListener('drop', deactivateDropBanner);
        ref?.addEventListener('drop', onDrop);

        return () => {
            ref?.removeEventListener('dragleave', deactivateDropBanner);
            ref?.removeEventListener('drop', deactivateDropBanner);
            ref?.removeEventListener('drop', onDrop);
        };
    }, [dropBannerRef]);

    return dropping ? (
        <Portal>
            <div className={styles.dropBanner} ref={dropBannerRef}>
                <figure>
                    {icon}
                    <figcaption>{captionText}</figcaption>
                </figure>
            </div>
        </Portal>
    ) : (
        <></>
    );
};
