import { ChangeEvent, Ref, TextareaHTMLAttributes } from 'react';
import styles from './LimitedTextArea.module.css';

interface LimitedTextAreaProps
    extends Pick<
        TextareaHTMLAttributes<HTMLTextAreaElement>,
        'disabled' | 'name' | 'className' | 'rows' | 'placeholder'
    > {
    value: string;
    onChange: (value: string) => void | Promise<void>;
    characterLimit?: number;
    forwardRef?: Ref<HTMLTextAreaElement>;
    readOnly?: boolean;
}

export const LimitedTextArea: React.FC<LimitedTextAreaProps> = ({
    forwardRef,
    name,
    className,
    value,
    disabled,
    onChange,
    characterLimit,
    rows,
    placeholder,
    readOnly,
}) => {
    const onBeforeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        if (characterLimit && newValue.length > characterLimit) {
            onChange(newValue.slice(0, characterLimit));
        } else {
            onChange(newValue);
        }
    };

    return (
        <>
            <textarea
                ref={forwardRef}
                name={name}
                className={className}
                value={value}
                disabled={disabled}
                onChange={onBeforeChange}
                rows={rows}
                placeholder={placeholder}
            />
            {!readOnly && Boolean(characterLimit) && (
                <span className={styles.characterLimit}>
                    {Math.max(0, (characterLimit ?? 0) - value.length)}{' '}
                    characters remaining.
                </span>
            )}
        </>
    );
};
