import Dexie, { Table } from 'dexie';

interface Export {
    userId: string;
    data: Blob;
}

export class UserDataDb extends Dexie {
    public exports!: Table<Export>;

    constructor() {
        super('userDataDb');

        this.version(1).stores({
            exports: '&userId',
        });
    }
}

export const userDataDb = new UserDataDb();
