import { useEffect, useState } from 'react';
import { useReceivedOrder } from '../../../hooks/useReceivedOrder';
import { ReceivedLineItem } from '../../../types/receivedOrder.types';
import { AddCommentToLineItem } from '../../AddCommentToLineItem/AddCommentToLineItem';
import { ModalHeader } from '../../ModalHeader/ModalHeader';

interface AddCommentToReceivedItemProps {
    orderIdFromQuery: number | undefined;
    commentViewIsActive: {
        isOpen: boolean;
        itemNumber?: string | undefined;
    };
    setCommentViewIsActive: React.Dispatch<
        React.SetStateAction<{
            isOpen: boolean;
            itemNumber?: string | undefined;
        }>
    >;
}

export const AddCommentToReveivedItem: React.FC<
    AddCommentToReceivedItemProps
> = ({ orderIdFromQuery, commentViewIsActive, setCommentViewIsActive }) => {
    const { data: receivedOrder, updateReceivedItemComment } =
        useReceivedOrder(orderIdFromQuery);

    const [commentInputValue, setCommentInputValue] = useState<string>('');
    const [lineItem, setLineItem] = useState<ReceivedLineItem | undefined>();

    const getLineItem = () => {
        if (!receivedOrder || !commentViewIsActive.itemNumber) {
            setCommentViewIsActive({ isOpen: false });
            return;
        }

        return receivedOrder.receivedLineItems[commentViewIsActive.itemNumber];
    };

    useEffect(() => {
        const lineItem = getLineItem();

        if (!lineItem) {
            setCommentViewIsActive({ isOpen: false });
        }
        setLineItem(lineItem);
        setCommentInputValue(lineItem?.comment ?? '');
    }, []);

    return (
        <AddCommentToLineItem
            commentContent={commentInputValue}
            setCommentContent={setCommentInputValue}
            addComment={async () => {
                if (!lineItem) {
                    setCommentViewIsActive({ isOpen: false });
                    return;
                }

                if (!commentViewIsActive.itemNumber) {
                    return;
                }

                await updateReceivedItemComment(
                    lineItem.itemNumber,
                    commentInputValue,
                );

                setCommentViewIsActive({ isOpen: false });
            }}
            inputTitle="optional comment"
            customHeader={<ModalHeader subTitle={'Additional notes'} />}
            onBack={() => setCommentViewIsActive({ isOpen: false })}
        />
    );
};
