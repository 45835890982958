import { db } from './db';
import { NotificationToDisplay } from '../context/NotificationContext';

export const putNotificationsToDB = async (
    notifications: NotificationToDisplay[],
) => {
    notifications?.map(async (notification) => {
        await db.notifications.put(notification);
    });
};

export const getNotificationsFromDb = async () => {
    return db.notifications.toArray();
};

export const clearNotifications = async () => {
    await db.notifications.clear();
};
