export interface CustomEvent extends Event {
    request?: Request;
    respondWith?(promise: Promise<any>): void;
    data?: EventData;
    ports?: MessagePort;
    tag?: string;
}

export interface MessagePort {
    postMessage: (dataInJSONFormat: string) => void;
    [key: number]: MessagePort;
}

export interface EventData {
    command: swMessages;
    payload: string;
}

export enum swMessages {
    getRequestQueueContent = 'getRequestQueueContent',
    removeRequestFromOfflineQueueByLocalId = 'removeRequestFromOfflineQueueByLocalId',
    removeRequestsFromOfflineQueueByClientId = 'removeRequestsFromOfflineQueueByClientId',
    getOfflineRequestsContent = 'getOfflineRequestsContent',
    sendAllRequests = 'sendAllRequests',
    getRequestById = 'getRequestById',
    retryFailedRequestByLocalId = 'retryFailedRequestByLocalId',
    //only example
    removeOfflineOrderByWbPono = 'removeOfflineOrderByWbPono',
    putDebugRequestToOnlineQueue = 'putDebugRequestToOnlineQueue',
    putDebugRequestToOfflineQueue = 'putDebugRequestToOfflineQueue',
    sendRequestsFromDb = 'sendRequestsFromDb',
    sendRequestByEntryId = 'sendRequestByEntryId',
}
