import { Portal } from '@radix-ui/react-portal';
import { useEffect, useState } from 'react';
import { ApiError, ItemIssueProblem } from '../../apiClient/generated';
import { OrderToDisplay, Port } from '../../types/order.types';
import Modal from '../Modal/Modal';
import AddCommentAndClaimPhotosStep from './AddCommentAndClaimPhotosStep/AddCommentAndClaimPhotosStep';
import AffectedAmountStep from './AffectedAmountContent/AffectedAmountStep';
import { SmallConfirmationModal } from '../SmallConfirmationModal/SmallConfirmationModal';
import ClaimsOrderSelectionStep from './ClaimsOrderSelectionContent/ClaimsOrderSelectionStep';
import SearchProductsWithIssuesStep from './SearchProductsWithIssuesStep/SearchProductsWithIssuesStep';
import { ItemIssuePreparationStep } from './utils/itemIssueSteps';
import { baseReasons, condemnBaseReasons } from './utils/selectableOptions';
import { useStepsInClaimsPreparationFlow } from './utils/useStepsInPreparationFlow';
import { apiClient } from '../../apiClient/apiClient';
import { db } from '../../db/db';
import { handleItemIssuesIdbUpdate } from '../ClaimsOverviewComponents/helpers/dbHelpers';
import { useUnsavedWork } from '../../context/UnsavedWorkContext';
import { useFeatureFlags } from '../../context/useFeatureFlags';
import ApiDowntimeErrorModal, {
    ItemType,
} from '../ErrorComponents/ApiDowntimeErrorModal';
import { CondemnReasons } from '../StocktakingProductsList/utils/stocktakingUtils';
import { useClaims } from '../../hooks/useClaims';
import { getInitialItemIssueData } from '../ClaimsOverviewComponents/helpers/getInitialItemIssueData';
import {
    parseCondemnReportToSendingCondemnReport,
    parseItemIssueDetailsToCondemnReport,
} from './utils/parsers';
import { useItemsAvailableToClaim } from '../../hooks/useItemsAvailableToClaim';
import { useCondemnReports } from '../../hooks/useCondemnReports';
import IssuesReasonsListStep from './IssuesReasonsListStep/IssuesReasonsListStep';
import ItemIssueSummaryStep from './ItemIssueSummaryStep/ItemIssueSummaryStep';
import { useSendingCondemnReports } from '../../hooks/useSendingCondemnReports';
import { useClaimSubmitting } from './utils/useClaimSubmitting';
import { usePendingItemIssue } from '../../hooks/usePendingItemIssues';
import { useRouter } from 'next/router';
import { useToast, ToastType } from '../../context/ToastContext';

interface ClaimOrCondemnPreparationModalProps {
    isOpen: boolean;
    onClose: () => void;
    initialIssueType: ItemIssueUIType;
}

export interface ClaimOrder extends OrderToDisplay {
    orderedQuantity: number;
    claimedQuantity: number;
}

export type UpdateItemIssue = <
    T extends keyof ItemIssueDetails,
    K extends ItemIssueDetails[T],
>(
    field: T,
    value: K,
) => void;

export enum ItemIssueUIType {
    Claim = 0,
    Condemn = 2,
}

export interface ItemIssueDetails {
    localId?: string;
    syncId?: number;
    clientId: string;
    searchProductInputValue: string;
    itemIssueProblem: ItemIssueProblem | CondemnReasons | null;
    selectedProduct: {
        name: string | null;
        itemNumber: string | null;
    };
    affectedAmount: number;
    comment: string;
    order: ClaimOrder | null;
    isProblemRelatedToExpiryDate: boolean;
    images?: { image: File; id: string }[];
    deliveryPort?: Port;
    isProductUsable: boolean | null;
    isOffline: boolean;
    itemIssueType: ItemIssueUIType;
    prevItemIssueReason?: ItemIssueProblem | CondemnReasons | null;
    changedToCondemnByReason?: boolean;
}

const ClaimOrCondemnPreparationModal: React.FC<
    ClaimOrCondemnPreparationModalProps
> = ({ isOpen, onClose, initialIssueType }) => {
    const { push } = useRouter();
    const [itemIssueDetails, setItemIssueDetails] = useState<ItemIssueDetails>(
        getInitialItemIssueData(initialIssueType),
    );

    const isReportCondemn =
        itemIssueDetails.itemIssueType === ItemIssueUIType.Condemn;

    const isReportClaim = !isReportCondemn;

    const { featureFlags } = useFeatureFlags();

    const { clearData } = usePendingItemIssue();

    const parsedItemIssueReasonsList = isReportCondemn
        ? [...(featureFlags?.condemn ? condemnBaseReasons : [])]
        : [...(featureFlags?.claims ? baseReasons : [])];
    const { showToast } = useToast();
    const { mutate: refreshClaims } = useClaims();
    const { revalidateItemsAvailableToClaim } = useItemsAvailableToClaim();
    const { addReportToSendingCondemnReports, sendingCondemnReports } =
        useSendingCondemnReports();
    const { revalidateCondemnReports } = useCondemnReports();

    const [condemnProcessing, setCondemnProcessing] = useState<boolean>(false);

    const { steps, setActiveStep, handleGoBack, handleResetSteps } =
        useStepsInClaimsPreparationFlow();

    const [
        isConfirmationOnCloseModalOpened,
        setIsConfirmationOnCloseModalOpened,
    ] = useState<boolean>(false);

    const updateItemIssue: UpdateItemIssue = (field, value) => {
        setItemIssueDetails((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const {
        submitClaim,
        isClaimOfflineModalOpen,
        processing,
        setIsClaimOfflineModalOpen,
    } = useClaimSubmitting();

    const submitCondemn = async (condemn: ItemIssueDetails) => {
        if (isReportCondemn) {
            setCondemnProcessing(true);
            const requestBody = parseItemIssueDetailsToCondemnReport(condemn);
            try {
                await apiClient.createOrUpdateCondemnReport(requestBody);
                showToast({
                    type: ToastType.success,
                    text: 'Condemn submitted',
                });
            } catch (error) {
                const { status } = error as ApiError;
                switch (status) {
                    case 502: {
                        setIsClaimOfflineModalOpen(true);
                        if (itemIssueDetails.localId) {
                            const condemnReportOfflineId =
                                (sendingCondemnReports?.length ?? 0) + 1;
                            const parsedCondemnData =
                                parseCondemnReportToSendingCondemnReport(
                                    itemIssueDetails,
                                    condemnReportOfflineId,
                                );
                            await addReportToSendingCondemnReports(
                                parsedCondemnData,
                            );
                        }
                        break;
                    }
                    default: {
                        // TO DO: some default error modal
                        return console.error(error);
                    }
                }
            }

            await revalidateCondemnReports();
        }
    };

    const submitItemIssue = async (itemIssue: ItemIssueDetails) => {
        if (isReportCondemn) {
            await submitCondemn(itemIssue);
            setCondemnProcessing(false);
        } else {
            await submitClaim(itemIssue);
        }

        await refreshClaims();
        await revalidateItemsAvailableToClaim();
        setIsConfirmationOnCloseModalOpened(false);
        handleResetSteps();
        onClose();

        if (
            (isReportCondemn && itemIssueDetails.changedToCondemnByReason) ||
            itemIssueDetails.prevItemIssueReason
        ) {
            await push('/condemn');
        }
    };

    const onContinueAfterReasonSelect = () => {
        if (isReportCondemn) {
            setActiveStep(ItemIssuePreparationStep.setAffectedAmount);
        } else {
            switch (itemIssueDetails.itemIssueProblem) {
                case CondemnReasons.Removed:
                case CondemnReasons.Expired:
                case CondemnReasons.Damaged:
                    updateItemIssue('itemIssueType', ItemIssueUIType.Condemn);
                    updateItemIssue('changedToCondemnByReason', true);
                    setActiveStep(ItemIssuePreparationStep.setAffectedAmount);
                    break;
                default:
                    setActiveStep(ItemIssuePreparationStep.selectOrder);
            }
        }
    };

    const onBackAfterReasonSelect = () => {
        if (isReportCondemn && itemIssueDetails.prevItemIssueReason) {
            updateItemIssue('itemIssueType', ItemIssueUIType.Claim);
            updateItemIssue(
                'itemIssueProblem',
                itemIssueDetails.prevItemIssueReason,
            );
            setActiveStep(ItemIssuePreparationStep.selectOrder);
        } else {
            setActiveStep(ItemIssuePreparationStep.searchProducts);
        }
    };

    const onBackAfterAffectedAmountStep = () => {
        if (itemIssueDetails.changedToCondemnByReason) {
            updateItemIssue('itemIssueType', ItemIssueUIType.Claim);
            setActiveStep(ItemIssuePreparationStep.selectReason);
        } else {
            handleGoBack();
        }
    };

    const onContinueAfterAffectedAmountStep = () => {
        if (isReportCondemn) {
            setActiveStep(ItemIssuePreparationStep.summary);
            return;
        }
        setActiveStep(ItemIssuePreparationStep.addCommentAndPhotos);
    };

    const onSwitchToCondemnThroughTooltip = () => {
        updateItemIssue('itemIssueType', ItemIssueUIType.Condemn);
        updateItemIssue(
            'prevItemIssueReason',
            itemIssueDetails.itemIssueProblem,
        );
        setActiveStep(ItemIssuePreparationStep.selectReason);
    };

    const { addUnsavedWork, removeUnsavedWork } = useUnsavedWork();
    const claimsHandleName = 'claimsJourney';

    const displayStep = () => {
        switch (steps.active) {
            case ItemIssuePreparationStep.searchProducts:
                return (
                    <SearchProductsWithIssuesStep
                        onContinue={() => {
                            setActiveStep(
                                ItemIssuePreparationStep.selectReason,
                            );
                        }}
                        updateItemIssue={updateItemIssue}
                        itemIssueDetails={itemIssueDetails}
                    />
                );
            case ItemIssuePreparationStep.selectReason:
                return (
                    <IssuesReasonsListStep
                        onContinue={onContinueAfterReasonSelect}
                        onBack={onBackAfterReasonSelect}
                        selectableOptionsList={parsedItemIssueReasonsList}
                        updateItemIssue={updateItemIssue}
                        itemIssueDetails={itemIssueDetails}
                        condemn={isReportCondemn}
                    />
                );
            case ItemIssuePreparationStep.selectOrder:
                return (
                    isReportClaim && (
                        <ClaimsOrderSelectionStep
                            onBack={handleGoBack}
                            updateClaim={updateItemIssue}
                            claimDetails={itemIssueDetails}
                            onContinue={() => {
                                setActiveStep(
                                    ItemIssuePreparationStep.setAffectedAmount,
                                );
                            }}
                            onSwitchToCondemn={onSwitchToCondemnThroughTooltip}
                        />
                    )
                );
            case ItemIssuePreparationStep.setAffectedAmount:
                return (
                    <AffectedAmountStep
                        itemIssueDetails={itemIssueDetails}
                        updateItemIssue={updateItemIssue}
                        onBack={onBackAfterAffectedAmountStep}
                        onContinue={onContinueAfterAffectedAmountStep}
                    />
                );
            case ItemIssuePreparationStep.addCommentAndPhotos:
                return (
                    isReportClaim && (
                        <AddCommentAndClaimPhotosStep
                            onContinue={() => {
                                setActiveStep(ItemIssuePreparationStep.summary);
                            }}
                            onBack={handleGoBack}
                            claimDetails={itemIssueDetails}
                            updateClaim={updateItemIssue}
                        />
                    )
                );
            case ItemIssuePreparationStep.whatProductDidYouOrder:
                return (
                    isReportClaim && (
                        <AddCommentAndClaimPhotosStep
                            forIncorrectItemDelivered
                            onContinue={() => {
                                setActiveStep(ItemIssuePreparationStep.summary);
                            }}
                            onBack={handleGoBack}
                            claimDetails={itemIssueDetails}
                            updateClaim={updateItemIssue}
                        />
                    )
                );
            case ItemIssuePreparationStep.summary:
                return (
                    <ItemIssueSummaryStep
                        itemIssueDetails={itemIssueDetails}
                        onBack={handleGoBack}
                        onSubmit={async () => {
                            await submitItemIssue(itemIssueDetails);
                        }}
                        processing={processing || condemnProcessing}
                    />
                );
        }
    };

    const setInitialItemIssueInDb = async () => {
        const initialItemIssue = getInitialItemIssueData(initialIssueType);
        await db.pendingItemIssues.clear();
        const pendingItemIssueId = await db.pendingItemIssues.put(
            initialItemIssue,
        );
        const { localId } =
            (await db.pendingItemIssues.get(pendingItemIssueId)) ?? {};
        if (!localId) {
            return;
        }

        setItemIssueDetails((prev) => ({
            ...prev,
            localId,
        }));
    };

    useEffect(() => {
        if (isOpen) {
            addUnsavedWork(claimsHandleName);
            setInitialItemIssueInDb();
            setItemIssueDetails(getInitialItemIssueData(initialIssueType));
        } else {
            removeUnsavedWork(claimsHandleName);
        }
    }, [isOpen]);

    useEffect(() => {
        handleItemIssuesIdbUpdate(
            itemIssueDetails,
            isReportClaim ? itemIssueDetails.images ?? [] : [],
        );
    }, [itemIssueDetails]);

    const closeClaimOfflineModal = () => {
        setIsClaimOfflineModalOpen(false);
        if (isReportCondemn) {
            showToast({
                type: ToastType.success,
                text: 'Condemn submitted (offline)',
            });
        }
    };

    const condemnOrClaimText =
        initialIssueType === ItemIssueUIType.Condemn ? 'condemn' : 'claim';

    return (
        <Portal>
            <ApiDowntimeErrorModal
                isOpen={isClaimOfflineModalOpen}
                itemType={isReportCondemn ? ItemType.condemn : ItemType.claim}
                onLinkToReportsPage={closeClaimOfflineModal}
                onClose={closeClaimOfflineModal}
                onConfirm={closeClaimOfflineModal}
            />
            <Modal
                isOpen={isOpen}
                onClose={() => setIsConfirmationOnCloseModalOpened(true)}
            >
                {isOpen && (
                    <>
                        <Portal>
                            <SmallConfirmationModal
                                isOpen={isConfirmationOnCloseModalOpened}
                                onClose={() => {
                                    setIsConfirmationOnCloseModalOpened(false);
                                }}
                                onAbort={() =>
                                    setIsConfirmationOnCloseModalOpened(false)
                                }
                                onConfirm={() => {
                                    clearData();
                                    setIsConfirmationOnCloseModalOpened(false);
                                    handleResetSteps();
                                    onClose();
                                }}
                                cancelBtnText="Keep editing"
                                confirmBtnText={`Discard ${condemnOrClaimText}`}
                                title={`Discard ${condemnOrClaimText}`}
                                subtitle={`If you exit this this window, your progress will be lost and you will have to create the ${condemnOrClaimText} again.`}
                                additionalSubtitle={`Are you sure you wish to discard this ${condemnOrClaimText}?`}
                            />
                        </Portal>
                        {displayStep()}
                    </>
                )}
            </Modal>
        </Portal>
    );
};

export default ClaimOrCondemnPreparationModal;
