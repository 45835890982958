import moment from 'moment';
import { forwardRef } from 'react';
import ArrowIcon from '../../public/gfx/uiIcons/collapsedArrow.svg';
import inputStyles from '../Input/Input.module.css';
import styles from './CustomDatePickerHeader.module.css';

type CustomInputProps = {
    onClick?: () => void;
    value?: string;
    label?: string;
    strongLabel?: boolean;
    error?: string;
    name?: string;
    disabled?: boolean;
};

type RefType = HTMLButtonElement;

export const CustomDatePickerInput = forwardRef<RefType, CustomInputProps>(
    ({ value, onClick, label, strongLabel, error, name, disabled }, ref) => (
        <>
            {label && (
                <label
                    className={`
                        ${
                            strongLabel
                                ? inputStyles.textInputLabelStrong
                                : inputStyles.textInputLabel
                        } ${styles.label}`}
                    onClick={onClick}
                >
                    {label}
                </label>
            )}
            <button
                className={[
                    inputStyles.textInput,
                    inputStyles.withIcon,
                    'cursorPointer',
                    error ? inputStyles.textInputWithError : '',
                ].join(' ')}
                onClick={onClick}
                ref={ref}
                name={name}
                disabled={disabled}
            >
                <span>
                    {value
                        ? moment(value, 'MM/DD/YYYY').format('DD/MM/YY')
                        : ''}
                </span>
                <ArrowIcon
                    className="transitionRegular rotated-90"
                    width="25"
                    height="25"
                    stroke="#333"
                />
            </button>
            {error && <span className={inputStyles.errorMessage}>{error}</span>}
        </>
    ),
);
