import { CSSProperties, InputHTMLAttributes } from 'react';
import styles from './Input.module.css';

export enum InputIconTypes {
    percentage = 'Percentage',
}

interface Props {
    label?: string;
    optionalLabel?: string;
    strongLabel?: boolean;
    icon?: React.ReactElement;
    onChangeValue?: (value: string) => void;
    error?: string;
    maxLength?: number;
    noMargin?: boolean;
    customStyle?: CSSProperties;
    numberInput?: boolean;
}

const Input: React.FC<Props & InputHTMLAttributes<HTMLInputElement>> = ({
    label,
    strongLabel,
    optionalLabel,
    onChangeValue,
    error,
    icon,
    maxLength,
    noMargin,
    customStyle,
    numberInput,
    ...inputProps
}) => {
    return (
        <label
            style={customStyle}
            className={
                strongLabel
                    ? styles.textInputLabelStrong
                    : styles.textInputLabel
            }
        >
            {label && label}
            {optionalLabel && (
                <label className={styles.optionalLabel}>{optionalLabel}</label>
            )}
            <div className={styles.inputContainer}>
                <input
                    type={`${numberInput ? 'number' : 'text'}`}
                    className={[
                        styles.textInput,
                        noMargin ? styles.noMargin : '',
                        error ? styles.textInputWithError : '',
                        icon ? styles.customNumberInput : '',
                    ].join(' ')}
                    {...inputProps}
                    onChange={(e) => {
                        inputProps?.onChange?.(e);
                        onChangeValue?.(e?.target?.value ?? '');
                    }}
                    maxLength={maxLength}
                />
                {icon && <div className={styles.inputIcon}>{icon}</div>}
            </div>
            {error && <span className={styles.errorMessage}>{error}</span>}
        </label>
    );
};

export default Input;
