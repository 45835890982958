import {
    Product,
    Order,
    OrderToDisplay,
    LineItem,
} from '../../types/order.types';
import { Changelog, getPreviousRfq } from '../../hooks/useChangelog';
import { mapToLineItem } from '../../apiClient/apiClientMappers';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { NutritionGroup } from '../../apiClient/generated';

interface Options {
    order: Order | undefined;
    orderToDisplay: OrderToDisplay | undefined | null;
    changelog: Changelog;
    products: Product[] | undefined;
}

export const getProductsListIncludingItemsRemovedDuringRfqFlow = (
    options: Options,
): Product[] => {
    const { order, orderToDisplay, changelog, products } = options;
    if (!orderToDisplay || !products) {
        return [];
    }

    const previousRfq = getPreviousRfq(order);

    const removedLineItemsIds = changelog.changes
        .filter((item) => item.status === 'removed')
        .map((item) => item.itemNumber);

    const removedLineItems =
        previousRfq?.lineItems?.filter((item) =>
            removedLineItemsIds.includes(item.itemNumber),
        ) ?? [];

    if (!orderToDisplay) return [];

    const orderStatus = parseOrderStatus(orderToDisplay);

    let lineItems = orderToDisplay.rfq.lineItems ?? [];
    if (orderStatus.isReceived) {
        lineItems = orderToDisplay.receival?.items.map(mapToLineItem) ?? [];
    }

    const lineItemsWithItemsRemovedDuringRfqFlow = [
        ...removedLineItems,
        ...lineItems,
    ];

    const productsToShow: Product[] = [];
    for (const lineItem of lineItemsWithItemsRemovedDuringRfqFlow) {
        const product = products.find(
            (product) => product.itemNumber === lineItem.itemNumber,
        );
        if (!product) {
            // Add line items that are no longer in the product list as removed
            productsToShow.push(getFallbackForUnavailableProduct(lineItem));
        } else {
            productsToShow.push(product);
        }
    }

    return productsToShow;
};

export const getFallbackForUnavailableProduct = (lineItem: LineItem) => ({
    itemNumber: lineItem.itemNumber,
    itemName:
        lineItem.itemName ?? `${lineItem.itemNumber} (Item name unavailable)`,
    unitOfMeasure: lineItem.unitOfMeasure ?? '',
    estimatedPackSize: lineItem.estimatedPackSize ?? 0,
    estimatedPrice: lineItem.estimatedPrice ?? 0,
    area: '',
    categoryLevel1: {
        number: -1,
        text: 'Unavailable',
    },
    categoryLevel2: {
        number: -1,
        text: 'Unavailable',
    },
    categoryLevel3: {
        number: -1,
        text: 'Unavailable',
    },
    nutritionGroup: NutritionGroup.Blank,
});

export const isUnavailableProduct = (product: Product) =>
    product.categoryLevel1.number === -1;
