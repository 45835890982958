import { GenerateDexieClass } from '../db';

export const onUpgradeTo17 = async (db: GenerateDexieClass) => {
    enum OrderTypes {
        provision = 'Provision',
        bonded = 'Bonded',
    }

    interface RFQ {
        id?: number;
        coveringDays: number;
        contractCurrency?: string;
        manning: number;
        comment?: string;
        lineItems?: LineItem[];
        deliveryPort?: RFQPort;
        nextPort: RFQPort;
        deliveryDate: Date;
        departureDate: Date;
        agent?: Agent;
        supplier?: Supplier;
        created?: Date;
        origin?: OrderRfqOrigin;
    }

    interface LineItem {
        id?: number;
        itemNumber: number;
        quantity: number;
        comment?: string;
        unitOfMeasure?: string;
        packSize?: number;
        estimatedPrice?: number;
        price?: number;
    }

    interface Agent {
        name?: string;
        city?: string;
        phone?: string;
        email?: string;
    }

    interface Supplier extends Agent {}

    interface RFQPort {
        portName: string;
        portCode: string;
    }

    enum OrderRfqOrigin {
        App,
        WristIntegration,
    }

    interface Order {
        orderId: number;
        type: OrderTypes;
        status: number;
        totalOrderValue: number;
        created: Date;
        lastModified?: Date;
        latestRfq?: RFQ;
        details?: {
            orderId: number;
            systemRfqId: string;
            status: number;
            vesselImo: number;
            type: OrderTypes;
            created: Date;
            rfqs: RFQ[];
        };
    }

    enum OrderStatus {
        blank = 0, //  = "blank",
        created = 1, //  = "Created" // (draft, only exists on vessel)
        rfqSent = 2, // = RFQ Sent (from vessel to Garrets)
        orderForReview = 3, //  = Order for Review ( sent from Garrets to vessel)
        finalOrder = 4, //  = Final Order (sent from Vessel to Garrets)
        orderForConfirmation = 5, // = Order for confirmation (Sent from Garrets to vessel) not used in MLP1
        receiptInProgress = 6, // = Receipt in progress (Draft Receipt, only exists on vessel) not used in MLP1
        receipt = 7, //  = Receipt (sent from vessel to Garrets)
        cancelledByVessel = 8, // Cancelled by vessel (Can only exist on Status 1) not used in MLP1
        cancelledByGarrets = 9, // = Cancelled by Garrets (can happen at all times after status 1) not used in MLP1
    }

    interface EditableOrder {
        localOrderId?: number;
        orderId: number;
        type: OrderTypes;
        status: OrderStatus;
        rfq: RFQ;
    }

    // Clear all orders and have them refetched from API
    await db.orders.clear();
    // Clear all products and have them refetched from API
    await db.products.clear();

    // Move from draftOrders to editableOrders
    const draftOrders = await db.table<Order>('draftOrders').toArray();
    if (draftOrders?.length) {
        for (const order of draftOrders) {
            if (!order.latestRfq) {
                continue;
            }

            const lineItems =
                order.latestRfq.lineItems?.map((lineItem) => {
                    const updatedlineItem = {
                        ...lineItem,
                        estimatedPrice: lineItem.price,
                        estimatedPackSize: lineItem.packSize,
                    };
                    delete updatedlineItem.price;
                    delete updatedlineItem.packSize;

                    return updatedlineItem;
                }) ?? [];

            const editableOrder: EditableOrder = {
                orderId: order.orderId,
                status: order.status,
                type: order.type,
                rfq: { ...order.latestRfq, lineItems },
            };
            await db.table<EditableOrder>('editableOrders').add(editableOrder);
        }
    }

    const offlineOrders = await db.table<Order>('offlineOrders').toArray();
    await db.offlineOrders.clear();
    if (offlineOrders?.length) {
        for (const order of offlineOrders) {
            if (!order.latestRfq) {
                continue;
            }

            const lineItems =
                order.latestRfq.lineItems?.map((lineItem) => {
                    const updatedlineItem = {
                        ...lineItem,
                        estimatedPrice: lineItem.price,
                        estimatedPackSize: lineItem.packSize,
                    };
                    delete updatedlineItem.price;
                    delete updatedlineItem.packSize;

                    return updatedlineItem;
                }) ?? [];

            const offlineOrder: EditableOrder = {
                orderId: order.orderId,
                status: order.status,
                type: order.type,
                rfq: { ...order.latestRfq, lineItems },
            };
            await db.table<EditableOrder>('offlineOrders').add(offlineOrder);
        }
    }
};
