import moment from 'moment';
import React from 'react';
import Button from '../../Button/Button';
import DetailsDataRow from '../../DetailsDataTable/DetailsDataRow';
import DetailsDataTable from '../../DetailsDataTable/DetailsDataTable';
import dataTableStyles from '../../DetailsDataTable/DetailsDataTable.module.css';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import modalStyles from '../../Modal/Modal.module.css';
import { getShortWbPono } from '../../utils/wbPono';
import claimOrCondemnPreparationModalStyles from '../ClaimOrCondemnPreparationModal.module.css';
import styles from './ItemIssueSummaryStep.module.css';
import { useLineItemDetailsFromOrder } from '../../../hooks/useLineItemDetailsFromOrder';
import StatusBadge from '../../StatusBadge/StatusBadge';
import { getIssueNameHeaderText } from '../utils/itemIssueHelpers';
import {
    ItemIssueDetails,
    ItemIssueUIType,
} from '../ClaimOrCondemnPreparationModal';

interface ItemIssueSummaryStepProps {
    itemIssueDetails: ItemIssueDetails;
    onBack: () => void;
    onSubmit: () => void;
    processing: boolean;
}

const getSummaryText = (condemned?: boolean): string => {
    if (condemned) {
        return 'Thanks for reporting an issue. Based on your input the item is about to be condemned and removed from your stock count.';
    }

    return 'Thank you for providing information about your issue. If needed, our team will get back to you shortly.';
};

const ItemIssueSummaryStep: React.FC<ItemIssueSummaryStepProps> = ({
    itemIssueDetails,
    onBack,
    onSubmit,
    processing,
}) => {
    const isCondemn =
        itemIssueDetails.itemIssueType === ItemIssueUIType.Condemn;

    const { order, affectedAmount } = itemIssueDetails;

    const { wbPono } = order?.rfq || {};
    const wbPonoShort = getShortWbPono(wbPono);

    const { unitOfMeasure } = useLineItemDetailsFromOrder(
        itemIssueDetails.selectedProduct.itemNumber,
        itemIssueDetails.order,
    );
    const reasonText = getIssueNameHeaderText(itemIssueDetails);
    const isProductUsable = itemIssueDetails.isProductUsable;
    return (
        <>
            <div
                className={claimOrCondemnPreparationModalStyles.headerContainer}
            >
                <p>{isCondemn ? 'Condemn' : 'Claim'}</p>
                <h1>Summary</h1>
            </div>

            <p className={`${styles.message} ${styles.largerPadding}`}>
                {getSummaryText(isCondemn)}
            </p>

            <DetailsDataTable>
                {itemIssueDetails.selectedProduct.name && (
                    <DetailsDataRow
                        title={
                            <p>
                                <strong>
                                    {itemIssueDetails.selectedProduct.name}
                                    <StatusBadge>
                                        {isCondemn ? 'Condemn' : 'Claim'}
                                    </StatusBadge>
                                </strong>
                            </p>
                        }
                    />
                )}
                {Boolean(wbPonoShort) && (
                    <>
                        <DetailsDataRow
                            title={<p>Order #</p>}
                            boldValue
                            value={wbPonoShort}
                        />
                    </>
                )}
                <>
                    <DetailsDataRow
                        title={<p>Issue</p>}
                        boldValue
                        value={reasonText}
                    />
                </>
                {Boolean(affectedAmount) && (
                    <DetailsDataRow
                        title={
                            <p>
                                Quantity affected
                                {isCondemn && (
                                    <p
                                        className={[
                                            dataTableStyles.rowTitle,
                                            styles.amountRemark,
                                        ].join(' ')}
                                    >
                                        This amount is being removed from your
                                        stock count.
                                    </p>
                                )}
                            </p>
                        }
                        boldValue
                        value={
                            <strong
                                className={styles.affectedQuantity}
                            >{`${affectedAmount} ${
                                unitOfMeasure ? unitOfMeasure : ''
                            }`}</strong>
                        }
                    />
                )}
                {order && (
                    <>
                        {order?.rfq.deliveryPort?.portName && (
                            <DetailsDataRow
                                title="Port"
                                boldValue
                                value={order?.rfq.deliveryPort?.portName}
                            />
                        )}
                        <DetailsDataRow
                            title="Delivery date"
                            boldValue
                            value={moment(order.rfq.deliveryDate).format(
                                'YYYY-MM-DD',
                            )}
                        />
                    </>
                )}
                {!isCondemn && isProductUsable !== null && (
                    <DetailsDataRow
                        title="Is the product still usable?"
                        boldValue
                        value={
                            <div
                                className={
                                    styles[
                                        isProductUsable ? 'usable' : 'notUsable'
                                    ]
                                }
                            >
                                {isProductUsable ? 'yes' : 'no'}
                            </div>
                        }
                    />
                )}
            </DetailsDataTable>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Back"
                        onClick={onBack}
                        secondary
                        disabled={processing}
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        disabled={processing}
                        text={processing ? 'Sending...' : 'Submit'}
                        onClick={onSubmit}
                        primary
                    />
                </div>
            </div>
        </>
    );
};

export default ItemIssueSummaryStep;
