import { OrderToDisplayInOrdersList } from '../../types/order.types';
import { OrderTypes } from '../../context/OrderTypes';
import { v4 } from 'uuid';
import { PendingCashPurchaseOrder } from '../../types/cashPurchaseOrders.types';

export const adjustSendingCashPurchaseOrderToDisplayInOrderList = (
    cashOrder: PendingCashPurchaseOrder,
): OrderToDisplayInOrdersList => ({
    localOrderId: cashOrder.localId ?? v4(),
    type: OrderTypes.cash,
    lineItems: cashOrder.items ?? [],
    deliveryPort: {
        portName: cashOrder?.deliveryPort?.portName,
        portCode: cashOrder?.deliveryPort?.portCode,
    },
    deliveryDate: new Date(cashOrder.deliveryDate ?? ''),
    lastUpdated: new Date(),
    currency: cashOrder.currency,
    cashDiscount: cashOrder.cashDiscount,
    isSending: true,
});
