/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Blank
 *
 * 1 = Meat
 *
 * 2 = FruitVegetables
 *
 * 3 = RicePasta
 *
 * 4 = MineralWater
 */
export enum NutritionGroup {
    Blank = 0,
    Meat = 1,
    FruitVegetables = 2,
    RicePasta = 3,
    MineralWater = 4,
}