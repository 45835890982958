// todo: it probably won't work now.

import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import modalStyles from '../Modal/Modal.module.css';
import styles from './ApiErrorModal.module.css';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import StatusBadge from '../StatusBadge/StatusBadge';
import { GarretsContact } from './GarretsContact';
import { SmallConfirmationModal } from '../SmallConfirmationModal/SmallConfirmationModal';
import { useEffect, useState } from 'react';
import { useSyncStatus } from '../../context/SyncStatusContext';
import { swMessages } from '../../worker/types';
import { getOrderTypeName } from '../../context/OrderTypes';
import { isParsedResponse } from '../../worker/requestQueue/typeguards';
import { resetEditableOrderById } from '../../db/editableOrders';
import { useRouter } from 'next/router';
import ApiDowntimeErrorModal, { ItemType } from './ApiDowntimeErrorModal';
import { OverviewTabType } from '../OrdersOverviewComponents/OrdersOverviewTabs/OverviewTab';
import { getOrderPath } from '../utils/orderPathsGenerator';
import { useFeatureFlags } from '../../context/useFeatureFlags';

export const FailedRequestModal = () => {
    const {featureFlags} = useFeatureFlags();
    const {
        failedRequestModalMetadata,
        putFailedRequestModalMetadata,
        revertOfflineOrderToDraftStage,
    } = useSyncStatus();

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState(false);
    const [isOfflineOrderModalOpen, setIsOfflineOrderModalOpen] =
        useState(false);

    const [processing, setProcessing] = useState(false);

    const localId = failedRequestModalMetadata?.orderToDisplay?.localOrderId;
    // todo: here
    const handleRevertToDraft = async () => {
        if (localId) {
            await revertOfflineOrderToDraftStage(localId);
            putFailedRequestModalMetadata();
        }
    };

    const { push } = useRouter();

    const retryRequest = async () => {
        setProcessing(true);
        const response = await window.workbox.messageSW({
            command: swMessages.retryFailedRequestByLocalId,
            payload: localId,
        });
        try {
            const parsedResponse = JSON.parse(response);

            if (isParsedResponse(parsedResponse)) {
                const { status } = parsedResponse;

                switch (status) {
                    case 200: {
                        if (failedRequestModalMetadata?.orderToDisplay) {
                            await resetEditableOrderById(
                                failedRequestModalMetadata.orderToDisplay
                                    .localOrderId,
                            );
                        }
                        push(`/order?tab=${OverviewTabType.history}`);
                        putFailedRequestModalMetadata(undefined);
                        break;
                    }
                    case 502: {
                        setIsOfflineOrderModalOpen(true);
                        putFailedRequestModalMetadata();
                        break;
                    }
                }
            }
        } finally {
            setProcessing(false);
        }
    };

    const getModalContent = () => {
        if (
            failedRequestModalMetadata?.orderToDisplay &&
            !failedRequestModalMetadata.isUnavailableItemModal
        ) {
            const { type } = failedRequestModalMetadata.orderToDisplay;
            return {
                title: `${getOrderTypeName(type)} order`,
                type: 'order',
                confirmationModal: {
                    title: 'Convert to draft',
                    subtitle:
                        'If you cancel sending this order, it will be converted back to a draft, and you will have to re-submit it.',
                    confirmBtnText: 'Convert',
                    onAbort: () => {
                        setIsConfirmationModalOpen(false);
                    },
                    onConfirm: async () => {
                        await handleRevertToDraft();
                    },
                },
            };
        }

        // todo: handle claims scenario
        if (failedRequestModalMetadata?.claimDetails) {
            return {
                title: 'claim',
                type: 'claim',
                confirmationModal: {
                    title: 'Delete claim',
                    subtitle: featureFlags?.hideName
                        ? 'If you cancel sending this claim, it will be deleted. If you still wish to send it, you will have to re-create it.'
                        : 'If you cancel sending this claim, it will be deleted. If you still wish to send it to Garrets, you will have to re-create it.',
                    confirmBtnText: 'Delete',
                    onAbort: () => {
                        setIsConfirmationModalOpen(false);
                    },
                    onConfirm: () => {
                        alert('not implemented');
                    },
                },
            };
        }

        return {
            title: '',
            confirmationModal: {
                title: '',
                subtitle: '',
                confirmBtnText: '',
                onAbort: () => {
                    alert('not implemented');
                },
                onConfirm: () => {
                    alert('not implemented');
                },
            },
        };
    };

    const closeUnavailableItemModal = async () => {
        let redirectTo = '';
        if (failedRequestModalMetadata?.orderToDisplay) {
            redirectTo = getOrderPath(
                failedRequestModalMetadata?.orderToDisplay,
            );
        }
        putFailedRequestModalMetadata();
        if (redirectTo) {
            push(redirectTo);
        }
    };

    const handleOnModalClose = () => {
        if (!failedRequestModalMetadata?.isUnavailableItemModal) {
            setIsConfirmationModalOpen(true);
        } else {
            closeUnavailableItemModal();
        }
    };

    const { title, confirmationModal, type } = getModalContent();

    useEffect(() => {
        if (failedRequestModalMetadata === null) {
            setIsConfirmationModalOpen(false);
        }
    }, [failedRequestModalMetadata]);

    if (isOfflineOrderModalOpen) {
        return (
            <ApiDowntimeErrorModal
                isOpen={isOfflineOrderModalOpen}
                itemType={ItemType.order}
                onClose={() => setIsOfflineOrderModalOpen(false)}
                onConfirm={() => setIsOfflineOrderModalOpen(false)}
            />
        );
    }
    return (
        <Modal
            isOpen={Boolean(failedRequestModalMetadata)}
            noHeader
            onClose={handleOnModalClose}
        >
            <SmallConfirmationModal
                isOpen={isConfirmationModalOpen}
                onClose={() => {
                    setIsConfirmationModalOpen(false);
                }}
                cancelBtnText="Go Back"
                confirmBtnText={confirmationModal.confirmBtnText}
                title={confirmationModal.title}
                subtitle={confirmationModal.subtitle}
                onAbort={confirmationModal.onAbort}
                onConfirm={confirmationModal.onConfirm}
            />
            <ModalHeader title="Error" subTitle={title} />
            <div className={`${modalStyles.modalContent} ${styles.container}`}>
                <div className={styles.statusBadgeContainer}>
                    <StatusBadge
                        backgroundColor="#ffe7e7"
                        color="#FF5A5A"
                        withPadding
                    >
                        FAILED TO SEND
                    </StatusBadge>
                </div>

                <GarretsContact
                    customMessage={
                        failedRequestModalMetadata?.unavailableItemModalMessage
                            ? failedRequestModalMetadata.unavailableItemModalMessage
                            : `Something went wrong when we tried to send your ${type} order. If the issue persists, please call your ${
                                featureFlags?.hideName ? 'regular' : 'Garrets'
                            } contact: `
                    }
                />
            </div>

            <div className={modalStyles.squareActionButton}>
                {!failedRequestModalMetadata?.unavailableItemButtonText && (
                    <div className={modalStyles.squareActionButtonChild}>
                        <Button
                            text="Cancel"
                            onClick={() => {
                                setIsConfirmationModalOpen(true);
                            }}
                            secondary
                        />
                    </div>
                )}

                <div className={modalStyles.squareActionButtonChild}>
                    {failedRequestModalMetadata?.unavailableItemButtonText ? (
                        <Button
                            text={
                                failedRequestModalMetadata?.unavailableItemButtonText ??
                                'Confirm'
                            }
                            onClick={handleOnModalClose}
                            primary
                        />
                    ) : (
                        <Button
                            text={processing ? 'Sending' : 'Try again'}
                            onClick={retryRequest}
                            primary
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};
