/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Created
 *
 * 1 = Submitted
 *
 * 2 = Cancelled
 */
export enum CashPurchaseStatus {
    Created = 0,
    Submitted = 1,
    Cancelled = 2,
}