/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Breakfast
 *
 * 1 = Lunch
 *
 * 2 = Dinner
 *
 * 3 = NightMeal
 *
 * 99 = LineComment
 */
export enum ManningMealType {
    Breakfast = 0,
    Lunch = 1,
    Dinner = 2,
    NightMeal = 3,
    LineComment = 99,
}