import { datadogRum } from '@datadog/browser-rum';

const allowedEnvs = [
    'gateway-app.garrets.com',
    'gateway-app-qa.garrets.com',
    'gateway-app-test.garrets.com',
    'gateway-app-beta.garrets.com',
];

export const initDatadog = () => {
    // Only run Datadog client-side
    if (typeof window === 'undefined') {
        return;
    }

    // Only run Datadog in production-ish environments
    const hostname = window?.location?.hostname;
    const isAllowedEnv = allowedEnvs.includes(hostname);
    if (!isAllowedEnv) {
        return;
    }

    datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DD_RUM_APPID!,
        clientToken: process.env.NEXT_PUBLIC_DD_RUM_TOKEN!,
        site: 'datadoghq.eu',
        service: 'gateway',
        env: window?.location?.hostname,
        version: process.env.NEXT_PUBLIC_REACT_APP_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
};
