import { LineItem } from '../../types/order.types';

type MathMethodType = keyof Pick<typeof Math, 'round' | 'floor' | 'ceil'>;

export const roundToNearestPackSize = (
    value: number,
    packSize: number,
    method: MathMethodType = 'round',
    decimalPrecison = 2,
): number => {
    if (value > 0 && value < packSize) {
        return packSize;
    }

    return Number(
        (Math[method](value / packSize) * packSize).toFixed(decimalPrecison),
    );
};

export const adjustLineItemValueToPackSizeAndUnit = (
    value: number,
    packSize?: number,
    unitOfMeasure?: string,
    method: MathMethodType = 'round',
): number => {
    if (!packSize && !unitOfMeasure) {
        return Number(value.toFixed(2));
    }

    if (!packSize) {
        const unitOfMeasureLowercase = unitOfMeasure?.toLowerCase();
        if (
            unitOfMeasureLowercase !== 'kg' &&
            unitOfMeasureLowercase !== 'ltr'
        ) {
            return Math.trunc(value);
        }

        return Number(value.toFixed(2));
    }

    return roundToNearestPackSize(value, packSize, method);
};

export const adjustLineItemQuantitiesFromWristIntegrationToRules = (
    lineItems: LineItem[],
): LineItem[] =>
    lineItems.map((lineItem) => {
        return {
            ...lineItem,
            quantity: adjustLineItemValueToPackSizeAndUnit(
                lineItem.quantity,
                lineItem.confirmedPackSize ?? lineItem.estimatedPackSize,
                lineItem.unitOfMeasure,
                'floor',
            ),
        };
    });

export const getLineItemsFilteredByAssortment = (
    lineItems: LineItem[],
    assortment: string[],
): LineItem[] => {
    return lineItems.filter((lineItem) =>
        assortment.includes(lineItem.itemNumber),
    );
};
