import { Order, OrderToDisplay } from '../../types/order.types';
import { db } from '../../db/db';
import { getLatestRfq } from '../../db/utils/getLatestRfq';
import { v4 } from 'uuid';

export const adjustOrderToDisplay = (order: Order): OrderToDisplay => ({
    localOrderId: v4(),
    orderId: order.orderId,
    type: order.type,
    status: order.status,
    receival: order.receival,
    rfq: getLatestRfq(order) ?? db.createInitialRfq(db.defaults.currency),
    lastModified: order.lastModified,
    isSsPoNumberAvailableForOrder: order.isSsPoNumberAvailableForOrder,
});
