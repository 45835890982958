export const downloadFile = (
    fileName: string,
    data: string | Blob,
    fileOutputExtension: 'json' | 'csv',
) => {
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    const downloadData =
        data instanceof Blob
            ? data
            : new Blob([data], { type: 'text/csv;charset=utf-8' });

    link.setAttribute('href', URL.createObjectURL(downloadData));
    link.setAttribute('download', `${fileName}.${fileOutputExtension}`);
    link.click();
    link.parentNode?.removeChild(link);
};
