export enum ItemIssuePreparationStep {
    searchProducts = 'searchProducts',
    selectReason = 'selectReason',
    selectExpirationReason = 'selectExpirationReason',
    selectOrder = 'selectOrder',
    setAffectedAmount = 'setAffectedAmount',
    addCommentAndPhotos = 'addCommentAndPhotos',
    summary = 'summary',
    whatProductDidYouOrder = 'whatProductDidYouOrder',
}
