import { useFeatureFlags } from "../context/useFeatureFlags";

export const useCashPurchaseReasonsStrings = () => {
    const { featureFlags } = useFeatureFlags();

    return {
        EmergencyForShortStay: 'Emergency cash purchase for short stay',
        NoGarretsSupplier: featureFlags?.hideName ? 'No supplier available at port' : 'No Garrets Supplier available at port',
        MissingItems: featureFlags?.hideName ? 'Missing items from recent delivery by supplier' : 'Missing items from recent delivery by Garrets supplier',
        SanctionCountry: 'Sanction Country',
        GarretsRecommendation: featureFlags?.hideName ? 'Recommended to make local cash purchase' : 'Garrets recommendation to make local cash purchase',
        FreshTopUp: 'Fresh top-up',
    };
};