import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import styles from './List.module.css';

interface LoadingStateProps {}

const LoadingRow = () => {
    return (
        <span role="row" className={[styles.row, styles.loading].join(' ')}>
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
        </span>
    );
};

export const LoadingState: React.FC<LoadingStateProps> = () => {
    return (
        <>
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
        </>
    );
};
