import { UseComboboxStateChange } from 'downshift';
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import Autocomplete from '../../Input/Autocomplete';
import Input from '../../Input/Input';
import styles from './OrderDetailsStepContent.module.css';
import orderPreparationModalStyles from '../OrderPreparationModal.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import { db } from '../../../db/db';
import useOrderType from '../../../hooks/useOrderType';
import moment, { isDate } from 'moment';
import {
    calculateProductAmountRatio,
    fetchAndUpdateProducts,
} from '../../../db/products';
import parsePort from '../../../db/utils/ports';
import {
    recalculateAmountsInEditableOrderByRatio,
    updateEditableOrder,
} from '../../../db/editableOrders';
import { OrderStatus } from '../../../apiClient/generated';
import { OrderTypes } from '../../../context/OrderTypes';
import { OrderPreparationModalDisplayData } from '../OrderPreparationModal';
import { usePorts } from '../../../hooks/usePorts';
import { MissingDeliveryPortHelper } from '../../MissingDeliveryPortHelper/MissingDeliveryPortHelper';
import Button from '../../Button/Button';
import { useEditableOrder } from '../../../hooks/useEditableOrder';
import { useOrder } from '../../../hooks/useOrder';
import { getLatestRfq } from '../../../db/utils/getLatestRfq';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';

interface Props {
    onAction: () => void;
    actionName: string;
    orderTypeSelectedInModal?: OrderTypes;
    shouldRecalculateAmounts?: boolean;
    handleCreateOrder?: () => Promise<string | undefined>;
    isOrderPreparationFlow?: boolean;
    editOrderDisplayData: OrderPreparationModalDisplayData;
    setEditOrderDisplayData: Dispatch<
        SetStateAction<OrderPreparationModalDisplayData>
    >;
    onBack?: () => void;
    orderIdToEdit?: string;
}

const OrderDetailsStepContent: React.FC<Props> = ({
    onAction,
    actionName,
    orderTypeSelectedInModal,
    handleCreateOrder,
    shouldRecalculateAmounts = false,
    isOrderPreparationFlow = false,
    editOrderDisplayData,
    setEditOrderDisplayData,
    onBack,
    orderIdToEdit,
}) => {
    let orderType = orderTypeSelectedInModal;
    const { activeOrderType } = useOrderType();
    if (!orderTypeSelectedInModal) {
        orderType = activeOrderType;
    }

    const { data: editableOrder } = useEditableOrder(orderIdToEdit);

    const [orderDetailsUpdated, setOrderDetailsUpdated] = useState(false);

    useEffect(() => {
        if (
            editableOrder &&
            editableOrder.rfq &&
            orderIdToEdit &&
            !orderDetailsUpdated
        ) {
            setEditOrderDisplayData({
                coveringDays: editableOrder.rfq.coveringDays,
                deliveryDate: editableOrder.rfq.deliveryDate,
                deliveryPort: editableOrder.rfq.deliveryPort,
                manning: editableOrder.rfq.manning,
            });
            setOrderDetailsUpdated(true);
        }
    }, [editableOrder, editableOrder?.type]);

    const [errors, setErrors] = useState({
        deliveryPortIsEmpty: false,
        deliveryPortDoesntMatch: false,
        manningIsNull: false,
        coveringDaysIsNull: false,
        deliveryDateIsEmpty: false,
        deliveryDateIsTooEarly: false,
    });

    const { data: rawOrder } = useOrder(editableOrder?.orderId);

    const pastDeliveryDate =
        rawOrder &&
        moment().isAfter(moment(getLatestRfq(rawOrder)?.deliveryDate), 'day');

    const deliveryDateDisabled =
        editableOrder?.status === OrderStatus.OrderForReview &&
        !pastDeliveryDate;

    const validate = () => {
        const deliveryPortIsEmpty =
            !editOrderDisplayData.deliveryPort?.portName ||
            editOrderDisplayData.deliveryPort?.portName === '';

        const manningIsNull = (editOrderDisplayData.manning ?? 0) <= 0;

        const coveringDaysIsNull =
            (editOrderDisplayData.coveringDays ?? 0) <= 0;

        const deliveryDateIsEmpty =
            !deliveryDateDisabled &&
            (!editOrderDisplayData.deliveryDate ||
                !isDate(moment(editOrderDisplayData.deliveryDate).toDate()));

        const deliveryDateIsTooEarly =
            !deliveryDateDisabled &&
            Boolean(editOrderDisplayData.deliveryDate) &&
            isDate(moment(editOrderDisplayData.deliveryDate).toDate()) &&
            moment(moment(editOrderDisplayData.deliveryDate).toDate()).isBefore(
                moment().startOf('day'),
            );
        if (
            deliveryPortIsEmpty ||
            manningIsNull ||
            coveringDaysIsNull ||
            deliveryDateIsEmpty ||
            deliveryDateIsTooEarly
        ) {
            setErrors((prev) => ({
                ...prev,
                deliveryPortIsEmpty,
                manningIsNull,
                coveringDaysIsNull,
                deliveryDateIsEmpty,
                deliveryDateIsTooEarly,
            }));
            return false;
        }
        return true;
    };

    const [isLoading, setIsLoading] = useState(false);
    const handleConfirm = async () => {
        setIsLoading(true);
        if (validate()) {
            if (editableOrder?.rfq && shouldRecalculateAmounts) {
                const newManning =
                    editOrderDisplayData.manning ?? db.defaults.manning;
                const newCoveringDays =
                    editOrderDisplayData.coveringDays ??
                    db.defaults.coveringDays;

                await recalculateAmountsInEditableOrderByRatio(
                    editableOrder.type,
                    calculateProductAmountRatio(
                        editableOrder,
                        newManning,
                        newCoveringDays,
                    ),
                    false, // event is triggered in next lines
                );
            }
            if (handleCreateOrder) {
                await handleCreateOrder();
            }

            if (!isOrderPreparationFlow && editableOrder) {
                await updateEditableOrder(editableOrder, {
                    rfq: { ...editableOrder.rfq, ...editOrderDisplayData },
                });
            }

            onAction();
        }
        setIsLoading(false);
    };

    const ports = usePorts();

    const handleChangeDeliveryLocation = async ({
        selectedItem,
    }: UseComboboxStateChange<string>) => {
        const selectedPortDetails = ports?.find(
            (port) => port.portNameWithCodeAndCountry === selectedItem,
        );

        if (selectedItem && selectedPortDetails && orderType) {
            fetchAndUpdateProducts();

            setEditOrderDisplayData((prev) => ({
                ...prev,
                deliveryPort: selectedPortDetails,
            }));

            setErrors((prev) => ({
                ...prev,
                deliveryPortIsEmpty: false,
                deliveryPortDoesntMatch: false,
            }));
        }
    };

    const handleChangeDeliveryDateTime = async (deliveryDate: Date | null) => {
        if (!deliveryDate) {
            return;
        }

        setEditOrderDisplayData((prev) => ({
            ...prev,
            deliveryDate,
        }));

        setErrors((prev) => ({
            ...prev,
            deliveryDateIsEmpty: false,
            deliveryDateIsTooEarly: false,
        }));
    };

    const handleChangeNumberInput = async (
        e: ChangeEvent<HTMLInputElement>,
    ) => {
        const { value, name } = e.target;

        const newValue: number = parseInt(value);

        setEditOrderDisplayData((prev) => ({
            ...prev,
            [name]: newValue,
        }));
    };

    const onDeliveryPortInputClear = () => {
        setEditOrderDisplayData((prev) => ({
            ...prev,
            deliveryPort: { portCode: '', portName: '' },
        }));

        setErrors((prev) => ({ ...prev, deliveryPortDoesntMatch: true }));
    };

    const { portNameWithCodeAndCountry } = parsePort(
        editOrderDisplayData?.deliveryPort?.portName ?? '',
        editOrderDisplayData?.deliveryPort?.portCode ?? '',
    );

    const isButtonActive = Boolean(
        editOrderDisplayData.deliveryPort &&
            editOrderDisplayData.deliveryDate &&
            portNameWithCodeAndCountry &&
            !isLoading,
    );
    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className={orderPreparationModalStyles.contentContainer}>
                    <div>
                        <Autocomplete
                            data-testid="orderDetailsDeliveryPortInput"
                            helper={
                                editableOrder?.status !==
                                OrderStatus.OrderForReview ? (
                                    <MissingDeliveryPortHelper />
                                ) : (
                                    <></>
                                )
                            }
                            strongLabel
                            label="Delivery port"
                            placeholder="Type to search delivery port"
                            items={
                                ports?.map(
                                    ({ portNameWithCodeAndCountry }) =>
                                        portNameWithCodeAndCountry,
                                ) || []
                            }
                            selectedItem={portNameWithCodeAndCountry}
                            handleSelectedItemChange={
                                handleChangeDeliveryLocation
                            }
                            error={
                                errors.deliveryPortIsEmpty
                                    ? 'This field is required'
                                    : errors.deliveryPortDoesntMatch
                                    ? 'Select a delivery port from the list'
                                    : undefined
                            }
                            onInputClear={onDeliveryPortInputClear}
                            disabled={
                                editableOrder?.status ===
                                OrderStatus.OrderForReview
                            }
                        />

                        <div className={styles.inputContainer}>
                            <CustomDatePicker
                                selectedDate={editOrderDisplayData.deliveryDate}
                                setSelectedDate={handleChangeDeliveryDateTime}
                                label={
                                    orderType === OrderTypes.cash
                                        ? 'Delivery date'
                                        : 'Estimated delivery date'
                                }
                                error={
                                    errors?.deliveryDateIsEmpty
                                        ? 'Estimated delivery date is required'
                                        : errors?.deliveryDateIsTooEarly
                                        ? 'Estimated delivery date is too early'
                                        : undefined
                                }
                                strongLabel
                                disabled={deliveryDateDisabled}
                                futureDates
                            />
                        </div>
                        {orderType === OrderTypes.provision && (
                            <div className={styles.inputsInRowContainer}>
                                <div className={styles.inputContainer}>
                                    <Input
                                        label="Manning"
                                        type="number"
                                        placeholder="-"
                                        name="manning"
                                        value={editOrderDisplayData.manning}
                                        onChange={(e) => {
                                            handleChangeNumberInput(e);
                                            setErrors((prev) => ({
                                                ...prev,
                                                manningIsNull: false,
                                            }));
                                        }}
                                        strongLabel
                                        min={1}
                                        error={
                                            errors.manningIsNull
                                                ? 'Manning size needs to be greater than 0'
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <Input
                                        type="number"
                                        label="Covering days"
                                        placeholder="-"
                                        name="coveringDays"
                                        value={
                                            editOrderDisplayData.coveringDays
                                        }
                                        onChange={(e) => {
                                            handleChangeNumberInput(e);
                                            setErrors((prev) => ({
                                                ...prev,
                                                coveringDaysIsNull: false,
                                            }));
                                        }}
                                        strongLabel
                                        min={1}
                                        error={
                                            errors.coveringDaysIsNull
                                                ? 'Covering days needs to be greater than 0'
                                                : undefined
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className={modalStyles.squareActionButton}>
                {onBack && (
                    <div className={modalStyles.squareActionButtonChild}>
                        <Button text="Back" onClick={onBack} secondary />
                    </div>
                )}
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={actionName}
                        onClick={handleConfirm}
                        primary={isButtonActive}
                        disabled={!isButtonActive}
                    />
                </div>
            </div>
        </>
    );
};

export default OrderDetailsStepContent;
