import { CategoryDetailsLevel3 } from './FiltersModal';
import styles from './FiltersModal.module.css';

interface FilterButtonProps {
    categoryDetails: CategoryDetailsLevel3;
    setFilters: React.Dispatch<React.SetStateAction<number[]>>;
    filters: number[];
}

const FilterButton: React.FC<FilterButtonProps> = ({
    categoryDetails,
    setFilters,
    filters,
}) => {
    const isFilterActive = () => filters.indexOf(categoryDetails.id) >= 0;

    const handleToggleFilter = () => {
        setFilters((prev) => {
            if (isFilterActive()) {
                return prev.filter((filter) => filter !== categoryDetails.id);
            }

            return [...prev, categoryDetails.id];
        });
    };

    return (
        <button
            className={[
                styles.filterCategoryToggleButton,
                isFilterActive() ? styles.active : '',
            ].join(' ')}
            key={categoryDetails.id}
            onClick={handleToggleFilter}
        >
            {categoryDetails.name}
        </button>
    );
};

export default FilterButton;
