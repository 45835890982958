import { useEffect, useState } from 'react';
import { useOrder } from './useOrder';
import useOrderType from './useOrderType';
import { useEditableOrder } from './useEditableOrder';

/**
 * This hook is used in situation where we have localOrderId in query but we need data from orders table in db.
 */
export const useOrderByLocalOrderIdFromQuery = () => {
    const { orderIdFromQuery } = useOrderType();
    const { data: editableOrder } = useEditableOrder(orderIdFromQuery);

    const getOrderId = () => {
        if (!orderIdFromQuery) {
            return;
        }

        if (!editableOrder) {
            return;
        }

        return editableOrder.orderId;
    };

    const [orderId, setOrderId] = useState<number | undefined>(undefined);

    useEffect(() => {
        const orderId = getOrderId();
        if (orderId) {
            setOrderId(orderId);
        }
    }, [editableOrder]);

    const { data: order, isOrderValidating } = useOrder(orderId);

    return {
        order,
        isOrderValidating: isOrderValidating,
    };
};
