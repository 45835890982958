import styles from './DropArea.module.css';

interface DropAreaProps {
    captionText: string;
    icon?: React.ReactElement;
}

export const DropArea: React.FC<DropAreaProps> = ({ captionText, icon }) => {
    return (
        <div className={styles.dropExplainer}>
            {icon}
            <p>
                <strong>{captionText}</strong>
                or drag and drop it here
            </p>
        </div>
    );
};
