import styles from './RadioInputsList.module.css';
import React from 'react';

export interface RadioInputElement {
    id: number;
    stringToDisplay: string;
}

interface Props {
    displayData: RadioInputElement[];
    handleChangeValue: (value: number) => void;
    defaultChecked?: number;
}

const RadioInputsList: React.FC<Props> = ({
    displayData,
    handleChangeValue,
    defaultChecked,
}) => {
    return (
        <div className={styles.listContainer}>
            {displayData.flatMap((field) => {
                return (
                    <div key={field.id}>
                        <label>
                            <input
                                value={field.id}
                                onChange={(e) => {
                                    handleChangeValue(Number(e.target.value));
                                }}
                                defaultChecked={field.id === defaultChecked}
                                name="radioInput"
                                type="radio"
                            />
                            <span className={styles.listItem}>
                                {field.stringToDisplay}
                            </span>
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default RadioInputsList;
