import { useEffect, useState } from 'react';

export const useStickyTopbar = () => {
    const [isPageScrolled, setIsPageScrolled] = useState(false);

    const handleStickyTopbar = () => {
        const scrollYPosition = window.scrollY;
        const isSummaryProductsPage =
            window.location.href.includes('summary/products');

        if (
            (!isSummaryProductsPage && scrollYPosition > 25) ||
            (isSummaryProductsPage && scrollYPosition > 300)
        ) {
            setIsPageScrolled(true);
        } else {
            setIsPageScrolled(false);
        }
    };

    useEffect(() => {
        window?.addEventListener('scroll', handleStickyTopbar, true);
        return () => {
            window?.removeEventListener('scroll', handleStickyTopbar, true);
        };
    }, []);

    return { isPageScrolled };
};
