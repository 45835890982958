import { CreateReceivalCommand } from './../../apiClient/generated/models/CreateReceivalCommand';
import { IncomingOrderDto } from '../../apiClient/generated/models/IncomingOrderDto';
import { IncomingItemIssueDto } from '../../apiClient/generated/models/IncomingItemIssueDto';
import { ImageRequest, ParsedResponse } from './types';
import {
    CreateCashPurchase,
    CreateCondemnReportDto,
    ManningReportDto,
    StocktakingDto,
} from '../../apiClient/generated';

export const isIncomingOrderDto = (data: unknown): data is IncomingOrderDto => {
    return (
        (data as IncomingOrderDto).agent !== undefined &&
        (data as IncomingOrderDto).type !== undefined &&
        (data as IncomingOrderDto).coveringDays !== undefined &&
        (data as IncomingOrderDto).manning !== undefined &&
        (data as IncomingOrderDto).deliveryPort !== undefined &&
        (data as IncomingOrderDto).contractCurrency !== undefined &&
        (data as IncomingOrderDto).lineItems !== undefined &&
        (data as IncomingOrderDto).deliveryDate !== undefined &&
        (data as IncomingOrderDto).localId !== undefined
    );
};

export const isCondemnReportDto = (
    data: unknown,
): data is CreateCondemnReportDto => {
    const condemnItems = (data as CreateCondemnReportDto).items;

    return (
        condemnItems !== undefined &&
        condemnItems.length > 0 &&
        (data as CreateCondemnReportDto).reportYearAndMonth != undefined &&
        condemnItems[0].reason !== undefined &&
        condemnItems[0].quantity !== undefined &&
        condemnItems[0].itemNumber !== undefined
    );
};

export const isIncomingItemIssueDto = (
    data: unknown,
): data is IncomingItemIssueDto => {
    return (
        (data as IncomingItemIssueDto).productItemNumber !== undefined &&
        (data as IncomingItemIssueDto).itemIssueType !== undefined &&
        (data as IncomingItemIssueDto).itemIssueProblem !== undefined &&
        (data as IncomingItemIssueDto).affectedAmount !== undefined &&
        (data as IncomingItemIssueDto).numberOfPhotos !== undefined &&
        (data as IncomingItemIssueDto).clientId !== undefined
    );
};

export const isReceival = (data: unknown): data is CreateReceivalCommand => {
    if (!(data as CreateReceivalCommand).receival) {
        return false;
    }

    return (
        (data as CreateReceivalCommand).receival.orderId !== undefined &&
        (data as CreateReceivalCommand).receival.deliveryDate !== undefined &&
        (data as CreateReceivalCommand).receival.items !== undefined &&
        (data as CreateReceivalCommand).localId !== undefined
    );
};

export const isImageRequest = (data: unknown): data is ImageRequest => {
    return (
        (data as ImageRequest).type === 'image' &&
        (data as ImageRequest).localId !== undefined
    );
};

export type ClearNotificationDto = number[];

export const isClearNotificationDto = (
    data: unknown,
): data is ClearNotificationDto => {
    return (
        Array.isArray(data) &&
        data.every((element) => {
            return typeof element === 'number';
        })
    );
};

export const isParsedResponse = (
    response: unknown,
): response is ParsedResponse => {
    return (
        typeof (response as ParsedResponse).status !== 'undefined' &&
        typeof (response as ParsedResponse).statusText !== 'undefined'
    );
};

export const isManningReportDto = (data: unknown): data is ManningReportDto => {
    return (
        (data as ManningReportDto).captain !== undefined &&
        (data as ManningReportDto).chiefCook !== undefined &&
        (data as ManningReportDto).days !== undefined &&
        (data as ManningReportDto).headerComment !== undefined &&
        (data as ManningReportDto).reportMonth !== undefined &&
        (data as ManningReportDto).localId !== undefined
    );
};

export const isStocktakingReportDto = (
    data: unknown,
): data is StocktakingDto => {
    return (
        (data as StocktakingDto).reportMonth !== undefined &&
        (data as StocktakingDto).submitDate !== undefined &&
        (data as StocktakingDto).items !== undefined &&
        (data as StocktakingDto).localId !== undefined
    );
};

export const isCreateCashPurchaseDto = (
    data: unknown,
): data is CreateCashPurchase => {
    return (
        (data as CreateCashPurchase).deliveryDate !== undefined &&
        (data as CreateCashPurchase).remarks !== undefined &&
        (data as CreateCashPurchase).currency !== undefined &&
        (data as CreateCashPurchase).deliveryPort !== undefined &&
        (data as CreateCashPurchase).cashDiscount !== undefined &&
        (data as CreateCashPurchase).reason !== undefined &&
        (data as CreateCashPurchase).items !== undefined &&
        (data as CreateCashPurchase).localId !== undefined
    );
};
