import { GenerateDexieClass } from '../../db/db';

export const importIdbContents = async (db: GenerateDexieClass, file: File) => {
    //Dynamic importing due to the lib lack of compatibility with next ssr
    const dbExportModule = await import('dexie-export-import');
    await dbExportModule.importInto(db, file, {
        clearTablesBeforeImport: true,
        acceptChangedPrimaryKey: true,
        overwriteValues: true,
        progressCallback: (progress) => {
            console.log(
                `Completed rows:${progress.completedRows}, Completed tables:${progress.completedTables}`,
            );
            if (progress.done) {
                console.log('Import successful!');
            }
            return true;
        },
    });
};
