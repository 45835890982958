import { NumberFormatter } from '../utils/numberFormat';
import styles from './BudgetFigure.module.css';
import { ReactElement } from 'react';

const BudgetFigure: React.FC<{
    figure: number | string | ReactElement;
    caption: string | ReactElement;
    formatter?:
        | NumberFormatter
        | ((figure: number | string) => string | React.ReactElement);
    fadedCaption?: boolean;
    inverted?: boolean;
    white?: boolean;
}> = ({
    figure,
    caption,
    formatter = (f: number | string) => `${f}`,
    white,
    fadedCaption,
    inverted,
}) => {
    return (
        <figure
            className={[
                styles.figure,
                inverted ? styles.inverted : '',
                white ? styles.white : '',
            ].join(' ')}
        >
            <figcaption
                className={[
                    styles.figcaption,
                    fadedCaption ? styles.faded : '',
                ].join(' ')}
            >
                {caption}
            </figcaption>
            {typeof figure === 'string' || typeof figure === 'number'
                ? formatter(figure)
                : figure}
        </figure>
    );
};

export default BudgetFigure;
