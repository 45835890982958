import { GenerateDexieClass } from '../db';

export const onUpgradeTo18 = async (db: GenerateDexieClass) => {
    enum OrderTypes {
        provision = 'Provision',
        bonded = 'Bonded',
        nonFood = 'Nonfood',
    }

    type Agent = {
        name: string;
        city: string;
        phone: string;
        email: string;
    };

    type OrderPort = {
        portName?: string | null;
        portCode?: string | null;
    };

    enum OrderRfqOrigin {
        App = 0,
        WristIntegration = 1,
    }

    enum OrderStatus {
        Blank = 0,
        Created = 1,
        RfqSent = 2,
        OrderForReview = 3,
        FinalOrder = 4,
        OrderForConfirmation = 5,
        ReceiptInProgress = 6,
        Receipt = 7,
        CancelledByVessel = 8,
        CancelledByGarrets = 9,
    }

    type SupplierInfo = {
        name?: string | null;
        city?: string | null;
        phone?: string | null;
        email?: string | null;
    };

    interface RFQ {
        id?: number;
        wbPono?: string;
        coveringDays: number;
        contractCurrency?: string;
        manning: number;
        comment?: string;
        lineItems: LineItem[];
        deliveryPort?: OrderPort;
        nextPort: OrderPort;
        deliveryDate: Date;
        departureDate: Date;
        agent?: Agent;
        supplier?: SupplierInfo;
        created?: Date;
        origin?: OrderRfqOrigin;
    }

    interface LineItem {
        id?: number;
        itemNumber: number;
        quantity: number;
        comment?: string;
        unitOfMeasure?: string;
        confirmedPackSize?: number;
        confirmedPrice?: number;
        estimatedPrice?: number;
        estimatedPackSize?: number;
    }

    interface Order {
        orderId: number;
        systemRfqId: string;
        vesselImo: number;
        type: OrderTypes;
        created: Date;
        rfqs: RFQ[];
        lastModified: Date;
        status: OrderStatus;
    }

    interface EditableOrder {
        localOrderId?: number;
        type: OrderTypes;
        orderId?: number;
        status: OrderStatus;
        rfq: RFQ;
    }

    const getLastComment = (order: Order | undefined, lineItem: LineItem) => {
        if (!order) {
            return undefined;
        }

        const commentsFromEachRfq = order.rfqs.map((rfq) => ({
            comment: rfq.lineItems.find(
                (item) => item.itemNumber === lineItem?.itemNumber,
            )?.comment,
            origin: rfq.origin,
            createdAt: rfq.created,
        }));

        const filledComments = commentsFromEachRfq.filter((item) =>
            Boolean(item.comment?.length),
        );

        const lastComment = filledComments[filledComments.length - 1];

        return lastComment;
    };

    const editableOrders = await db
        .table<EditableOrder>('editableOrders')
        .toArray();

    if (editableOrders?.length) {
        async () => {
            for (const editableOrder of editableOrders) {
                let order: Order | undefined;
                if (editableOrder?.orderId) {
                    order = await db
                        .table<Order>('orders')
                        .get(editableOrder.orderId);
                }

                const editedLineItems = editableOrder.rfq.lineItems.map(
                    (lineItem) => ({
                        ...lineItem,
                        lastCommentFromRfqFlow: getLastComment(order, lineItem),
                    }),
                );

                await db.table<EditableOrder>('editableOrders').put({
                    ...editableOrder,
                    rfq: {
                        ...editableOrder.rfq,
                        lineItems: editedLineItems,
                    },
                });
            }
        };
    }

    const offlineOrders = await db
        .table<EditableOrder>('offlineOrders')
        .toArray();

    if (offlineOrders?.length) {
        async () => {
            for (const offlineOrder of offlineOrders) {
                let order: Order | undefined;
                if (offlineOrder?.orderId) {
                    order = await db
                        .table<Order>('orders')
                        .get(offlineOrder.orderId);
                }

                const editedLineItems = offlineOrder.rfq.lineItems.map(
                    (lineItem) => ({
                        ...lineItem,
                        lastCommentFromRfqFlow: getLastComment(order, lineItem),
                    }),
                );

                await db.table<EditableOrder>('editableOrders').put({
                    ...offlineOrder,
                    rfq: {
                        ...offlineOrder.rfq,
                        lineItems: editedLineItems,
                    },
                });
            }
        };
    }
};
