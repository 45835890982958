import useSWR, { mutate } from 'swr';
import { db } from '../db/db';
import { dataFlowEventHub, SWRCacheKeys } from '../events/dataFlowEvents';
import { updateOrders } from '../db/orders';
import { apiClient } from '../apiClient/apiClient';
import { mapToOrderSummary } from '../apiClient/apiClientMappers';
import { Order, OrderToDisplay } from '../types/order.types';
import { OrderStatus } from '../apiClient/generated';
import { useSWRCachedResult } from './useSWRCachedResult';

dataFlowEventHub.on('ordersChanged', () =>
    mutate(SWRCacheKeys.orders, undefined, { revalidate: true }),
);

const fetchAndUpdateOrders = async () => {
    const response = await apiClient.getOrders();
    const mappedToOrderSummaries = response.map((item) =>
        mapToOrderSummary(item),
    );
    await updateOrders(mappedToOrderSummaries);
    return (await db.orders.toCollection().sortBy('lastModified')).reverse();
};

export const useOrders = () => {
    const { data: orders, isValidating: isValidatingOrders } = useSWR(
        SWRCacheKeys.orders,
        fetchAndUpdateOrders,
    );

    const data = useSWRCachedResult(orders);

    const isAnyOrderInAwaitingAction = Boolean(
        data?.find(isAwaitingActionOrder),
    );

    return {
        data,
        isValidatingOrders,
        isAnyOrderInAwaitingAction,
    };
};

const isOrderForReview = (order: Order) =>
    order.status === OrderStatus.OrderForReview;

export const isRedoReceivalOrder = (order: Order | OrderToDisplay) =>
    order.status === OrderStatus.ReceiptInProgress;

const isAwaitingActionOrder = (order: Order) =>
    isOrderForReview(order) || isRedoReceivalOrder(order);
