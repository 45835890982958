import useSWR, { mutate } from 'swr';
import {
    dataFlowEventHub,
    SWRCacheKeys,
    swrDefaultConfig,
} from '../events/dataFlowEvents';
import { getEditableOrderById } from '../db/editableOrders';
import { EditableOrder } from '../types/order.types';
import { db } from '../db/db';
import equal from 'fast-deep-equal';

dataFlowEventHub.on(
    'editableOrderChanged',
    ({ orderId, localOrderId, updatedOrder }) => {
        if (orderId) {
            mutate(`${SWRCacheKeys.editableOrder}-${orderId}`, updatedOrder, {
                revalidate: true,
            });
        }
        if (localOrderId) {
            mutate(
                `${SWRCacheKeys.editableOrder}-${localOrderId}`,
                updatedOrder,
                {
                    revalidate: true,
                },
            );
        }
    },
);

const fetchDbData = (localOrderId?: string) => {
    if (localOrderId === undefined) {
        return;
    }

    if (localOrderId) {
        return getEditableOrderById(localOrderId);
    }
};

export const useEditableOrder = (localOrderId?: string | number) => {
    /**
     * This is validation to make sure local orderId from parameter is in proper type.
     * In editable order we use only uuid localOrderId
     */
    const isInvalidOrderId = typeof localOrderId === 'number';
    const {
        data,
        isValidating: isEditableOrderValidating,
        mutate: mutateEditableOrder,
    } = useSWR(
        `${SWRCacheKeys.editableOrder}-${localOrderId}`,
        () => {
            if (isInvalidOrderId) {
                return undefined;
            }
            return fetchDbData(localOrderId);
        },
        swrDefaultConfig,
    );

    const revalidateEditableOrder = async (editableOrder: EditableOrder) => {
        await mutate(
            `${SWRCacheKeys.editableOrder}-${editableOrder.localOrderId}`,
        );
    };

    const optimisticUpdate = async (editableOrder: EditableOrder) => {
        mutateEditableOrder(editableOrder, { revalidate: false });
        db.editableOrders.put(editableOrder);
    };

    const changeEditableOrderItemStatus = (
        editableOrder: EditableOrder,
        isSending: boolean,
    ) => {
        optimisticUpdate({ ...editableOrder, isSending });
    };

    const isEditableOrderSavedAsDraftAlready =
        data?.savedRfq && data?.draftName;

    const saveDraft = async (
        editableOrder: EditableOrder,
        draftName?: string,
    ) => {
        await optimisticUpdate({
            ...editableOrder,
            savedRfq: editableOrder?.rfq,
            draftName: draftName ?? editableOrder.draftName,
        });
    };

    const removeUnsavedDraft = async (editableOrder: EditableOrder) => {
        if (!editableOrder) {
            return;
        }

        if (editableOrder.savedRfq) {
            await optimisticUpdate({
                ...editableOrder,
                rfq: editableOrder.savedRfq,
            });
        } else {
            await db.editableOrders
                .where('localOrderId')
                .equalsIgnoreCase(editableOrder.localOrderId)
                .delete();
        }

        await revalidateEditableOrder(editableOrder);
    };

    const hasChanges = !equal(data?.rfq, data?.savedRfq);

    return {
        data,
        isEditableOrderValidating,
        changeEditableOrderItemStatus,
        isEditableOrderSavedAsDraftAlready,
        saveDraft,
        removeUnsavedDraft,
        hasChanges,
        isLocalOrderIdValid: !isInvalidOrderId,
    };
};
