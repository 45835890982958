export enum MessageType {
    newRequestInOfflineQueue = 'newRequestInOfflineQueue',
    newRequestInOnlineQueue = 'newRequestInOnlineQueue',
    requestSent = 'requestSent',
    unavailableItemsErrorOnRequest = 'unavailableItemsErrorOnRequest',
    requestRemovedByUi = 'requestRemovedViaUiCommand',
    claimValidationFailed = 'claimValidationFailed',
}

export interface WorkboxMessage {
    messageType: MessageType;
    offlineQueue?: Object[];
    onlineQueue?: Object[];
    body?: Object;
    localId?: string;
    type?: string;
}

/**
 * This method sends message to all clients (open tabs)
 */
export const emitEventToWindow = async (message: WorkboxMessage) => {
    // TODO: we should have better types for self.
    const clients = await (self as any).clients.matchAll({
        type: 'window',
    });

    const obj = JSON.parse(JSON.stringify(message));

    for (const client of clients) {
        client.postMessage(obj);
    }
};
