import styles from './OrderPortDetails.module.css';
import BondedIcon from '../../public/gfx/menuIcons/bondedBold.svg';
import ProvisionIcon from '../../public/gfx/menuIcons/provisionBold.svg';
import NonFoodIcon from '../../public/gfx/menuIcons/nonfoodBold.svg';
import CountryFlag from '../Flags/CountryFlag';
import { OrderPort } from '../../apiClient/generated';
import { OrderTypes } from '../../context/OrderTypes';

interface Props {
    deliveryPort: OrderPort | undefined;
    orderType: OrderTypes;
}

export const OrderPortDetails: React.FC<Props> = ({
    deliveryPort,
    orderType,
}) => {
    return (
        <div className={styles.labelContainer}>
            <div className={styles.icon}>
                {orderType === OrderTypes.provision && (
                    <ProvisionIcon width="13" height="14" stroke="#7A808D" />
                )}
                {orderType === OrderTypes.bonded && (
                    <BondedIcon width="13" height="14" stroke="#7A808D" />
                )}
                {orderType === OrderTypes.nonFood && (
                    <NonFoodIcon width="13" height="14" stroke="#7A808D" />
                )}
            </div>
            <div className={styles.flag}>
                <CountryFlag portCode={deliveryPort?.portCode} />
            </div>
            <p className={styles.portLabel}>
                {deliveryPort?.portName} {deliveryPort?.portCode}
            </p>
        </div>
    );
};
