import { BaseIndicator } from '../SyncSidebarIndicators/BaseIndicator/BaseIndicator';
import { ConnectionStatusIndicator } from '../SyncSidebarIndicators/ConnectionStatusIndicator/ConnectionStatusIndicator';
import styles from './SyncStatusPopup.module.css';
import PauseIcon from '../../public/gfx/uiIcons/pause.svg';
import PlusIcon from '../../public/gfx/uiIcons/add.svg';
import MinusIcon from '../../public/gfx/uiIcons/minus.svg';
import { useMemo, useState } from 'react';
import { RequestType, useSyncStatus } from '../../context/SyncStatusContext';

export const SyncStatusPopup = () => {
    const {
        queueStatus: { onlineQueue, offlineQueue },
        isOnline,
    } = useSyncStatus();

    const queue = useMemo(
        () => (isOnline ? onlineQueue : offlineQueue),
        [isOnline],
    );

    const [collapsed, setCollapsed] = useState(true);
    const toggleCollapsing = () => {
        setCollapsed((prev) => !prev);
    };

    return (
        <div className={styles.container}>
            <ConnectionStatusIndicator withText />
            {queue.length > 0 && (
                <div className={styles.indicatorsContainer}>
                    <span className={styles.spacer} />
                    <button
                        onClick={toggleCollapsing}
                        className={styles.toggleButton}
                    >
                        <BaseIndicator
                            pending={isOnline}
                            icon={isOnline ? undefined : PauseIcon}
                            toggleButton={collapsed ? PlusIcon : MinusIcon}
                            text={`${queue.length} items ${
                                isOnline ? 'sending' : 'waiting'
                            }`}
                            withMarginBottom
                        />
                    </button>
                    {!collapsed &&
                        queue.map((item, index) => {
                            const getLabel = () => {
                                switch (item.requestType) {
                                    case RequestType.provisionOrder:
                                        return 'Provision order';
                                    case RequestType.bondedOrder:
                                        return 'Bonded order';
                                    case RequestType.nonFoodOrder:
                                        return 'Non-food order';
                                    case RequestType.claim:
                                        return 'Claim';
                                    case RequestType.receival:
                                        return 'Receival';
                                    case RequestType.file:
                                        return 'Attachment';
                                    case RequestType.manning:
                                        return 'Manning report';
                                    case RequestType.stocktaking:
                                        return 'Stocktaking report';
                                    case RequestType.condemn:
                                        return 'Condemn';
                                    case RequestType.cashPurchase:
                                        return 'Cash purchase order';
                                    default:
                                        return 'Unhandled item';
                                }
                            };
                            return (
                                <BaseIndicator
                                    key={item.entryId}
                                    small
                                    text={getLabel()}
                                    icon={index === 0 ? PauseIcon : undefined}
                                    bold={index === 0}
                                />
                            );
                        })}
                </div>
            )}
        </div>
    );
};
