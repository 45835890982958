export enum ItemType {
    stocktaking = 'stocktaking',
    manning = 'manning',
}

type GetLocalIdOptions =
    | { itemType: ItemType.manning; monthAndYear: string }
    | { itemType: ItemType.stocktaking; reportMonth: string };

enum TypeId {
    manning,
    stocktaking,
}

/**
 * We need this function to make sure localIds are unique. (This is why we add typeId to each loclId)
 */
export const getLocalId = (options: GetLocalIdOptions) => {
    switch (options.itemType) {
        case ItemType.manning:
            return `${options.monthAndYear.replace('-', '')}${TypeId.manning}`;
        case ItemType.stocktaking:
            return `${options.reportMonth.replace('-', '')}${
                TypeId.stocktaking
            }`;
    }
};
