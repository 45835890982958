import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useVesselMetadata } from '../hooks/useVesselMetadata';

export const Tracking = () => {
    const { isAuthenticated, isLoading, user } = useAuth0();
    const router = useRouter();
    const [initialized, setInitialized] = useState(false);
    const [userRegistered, setUserRegistered] = useState(false);
    const { data: vesselMetadata } = useVesselMetadata();

    useEffect(() => {
        if (
            isLoading ||
            !isAuthenticated ||
            !user ||
            !process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ||
            initialized
        ) {
            return;
        }

        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
            debug: process.env.NODE_ENV !== 'production',
            api_host: '/mixpanel',
        });
        setInitialized(true);

        mixpanel.identify(user.sub);

        mixpanel.track(trackingEvents.appLoaded);
    }, [isLoading, isAuthenticated, user]);

    // This is a seperate effect to ensure basic tracking works
    // even if we never get the right user claims
    useEffect(() => {
        if (!initialized || !user || !vesselMetadata?.imoNo || userRegistered) {
            return;
        }

        // Updates user profile
        mixpanel.people.set({
            email: user.email,
            vesselIMO: vesselMetadata.imoNo,
            company: vesselMetadata.company,
            vesselName: vesselMetadata.vesselName,
        });

        // Registers metadata for all events
        mixpanel.register({
            vesselIMO: vesselMetadata.imoNo,
            company: vesselMetadata.company,
            vesselName: vesselMetadata.vesselName,
        });

        setUserRegistered(true);
    }, [user, initialized, vesselMetadata]);

    useEffect(() => {
        const handleRouteChange: (
            url: string,
            evt2: { shallow: boolean },
        ) => void = (url, { shallow }) => {
            if (!initialized) {
                return;
            }

            mixpanel.track(trackingEvents.routeChanged, {
                url,
                shallow,
            });
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [initialized]);

    return <></>;
};

export const trackingEvents = {
    routeChanged: 'Route changed',
    appLoaded: 'App loaded',
    remarkOrderAdded: 'Remark added to order',
    remarkItemAdded: 'Remark added to item',
    draftSent: 'Initial order placed',
};
