import { Portal } from '@radix-ui/react-portal';
import { useClaimsInReceivalFlow } from '../../context/ClaimsInReceivalFlowContext';
import Modal from '../Modal/Modal';
import { ReasonsList } from './steps/ReasonsList';
import { WhatIsTheIssue } from './steps/WhatIsTheIssue';
import { ClaimDescriptionAndPhotos } from './steps/ClaimDescriptionAndPhotos';
import { ClaimSummary } from './steps/ClaimSummary';
import { ClaimSummaryReadOnly } from './steps/ClaimSummaryReadOnly';

export const ClaimsInReceivalModal = () => {
    const {
        claimsInReceivalModalMetadata: { selectedStep, isOpen },
        closeClaimsInReceivalModal,
    } = useClaimsInReceivalFlow();

    return (
        <Portal>
            <Modal
                noHeader
                isOpen={isOpen}
                onClose={closeClaimsInReceivalModal}
            >
                {isOpen && (
                    <>
                        {selectedStep === 'whatIsTheIssue' && (
                            <WhatIsTheIssue />
                        )}
                        {selectedStep === 'reasonsList' && <ReasonsList />}
                        {selectedStep === 'issueDescriptionAndPhotos' && (
                            <ClaimDescriptionAndPhotos />
                        )}
                        {selectedStep === 'issueSummary' && <ClaimSummary />}
                        {selectedStep === 'issueSummaryReadOnly' && (
                            <ClaimSummaryReadOnly />
                        )}
                    </>
                )}
            </Modal>
        </Portal>
    );
};
