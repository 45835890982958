import styles from './SearchProductsBox.module.css';
import SearchButton from '../SearchBox/SearchButton';
import React, { FocusEventHandler, useRef, useState } from 'react';

interface Props {
    initialValue: string;
    onChange: (value: string) => void;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    isProductSelected?: boolean;
    isLoading?: boolean;
}

const SearchProductsBox: React.FC<Props> = ({
    initialValue,
    onChange,
    onBlur,
    onFocus,
    isProductSelected,
    isLoading,
}) => {
    const [localValue, setLocalValue] = useState(initialValue);

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div
            className={`${styles.container} ${
                isProductSelected ? styles.containerWhenButtonAppear : ''
            }`}
        >
            <SearchButton onClick={() => {}} />
            <input
                className={styles.input}
                placeholder={'Type here to search...'}
                value={localValue}
                onChange={(e) => {
                    setLocalValue(e.target.value);
                    onChange(e.target.value);
                }}
                ref={inputRef}
                onFocus={(e) => {
                    onFocus?.(e);
                }}
                onBlur={(e) => {
                    onBlur?.(e);
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        inputRef?.current?.blur();
                    }
                }}
            />
            <div
                className={`${styles.loading} ${
                    isLoading ? styles.visible : ''
                }`}
            ></div>
        </div>
    );
};

export default SearchProductsBox;
