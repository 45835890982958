import { IExtendedItemIssueDto } from '../db/overrideTypes';
import { apiClient } from '../apiClient/apiClient';
import { CancelablePromise } from '../apiClient/generated';

export const attachExtendedItemIssuePhoto = (
    clientId: string,
    formData?: {
        file: Blob;
        localId: string;
        filename?: string;
    },
): CancelablePromise<IExtendedItemIssueDto> => {
    return apiClient.httpRequest.request({
        method: 'POST',
        url: '/api/ItemIssue/{clientId}/photo',
        path: {
            clientId: clientId,
        },
        formData: formData,
        mediaType: 'multipart/form-data',
    });
};
