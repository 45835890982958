import { ItemStatus } from '../types/itemStatuses.types';
import {
    StocktakingItem,
    StocktakingImportDto,
} from '../types/stocktaking.types';
import { db } from './db';

export const getStocktakingReport = async (monthAndYear: string) => {
    return db.stocktakingReports
        .where('reportMonth')
        .equals(monthAndYear)
        .first();
};

export const sanitizeStocktakingReportItems = (
    maybeStocktakingReportItems: StocktakingImportDto[],
): StocktakingItem[] => {
    const numberOrZero = (input: string): number =>
        !isNaN(Number(input)) ? Number(input) : 0;

    return maybeStocktakingReportItems.map((item) => ({
        itemName: item.itemName,
        itemNumber: item.itemNumber ?? '',
        quantity: numberOrZero(item.quantity),
        unitPrice: numberOrZero(item.unitPrice),
        comment: item.comment ?? '',
        counted: item.counted?.toLowerCase() === 'true' ? true : false,
        expectedCount: numberOrZero(item.expectedCount),
        lastCount: numberOrZero(item.lastCount),
        lastMonthPurchaseCount: numberOrZero(item.lastMonthPurchaseCount),
        category: item.category,
        unitOfMeasure: item.unitOfMeasure,
    }));
};

export const deleteStocktakingReport = async (reportPeriod: string) => {
    return db.stocktakingReports
        .where('reportMonth')
        .equals(reportPeriod)
        .and((report) => report.state === ItemStatus.draft)
        .delete();
};
