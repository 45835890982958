import {
    ApiError,
    CancelablePromise,
    IncomingItemIssueDto,
} from '../../../apiClient/generated';
import { apiClient } from '../../../apiClient/apiClient';
import { getImagesFromDb } from '../../ClaimsOverviewComponents/helpers/dbHelpers';
import { attachExtendedItemIssuePhoto } from '../../../apiClientOverrides/createItemIssue';
import { dataFlowEventHub } from '../../../events/dataFlowEvents';

export enum ClaimSubmissionResult {
    success,
    apiOffline,
    toHighQuantity,
    productNotFound,
    orderNotFound,
    claimingProductDoesNotExist,
    portRequired,
    requestFailed,
}

export enum ClaimPhotosSubmissionResult {
    success,
    apiOffline,
    requestFailed,
}

type PromisedType<T> = T extends CancelablePromise<infer U> ? U : never;
//eslint-disable-next-line
const runPartialClaimRequest = <T extends CancelablePromise<any>>(
    claimRequest: () => T,
): Promise<PromisedType<T>> => {
    //eslint-disable-next-line
    return new Promise<PromisedType<T>>(async (resolve, reject) => {
        try {
            const result = await claimRequest();
            resolve(result);
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });
};

export const handleClaimValidationError = (statusText: string) => {
    if (statusText.includes('Claiming quantity exceeds the order')) {
        return ClaimSubmissionResult.toHighQuantity;
    } else if (statusText.includes('Product item not found')) {
        return ClaimSubmissionResult.productNotFound;
    } else if (statusText.includes('Order not found')) {
        return ClaimSubmissionResult.orderNotFound;
    } else if (
        statusText.includes('Claiming product does not exist on the order')
    ) {
        return ClaimSubmissionResult.claimingProductDoesNotExist;
    } else if (statusText.includes('Port is required for provided problem')) {
        return ClaimSubmissionResult.portRequired;
    } else return ClaimSubmissionResult.requestFailed;
};

export const submitClaimDataPart = async (
    parsedClaim: IncomingItemIssueDto,
): Promise<ClaimSubmissionResult> => {
    try {
        await runPartialClaimRequest(() =>
            apiClient.createItemIssue(parsedClaim),
        );
        return ClaimSubmissionResult.success;
    } catch (error) {
        if ((error as ApiError).status) {
            const { status, statusText } = error as ApiError;

            switch (status) {
                case 400: {
                    return handleClaimValidationError(statusText);
                }
                case 502: {
                    return ClaimSubmissionResult.apiOffline;
                }
                default:
                    return ClaimSubmissionResult.requestFailed;
            }
        } else return ClaimSubmissionResult.requestFailed;
    }
};

export const submitClaimPhotosPart = async (
    clientId: string,
    localId: string | undefined,
    claimsImages?: { image: File; id: string }[],
): Promise<ClaimPhotosSubmissionResult> => {
    let claimImages = claimsImages;

    if (claimsImages === undefined) {
        // images are passed only for claimsInReceivalFlow, otherwise we need to take them from indexedDB
        claimImages = await getImagesFromDb();
    }

    const photoPromises = [
        ...(claimImages?.map((item) =>
            runPartialClaimRequest(() =>
                attachExtendedItemIssuePhoto(clientId, {
                    file: item.image,
                    localId: clientId,
                    filename: item.image.name,
                }),
            ),
        ) ?? []),
    ];

    const listOfResponses = await Promise.allSettled(photoPromises);
    dataFlowEventHub.emit('claimItemSubmitted');

    const areRejectedPromisesIncluded = listOfResponses.some(
        (res) => (res.status = 'rejected'),
    );

    if (!localId || areRejectedPromisesIncluded) {
        const errors = listOfResponses.filter(
            (response): response is PromiseRejectedResult =>
                response.status === 'rejected',
        );
        const offlineErrorsFromSw = errors.filter((err) => {
            return err.reason !== undefined && err.reason.status === 502;
        });

        const validationErrorFromApi = errors.filter((err) => {
            return err.reason !== undefined && err.reason.status === 400;
        });

        if (offlineErrorsFromSw.length > 0) {
            return ClaimPhotosSubmissionResult.apiOffline;
        } else if (validationErrorFromApi.length > 0) {
            return ClaimPhotosSubmissionResult.requestFailed;
        } else return ClaimPhotosSubmissionResult.success;
    } else return ClaimPhotosSubmissionResult.success;
};
