/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminOrderDto } from '../models/AdminOrderDto';
import type { AdminOrderDtoSingleResult } from '../models/AdminOrderDtoSingleResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminOrdersScope {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns AdminOrderDto Success
     * @throws ApiError
     */
    public apiOdataAdminOrders(): CancelablePromise<Array<AdminOrderDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminOrders',
        });
    }

    /**
     * @returns AdminOrderDto Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersCount(): CancelablePromise<Array<AdminOrderDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminOrders/$count',
        });
    }

    /**
     * @param key 
     * @returns AdminOrderDtoSingleResult Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersKey(
key?: number,
): CancelablePromise<AdminOrderDtoSingleResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminOrders({key})',
            query: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @returns AdminOrderDtoSingleResult Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersKey1(
key?: number,
): CancelablePromise<AdminOrderDtoSingleResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminOrders/{key}',
            query: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns AdminOrderDto Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersKeyOrderSetStatus(
key?: number,
requestBody?: Record<string, any>,
): CancelablePromise<AdminOrderDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminOrders({key})/Order.SetStatus',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns AdminOrderDto Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersKeySetStatus(
key?: number,
requestBody?: Record<string, any>,
): CancelablePromise<AdminOrderDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminOrders({key})/SetStatus',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns AdminOrderDto Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersKeyOrderSetStatus1(
key?: number,
requestBody?: Record<string, any>,
): CancelablePromise<AdminOrderDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminOrders/{key}/Order.SetStatus',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns AdminOrderDto Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersKeySetStatus1(
key?: number,
requestBody?: Record<string, any>,
): CancelablePromise<AdminOrderDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminOrders/{key}/SetStatus',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns AdminOrderDto Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersKeyMetadataUpdateDeliveryDate(
key?: number,
requestBody?: Record<string, any>,
): CancelablePromise<AdminOrderDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminOrders({key})/Metadata.UpdateDeliveryDate',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns AdminOrderDto Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersKeyUpdateDeliveryDate(
key?: number,
requestBody?: Record<string, any>,
): CancelablePromise<AdminOrderDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminOrders({key})/UpdateDeliveryDate',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns AdminOrderDto Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersKeyMetadataUpdateDeliveryDate1(
key?: number,
requestBody?: Record<string, any>,
): CancelablePromise<AdminOrderDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminOrders/{key}/Metadata.UpdateDeliveryDate',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns AdminOrderDto Success
     * @throws ApiError
     */
    public apiOdataAdminOrdersKeyUpdateDeliveryDate1(
key?: number,
requestBody?: Record<string, any>,
): CancelablePromise<AdminOrderDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminOrders/{key}/UpdateDeliveryDate',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}