import * as Tabs from '@radix-ui/react-tabs';
import React from 'react';
import { ValidationFields } from '../../hooks/useOrderValidation';
import styles from './OrderSummaryTabs.module.css';

const OrderSummaryTabs: React.FC<{
    detailsTabContent: React.ReactElement;
    productsTabContent: React.ReactElement;
    remarksTabContent: React.ReactElement;
    activeTab: string;
    setActiveTab: (tab: string) => void;
    validationErrors: ValidationFields;
}> = ({
    detailsTabContent,
    productsTabContent,
    remarksTabContent,
    activeTab,
    setActiveTab,
    validationErrors,
}) => {
    return (
        <Tabs.Root
            value={activeTab}
            onValueChange={setActiveTab}
            orientation="vertical"
        >
            <Tabs.List
                aria-label="Order summary tabs section"
                className={`${styles.tabsList} ${styles.withPaddedWrapper}`}
            >
                <Tabs.Trigger value="details" className={styles.tabsTrigger}>
                    Details
                </Tabs.Trigger>
                <Tabs.Trigger
                    value="products"
                    className={[
                        styles.tabsTrigger,
                        validationErrors.noLineItems ? styles.tabWithError : '',
                    ].join(' ')}
                >
                    Products
                </Tabs.Trigger>
                <Tabs.Trigger value="remarks" className={styles.tabsTrigger}>
                    Remarks
                </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="details">{detailsTabContent}</Tabs.Content>
            <Tabs.Content value="products">{productsTabContent}</Tabs.Content>
            <Tabs.Content value="remarks">{remarksTabContent}</Tabs.Content>
        </Tabs.Root>
    );
};

export default OrderSummaryTabs;
