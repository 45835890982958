import React from 'react';
import { SmallConfirmationModal } from '../SmallConfirmationModal/SmallConfirmationModal';

interface Props {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

export const DeleteDraftModal: React.FC<Props> = ({
    isOpen,
    onCancel,
    onConfirm,
}) => {
    return (
        <SmallConfirmationModal
            isOpen={isOpen}
            onClose={onCancel}
            onConfirm={onConfirm}
            onAbort={onCancel}
            confirmBtnText="Delete draft"
            cancelBtnText="Cancel"
            subtitle="Are you sure you want to delete this draft?"
            title="Delete draft"
        />
    );
};
