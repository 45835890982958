/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Claim
 *
 * 1 = Support
 */
export enum ItemIssueType {
    Claim = 0,
    Support = 1,
}