import { ExperienceKey } from '../../../../types/receivedOrder.types';

export const questions: {
    key: ExperienceKey;
    question: string;
}[] = [
    {
        key: 'supplierRepresentative',
        question: 'Did a Supplier Representative accompany the delivery?',
    },
    {
        key: 'blockFrozen',
        question: 'Is the delivery without block frozen products?',
    },
    { key: 'labelsInEnglish', question: 'Are provisions labelled in English?' },
    { key: 'supplyOnTime', question: 'Were provisions supplied on time?' },
    {
        key: 'plasticReturned',
        question:
            'Did the supplier take back any plastic packaging used for delivery?',
    },
];
