import { LineItemWithProductDetails } from '../../../db/utils/products';
import DetailsDataRow from '../../DetailsDataTable/DetailsDataRow';
import styles from './DuplicateOrderModal.module.css';

interface RemovedLineItemsChangelogProps {
    removedLineItemsWithProductDetails: LineItemWithProductDetails[];
    showIntroduction?: boolean;
    listTitle?: string;
    quantityColumnTitle?: string;
}

export const RemovedLineItemsChangelog: React.FC<
    RemovedLineItemsChangelogProps
> = ({
    removedLineItemsWithProductDetails,
    showIntroduction = true,
    listTitle,
    quantityColumnTitle,
}) => {
    return (
        <>
            {showIntroduction && (
                <p className={styles.introduction}>
                    Some products from your previous order are no longer in the
                    assortment and will be omitted from the new order:
                </p>
            )}
            <DetailsDataRow
                title={listTitle ?? 'Omitted items'}
                value={
                    <p className={styles.columnHeadline}>
                        {quantityColumnTitle ?? 'Last order'}
                    </p>
                }
                uppercaseTitle
            />
            {removedLineItemsWithProductDetails.map((details) => (
                <DetailsDataRow
                    key={details.lineItem.itemNumber}
                    title={
                        <p className={styles.removedItem}>
                            {details.product.itemName ||
                                details.lineItem.itemName}
                        </p>
                    }
                    value={`${details.lineItem.quantity} ${details.lineItem.unitOfMeasure}`}
                />
            ))}
        </>
    );
};
