interface ParsedPort {
    portNameWithCode: string;
    portNameWithCodeAndCountry: string;
    countryFullName: string;
}

export const portCodeToCountryDisplayName = (
    code: string,
): string | undefined => {
    if (code) {
        try {
            const formatter = new Intl.DisplayNames(['en'], { type: 'region' });
            return formatter.of(code);
        } catch (error) {
            return code;
        }
    }

    return code;
};

const parsePort = (portName?: string, portCode?: string): ParsedPort => {
    if (!portName || !portCode) {
        return {
            // For nextPort `portCode` is undefined if text was customized
            // In that case we can use the custom `portName` as `portNameWithCode`
            portNameWithCode: portName ?? '',
            portNameWithCodeAndCountry: portName ?? '',
            countryFullName: '',
        };
    }

    const countryFullName =
        portCodeToCountryDisplayName(portCountryCodeFromFullCode(portCode)) ||
        'N/A';

    return {
        portNameWithCode: `${portName} (${portCode})`,
        portNameWithCodeAndCountry: `${portName}, ${countryFullName} (${portCode})`,
        countryFullName,
    };
};

export const portCountryCodeFromFullCode = (code: string): string =>
    code.substring(0, 2);

export default parsePort;
