import Button from '../../Button/Button';
import DetailsDataRow from '../../DetailsDataTable/DetailsDataRow';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import { ModalHeader } from '../../ModalHeader/ModalHeader';
import styles from '../commonStyles.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import { useClaimsInReceivalFlow } from '../../../context/ClaimsInReceivalFlowContext';
import ArrowIcon from '../../../public/gfx/uiIcons/collapsedArrow.svg';
import Tooltip from '../../Tooltips/Tooltip';
import DecimalInput from '../../DecimalInput/DecimalInput';
import { adjustAmountToUnitOfMeasure } from '../../../utils/adjustAmountToUnitOfMeasure';
import { getItemIssueLabel } from './utils';

export const WhatIsTheIssue = () => {
    const {
        changeStep,
        closeClaimsInReceivalModal,
        claimsInReceivalModalMetadata: { itemIssueDetails, unitOfMeasure },
        updateItemIssue,
    } = useClaimsInReceivalFlow();

    if (!itemIssueDetails) {
        console.error('itemIssueDetails is not defined');
        return null;
    }
    const { itemIssueProblem, affectedAmount, order } = itemIssueDetails;

    return (
        <>
            <ModalHeader
                title="Claim step 1 of 3"
                subTitle="What is the issue?"
                description="Provide details on the issue and we can open a claim with the supplier."
            />
            <div className={styles.contentContainer}>
                <DetailsDataRow title="Order details" boldTitle />
                <DetailsDataRow
                    title="What was the issue?"
                    onClick={() => {
                        changeStep('reasonsList');
                    }}
                    value={
                        <div className={styles.reasonDropdownButton}>
                            <span>{getItemIssueLabel(itemIssueProblem)}</span>
                            <ArrowIcon
                                className="transitionRegular rotated270"
                                width="24"
                                height="24"
                                stroke="#000"
                            />
                        </div>
                    }
                    withMarginBottom
                />
                <DetailsDataRow title="How much is affected?" boldTitle />
                <DetailsDataRow
                    title="Quantity affected"
                    value={
                        <div className={styles.inputContainer}>
                            <Tooltip
                                isOpen={
                                    (order?.orderedQuantity ?? 0) -
                                        (order?.claimedQuantity ?? 0) <
                                    affectedAmount
                                }
                                tooltipMessage="The quantity affected cannot exceed the quantity ordered. If you previously claimed the item, you can only claim remaining quantity."
                            >
                                <DecimalInput
                                    value={affectedAmount}
                                    onChange={(qty) => {
                                        updateItemIssue(
                                            'affectedAmount',
                                            Number(qty),
                                        );
                                    }}
                                    onBlurValueConverter={(value) =>
                                        adjustAmountToUnitOfMeasure(
                                            unitOfMeasure,
                                            value,
                                        )
                                    }
                                    hasError={
                                        (order?.orderedQuantity ?? 0) -
                                            (order?.claimedQuantity ?? 0) <
                                        affectedAmount
                                    }
                                />
                            </Tooltip>
                            <p className={styles.unitOfMeasure}>
                                {unitOfMeasure}
                            </p>
                        </div>
                    }
                />
                <DetailsDataRow
                    title="Received"
                    value={`${order?.orderedQuantity} ${unitOfMeasure}`}
                />
                <DetailsDataRow
                    title="Previously claimed qty"
                    value={`${order?.claimedQuantity} ${unitOfMeasure}`}
                />
            </div>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Cancel claim"
                        onClick={closeClaimsInReceivalModal}
                        secondary
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Next"
                        onClick={() => changeStep('issueDescriptionAndPhotos')}
                        disabled={
                            !itemIssueDetails.affectedAmount ||
                            !itemIssueDetails.itemIssueProblem ||
                            (order?.orderedQuantity ?? 0) < affectedAmount
                        }
                        primary={Boolean(
                            itemIssueDetails.affectedAmount &&
                                itemIssueDetails.itemIssueProblem,
                        )}
                    />
                </div>
            </div>
        </>
    );
};
