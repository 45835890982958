import React from 'react';
import styles from './ProductsList.module.css';
import OpenFiltersButton from '../FiltersModal/OpenFiltersButton';
import { useStickyTopbar } from '../../hooks/useStickyTopbar';
import { useFeatureFlags } from '../../context/useFeatureFlags';

interface TableHeadProps {
    handleOpenFiltersModal: () => void;
    compact?: boolean;
    filters: number[];
    withBorder?: boolean;
    noHeader?: boolean;
    showPrice?: boolean;
}

export const TableHeadRoot: React.FC<
    React.PropsWithChildren<Pick<TableHeadProps, 'compact' | 'withBorder' | 'noHeader'>>
> = ({ children, compact, withBorder, noHeader }) => {
    const { isPageScrolled } = useStickyTopbar();

    return (
        <div className={styles.container}>
            <div
                className={[
                    styles.tableHead,
                    isPageScrolled && compact ? styles.sticky && styles.stickySummary : '',
                    noHeader ? styles.noHeader : '',
                    isPageScrolled ? styles.sticky : '',
                    compact ? styles.compact : '',
                    withBorder ? styles.withBorder : '',
                ].join(' ')}
            >
                {children}
            </div>
        </div>
    );
};

export const TableHead: React.FC<TableHeadProps> = ({
    handleOpenFiltersModal,
    compact,
    filters,
}) => {
    const hasActiveFilters = filters.length > 0;
    const { featureFlags } = useFeatureFlags();

    return (
        <TableHeadRoot compact={compact}>
            <p className={styles.tableCell} />
            <p className={styles.tableCell}>Item</p>
            <p className={styles.tableCell}>Packing</p>
            {!featureFlags?.hidePrice && <p className={styles.tableCell}>Price</p>}
            <p className={styles.tableCell}>Quantity</p>
            {!featureFlags?.hidePrice && <p className={styles.tableCell}>Total</p>}
            <p className={styles.tableCell}>
                {!compact && (
                    <OpenFiltersButton
                        hasActiveFilters={hasActiveFilters}
                        handleOpenFiltersModal={handleOpenFiltersModal}
                    />
                )}
            </p>
        </TableHeadRoot>
    );
};

export const TableHeadReceived: React.FC<TableHeadProps> = ({
    handleOpenFiltersModal,
    compact,
    filters,
}) => {
    const hasActiveFilters = filters.length > 0;
    const { featureFlags } = useFeatureFlags();

    return (
        <TableHeadRoot compact={compact}>
            <p className={styles.tableCell} />
            <p className={styles.tableCell}>Product name</p>
            <p className={styles.tableCell}>Ordered</p>
            <p className={styles.tableCell}>Received</p>
            {!featureFlags?.hidePrice && <p className={styles.tableCell}>Price</p>}
            {!featureFlags?.hidePrice && <p className={styles.tableCell}>Received total</p>}
            <p className={styles.tableCell}>
                {!compact && (
                    <OpenFiltersButton
                        hasActiveFilters={hasActiveFilters}
                        handleOpenFiltersModal={handleOpenFiltersModal}
                    />
                )}
            </p>
        </TableHeadRoot>
    );
};
