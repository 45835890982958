import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useUnsavedWork } from '../context/UnsavedWorkContext';

export const usePreventWindowClosing = ({
    routeChangeEventHandler,
    shouldPreventClose,
}: {
    routeChangeEventHandler: (route: string) => void;
    shouldPreventClose: boolean;
}) => {
    const { events } = useRouter();
    const { addUnsavedWork, removeUnsavedWork } = useUnsavedWork();

    useEffect(() => {
        events.on('routeChangeStart', routeChangeEventHandler);

        return () => {
            events.off('routeChangeStart', routeChangeEventHandler);
        };
    }, [shouldPreventClose]);

    useEffect(() => {
        if (shouldPreventClose) {
            addUnsavedWork('unsavedDraft');
        } else {
            removeUnsavedWork('unsavedDraft');
        }
    }, [shouldPreventClose]);
};
