import { ItemIssueProblem, ItemIssueType } from '../../../apiClient/generated';
import { CondemnReasons } from '../../StocktakingProductsList/utils/stocktakingUtils';

export type ClaimWizardProblem = ItemIssueProblem | CondemnReasons;

export type ClaimWizardTypes = ItemIssueType | 'condemned';

export interface SelectableOption {
    id: number;
    text: string;
    itemIssueProblem: ClaimWizardProblem;
}

export const claimBaseReasons: SelectableOption[] = [
    {
        id: 1,
        text: 'Poor quality item delivered',
        itemIssueProblem: ItemIssueProblem.BadQualityItemDelivered,
    },
    {
        id: 2,
        text: 'Incorrect item delivered',
        itemIssueProblem: ItemIssueProblem.IncorrectItemDelivered,
    },
    {
        id: 3,
        text: 'Damaged on delivery',
        itemIssueProblem: ItemIssueProblem.DamagedOnDelivery,
    },
    {
        id: 4,
        text: 'Expired on delivery',
        itemIssueProblem: ItemIssueProblem.ExpiredOnDelivery,
    },
];

export const condemnBaseReasons: SelectableOption[] = [
    {
        id: 5,
        text: 'Expired on board',
        itemIssueProblem: CondemnReasons.Expired,
    },
    {
        id: 6,
        text: 'Removed from stock',
        itemIssueProblem: CondemnReasons.Removed,
    },
    {
        id: 7,
        text: 'Damaged on board',
        itemIssueProblem: CondemnReasons.Damaged,
    },
];

export const baseReasons: SelectableOption[] = [
    ...claimBaseReasons,
    ...condemnBaseReasons,
];

export const itemIssueReasonsList: SelectableOption[] = [
    ...baseReasons,
    ...condemnBaseReasons,
];
