import React from 'react';
import styles from './EmptyState.module.css';

interface EmptyStateProps {
    image?: JSX.Element;
    title: string | React.ReactElement;
    subTitle?: string | React.ReactElement;
    lessSpace?: boolean;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
    image,
    title,
    subTitle,
    lessSpace,
}) => {
    return (
        <div
            className={[
                styles.container,
                lessSpace ? styles.noMargins : '',
            ].join(' ')}
        >
            <div className={styles.imageContainer}>{image}</div>
            <h3
                className={[
                    styles.title,
                    lessSpace ? styles.noMarginTitle : '',
                ].join(' ')}
            >
                {title}
            </h3>
            <p className={styles.subTitle}>{subTitle}</p>
        </div>
    );
};
