import { GenerateDexieClass } from '../db';
import { getProductByItemNumber } from '../utils/getProductByItemNumber';
import {
    StocktakingItem,
    StocktakingReport,
} from '../../types/stocktaking.types';
import { Product } from '../../types/order.types';

export const onUpgradeTo28 = async (db: GenerateDexieClass) => {
    const draftReport = await db
        .table<StocktakingReport>('stocktakingReports')
        .where('state')
        .equals('draft')
        .first();

    await db.stocktakingReports.clear();

    if (draftReport) {
        const products = await db.table<Product>('products').toArray();

        await db.stocktakingReports.put(draftReport);

        const updatedItems: StocktakingItem[] = [];
        draftReport.items.map((item) => {
            const productForUpdate = getProductByItemNumber(
                item.itemNumber,
                products,
            );

            if (!productForUpdate) {
                return;
            }

            const updatedItem = {
                ...item,
                itemName: productForUpdate.itemName,
                category: productForUpdate.categoryLevel3.text,
                unitOfMeasure: productForUpdate.unitOfMeasure,
            };
            updatedItems.push(updatedItem);
        });

        await db.stocktakingReports.update(draftReport.reportMonth, {
            items: updatedItems,
        });
    }
};
