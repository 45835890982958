import useSWR from 'swr';
import { db } from '../db/db';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import { FeatureAnnouncement } from '../types/featureAnnouncement.types';

export enum AnnouncementTypes {
    multipleOrdersV1 = 'multipleOrdersV1',
}

const fetchDbData = () => db.featureAnnouncements.toArray();

export const useFeatureAnnouncements = () => {
    const { data, isValidating, mutate } = useSWR(
        SWRCacheKeys.featureAnnouncements,
        () => fetchDbData(),
        swrDefaultConfig,
    );

    const upsertOne = async (update: FeatureAnnouncement) => {
        await db.featureAnnouncements.put(update);
        await mutate();
    };

    return {
        data,
        isValidating,
        upsertOne,
    };
};
