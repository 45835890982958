import { useClaimsInReceivalFlow } from '../../../context/ClaimsInReceivalFlowContext';
import Button from '../../Button/Button';
import DetailsDataRow from '../../DetailsDataTable/DetailsDataRow';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import { ModalHeader } from '../../ModalHeader/ModalHeader';
import modalStyles from '../../Modal/Modal.module.css';
import styles from '../commonStyles.module.css';
import { ItemIssueDetails } from '../../ClaimOrCondemnPreparationModal/ClaimOrCondemnPreparationModal';
import { getItemIssueLabel } from './utils';
import { parseItemIssueDetailsToClaimDto } from '../../ClaimOrCondemnPreparationModal/utils/parsers';
import { IExtendedItemIssueDto } from '../../../db/overrideTypes';
import { useClaimsInReceival } from '../../../hooks/useClaimsInReceival';
import useOrderType from '../../../hooks/useOrderType';
import { getShortWbPono } from '../../utils/wbPono';

export const ClaimSummary = () => {
    const {
        changeStep,
        closeClaimsInReceivalModal,
        claimsInReceivalModalMetadata,
    } = useClaimsInReceivalFlow();

    const { orderIdFromQuery } = useOrderType();

    const { addClaimsInReceival } = useClaimsInReceival(
        Number(orderIdFromQuery),
    );

    if (!claimsInReceivalModalMetadata.itemIssueDetails) {
        console.error('itemIssueDetails is not defined');
        return null;
    }

    const { itemIssueDetails, itemNumber, unitOfMeasure } =
        claimsInReceivalModalMetadata;

    const getProductName = (itemIssueDetails: ItemIssueDetails) => {
        const rfq = itemIssueDetails.order?.rfq;
        if (!rfq || !itemNumber) {
            console.error('RFQ or itemNumber not found');
            return null;
        }
        const lineItem = rfq.lineItems.find(
            (item) => item.itemNumber === itemNumber,
        );

        return lineItem?.itemName;
    };

    const addClaim = () => {
        const itemName = getProductName(itemIssueDetails);
        if (!itemIssueDetails.itemIssueProblem || !itemNumber || !itemName) {
            return;
        }

        const parsedClaim = parseItemIssueDetailsToClaimDto(
            {
                ...itemIssueDetails,
                selectedProduct: {
                    itemNumber: itemNumber,
                    name: itemName,
                },
            },
            itemIssueDetails.images?.length,
        );

        const claimInReceival: IExtendedItemIssueDto = {
            ...parsedClaim,
            localPhotos: itemIssueDetails.images,
        };

        addClaimsInReceival(claimInReceival);
        closeClaimsInReceivalModal();
    };

    return (
        <>
            <ModalHeader
                title="Claim step 3 of 3"
                subTitle="Claim Summary"
                description="Thanks for reporting an issue. We will review and confirm whether you’re eligible for a credit or discount."
            />
            <div className={styles.contentContainer}>
                <DetailsDataRow
                    boldTitle
                    title={
                        getProductName(itemIssueDetails) ??
                        'Product name not found'
                    }
                />
                <DetailsDataRow
                    title={'Order #'}
                    value={getShortWbPono(itemIssueDetails.order?.rfq.wbPono)}
                />
                <DetailsDataRow
                    title={'Issue'}
                    value={getItemIssueLabel(itemIssueDetails.itemIssueProblem)}
                />
                <DetailsDataRow
                    title={'Quantity affected'}
                    value={
                        <span
                            className={styles.redValue}
                        >{`${itemIssueDetails.affectedAmount} ${unitOfMeasure}`}</span>
                    }
                />
            </div>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Back"
                        onClick={() => changeStep('issueDescriptionAndPhotos')}
                        secondary
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Add Claim" onClick={addClaim} primary />
                </div>
            </div>
        </>
    );
};
