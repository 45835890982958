import useSWR from 'swr';
import { SWRCacheKeys } from '../events/dataFlowEvents';
import { db } from '../db/db';

export const usePendingItemIssue = () => {
    const { data: pendingItemIssue } = useSWR(
        SWRCacheKeys.pendingItemIssues,
        () => db.pendingItemIssues.toCollection().first(),
    );

    const clearData = () => {
        db.pendingItemIssues.clear();
    };

    return {
        pendingItemIssue,
        clearData,
    };
};
