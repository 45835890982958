export type NumberFormatter = (
    figure: number | string,
    currency?: string,
) => string;

/**
 * Formats amount as the given currency in en-US locale
 * @param number Amount as float
 * @param currency A currency code understood by `Intl.NumberFormat`, defaults to `'USD'`
 * @returns Formatted and rounded amount with currency sign
 */
export const currencyFormatter: NumberFormatter = (
    number,
    currency = 'USD',
) => {
    const unformatted = !isNaN(Number(number)) ? Number(number) : 0;
    let formattedPriceCurrency;

    try {
        formattedPriceCurrency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        }).format(unformatted);
    } catch {
        formattedPriceCurrency = `${number} ${currency}`;
    }
    return formattedPriceCurrency;
};

export const quantityFormatter = (number?: number | string) => {
    const unformatted = Number(number ?? 0);
    if (!isNaN(unformatted)) {
        return unformatted.toFixed(2).toString();
    }

    return `${number}`;
};
