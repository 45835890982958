import { LineItem, RFQ } from './order.types';

export const initialReceivedOrder: Omit<ReceivedOrder, 'orderId'> = {
    receivedLineItems: {},
    rfqUpdates: {},
};

export type ReceivedOrderChangelogItem = {
    orderedLineItem?: LineItem;
    receivedLineItem?: ReceivedLineItem;
};

export interface ReceivedLineItem {
    isReceived: boolean;
    itemNumber: LineItem['itemNumber'];
    receivedQuantity: LineItem['quantity'];
    comment?: LineItem['comment'];
    isFromOrder: boolean; // user can add new items (outside the order).
    itemName?: string | null;
}

export interface ReceivedOrder {
    orderId: number;
    rfqUpdates: {
        deliveryDate?: RFQ['deliveryDate'];
    };
    // It's mapped by the unique itemNumber to make access faster
    receivedLineItems: Record<LineItem['itemNumber'], ReceivedLineItem>;
    remarks?: string;
    performanceReport?: PerformanceReport;
}

export interface ReceivedItemUpdateDto {
    itemNumber: string;
    receivedLineItem: Partial<ReceivedLineItem>;
}

export enum RatingKey {
    qualityFresh = 'qualityFresh',
    qualityDry = 'qualityDry',
    qualityFrozen = 'qualityFrozen',
    qualityPackingMarking = 'qualityPackingMarking',
    overAllPerformance = 'overAllPerformance',
}

export type ExperienceKey =
    | 'supplierRepresentative'
    | 'blockFrozen'
    | 'labelsInEnglish'
    | 'supplyOnTime'
    | 'plasticReturned';

export type PerformanceReportRating = {
    rating: PerformanceRating;
    comment?: string;
};

export interface PerformanceReport {
    ratings?: Partial<Record<RatingKey, PerformanceReportRating>>;
    experience?: Partial<Record<ExperienceKey, boolean>>;
}

export type PerformanceRating = 0 | 1 | 2 | 3 | 4 | 5;
