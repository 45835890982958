import { CSSProperties } from 'react';
import styles from './LoadingSpinner.module.css';

interface LoadingSpinnerProps {
    /**
     * The size in px. Defaults to 20
     */
    size?: number;
    /**
     * A variant that only shows after a short delay to avoid flickering in short loading scenarios
     */
    delayed?: boolean;
}

export const LoadingSpinner = ({ size = 20, delayed }: LoadingSpinnerProps) => (
    <div
        className={styles.loadingSpinnerContainer}
        style={{ '--loading-spinner-size': `${size}px` } as LoadingSpinnerCSS}
    >
        <i
            className={[
                styles.loadingSpinner,
                delayed ? styles.delayed : '',
            ].join(' ')}
        >
            Loading...
        </i>
    </div>
);

interface LoadingSpinnerCSS extends CSSProperties {
    '--loading-spinner-size': string;
}
