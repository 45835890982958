// TODO - temporary interface (in the end it will go directly from BE interfaces Dto)
import { db } from './db';
import { Product } from '../types/order.types';
import { AvailableClaimItemDto } from '../apiClient/generated';
import { apiClient } from '../apiClient/apiClient';

export const fetchAndUpdateAvailableItemsToClaim = async () => {
    //TODO - uncomment when bug with many requests will be fixed
    // const response = await apiClient.getAvailableClaimItems();
    // await db.itemsAvailableToClaim.bulkPut(response);
    return (
        await db.itemsAvailableToClaim.toCollection().sortBy('lastOrdered')
    ).reverse();
};

export const getProductsAvailableToClaimToDisplayInSearchList = (
    itemsAvailableToClaim: AvailableClaimItemDto[],
    products: Product[],
): Product[] => {
    const productsToClaim: Product[] = [];
    itemsAvailableToClaim.forEach((itemAvailableToClaim) => {
        const productToClaim = products.find(
            (product) => product.itemNumber === itemAvailableToClaim.itemNumber,
        );
        if (productToClaim) productsToClaim.push(productToClaim);
    });

    return productsToClaim;
};
