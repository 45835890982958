/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = App
 *
 * 1 = WristIntegration
 */
export enum OrderRfqOrigin {
    App = 0,
    WristIntegration = 1,
}