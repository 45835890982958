import { OrderToDisplayInOrdersList } from '../../types/order.types';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { Field } from '../List/Field';
import { List, SeparatorMode } from '../List/List';
import StatusInfo from './StatusInfo';
import { currencyFormatter } from '../utils/numberFormat';
import { getShortWbPono } from '../utils/wbPono';
import { getOrderTypeName, OrderTypes } from '../../context/OrderTypes';
import { useSyncStatus } from '../../context/SyncStatusContext';
import React from 'react';
import styles from './OrdersOverview.module.css';
import CountryFlag from '../Flags/CountryFlag';
import moment from 'moment';
import ArrowRightIcon from '../../public/gfx/uiIcons/arrowRight.svg';
import { OrderIcon } from './OrderIcon';
import { getOrderPath } from '../utils/orderPathsGenerator';
import { sumElements } from '../utils/arrayMethods';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import { parseCashPurchaseOrderStatusToDisplayInOrdersList } from '../utils/cashPurchaseStatusParser';
import { CashPurchaseOrigin } from '../../apiClient/generated';
import { useFeatureFlags } from '../../context/useFeatureFlags';

interface OrdersOverviewListProps {
    orders: OrderToDisplayInOrdersList[];
    isValidating?: boolean;
    showLastUpdated?: boolean;
    showHeader?: boolean;
    emptyState?: React.ReactElement;
}

const getOrderType = ({}, orderToDisplay?: OrderToDisplayInOrdersList) => {
    if (!orderToDisplay) {
        return 'Unknown order';
    }
    return getOrderTypeName(orderToDisplay.type);
};

export const OrdersOverviewList: React.FC<OrdersOverviewListProps> = ({
    orders,
    isValidating,
    showLastUpdated = true,
    showHeader = true,
    emptyState = (
        <div className={styles.emptyState}>You currently have no orders.</div>
    ),
}) => {
    const {
        queueStatus: { onlineQueue },
    } = useSyncStatus();

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const { featureFlags } = useFeatureFlags();

    const getOrderTotal = (
        {},
        orderToDisplay?: OrderToDisplayInOrdersList,
    ): string => {
        const items = orderToDisplay?.lineItems;
        if (items) {
            const orderTotal = sumElements(
                items?.map(
                    (item) =>
                        (item.price ??
                            item.confirmedPrice ??
                            item.estimatedPrice ??
                            0) * item.quantity,
                ),
            );

            const isCashOrder = orderToDisplay?.type === OrderTypes.cash
            const isPriceDiscountedByAX = orderToDisplay?.origin === CashPurchaseOrigin.AX

            const discount = orderToDisplay?.cashDiscount ?? 0
            const finalTotal = isCashOrder
                ? orderTotal * (isPriceDiscountedByAX ? 1 : 1 - discount)
                : orderTotal  

            if (isCashOrder) {
                return currencyFormatter(finalTotal, orderToDisplay.currency); 
            }
            return currencyFormatter(finalTotal, vesselCurrency); 
        }
        
        return currencyFormatter(0, vesselCurrency);
    };

    return (
        <List<OrderToDisplayInOrdersList>
            isValidating={isValidating}
            data={orders}
            idSource="localOrderId"
            showHeader={showHeader}
            getRowLink={getOrderPath}
            separatorMode={SeparatorMode.betweenEach}
            emptyState={emptyState}
        >
            <Field<OrderToDisplayInOrdersList>
                source=""
                title="Order"
                renderFunction={({}, orderToDisplay) => {
                    if (!orderToDisplay) return <></>;
                    return <OrderIcon orderToDisplay={orderToDisplay} />;
                }}
                iconOnly
            />
            <Field<OrderToDisplayInOrdersList>
                source=""
                renderFunction={({}, orderToDisplay) => {
                    const getEllipsisText = (
                        text: string,
                        maxLength: number,
                    ) => {
                        if (text.length <= maxLength) return text;
                        const start = text.slice(0, maxLength - 6);
                        const end = text.slice(-3);
                        return `${start}...${end}`;
                    };

                    if (orderToDisplay?.wbPono) {
                        const shortWbPono = getShortWbPono(
                            orderToDisplay.wbPono,
                        );
                        return (
                            <strong
                                className={styles.ellipsis}
                                title={shortWbPono}
                            >
                                {getEllipsisText(shortWbPono, 15)}
                            </strong>
                        );
                    }
                    if (orderToDisplay?.draftName) {
                        return (
                            <span
                                className={styles.ellipsis}
                                title={orderToDisplay.draftName}
                            >
                                {getEllipsisText(orderToDisplay.draftName, 15)}
                            </span>
                        );
                    }
                    return (
                        <span className={styles.ellipsis} title="pending">
                            pending
                        </span>
                    );
                }}
                title=""
                grey
            />
            <Field source="" title="Type" grey formatter={getOrderType} />
            <Field<OrderToDisplayInOrdersList>
                source=""
                title="Delivery port"
                grey
                renderFunction={({}, orderToDisplay) => {
                    return (
                        <div className={styles.rowContainer}>
                            <div className={styles.flag}>
                                <CountryFlag
                                    portCode={
                                        orderToDisplay?.deliveryPort?.portCode
                                    }
                                />
                            </div>
                            &nbsp;
                            <div>{orderToDisplay?.deliveryPort?.portName}</div>
                        </div>
                    );
                }}
            />
            <Field<OrderToDisplayInOrdersList>
                source=""
                title="Delivery date"
                renderFunction={({}, orderToDisplay) => {
                    return (
                        <>
                            {orderToDisplay?.deliveryDate &&
                            // This catches the case where it's an instance of Date, but "Invalid Date"
                            !isNaN(orderToDisplay?.deliveryDate.getTime())
                                ? moment(orderToDisplay?.deliveryDate).format(
                                      'DD.MM.YYYY',
                                  )
                                : '-'}
                        </>
                    );
                }}
                grey
            />

            {!featureFlags?.hidePrice ? (
                <Field<OrderToDisplayInOrdersList>
                    source=""
                    title="Amount"
                    formatter={getOrderTotal}
                    grey
                />
            ) : null}

            {showLastUpdated ? (
                <Field<OrderToDisplayInOrdersList>
                    source=""
                    title="Last updated"
                    renderFunction={({}, orderToDisplay) => {
                        return (
                            <>
                                {orderToDisplay?.lastUpdated &&
                                // This catches the case where it's an instance of Date, but "Invalid Date"
                                !isNaN(orderToDisplay?.lastUpdated.getTime())
                                    ? moment(
                                          orderToDisplay?.lastUpdated,
                                      ).format('DD.MM.YYYY')
                                    : '-'}
                            </>
                        );
                    }}
                    grey
                />
            ) : null}
            <Field<OrderToDisplayInOrdersList>
                source="status"
                title="Status"
                minWidth={165}
                renderFunction={({}, orderToDisplay) => {
                    if (orderToDisplay?.type === OrderTypes.cash) {
                        return (
                            <StatusInfo
                                displayedStatus={parseCashPurchaseOrderStatusToDisplayInOrdersList(
                                    orderToDisplay,
                                )}
                            />
                        );
                    }
                    const { displayedStatus } = parseOrderStatus(
                        orderToDisplay,
                        onlineQueue,
                    );

                    return (
                        <div
                            onClick={(e) => {
                                if (orderToDisplay?.isSending === true) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }
                            }}
                        >
                            <StatusInfo
                                displayedStatus={displayedStatus}
                                isSendingOffline={orderToDisplay?.isSending}
                            />
                        </div>
                    );
                }}
            />
            <Field
                source=""
                title={<div className={styles.lastColumnEmptyTitle}></div>}
                renderFunction={() => <ArrowRightIcon width={20} height={40} />}
                iconOnly
            />
        </List>
    );
};
