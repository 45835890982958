/**
 * Removes the Vessel IMO from a po number
 * @param wbPono A full po number
 * @returns A short po number
 */
export const getShortWbPono = (wbPono?: string): string => {
    // Format: xxx-yyy, where xxx is the Vessel IMO and yyy the order number
    const parts = wbPono?.split('-');
    if (parts?.length && parts.length > 1) {
        return parts[1];
    }

    return wbPono || '';
};
