/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = EmergencyForShortStay
 *
 * 1 = NoGarretsSupplier
 *
 * 2 = MissingItems
 *
 * 3 = SanctionCountry
 *
 * 4 = GarretsRecommendation
 *
 * 5 = FreshTopUp
 */
export enum CashPurchaseReason {
    EmergencyForShortStay = 0,
    NoGarretsSupplier = 1,
    MissingItems = 2,
    SanctionCountry = 3,
    GarretsRecommendation = 4,
    FreshTopUp = 5,
}