import React from 'react';
import Button from '../../Button/Button';
import modalStyles from '../../Modal/Modal.module.css';
import {
    ItemIssueDetails,
    UpdateItemIssue,
} from '../ClaimOrCondemnPreparationModal';
import claimOrCondemnPreparationModalStyles from '../ClaimOrCondemnPreparationModal.module.css';
import { SelectableOption } from '../utils/selectableOptions';
import SelectableIssueReason from './SelectableIssueReason';

interface IssuesReasonsListStep {
    selectableOptionsList: SelectableOption[];
    onContinue: () => void;
    onBack: () => void;
    updateItemIssue: UpdateItemIssue;
    itemIssueDetails: ItemIssueDetails;
    condemn?: boolean;
}

const IssuesReasonsListStep: React.FC<IssuesReasonsListStep> = ({
    selectableOptionsList,
    onContinue,
    onBack,
    updateItemIssue,
    itemIssueDetails,
    condemn,
}) => {
    const isSelected = (option: SelectableOption) => {
        return option.itemIssueProblem === itemIssueDetails.itemIssueProblem;
    };

    const isButtonActive = itemIssueDetails.itemIssueProblem !== null;

    return (
        <>
            <div
                className={claimOrCondemnPreparationModalStyles.headerContainer}
            >
                <p>{condemn ? 'Condemn' : 'Claim'}</p>
                <h1 className="no-margin--bottom">Tell us what went wrong</h1>
                <p>Please provide details of the issue.</p>
            </div>
            <div
                className={
                    claimOrCondemnPreparationModalStyles.contentContainer
                }
            >
                {selectableOptionsList.map((option) => (
                    <SelectableIssueReason
                        key={option.id}
                        option={option}
                        updateItemIssue={updateItemIssue}
                        isSelected={isSelected(option)}
                    />
                ))}
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Continue"
                        onClick={onContinue}
                        disabled={!isButtonActive}
                        primary={isButtonActive}
                    />
                </div>
            </div>
        </>
    );
};

export default IssuesReasonsListStep;
