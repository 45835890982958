import { GenerateDexieClass } from '../../db/db';
import { downloadFile } from './downloadFile';

export const exportIdbContents = async (
    db: GenerateDexieClass,
    vesselImo: number,
) => {
    //Dynamic importing due to the lib lack of compatibility with next ssr
    const dbExportModule = await import('dexie-export-import');
    const dbExportJsonBlob = await dbExportModule.exportDB(db, {
        prettyJson: true,
    });
    const filename = `idbExport-${vesselImo}`;
    downloadFile(filename, dbExportJsonBlob, 'json');
};
