import * as Toast from '@radix-ui/react-toast';
import { ToastMetadata, ToastType } from '../../context/ToastContext';
import styles from './Toast.module.css';
import CheckmarkIcon from '../../public/gfx/uiIcons/successCheckmarkWhite.svg';

interface ToastComponentProps {
    toastMetadata: ToastMetadata | undefined;
    hideToast: () => void;
}

const ToastComponent: React.FC<ToastComponentProps> = ({
    toastMetadata,
    hideToast,
}) => {
    const getClassnameByType = () => {
        if (!toastMetadata) return '';
        switch (toastMetadata?.type) {
            case ToastType.success:
                return styles.success;
            case ToastType.error:
                return styles.error;
            default:
                return '';
        }
    };

    return (
        <>
            <Toast.Provider swipeDirection="right" duration={3000}>
                <Toast.Root
                    className={[
                        styles.root,
                        !toastMetadata?.isOpen ? styles.close : styles.open,
                    ].join(' ')}
                    open={Boolean(toastMetadata)}
                    onOpenChange={(isOpen) => {
                        if (!isOpen) {
                            hideToast();
                        }
                    }}
                >
                    <div
                        className={[
                            styles.toastContainer,
                            getClassnameByType(),
                        ].join(' ')}
                        onClick={hideToast}
                    >
                        {toastMetadata?.type === ToastType.success && (
                            <div className={styles.iconContainer}>
                                <CheckmarkIcon />
                            </div>
                        )}
                        <p>{toastMetadata?.text}</p>
                    </div>
                </Toast.Root>
                <Toast.Viewport className={styles.viewport} />
            </Toast.Provider>
        </>
    );
};

export default ToastComponent;
