import {
    BufferGeometry,
    Scene,
    PerspectiveCamera,
    BufferAttribute,
    ShaderMaterial,
    Color,
    Points,
    WebGLRenderer,
} from 'three';
import styles from './WaveAnimation.module.css';
import { useEffect, useRef } from 'react';

export const Wave: React.FC = () => {
    const sceneRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const SEPARATION = 20,
            AMOUNT_X = 200,
            AMOUNT_Y = 100;

        let count = 0;

        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight,
        };

        const camera = new PerspectiveCamera(
            100,
            sizes.width / sizes.height,
            14,
            10000,
        );

        const container = document.createElement('div');
        document.body.appendChild(container);

        camera.position.z = 300;

        const scene = new Scene();

        const numParticles = AMOUNT_X * AMOUNT_Y;

        const positions = new Float32Array(numParticles * 3);
        const scales = new Float32Array(numParticles);

        let i = 0,
            j = 0;

        for (let ix = 0; ix < AMOUNT_X; ix++) {
            for (let iy = 0; iy < AMOUNT_Y; iy++) {
                positions[i] = ix * SEPARATION - (AMOUNT_X * SEPARATION) / 2; // x
                positions[i + 1] = 0; // y
                positions[i + 2] =
                    iy * SEPARATION - (AMOUNT_Y * SEPARATION) / 2; // z

                scales[j] = 0.01;

                i += 3;
                j++;
            }
        }

        const geometry = new BufferGeometry();
        geometry.setAttribute('position', new BufferAttribute(positions, 3));
        geometry.setAttribute('scale', new BufferAttribute(scales, 1));

        const vertexShader = `
            attribute float scale;

            void main() {
                vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                gl_PointSize = scale * (300.0 / -mvPosition.z);
                gl_Position = projectionMatrix * mvPosition;
            }`;

        const fragmentShader = `
            uniform vec3 color;

            void main() {
                if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
                gl_FragColor = vec4( color, 1.0 );
            }`;

        const material = new ShaderMaterial({
            uniforms: {
                color: { value: new Color('white') },
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
        });

        const particles = new Points(geometry, material);
        scene.add(particles);

        const renderer = new WebGLRenderer({
            alpha: true,
        });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(sizes.width, sizes.height);
        container.appendChild(renderer.domElement);

        window.addEventListener('resize', onWindowResize);

        function onWindowResize() {
            camera.aspect = sizes.width / sizes.height;
            camera.updateProjectionMatrix();

            renderer.setSize(sizes.width, sizes.height);
        }

        if (sceneRef.current) {
            sceneRef.current.appendChild(renderer.domElement);
        }

        function render() {
            camera.lookAt(scene.position);

            camera.position.set(0, 120, 120);
            camera.rotation.set(0, 0, 0);

            let i = 0,
                j = 0;

            for (let ix = 0; ix < AMOUNT_X; ix++) {
                for (let iy = 0; iy < AMOUNT_Y; iy++) {
                    positions[i + 1] =
                        Math.sin((ix + count) * 0.1) * 50 +
                        Math.sin((iy + count) * 0.2) * 50;

                    scales[j] = 2;

                    i += 3;
                    j++;
                }
            }

            particles.geometry.attributes.position.needsUpdate = true;
            particles.geometry.attributes.scale.needsUpdate = true;

            renderer.render(scene, camera);

            count += 0.1;
        }

        function animate() {
            requestAnimationFrame(animate);
            render();
        }

        animate();

        return () => {
            window.removeEventListener('resize', onWindowResize);
            scene.remove(particles);
            geometry.dispose();
            material.dispose();
            renderer.dispose();
        };
    }, []);

    return <div ref={sceneRef} className={styles.animationContainer}></div>;
};
