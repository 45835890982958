import { SmallConfirmationModal } from '../SmallConfirmationModal/SmallConfirmationModal';
import React, { useEffect, useState } from 'react';
import { db } from '../../db/db';
import { usePendingCashPurchaseOrder } from '../../hooks/usePendingCashPurchaseOrder';
import { useRouter } from 'next/router';
import { ModalType, useModalContext } from '../../context/ModalContext';
import { CashPurchasePreparationModalStep } from '../CashOrderOptionsModal/useStepsNavigator';

export const RestoreCashPurchaseOrderModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { pendingCashPurchaseOrder, deletePendingCashPurchaseOrder } =
        usePendingCashPurchaseOrder();
    const { push } = useRouter();
    const { openGlobalModal } = useModalContext();

    const checkForPendingCashPurchaseOrder = async () => {
        const pendingCashPurchaseOrder =
            await db.pendingCashPurchaseOrders.toArray();

        if (pendingCashPurchaseOrder.length > 0) {
            setIsModalOpen(true);
        }
    };

    const onRestore = () => {
        setIsModalOpen(false);
        push('/order/cash/preparation');
        if (
            !pendingCashPurchaseOrder?.deliveryPort ||
            !pendingCashPurchaseOrder.deliveryDate ||
            !pendingCashPurchaseOrder.currency
        ) {
            openGlobalModal({
                modalType: ModalType.cashOrderPreparation,
                cashOrderPreparationStep:
                    CashPurchasePreparationModalStep.orderDetails,
            });
        } else if (!pendingCashPurchaseOrder.reason) {
            openGlobalModal({
                modalType: ModalType.cashOrderPreparation,
                cashOrderPreparationStep:
                    CashPurchasePreparationModalStep.orderReason,
            });
        }
    };

    const onDiscard = async () => {
        await deletePendingCashPurchaseOrder();
        setIsModalOpen(false);
    };

    useEffect(() => {
        checkForPendingCashPurchaseOrder();
    }, []);

    return (
        <SmallConfirmationModal
            isOpen={isModalOpen}
            onClose={onDiscard}
            onAbort={onDiscard}
            onConfirm={onRestore}
            confirmBtnText="Restore"
            cancelBtnText="Discard"
            title="Oh no! Gateway closed unexpectedly"
            subtitle="Don't worry, we saved your Cash Purchase Order changes."
            additionalSubtitle="Would you like to restore?"
            redAbortButton
        />
    );
};
