import useSWR from 'swr';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import { getAssortment, OrderTypes } from '../context/OrderTypes';
import { db } from '../db/db';

const fetchDbData = () => db.assortments.toCollection().first();

export const useAssortments = () => {
    const { data, isValidating } = useSWR(
        SWRCacheKeys.assortments,
        fetchDbData,
        swrDefaultConfig,
    );

    return { data, isValidating };
};

export const useAssortment = (orderType?: OrderTypes) => {
    const { data: assortments, isValidating } = useAssortments();

    const data =
        assortments && orderType
            ? getAssortment(assortments, orderType)
            : undefined;

    return {
        data,
        isAssortmentValidating: isValidating,
    };
};
