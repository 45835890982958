import { Product } from '../types/order.types';
import { useEffect, useRef } from 'react';
import * as Comlink from 'comlink';
import type { productSearchWorker } from './searchWorker';

type ProductSearchWorker = typeof productSearchWorker;

export const useProductSearch = (
    availableProducts?: Product[],
    maxResults = 100,
) => {
    if (typeof window === 'undefined') {
        // Don't use this on SSR
        return {
            search: async (query: string) => [],
        };
    }

    const workerRef = useRef<Worker>();
    const linkRef = useRef<Comlink.Remote<ProductSearchWorker>>();
    useEffect(() => {
        workerRef.current = new Worker(
            new URL('./searchWorker.ts', import.meta.url),
        );
        linkRef.current = Comlink.wrap<ProductSearchWorker>(workerRef.current);

        return () => {
            workerRef.current?.terminate();
        };
    }, []);

    useEffect(() => {
        linkRef.current?.setProducts(availableProducts);
    }, [availableProducts, linkRef.current]);

    const search = async (query: string) => {
        return (
            (await linkRef.current?.search(query))?.slice(0, maxResults) ?? []
        );
    };

    return {
        search,
    };
};
