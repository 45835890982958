import { useSyncStatus } from '../../../context/SyncStatusContext';
import styles from './BaseIndicator.module.css';
import CloseIcon from '../../../public/gfx/uiIcons/closeGrey.svg';

interface BaseIndicatorProps {
    text?: string | undefined;
    pending?: boolean;
    icon?: React.VFC<React.SVGProps<SVGSVGElement>>;
    primary?: boolean;
    optionalHelper?: string | undefined;
    toggleButton?: React.VFC<React.SVGProps<SVGSVGElement>>;
    small?: boolean;
    bold?: boolean;
    withMarginBottom?: boolean;
    noMargin?: boolean;
    onClose?: () => void;
}

export const BaseIndicator: React.FC<BaseIndicatorProps> = ({
    text,
    pending,
    icon,
    primary,
    bold,
    optionalHelper,
    toggleButton,
    small,
    withMarginBottom,
    noMargin,
    onClose,
}) => {
    const Icon = icon;
    const ToggleButtonIcon = toggleButton;

    const { isOnline } = useSyncStatus();
    return (
        <>
            <div
                className={[
                    styles.container,
                    text ? styles.withText : '',
                    text && primary ? styles.primary : '',
                    isOnline && !pending ? styles.online : styles.offline,
                    pending ? styles.blue : '',
                    small ? styles.small : '',
                    bold ? styles.bold : '',
                    withMarginBottom ? styles.withMarginBottom : '',
                    noMargin ? styles.noMargin : '',
                ].join(' ')}
            >
                <div
                    className={[
                        styles.iconContainer,
                        pending ? styles.pending : '',
                    ].join(' ')}
                >
                    {Icon && <Icon />}
                </div>
                {text && <p className={styles.text}>{text}</p>}
                {ToggleButtonIcon && (
                    <div className={styles.toggleButtonContainer}>
                        <ToggleButtonIcon stroke="#7A808D" />
                    </div>
                )}
            </div>
            {onClose && (
                <button className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </button>
            )}
            {optionalHelper && (
                <p className={styles.optionalHelper}>{optionalHelper}</p>
            )}
        </>
    );
};
