import { useMemo, useState } from 'react';
import SelectableCard from '../../SelectableCard/SelectableCard';
import styles from './StartingPointStepContent.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import orderPreparationModalStyles from '../OrderPreparationModal.module.css';
import { Order } from '../../../types/order.types';
import { getLatestRfq } from '../../../db/utils/getLatestRfq';
import { sortOrders } from '../../utils/sortingFunctions';
import Button from '../../Button/Button';
import { OrderTypes } from '../../../context/OrderTypes';
import { LineItemWithProductDetails } from '../../../db/utils/products';
import { useProducts } from '../../../hooks/useProducts';
import { parseLineItemsToLineItemsWithProductDetails } from '../../utils/orderSummary';
import { fetchAndUpdateProducts } from '../../../db/products';
import { OrderPortDetails } from '../OrderPortDetails';
import { duplicateOrder } from '../../../db/utils/duplicateOrder';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';

interface Props {
    onAction: () => void;
    actionName: string;
    orderTypeSelectedInModal: OrderTypes;
    handleCreateOrder: () => Promise<string | undefined>;
    completedOrdersList: Order[] | undefined;
    onBack: () => void;
    openRemovedLineItemsChangelogModal: (
        removedLineItemsWithProductDetails: LineItemWithProductDetails[],
    ) => void;
}

const StartingPointStepContent: React.FC<Props> = ({
    onAction,
    actionName,
    orderTypeSelectedInModal,
    handleCreateOrder,
    completedOrdersList,
    onBack,
    openRemovedLineItemsChangelogModal,
}) => {
    const { data: products } = useProducts();
    const [selectedStartingPoint, setSelectedStartingPoint] = useState<
        'new' | Order
    >('new');

    const [showAllOrders, setShowAllOrders] = useState(false);

    const getOrderDetails = (order: Order) => {
        const rfq = getLatestRfq(order);
        if (!rfq) {
            return;
        }

        return { deliveryPort: rfq.deliveryPort, date: rfq.deliveryDate };
    };

    const orders = useMemo(() => {
        if (!completedOrdersList) {
            return [];
        }

        const sortedOrders = sortOrders(completedOrdersList);

        if (showAllOrders) {
            return sortedOrders;
        }

        return sortedOrders.slice(0, 3);
    }, [completedOrdersList, showAllOrders]);

    const duplicateProducts = async (localOrderId: string | undefined) => {
        if (!localOrderId) {
            return;
        }

        if (selectedStartingPoint === 'new') {
            return;
        }

        fetchAndUpdateProducts();

        const { lineItemsOutOfAssortment } =
            (await duplicateOrder(selectedStartingPoint.orderId, {
                onlyLineItems: true,
                localOrderId,
            })) ?? {};

        // open a modal if there were items from outside the assortment in the copied order
        if (lineItemsOutOfAssortment && lineItemsOutOfAssortment.length > 0) {
            openRemovedLineItemsChangelogModal(
                parseLineItemsToLineItemsWithProductDetails(
                    lineItemsOutOfAssortment,
                    products,
                ),
            );
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const handleConfirm = async () => {
        setIsLoading(true);
        const localOrderId = await handleCreateOrder();
        await duplicateProducts(localOrderId);
        onAction();
        setIsLoading(false);
    };

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className={orderPreparationModalStyles.contentContainer}>
                    <div>
                        <SelectableCard
                            isSelected={selectedStartingPoint === 'new'}
                            title="New Order"
                            onClick={() => {
                                setSelectedStartingPoint('new');
                            }}
                        />
                        <div className={styles.orderListHeading}>
                            <p>Use Previous Order as Template</p>
                            {!showAllOrders && (
                                <Button
                                    onClick={() => setShowAllOrders(true)}
                                    text="See all"
                                    textButton
                                ></Button>
                            )}
                        </div>
                        {orders.map((order) => (
                            <SelectableCard
                                key={order.orderId}
                                small
                                title={
                                    <OrderPortDetails
                                        deliveryPort={
                                            getOrderDetails(order)?.deliveryPort
                                        }
                                        orderType={order.type}
                                    />
                                }
                                isSelected={
                                    selectedStartingPoint !== 'new' &&
                                    selectedStartingPoint?.orderId ===
                                        order?.orderId
                                }
                                onClick={() => setSelectedStartingPoint(order)}
                                orderDate={getOrderDetails(order)?.date}
                                orderLink={`/order/${orderTypeSelectedInModal.toLowerCase()}/${
                                    order.orderId
                                }/summary/details`}
                            />
                        ))}
                    </div>
                </div>
            )}
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={actionName}
                        onClick={handleConfirm}
                        primary={Boolean(selectedStartingPoint)}
                        disabled={Boolean(!selectedStartingPoint || isLoading)}
                    />
                </div>
            </div>
        </>
    );
};

export default StartingPointStepContent;
