import { db } from './db';
import { ManningMetadataDto } from '../apiClient/generated/models/ManningMetadataDto';
import { dataFlowEventHub } from '../events/dataFlowEvents';

export const updateManningMetadata = async (
    manningMetadata: ManningMetadataDto,
) => {
    await db.manningMetadata.clear();
    await db.manningMetadata.add(manningMetadata);
    dataFlowEventHub.emit('manningMetadataChanged');
};

export const getManningMetadata = async () => {
    return await db.manningMetadata.toArray();
};
