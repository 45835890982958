import { LineItem, Product } from '../../types/order.types';

export const createNewLineItemFromProduct = (
    product: Product,
    initialAmount: number,
): LineItem => {
    return {
        itemNumber: product.itemNumber,
        quantity: initialAmount,
        unitOfMeasure: product.unitOfMeasure,
        estimatedPrice: product.estimatedPrice,
        estimatedPackSize: product.estimatedPackSize,
        itemName: product.itemName,
    };
};

export interface LineItemWithProductDetails {
    product: Product;
    lineItem: LineItem;
}
