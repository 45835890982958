import { useState } from 'react';
import { ItemIssuePreparationStep } from './itemIssueSteps';

export const useStepsInClaimsPreparationFlow = () => {
    const initialSteps = {
        active: ItemIssuePreparationStep.searchProducts,
        previous: [ItemIssuePreparationStep.searchProducts],
    };

    const [steps, setSteps] = useState<{
        active: ItemIssuePreparationStep;
        previous: ItemIssuePreparationStep[];
    }>(initialSteps);

    const setActiveStep = (step: ItemIssuePreparationStep) => {
        setSteps((prev) => ({
            active: step,
            previous: [...prev.previous, step],
        }));
    };

    const handleGoBack = () => {
        setSteps((prev) => {
            const steps = { ...prev };

            const previousStep = steps.previous[steps.previous.length - 2];

            const getPreviousStepsReduced = () => {
                const reducedSteps = [...steps.previous];
                reducedSteps.pop();
                return reducedSteps ?? [];
            };
            return {
                active: previousStep,
                previous: getPreviousStepsReduced(),
            };
        });
    };

    const handleResetSteps = () => setSteps(initialSteps);

    return {
        steps,
        setActiveStep,
        handleGoBack,
        handleResetSteps,
    };
};
