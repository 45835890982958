import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import errorStyles from './UnhandledErrorModal.module.css';
import styles from './ApiErrorModal.module.css';
import { useFeatureFlags } from '../../context/useFeatureFlags';

export const ContactKeyValue = ({
    title,
    value,
}: {
    title: string;
    value: string;
}) => {
    return (
        <div className={errorStyles.contactRow}>
            <p className={errorStyles.contactKey}>{title}</p>
            <p className={errorStyles.contactValue}>{value}</p>
        </div>
    );
};

interface GarretsContactProps {
    customMessage?: string;
}

export const GarretsContact: React.FC<GarretsContactProps> = ({
    customMessage,
}) => {
    const {featureFlags} = useFeatureFlags();
    const {
        data: {
            seaStarContact,
            seaStarEmail,
            seaStarOfficeHours,
            seaStarPhone,
        },
    } = useVesselMetadata();

    return (
        <>
            <p
                className={`${styles.description} ${styles.descriptionCentered}`}
            >
                {customMessage ??
                    `If the issue persists, please call your ${
                        featureFlags?.hideName ? 'regular' : 'Garrets'
                    } contact:`}
            </p>
            <div className={errorStyles.contactContainer}>
                <ContactKeyValue title="Name" value={seaStarContact ?? ''} />
                <ContactKeyValue title="Phone" value={seaStarPhone ?? ''} />
                <ContactKeyValue title="Email" value={seaStarEmail ?? ''} />
                <ContactKeyValue
                    title="Office hours"
                    value={seaStarOfficeHours ?? ''}
                />
            </div>
        </>
    );
};
