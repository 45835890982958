import styles from './CashOrderReasonStepContent.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import Button from '../../Button/Button';
import RadioInputsList, { RadioInputElement } from '../RadioInputsList';
import { CashPurchaseReason } from '../../../apiClient/generated';
import { usePendingCashPurchaseOrder } from '../../../hooks/usePendingCashPurchaseOrder';
import { useCashPurchaseReasonsStrings } from '../../../utils/cashPurchaseReasonString';

interface Props {
    onAction: () => void;
    onBack: () => void;
}

const CashOrderReasonStepContent: React.FC<Props> = ({ onAction, onBack }) => {
    const { pendingCashPurchaseOrder, updatePendingCashPurchaseOrder } = usePendingCashPurchaseOrder();

    const CashPurchaseReasonsStrings = useCashPurchaseReasonsStrings();

    const cashOrdersReasonsArray: RadioInputElement[] = [
        {
            id: CashPurchaseReason.EmergencyForShortStay,
            stringToDisplay: CashPurchaseReasonsStrings.EmergencyForShortStay,
        },
        {
            id: CashPurchaseReason.NoGarretsSupplier,
            stringToDisplay: CashPurchaseReasonsStrings.NoGarretsSupplier,
        },
        {
            id: CashPurchaseReason.MissingItems,
            stringToDisplay: CashPurchaseReasonsStrings.MissingItems,
        },
        {
            id: CashPurchaseReason.SanctionCountry,
            stringToDisplay: CashPurchaseReasonsStrings.SanctionCountry,
        },
        {
            id: CashPurchaseReason.GarretsRecommendation,
            stringToDisplay: CashPurchaseReasonsStrings.GarretsRecommendation,
        },
        {
            id: CashPurchaseReason.FreshTopUp,
            stringToDisplay: CashPurchaseReasonsStrings.FreshTopUp,
        },
    ];

    const handleChangeReason = async (reasonId: CashPurchaseReason) => {
        if (!pendingCashPurchaseOrder) {
            return;
        }
        await updatePendingCashPurchaseOrder({
            ...pendingCashPurchaseOrder,
            reason: reasonId,
        });
    };

    const isContinueButtonActive =
        pendingCashPurchaseOrder?.reason ||
        pendingCashPurchaseOrder?.reason === 0;

    return (
        <>
            <div className={styles.contentContainer}>
                <RadioInputsList
                    displayData={cashOrdersReasonsArray}
                    handleChangeValue={handleChangeReason}
                    defaultChecked={pendingCashPurchaseOrder?.reason}
                />
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Continue"
                        onClick={onAction}
                        primary={true}
                        disabled={!isContinueButtonActive}
                    />
                </div>
            </div>
        </>
    );
};

export default CashOrderReasonStepContent;