import React from 'react';
import styles from './SkeletonLoader.module.css';

interface Props {
    loaderWidth?: number;
}

const SkeletonLoader: React.FC<Props> = ({ loaderWidth }) => {
    return (
        <span className={styles.loader} style={{ width: loaderWidth }}></span>
    );
};

export default SkeletonLoader;
