import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styles from './Dropdown.module.css';
import OptionsIcon from '../../public/gfx/uiIcons/threeDots.svg';
import { useLayoutEffect, useRef, useState } from 'react';

export interface DropdownItem {
    id: string;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    content: React.ReactElement;
}

interface DropdownProps {
    items: DropdownItem[];
    biggerDropdownWithTriggerButton?: boolean;
    boldItems?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
    items,
    biggerDropdownWithTriggerButton,
    boldItems,
}) => {
    const triggerRef = useRef<HTMLButtonElement | null>(null);
    const [shouldPlaceContentOnTop, setShouldPlaceContentOnTop] =
        useState(false);

    const calculateShouldPlaceContentOnTop = () => {
        const viewportHeight = window.innerHeight;
        const triggerBottom =
            triggerRef.current?.getClientRects()[0].bottom ?? 0;
        const minContentHeight = 200;

        setShouldPlaceContentOnTop(
            viewportHeight - triggerBottom < minContentHeight,
        );
    };

    useLayoutEffect(() => {
        setTimeout(() => calculateShouldPlaceContentOnTop, 200);

        window.addEventListener('resize', calculateShouldPlaceContentOnTop);

        return () => {
            window.removeEventListener(
                'resize',
                calculateShouldPlaceContentOnTop,
            );
        };
    }, []);

    return (
        <div className={styles.dropdownContainer}>
            <DropdownMenu.Root modal={false}>
                <DropdownMenu.Trigger
                    className={[
                        biggerDropdownWithTriggerButton
                            ? styles.triggerButton
                            : styles.trigger,
                    ].join(' ')}
                    ref={triggerRef}
                >
                    <OptionsIcon width={24} height={24} />
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                    portalled={false}
                    className={[
                        styles.dropdownContent,
                        shouldPlaceContentOnTop ? styles.top : '',
                        biggerDropdownWithTriggerButton
                            ? styles.withTriggerButton
                            : '',
                    ].join(' ')}
                >
                    {items.map((item) => (
                        <DropdownMenu.DropdownMenuItem
                            className={[
                                styles.dropdownItem,
                                biggerDropdownWithTriggerButton
                                    ? styles.paddings
                                    : '',
                                boldItems ? styles.dropdownItemBolded : '',
                            ].join(' ')}
                            onClick={item.onClick}
                            key={item.id}
                        >
                            {item.content}
                        </DropdownMenu.DropdownMenuItem>
                    ))}
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        </div>
    );
};
