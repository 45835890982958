import GoBackIcon from '../../public/gfx/uiIcons/goBackArrowIcon.svg';
import styles from './BackButton.module.css';

const BackButton: React.FC<{
    onGoBack: () => void;
    caption?: string;
    color?: 'white' | 'black' | 'grey';
}> = ({ onGoBack, caption = 'Back', color = 'grey' }) => {
    const getColor = () => {
        switch (color) {
            case 'black':
                return { arrow: '#323232', text: '#323232' };
            case 'white':
                return { arrow: '#fff', text: '#fff' };
            case 'grey':
                return { arrow: '#323232', text: '#acb4bb' };
            default:
                return { arrow: '#323232', text: '#acb4bb' };
        }
    };

    return (
        <button
            className={styles.goBackButton}
            onClick={onGoBack}
            style={{ color: getColor().text }}
        >
            <GoBackIcon
                className={styles.goBackIcon}
                stroke={getColor().arrow}
            />
            {caption}
        </button>
    );
};

export default BackButton;
