import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import modalStyles from '../Modal/Modal.module.css';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import styles from './ConfirmationModal.module.css';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onComplete: () => void;
    processing?: boolean;
    title: string;
    subtitle?: string;
    description?: string;
    confirmOptionText?: string;
    cancelOptionText?: string;
    type?: string;
    processingText?: string;
    red?: boolean;
}

const ConfirmationModal: React.FC<React.PropsWithChildren<Props>> = ({
    isOpen,
    onClose,
    onComplete,
    processing,
    title,
    subtitle,
    description,
    confirmOptionText = 'Confirm',
    cancelOptionText = 'Cancel',
    type = 'info',
    processingText = 'Sending...',
    children,
    red,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} noHeader>
            <ModalHeader title={type} subTitle={title} />
            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    {subtitle && <p>{subtitle}</p>}
                    {description && <p>{description}</p>}
                </div>
                {children}
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        disabled={processing}
                        text={cancelOptionText}
                        onClick={onClose}
                        secondary
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        disabled={processing}
                        text={processing ? processingText : confirmOptionText}
                        onClick={onComplete}
                        primary
                        red={red}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
