import { Portal } from '@radix-ui/react-portal';
import React, { useRef } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { v4 } from 'uuid';
import styles from './Tooltip.module.css';

/**
 * @example
 * children of component will show tooltip after click/hover
 * ```
 * <Tooltip
 *      tooltipTitle='Bold title of tooltip'
 *      tooltipMessage='Message that tooltip should show' _or_ <Component />
 * >
 *         <p>
 *            [tooltip trigger]
 *         </p>
 * </Tooltip>
 * ```
 **/

type Props = {
    tooltipTitle?: string;
    tooltipMessage: string | React.ReactElement;
    isOpen?: boolean;
    additionalTriggerStyles?: string;
    clickable?: boolean;
};

const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    tooltipTitle,
    tooltipMessage,
    isOpen,
    additionalTriggerStyles,
    clickable,
}) => {
    const id = useRef(v4());

    return (
        <>
            <button
                id={id.current}
                className={[styles.button, additionalTriggerStyles].join(' ')}
            >
                {children}
            </button>
            <Portal>
                <ReactTooltip
                    className={styles.tooltip}
                    anchorId={id.current}
                    isOpen={isOpen}
                    clickable={clickable}
                >
                    <>
                        {tooltipTitle && (
                            <p className={styles.tooltipTitle}>
                                {tooltipTitle}
                            </p>
                        )}
                        {React.isValidElement(tooltipMessage) ? (
                            tooltipMessage
                        ) : (
                            <p
                                className={styles.tooltipContent}
                            >{`${tooltipMessage}`}</p>
                        )}
                    </>
                </ReactTooltip>
            </Portal>
        </>
    );
};
export default Tooltip;
