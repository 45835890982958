// https://github.com/fengyuanchen/compressorjs/blob/main/README.md
import React, { useState } from 'react';
import Button from '../../Button/Button';
import { compressImages } from '../utils/compressionHelper';
import claimOrCondemnPreparationModalStyles from '../ClaimOrCondemnPreparationModal.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import tableStyles from '../../DetailsDataTable/DetailsDataTable.module.css';
import { getIssueNameHeaderText } from '../utils/itemIssueHelpers';
import {
    ItemIssueDetails,
    UpdateItemIssue,
} from '../ClaimOrCondemnPreparationModal';
import { getItemIssueType } from '../utils/itemIssueType';
import { ItemIssueType } from '../../../apiClient/generated';
import { v4 } from 'uuid';
import { FullscreenDropArea } from '../../DropArea/FullscreenDropArea';
import { Question } from '../../Question/Question';
import styles from './AddCommentAndClaimPhotosStep.module.css';
import PictureIcon from '../../../public/gfx/uiIcons/picture.svg';
import { LimitedTextArea } from '../../LimitedTextArea/LimitedTextArea';
import { db } from '../../../db/db';
import FilesSection from '../../FilesSection/FilesSection';

interface AddCommentAndPhotosStepProps {
    claimDetails: ItemIssueDetails;
    onBack: () => void;
    updateClaim: UpdateItemIssue;
    onContinue: () => void;
    forIncorrectItemDelivered?: boolean;
}

const AddCommentAndClaimPhotosStep: React.FC<AddCommentAndPhotosStepProps> = ({
    onContinue,
    onBack,
    claimDetails,
    updateClaim,
    forIncorrectItemDelivered,
}) => {
    const [images, setImages] = useState<File[]>(
        claimDetails?.images?.map((entry) => entry.image) ?? [],
    );
    const [compressionInProgress, setCompressionInProgress] = useState(false);

    const claimType = getItemIssueType(claimDetails);

    const headerText = getIssueNameHeaderText(claimDetails);

    const handleAddImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        return await addImagesFromFileList(e.target.files);
    };

    const addImagesFromFileList = async (fileList: FileList) => {
        const files = Array.from(fileList);

        if (files.length === 0) {
            return;
        }

        setCompressionInProgress(true);
        const compressedImages = await compressImages({
            files,
        });
        setCompressionInProgress(false);
        const allImages = [...images, ...(compressedImages as File[])];
        setImages(allImages);
        updateClaim(
            'images',
            allImages.map((image) => ({ image, id: v4() })),
        );
    };

    const handleRemoveImage = (item: File) => {
        setImages((prev) => {
            const reducedImages = prev.filter(
                (file) =>
                    !(
                        file.name === item.name &&
                        file.lastModified === item.lastModified
                    ),
            );

            return reducedImages;
        });
    };

    const isButtonActive = Boolean(claimDetails.comment.length > 0);

    return (
        <>
            <div
                className={claimOrCondemnPreparationModalStyles.headerContainer}
            >
                <p>{`Claim: ${headerText}`}</p>
                <h1>
                    {forIncorrectItemDelivered
                        ? 'What product did you order?'
                        : 'Please describe the issue'}
                </h1>
            </div>
            <div
                className={
                    claimOrCondemnPreparationModalStyles.contentContainer
                }
            >
                <h3
                    className={`${tableStyles.sectionTitle} ${tableStyles.uppercaseText}`}
                >
                    Comment
                </h3>
                <LimitedTextArea
                    name="commentInput"
                    placeholder={
                        forIncorrectItemDelivered
                            ? 'Please specify any details about the product, when it was ordered, etc. '
                            : 'Additional notes that would help us process your request.'
                    }
                    className={[
                        modalStyles.commentInput,
                        modalStyles.small,
                    ].join(' ')}
                    value={claimDetails.comment}
                    onChange={(value) => updateClaim('comment', value ?? '')}
                    characterLimit={db.defaults.axOrderLineStringFieldLimit}
                />
                <div className={styles.question}>
                    <Question
                        question="Is the product still usable?"
                        answer={claimDetails.isProductUsable}
                        experienceKey="isUsable"
                        setAnswer={(key, answer) =>
                            updateClaim('isProductUsable', answer)
                        }
                        remark="This determines whether we should remove the item from your stock."
                    />
                </div>
                {claimType === ItemIssueType.Claim && (
                    <div className={styles.filesSectionContainer}>
                        <FilesSection
                            files={images}
                            handleAddFiles={handleAddImages}
                            handleRemoveFiles={handleRemoveImage}
                            compressionInProgress={compressionInProgress}
                        />
                        <FullscreenDropArea
                            onFilesDrop={addImagesFromFileList}
                            icon={<PictureIcon />}
                            captionText="Drop your image anywhere to add it"
                        />
                    </div>
                )}
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={
                            compressionInProgress ? 'Compressing' : 'Continue'
                        }
                        disabled={compressionInProgress || !isButtonActive}
                        onClick={async () => {
                            onContinue();
                        }}
                        primary={isButtonActive}
                    />
                </div>
            </div>
        </>
    );
};

export default AddCommentAndClaimPhotosStep;
