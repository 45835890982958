import SearchButton from './SearchButton';
import styles from './SearchBox.module.css';
import SearchInput from './SearchInput';
import { useState } from 'react';

interface SearchBoxProps {
    searchQuery: string;
    handleChangeQuery: (query: string) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({
    searchQuery,
    handleChangeQuery,
}) => {
    const [isInputActive, setIsInputActive] = useState(false);

    return (
        <div className={styles.container}>
            <SearchButton
                onClick={() => {
                    setIsInputActive(true);
                }}
            />
            <SearchInput
                isInputActive={isInputActive || searchQuery.length > 0}
                onBlur={() => setIsInputActive(false)}
                searchQuery={searchQuery}
                handleChangeQuery={handleChangeQuery}
            />
        </div>
    );
};

export default SearchBox;
