import { useState } from 'react';

export interface PopupsHandlers {
    openConfirmSubmitModal: () => void;
    closeConfirmSubmitModal: () => void;
    closeSummaryHalfStepModal: () => void;
    openConfirmDuplicateModal: () => void;
    closeConfirmDuplicateModal: () => void;
    openApiDowntimeErrorModal: () => void;
    closeApiDowntimeErrorModal: () => void;
    openOrderChangelogSideModal: () => void;
    closeOrderChangelogSideModal: () => void;
    openBudgetExceededAlertModal: () => void;
    closeBudgetExceededAlertModal: () => void;

    openUnavailableProductsModal: (unavailableItemNumbers: string[]) => void;
    closeUnavailableProductsModal: () => void;
    openAddItemsModal: () => void;
    closeAddItemsModal: () => void;
    openConfirmStopSendingModal: () => void;
    closeConfirmStopSendingModal: () => void;
    openUnsavedDraftModal: () => void;
    closeUnsavedDraftModal: () => void;
    openUnhandledErrorModal: () => void;
    closeUnhandledErrorModal: () => void;
}

export interface PopupState {
    confirmSubmitModal: boolean;
    confirmDuplicateModal: boolean;
    orderChangelogSideModal: boolean;
    budgetExceededAlert: boolean;
    summaryHalfStepModal: boolean;
    apiDowntimeErrorModal: boolean;
    unavailableProductsModal: boolean;
    unavailableProductsModalItemNumbers: string[];
    addItemsModal: boolean;
    confirmStopSendingModal: boolean;
    unsavedDraftModal: boolean;
    unhandledErrorModal: boolean;
}

const usePopupsInSummaryPage = () => {
    const [popupsState, setPopupsState] = useState<PopupState>({
        confirmSubmitModal: false,
        confirmDuplicateModal: false,
        orderChangelogSideModal: false,
        summaryHalfStepModal: false,
        apiDowntimeErrorModal: false,
        budgetExceededAlert: false,
        unavailableProductsModal: false,
        unavailableProductsModalItemNumbers: [],
        addItemsModal: false,
        confirmStopSendingModal: false,
        unsavedDraftModal: false,
        unhandledErrorModal: false,
    });

    const popupsHandlers: PopupsHandlers = {
        openConfirmSubmitModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                confirmSubmitModal: true,
            }));
        },
        closeConfirmSubmitModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                confirmSubmitModal: false,
                summaryHalfStepModal: false,
            }));
        },
        closeSummaryHalfStepModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                summaryHalfStepModal: false,
            }));
        },
        openConfirmDuplicateModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                confirmDuplicateModal: true,
            }));
        },
        closeConfirmDuplicateModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                confirmDuplicateModal: false,
            }));
        },
        openApiDowntimeErrorModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                apiDowntimeErrorModal: true,
            }));
        },
        closeApiDowntimeErrorModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                apiDowntimeErrorModal: false,
            }));
        },
        openOrderChangelogSideModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                orderChangelogSideModal: true,
            }));
        },
        closeOrderChangelogSideModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                orderChangelogSideModal: false,
            }));
        },
        openBudgetExceededAlertModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                budgetExceededAlert: true,
            }));
        },
        closeBudgetExceededAlertModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                confirmSubmitModal: false,
                budgetExceededAlert: false,
            }));
        },
        openUnavailableProductsModal: (unavailableItemNumbers) => {
            setPopupsState((prev) => ({
                ...prev,
                unavailableProductsModal: true,
                unavailableProductsModalItemNumbers: unavailableItemNumbers,
            }));
        },
        closeUnavailableProductsModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                unavailableProductsModal: false,
                unavailableProductsModalItemNumbers: [],
            }));
        },
        openAddItemsModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                addItemsModal: true,
            }));
        },
        closeAddItemsModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                addItemsModal: false,
            }));
        },
        openConfirmStopSendingModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                confirmStopSendingModal: true,
            }));
        },
        closeConfirmStopSendingModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                confirmStopSendingModal: false,
            }));
        },
        openUnsavedDraftModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                unsavedDraftModal: true,
            }));
        },
        closeUnsavedDraftModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                unsavedDraftModal: false,
            }));
        },
        openUnhandledErrorModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                unhandledErrorModal: true,
            }));
        },
        closeUnhandledErrorModal: () => {
            setPopupsState((prev) => ({
                ...prev,
                unhandledErrorModal: false,
            }));
        },
    };

    return { popupsState, popupsHandlers };
};

export default usePopupsInSummaryPage;
