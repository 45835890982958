/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Order
 *
 * 1 = Reopen
 *
 * 2 = CashPurchase
 *
 * 3 = ItemIssue
 */
export enum NotificationSource {
    Order = 0,
    Reopen = 1,
    CashPurchase = 2,
    ItemIssue = 3,
}