import { db } from '../../../db/db';
import { ItemIssueDetails } from '../../ClaimOrCondemnPreparationModal/ClaimOrCondemnPreparationModal';

export const sanitizePendingItemIssuesTable = async () => {
    const countOfLocalItemIssues = await db.pendingItemIssues.count();

    if (countOfLocalItemIssues > 1) {
        db.pendingItemIssues.clear();
    }
};

export const getPendingItemIssuesIndex = async () => {
    const pendingItemIssues = await db.pendingItemIssues.toArray();
    if (pendingItemIssues.length < 1) {
        return '';
    }
    return pendingItemIssues[0].localId;
};

export const handleItemIssuesIdbUpdate = async (
    updatedData?: ItemIssueDetails,
    images?: { image: File; id: string }[],
) => {
    await sanitizePendingItemIssuesTable();
    const pendingClaimIndex = await getPendingItemIssuesIndex();
    const updatedClaims = { ...updatedData, images: images };
    if (pendingClaimIndex === undefined) {
        throw new Error('Pending claim index not found');
    }
    db.pendingItemIssues.update(pendingClaimIndex, updatedClaims);
};

export const getImagesFromDb = async () => {
    const pendingClaim = await db.pendingItemIssues.toCollection().first();
    return pendingClaim?.images;
};

export const removeImageFromDb = async (id: string) => {
    const images = [...((await getImagesFromDb()) ?? [])];
    const reducedImages = images.filter((item) => item.id !== id);
    await handleItemIssuesIdbUpdate(undefined, reducedImages);
};
