import { ItemIssueProblem, ItemIssueType } from '../../../apiClient/generated';
import { CondemnReasons } from '../../StocktakingProductsList/utils/stocktakingUtils';
import { ItemIssueDetails } from '../ClaimOrCondemnPreparationModal';
import {
    ClaimWizardProblem,
    ClaimWizardTypes as ClaimWizardType,
} from './selectableOptions';

const claimIssueProblems: ClaimWizardProblem[] = [
    ItemIssueProblem.BadQualityItemDelivered,
    ItemIssueProblem.IncorrectItemDelivered,
    ItemIssueProblem.DamagedOnDelivery,
    ItemIssueProblem.ExpiredOnDelivery,
];

const condemnedIssueProblem: ClaimWizardProblem[] = [
    CondemnReasons.Damaged,
    CondemnReasons.Removed,
    CondemnReasons.Expired,
];

export const getItemIssueType = (
    itemIssueDetails: ItemIssueDetails,
): ClaimWizardType | undefined => {
    const { itemIssueProblem, order } = itemIssueDetails;

    if (itemIssueProblem === null || itemIssueProblem === undefined) {
        return undefined;
    }

    if (claimIssueProblems.includes(itemIssueProblem) || order) {
        return ItemIssueType.Claim;
    }

    if (condemnedIssueProblem.includes(itemIssueProblem)) {
        return 'condemned';
    }

    return ItemIssueType.Support;
};

export const isItemIssueClaimType = (itemIssueProblem: ClaimWizardProblem) => {
    return claimIssueProblems.includes(itemIssueProblem);
};
