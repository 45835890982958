import { useProducts } from '../../../hooks/useProducts';
import { LineItem, OrderToDisplay, Product } from '../../../types/order.types';
import { getLineItemsWithProductDetails } from '../../utils/orderSummary';
import styles from './UnavailableProductsModal.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import { RemovedLineItemsChangelog } from '../DuplicateOrderModal/RemovedLineItemsChangelog';
import StatusBadge from '../../StatusBadge/StatusBadge';
import { ModalHeader } from '../../ModalHeader/ModalHeader';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { getFallbackForUnavailableProduct } from '../../utils/getProductsForOrderSummaryScreen';
import { useState } from 'react';
import { useSyncStatus } from '../../../context/SyncStatusContext';
import { PendingCashPurchaseOrder } from '../../../types/cashPurchaseOrders.types';
import {
    getCashPurchaseLineItemsWithProductDetails,
    isPendingCashPurchaseOrder,
} from '../../utils/submitCashPurchaseOrderHelpers';

interface UnavailableProductsModalProps {
    isOpen: boolean;
    orderToDisplay: OrderToDisplay | PendingCashPurchaseOrder;
    onReview: (productsMarkedMissingByAPI: Product[]) => Promise<void>;
    displayContext?: 'orderSummary' | 'productList';
    unavailableItemNumbersFromApiResponse: string[];
}

export const UnavailableProductsModal: React.FC<
    UnavailableProductsModalProps
> = ({
    isOpen,
    orderToDisplay,
    onReview,
    displayContext = 'orderSummary',
    unavailableItemNumbersFromApiResponse,
}) => {
    const { data: products } = useProducts();
    const [processing, setProcessing] = useState(false);

    const { failedRequestModalMetadata, putFailedRequestModalMetadata } =
        useSyncStatus();

    const lineItemsWithProductDetails = isPendingCashPurchaseOrder(
        orderToDisplay,
    )
        ? getCashPurchaseLineItemsWithProductDetails(orderToDisplay, products)
        : getLineItemsWithProductDetails(orderToDisplay, products);

    const isUnavailableLineItem = (lineItem: LineItem) => {
        const hasQuantity = lineItem.quantity > 0;

        const inMainProductList = lineItemsWithProductDetails.find(
            (lineItemWithProduct) =>
                lineItemWithProduct.lineItem.itemNumber === lineItem.itemNumber,
        );

        const rejectedInApiResponse =
            unavailableItemNumbersFromApiResponse.includes(lineItem.itemNumber);

        return hasQuantity && (!inMainProductList || rejectedInApiResponse);
    };

    const unavailableLineItems = isPendingCashPurchaseOrder(orderToDisplay)
        ? orderToDisplay?.items.filter(isUnavailableLineItem)
        : orderToDisplay?.rfq?.lineItems.filter(isUnavailableLineItem);

    const unavailableLineItemsWithProductDetails = unavailableLineItems?.map(
        (lineItem) => ({
            lineItem,
            product: getFallbackForUnavailableProduct(lineItem),
        }),
    );

    const hasUnavailableLineItems = Boolean(
        unavailableLineItemsWithProductDetails?.length,
    );

    const onReviewAction = async () => {
        setProcessing(true);
        if (failedRequestModalMetadata?.isUnavailableItemModal) {
            putFailedRequestModalMetadata();
        }
        await onReview(
            unavailableLineItemsWithProductDetails?.map((item) => item.product),
        );
        setProcessing(false);
    };

    return (
        <Modal isOpen={isOpen} noHeader onClose={onReviewAction}>
            <ModalHeader title="Error" subTitle="Unavailable products" />
            <div className={modalStyles.modalContent}>
                {displayContext === 'orderSummary' && (
                    <div className={styles.badgeWrapper}>
                        <StatusBadge
                            backgroundColor="#ffe7e7"
                            color="#FF5A5A"
                            withPadding
                        >
                            FAILED TO SEND
                        </StatusBadge>
                    </div>
                )}
                {displayContext === 'orderSummary' && (
                    <p className={styles.introduction}>
                        Your order could not be submitted, because some products
                        in your order are no longer available.
                    </p>
                )}
                {displayContext === 'productList' && (
                    <p className={styles.introduction}>
                        Some products in your order draft are unfortunately no
                        longer available.
                    </p>
                )}

                {hasUnavailableLineItems ? (
                    <>
                        <p className={styles.introduction}>
                            Gateway has updated the product list and indentified
                            the products in your order that need to be removed.
                        </p>
                        <p className={styles.introduction}>
                            <strong>
                                Please replace the removed products with similar
                                ones.
                            </strong>
                        </p>
                        <RemovedLineItemsChangelog
                            removedLineItemsWithProductDetails={
                                unavailableLineItemsWithProductDetails
                            }
                            showIntroduction={false}
                            listTitle="Unavailable items"
                            quantityColumnTitle="Quantity"
                        />
                    </>
                ) : (
                    <>
                        <p className={styles.introduction}>
                            Gateway is updating your local product list now.
                        </p>
                        <div className={styles.loading}></div>
                    </>
                )}
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={processing ? 'Loading...' : 'Review order'}
                        onClick={onReviewAction}
                        primary
                        disabled={processing}
                    />
                </div>
            </div>
        </Modal>
    );
};
