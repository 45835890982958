import { useLiveQuery } from 'dexie-react-hooks';
import { LineItem, Order, OrderRfqOrigin, Product, RFQ } from '../types/order.types';
import { getEditableOrderById } from '../db/editableOrders';
import { getLatestRfq, getSortedRfqs } from '../db/utils/getLatestRfq';

type LineItemChangeStatus = 'removed' | 'updated' | 'added';

interface LineItemChange<T> {
    previousValue?: T;
    currentValue: T;
    isModified: boolean;
}

interface RfqsToCompare {
    currentRfq?: RFQ;
    previousRfq?: RFQ;
    previousWristRfq?: RFQ;
}

export interface RFQLineItemChangelog {
    itemNumber: string;
    itemName: string;
    status: LineItemChangeStatus;
    priceChange?: LineItemChange<number>;
    amountChange?: LineItemChange<number | undefined>;
    unitOfMeasureChange?: LineItemChange<string>;
    packSizeChange?: LineItemChange<number>;
}

export interface RFQChangelog {
    changes: RFQLineItemChangelog[];
    filteredChangelogForModal: RFQLineItemChangelog[];
    numberOfChangedProductsInModal: number;
}

export const getProductName = (products: Product[], lineItem: LineItem): string => {
    return (
        products.find((item) => item.itemNumber === lineItem.itemNumber)?.itemName ||
        lineItem.itemName ||
        'Unknown product'
    );
};

const getLineItemChange = <T>(previousValue: T, currentValue: T): LineItemChange<T> => {
    return {
        previousValue: previousValue,
        currentValue: currentValue,
        isModified: previousValue !== currentValue,
    };
};

export const getPreviousRfq = (order?: Order): RFQ | null => {
    if (!order) {
        return null;
    }

    const latestRfq = getLatestRfq(order);
    const latestRfqIndex = order.rfqs?.findIndex((rfq) => rfq.id === latestRfq?.id);

    const hasPreviousOrder = latestRfqIndex && latestRfqIndex > 0;
    if (!hasPreviousOrder) {
        return null;
    }

    return order.rfqs?.[latestRfqIndex - 1] ?? null;
};

const getConfirmedPricesChange = (
    lineItemFromPreviousRfq: LineItem,
    legacyProductFromCurrentRfq?: LineItem,
    previousWristRfq?: RFQ,
): LineItemChange<number> => {
    const lineChange = getLineItemChange(
        lineItemFromPreviousRfq.confirmedPrice ?? 0,
        legacyProductFromCurrentRfq?.confirmedPrice ?? 0,
    );

    // Estimated prices are not considered as changes
    const hasPreviousLineItemConfirmedPrice = previousWristRfq?.lineItems.find(
        (item) => item.itemNumber === lineItemFromPreviousRfq.itemNumber,
    );
    if (!hasPreviousLineItemConfirmedPrice) {
        lineChange.isModified = false;
    }

    return lineChange;
};

const getPackSizeChange = (
    lineItemFromPreviousRfq: LineItem,
    legacyProductFromCurrentRfq?: LineItem,
): LineItemChange<number> => {
    const lineChange = getLineItemChange(
        lineItemFromPreviousRfq.confirmedPackSize ?? 0,
        legacyProductFromCurrentRfq?.confirmedPackSize ?? 0,
    );

    // If previous packSize was missing then it's not a change
    if ((lineItemFromPreviousRfq.confirmedPackSize ?? 0) === 0) {
        lineChange.isModified = false;
    }

    return lineChange;
};

const getRfqsToCompare = async (order: Order | undefined): Promise<RfqsToCompare> => {
    if (!order) return {};

    const sortedRfqs = getSortedRfqs(order);
    const hasPreviousRfq = sortedRfqs && sortedRfqs.length > 1;
    if (!hasPreviousRfq) {
        return {};
    }

    const previousRfq = sortedRfqs[sortedRfqs.length - 2];

    const editableOrder = await getEditableOrderById(order.orderId);
    if (!editableOrder) {
        return {};
    }
    const currentRfq = editableOrder.rfq;

    const wristRfq = sortedRfqs.filter((item) => item.origin === OrderRfqOrigin.WristIntegration);

    if (wristRfq.length > 1) {
        const previousWristRfq = wristRfq[wristRfq.length - 2];
        return { currentRfq, previousRfq, previousWristRfq: previousWristRfq };
    }

    return { currentRfq, previousRfq };
};

export interface Changelog {
    changes: RFQLineItemChangelog[];
    filteredChangelogForModal: RFQLineItemChangelog[];
    numberOfChangedProductsInModal: number;
}

type UseChangelog = (order: Order | undefined, products: Product[] | undefined) => Changelog;

// @todo  indexedDb performance
const useChangelog: UseChangelog = (order, products) => {
    const emptyChangelog = {
        changes: [],
        filteredChangelogForModal: [],
        numberOfChangedProductsInModal: 0,
    };

    const rfqs = useLiveQuery(() => getRfqsToCompare(order), [order], {
        currentRfq: undefined,
        previousRfq: undefined,
        previousWristRfq: undefined,
    });

    if (!order || !products || !rfqs) {
        return emptyChangelog;
    }

    const { currentRfq, previousRfq, previousWristRfq } = rfqs;

    const getChangedLineItems = (): RFQLineItemChangelog[] => {
        if (!currentRfq || !previousRfq) {
            return [];
        }
        const changes: RFQLineItemChangelog[] = [];

        previousRfq.lineItems.forEach((lineItemFromPreviousRfq) => {
            const legacyProductFromCurrentRfq = currentRfq.lineItems.find(
                (lineItemFromCurrentRfq) =>
                    lineItemFromCurrentRfq.itemNumber === lineItemFromPreviousRfq.itemNumber,
            );

            const priceChange = getConfirmedPricesChange(
                lineItemFromPreviousRfq,
                legacyProductFromCurrentRfq,
                previousWristRfq,
            );
            const amountChange = getLineItemChange(
                lineItemFromPreviousRfq.quantity,
                legacyProductFromCurrentRfq?.quantity,
            );
            const unitOfMeasureChange = getLineItemChange(
                lineItemFromPreviousRfq.unitOfMeasure ?? '',
                legacyProductFromCurrentRfq?.unitOfMeasure ?? '',
            );
            const packSizeChange = getPackSizeChange(
                lineItemFromPreviousRfq,
                legacyProductFromCurrentRfq,
            );

            const wasLineItemChanged = [
                priceChange,
                amountChange,
                unitOfMeasureChange,
                packSizeChange,
            ].some((item) => item.isModified);

            if (wasLineItemChanged || !legacyProductFromCurrentRfq) {
                changes.push({
                    status: legacyProductFromCurrentRfq ? 'updated' : 'removed',
                    itemNumber: lineItemFromPreviousRfq.itemNumber,
                    itemName: getProductName(products, lineItemFromPreviousRfq),
                    priceChange,
                    amountChange,
                    unitOfMeasureChange,
                    packSizeChange,
                });
            }
        });

        return changes;
    };

    const getAddedLineItems = (): RFQLineItemChangelog[] => {
        const changes: RFQLineItemChangelog[] = [];
        if (!currentRfq || !previousRfq) {
            return [];
        }

        const addedLineItems = currentRfq.lineItems.filter(
            (lineItemFromCurrentRfq) =>
                !previousRfq.lineItems.find(
                    (lineItemFromPreviousRfq) =>
                        lineItemFromPreviousRfq.itemNumber === lineItemFromCurrentRfq.itemNumber,
                ),
        );

        addedLineItems.forEach((addedLineItem) => {
            changes.push({
                status: 'added',
                itemNumber: addedLineItem.itemNumber,
                itemName: getProductName(products, addedLineItem),
                priceChange: {
                    currentValue: addedLineItem.confirmedPrice ?? addedLineItem.estimatedPrice ?? 0,
                    isModified: false,
                },
                amountChange: {
                    currentValue: addedLineItem.quantity,
                    isModified: false,
                },
                unitOfMeasureChange: {
                    currentValue: addedLineItem.unitOfMeasure ?? '',
                    isModified: false,
                },
                packSizeChange: {
                    currentValue:
                        addedLineItem.confirmedPackSize ?? addedLineItem.estimatedPackSize ?? 1,
                    isModified: false,
                },
            });
        });

        return changes;
    };

    const changes = [...getChangedLineItems(), ...getAddedLineItems()];
    changes.sort((changelog1, changelog2) => -changelog1.status.localeCompare(changelog2.status));

    // Only items with changed quantity or confirmedPrice, added and removed items should be shown in modal
    const filteredChangelogForModal = changes.filter(
        (change) =>
            change.priceChange?.isModified ||
            change.amountChange?.isModified ||
            change.status === 'added' ||
            change.status === 'removed',
    );

    const numberOfChangedProductsInModal = filteredChangelogForModal.length;

    return {
        changes,
        filteredChangelogForModal,
        numberOfChangedProductsInModal,
    };
};

export default useChangelog;
