import { useEffect, useMemo, useState } from 'react';
import { ProductCategory } from '../ProductsList';
import productsListsStyles from '../ProductsList.module.css';
import styles from './ProductCategoryRow.module.css';
import DoubleCheckmarkBlue from '../../../public/gfx/uiIcons/doubleCheckmarkBlue.svg';
import DoubleCheckmarkGreen from '../../../public/gfx/uiIcons/doubleCheckmarkGreen.svg';
import { List, SeparatorMode } from '../../List/List';
import { Field } from '../../List/Field';
import DecimalInput from '../../DecimalInput/DecimalInput';
import useOrderType from '../../../hooks/useOrderType';
import { useReceivedOrder } from '../../../hooks/useReceivedOrder';
import { ReceivedLineItem } from '../../../types/receivedOrder.types';
import ConfirmAllProductsConfirmationModal from '../../Receival/ReceivalModals/ConfirmAllProductsConfirmationModal';
import { useRouter } from 'next/router';
import CommentButton from '../../CommentButton/CommentButton';
import { Checkbox } from '../../Checkbox/Checkbox';
import {
    idPrefixForCategoryRowElement,
    idPrefixForProductRowElement,
} from '../../../hooks/useCategoriesBarScrollFunctions';
import { adjustLineItemValueToPackSizeAndUnit } from '../../utils/lineItemUtils';
import { Product } from '../../../types/order.types';
import { FlagButton as StartClaimInReceivalFlagButton } from '../../FlagButton/FlagButton';
import { useFeatureFlags } from '../../../context/useFeatureFlags';
import { useClaimsInReceivalFlow } from '../../../context/ClaimsInReceivalFlowContext';
import { useClaimsInReceival } from '../../../hooks/useClaimsInReceival';
import Tooltip from '../../Tooltips/Tooltip';
import { useItemsAvailableToClaim } from '../../../hooks/useItemsAvailableToClaim';

interface OrderedProduct {
    itemName: string;
    unitOfMeasure: string;
    estimatedPackSize: number;
    orderedQuantity?: number;
}

interface ListItem {
    id: string;
    orderedProduct: OrderedProduct;
    receivedLineItem: ReceivedLineItem;
}

interface ProductCategoryRowProps {
    productCategory: ProductCategory<Product>;
    setCommentViewIsActive: React.Dispatch<
        React.SetStateAction<{
            isOpen: boolean;
            itemNumber?: string | undefined;
        }>
    >;
    showConfirmed: boolean;
    setReceivedCategories: React.Dispatch<
        React.SetStateAction<ProductCategory<Product>[]>
    >;
    isSelected: boolean;
    className: string;
}

export const ProductCategoryRow: React.FC<ProductCategoryRowProps> = ({
    productCategory,
    setCommentViewIsActive,
    showConfirmed,
    setReceivedCategories,
    isSelected,
}) => {
    const { openClaimsInReceivalModal } = useClaimsInReceivalFlow();
    const { featureFlags } = useFeatureFlags();
    const { query } = useRouter();
    const [isProductsListExpanded, setIsProductsListExpanded] = useState(true);
    const { orderIdFromQuery } = useOrderType();

    const queryItemNumber = query?.itemNumber;

    const {
        bulkUpsertReceivedLineItems,
        updateReceivedItemQuantity,
        toggleReceivedItemIsReceived,
        data: receivedOrder,
    } = useReceivedOrder(Number(orderIdFromQuery));

    const { claimsInReceival } = useClaimsInReceival(Number(orderIdFromQuery));

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const [confirmedCategory, setConfirmedCategory] = useState<ProductCategory<Product>>();

    const listItems: ListItem[] = useMemo(() => {
        if (!receivedOrder) {
            return [];
        }

        return productCategory.products.map((orderedProduct) => ({
            id: orderedProduct.itemNumber,
            orderedProduct: {
                itemName: orderedProduct.itemName,
                unitOfMeasure: orderedProduct.unitOfMeasure,
                estimatedPackSize: orderedProduct.estimatedPackSize,
                orderedQuantity: orderedProduct.orderedQuantity,
            },
            receivedLineItem: receivedOrder.receivedLineItems[orderedProduct.itemNumber],
        }));
    }, [productCategory.products, receivedOrder]);

    const categoryReceived = useMemo(() => {
        const notReceivedItems = listItems?.filter(
            (listItem) => !listItem.receivedLineItem.isReceived,
        );
        return notReceivedItems.length === 0;
    }, [listItems]);

    useEffect(() => {
        setReceivedCategories((prev) =>
            categoryReceived
                ? [...prev, productCategory]
                : prev.filter((item) => item?.categoryNumber !== productCategory?.categoryNumber),
        );
    }, [categoryReceived]);

    const updateCategory = async () => {
        setIsConfirmationModalOpen(false);

        await bulkUpsertReceivedLineItems(
            listItems.map((listItem) => ({
                itemNumber: listItem.receivedLineItem.itemNumber,
                receivedLineItem: {
                    isReceived: !categoryReceived,
                },
            })),
        );
    };

    const { itemsAvailableToClaim } = useItemsAvailableToClaim();
    const getClaimedQuantity = (item: ListItem) => {
        return (
            itemsAvailableToClaim
                ?.find((claim) => claim.itemNumber === item.receivedLineItem.itemNumber)
                ?.orders.find((order) => order.orderId === Number(orderIdFromQuery))
                ?.claimedQuantity ?? 0
        );
    };

    return (
        <div
            id={`${idPrefixForCategoryRowElement}${productCategory.categoryName}`}
            className={`${isSelected ? styles.selectedCategory : ''} ${
                isProductsListExpanded ? styles.expandedBackground : ''
            }`}
        >
            <ConfirmAllProductsConfirmationModal
                isOpen={isConfirmationModalOpen}
                category={confirmedCategory}
                onClose={() => setIsConfirmationModalOpen(false)}
                onCancel={() => setIsConfirmationModalOpen(false)}
                onConfirm={updateCategory}
                confirm={!categoryReceived}
            />
            <div
                className={`${productsListsStyles.productCategoryRow} ${
                    isProductsListExpanded ? productsListsStyles.expandedProductsList : ''
                }`}
                onClick={() => setIsProductsListExpanded((prev) => !prev)}
            >
                <div className={`${productsListsStyles.tableCell} ${productsListsStyles.categoryName}`}>
                    <p>{productCategory.categoryName.toLowerCase()}</p>
                </div>
                <div className={productsListsStyles.tableCell} />
                <div className={productsListsStyles.tableCell} />
                <div className={productsListsStyles.tableCell}>
                    <button
                        className={styles.confirmAllButton}
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsConfirmationModalOpen(true);
                            setConfirmedCategory(productCategory);
                        }}
                        data-testid="confirmAllProductsButton"
                    >
                        {categoryReceived ? <DoubleCheckmarkGreen /> : <DoubleCheckmarkBlue />}
                    </button>
                </div>
            </div>
            {isProductsListExpanded && (
                <div className={`${productsListsStyles.whiteBackground}`}>
                    <List<ListItem>
                        rowElementIdPrefix={idPrefixForProductRowElement}
                        data={listItems.sort((a, b) => a.orderedProduct.itemName.localeCompare(b.orderedProduct.itemName))}
                        idSource="id"
                        showHeader={false}
                        separatorMode={SeparatorMode.betweenEach}
                        getIsLineItemReceived={(record) => ({
                            isReceived: record.receivedLineItem.isReceived,
                            showConfirmed,
                        })}
                        getAddedElementId={(record) => ({
                            addedElementId: Number(queryItemNumber),
                            elementId: parseInt(record.receivedLineItem.itemNumber),
                        })}
                    >
                        <Field<ListItem>
                            source=""
                            smallFont
                            bold
                            customWidth="40%"
                            withPadding={false}
                            renderFunction={({}, item) => (
                                <>
                                    {item?.orderedProduct.itemName ||
                                        item?.receivedLineItem?.itemName ||
                                        ''}
                                </>
                            )}
                        />
                        <Field<ListItem>
                            source=""
                            smallFont
                            grey
                            customWidth="15%"
                            renderFunction={({}, item) => {
                                if (!item) {
                                    return <></>;
                                }

                                const {
                                    orderedProduct: {
                                        orderedQuantity,
                                        unitOfMeasure,
                                    },
                                } = item;

                                return (
                                    <>
                                        {`${orderedQuantity ?? ''} ${
                                            orderedQuantity ? unitOfMeasure : ''
                                        }`.toUpperCase()}
                                    </>
                                );
                            }}
                            withPadding={false}
                        />
                        <Field<ListItem>
                            source=""
                            smallFont
                            grey
                            customWidth="20%"
                            renderFunction={({}, item) => {
                                if (!item) {
                                    return <></>;
                                }

                                const {
                                    receivedLineItem: {
                                        itemNumber,
                                        receivedQuantity,
                                        isReceived,
                                    },
                                    orderedProduct: {
                                        unitOfMeasure,
                                        estimatedPackSize,
                                        orderedQuantity,
                                    },
                                } = item;

                                const isInteger = !(
                                    unitOfMeasure.toLowerCase() === 'kg' ||
                                    unitOfMeasure.toLowerCase() === 'ltr'
                                );

                                return (
                                    <>
                                        <DecimalInput
                                            hasError={
                                                orderedQuantity
                                                    ? receivedQuantity >
                                                      orderedQuantity
                                                    : false
                                            }
                                            value={
                                                receivedQuantity.toString() ?? 0
                                            }
                                            lightInput={isReceived}
                                            onChange={async (changeValue) => {
                                                const roundedQuantity =
                                                    adjustLineItemValueToPackSizeAndUnit(
                                                        parseFloat(
                                                            changeValue.toString(),
                                                        ),
                                                        estimatedPackSize,
                                                        unitOfMeasure,
                                                    );

                                                await updateReceivedItemQuantity(
                                                    itemNumber,
                                                    roundedQuantity,
                                                );
                                            }}
                                            integer={isInteger}
                                        />
                                        &nbsp;
                                        {`${unitOfMeasure ?? ''}`.toUpperCase()}
                                    </>
                                );
                            }}
                            withPadding={false}
                        />
                        <Field<ListItem>
                            source=""
                            customWidth="25%"
                            renderFunction={({}, item) => {
                                if (!item) {
                                    return <></>;
                                }

                                const {
                                    receivedLineItem: {
                                        itemNumber,
                                        isReceived,
                                        comment,
                                    },
                                    orderedProduct: { orderedQuantity },
                                } = item;

                                const createdClaim = claimsInReceival?.find(
                                    (item) =>
                                        item.productItemNumber === itemNumber,
                                );

                                return (
                                    <span className={styles.buttons}>
                                        <CommentButton
                                            onClick={() => {
                                                setCommentViewIsActive({
                                                    isOpen: true,
                                                    itemNumber,
                                                });
                                            }}
                                            isActive
                                            hasComment={Boolean(
                                                comment?.length,
                                            )}
                                        />
                                        <>
                                            {featureFlags?.claimsInReceival && (
                                                <>
                                                    {getClaimedQuantity(
                                                        item,
                                                    ) === orderedQuantity ? (
                                                        <Tooltip
                                                            clickable
                                                            tooltipTitle="You've already submitted a claim for this item"
                                                            tooltipMessage={
                                                                <>
                                                                    <p>
                                                                        {`This item has been claimed on this order previously. You cannot
                                                            claim it again so this item has been removed.`}
                                                                    </p>
                                                                </>
                                                            }
                                                        >
                                                            <StartClaimInReceivalFlagButton
                                                                isActive={false}
                                                            />
                                                        </Tooltip>
                                                    ) : (
                                                        <StartClaimInReceivalFlagButton
                                                            onClick={() => {
                                                                if (
                                                                    !receivedOrder
                                                                )
                                                                    return;

                                                                openClaimsInReceivalModal(
                                                                    receivedOrder,
                                                                    itemNumber,
                                                                    createdClaim,
                                                                );
                                                            }}
                                                            isActive={Boolean(
                                                                createdClaim,
                                                            )}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            <Checkbox
                                                onClick={async () => {
                                                    await toggleReceivedItemIsReceived(
                                                        itemNumber,
                                                    );
                                                }}
                                                isChecked={isReceived}
                                            />
                                        </>
                                    </span>
                                );
                            }}
                            iconOnly
                            withPadding={false}
                        />
                    </List>
            </div>
            )}
        </div>
    );
};
