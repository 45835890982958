import React, { useState } from 'react';
import claimOrCondemnPreparationModalStyles from '../ClaimOrCondemnPreparationModal.module.css';
import styles from './AffectedAmountStep.module.css';
import Button from '../../Button/Button';
import DecimalInput from '../../DecimalInput/DecimalInput';
import modalStyles from '../../Modal/Modal.module.css';
import { getIssueNameHeaderText } from '../utils/itemIssueHelpers';
import Tooltip from '../../Tooltips/Tooltip';
import DetailsDataRow from '../../DetailsDataTable/DetailsDataRow';
import DetailsDataTable from '../../DetailsDataTable/DetailsDataTable';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import { adjustAmountToUnitOfMeasure } from '../../../utils/adjustAmountToUnitOfMeasure';
import { useLineItemDetailsFromOrder } from '../../../hooks/useLineItemDetailsFromOrder';
import { useStocktakingReports } from '../../../hooks/useStocktakingReports';
import { LimitedTextArea } from '../../LimitedTextArea/LimitedTextArea';
import { db } from '../../../db/db';
import {
    ItemIssueDetails,
    ItemIssueUIType,
    UpdateItemIssue,
} from '../ClaimOrCondemnPreparationModal';

interface AffectedAmountStepProps {
    itemIssueDetails: ItemIssueDetails;
    onBack: () => void;
    updateItemIssue: UpdateItemIssue;
    onContinue: () => void;
}

const AffectedAmountStep: React.FC<AffectedAmountStepProps> = ({
    itemIssueDetails,
    onBack,
    updateItemIssue,
    onContinue,
}) => {
    const isCondemn =
        itemIssueDetails.itemIssueType === ItemIssueUIType.Condemn;

    const {
        affectedAmount,
        order: claimOrder,
        selectedProduct,
    } = itemIssueDetails;
    const [isClaimQuantityTooHigh, setIsClaimQuantityTooHigh] = useState(false);

    const { stocktakingReports } = useStocktakingReports();
    const lastStocktakingReportItems =
        stocktakingReports && stocktakingReports[0]?.items;
    const chosenProductMaxStockQuantity = lastStocktakingReportItems?.find(
        (stockItem) =>
            stockItem.itemNumber ===
            itemIssueDetails.selectedProduct.itemNumber,
    )?.quantity;

    const { unitOfMeasure } = useLineItemDetailsFromOrder(
        selectedProduct.itemNumber,
        claimOrder,
    );

    const hasSelectedOrder = Boolean(itemIssueDetails.order);

    const headerText =
        (isCondemn ? 'Condemn: ' : '') +
        getIssueNameHeaderText(itemIssueDetails);

    const isButtonActive = Boolean(
        affectedAmount > 0 && !isClaimQuantityTooHigh,
    );

    const isAffectedQuantityTooHigh = (affectedQuantity: number) => {
        if (!isCondemn)
            setIsClaimQuantityTooHigh(() => {
                return (
                    hasSelectedOrder &&
                    Number(affectedQuantity) >
                        Number(claimOrder?.orderedQuantity) -
                            Number(claimOrder?.claimedQuantity)
                );
            });
    };

    return (
        <>
            <div
                className={claimOrCondemnPreparationModalStyles.headerContainer}
            >
                <p>{headerText}</p>
                <h1>What is the quantity affected?</h1>
            </div>
            <h1 className={claimOrCondemnPreparationModalStyles.itemName}>
                {itemIssueDetails.selectedProduct.name}
            </h1>
            <DetailsDataTable>
                <DetailsDataRow
                    title="Quantity affected"
                    boldTitle
                    value={
                        <div className={styles.inputContainer}>
                            <Tooltip
                                isOpen={isClaimQuantityTooHigh}
                                tooltipMessage="The quantity affected cannot exceed the quantity ordered. If you previously claimed the item, you can only claim remaining quantity."
                            >
                                <DecimalInput
                                    value={affectedAmount}
                                    onChange={(qty) => {
                                        isAffectedQuantityTooHigh(Number(qty));
                                        updateItemIssue(
                                            'affectedAmount',
                                            Number(qty),
                                        );
                                    }}
                                    onBlurValueConverter={(value) =>
                                        adjustAmountToUnitOfMeasure(
                                            unitOfMeasure,
                                            value,
                                        )
                                    }
                                    hasError={isClaimQuantityTooHigh}
                                />
                            </Tooltip>
                            <p
                                className={
                                    claimOrCondemnPreparationModalStyles.measuresText
                                }
                            >
                                {unitOfMeasure}
                            </p>
                        </div>
                    }
                />
                {isCondemn && (
                    <DetailsDataRow
                        title="Max stock quantity"
                        boldTitle
                        value={`${
                            chosenProductMaxStockQuantity ?? 0
                        } ${unitOfMeasure}`}
                    />
                )}
                {hasSelectedOrder && !isCondemn && (
                    <DetailsDataRow
                        title="Received"
                        boldTitle
                        value={`${claimOrder?.orderedQuantity} ${unitOfMeasure}`}
                    />
                )}
                {claimOrder &&
                    claimOrder?.claimedQuantity > 0 &&
                    !isCondemn && (
                        <DetailsDataRow
                            title="Previously claimed qty"
                            boldTitle
                            value={`${claimOrder?.claimedQuantity} ${unitOfMeasure}`}
                        />
                    )}
            </DetailsDataTable>
            {isCondemn && (
                <DetailsDataTable>
                    <DetailsDataRow
                        title={'Describe the issue'}
                        optionalTitle=" (Optional)"
                        boldTitle
                    />
                    <LimitedTextArea
                        name="commentInput"
                        placeholder="Additional notes on why the item is being condemned."
                        className={[
                            modalStyles.commentInput,
                            modalStyles.small,
                        ].join(' ')}
                        value={itemIssueDetails.comment}
                        onChange={(value) =>
                            updateItemIssue('comment', value ?? '')
                        }
                        characterLimit={db.defaults.axOrderLineStringFieldLimit}
                    />
                </DetailsDataTable>
            )}
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Continue"
                        onClick={() => onContinue()}
                        primary={isButtonActive}
                        disabled={!isButtonActive}
                    />
                </div>
            </div>
        </>
    );
};

export default AffectedAmountStep;
