import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { appEventHub } from '../../events/appEvents';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import modalStyles from '../Modal/Modal.module.css';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import styles from './UpdateAvailableModal.module.css';

export const triggerUpdateAvailableModal = () =>
    appEventHub.emit('appUpdateAvailable');

export const UpdateAvailableModal = () => {
    const { reload } = useRouter();
    const [isOpen, setIsOpen] = useState(false);

    const closeModal = () => setIsOpen(false);
    const update = () => {
        closeModal();
        reload();
    };

    useEffect(() => {
        const openModal = () => setIsOpen(true);
        appEventHub.on('appUpdateAvailable', openModal);

        return () => {
            appEventHub.removeListener('appUpdateAvailable', openModal);
        };
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={closeModal} noHeader>
            <div className={`${modalStyles.modalContent} ${styles.container}`}>
                <ModalHeader subTitle="Update required" />

                <p className={`${styles.text} ${modalStyles.center}`}>
                    We have released an updated version of Gateway. Please
                    update to make sure everything works correctly.
                </p>
                <p className={`${styles.text} ${modalStyles.center}`}>
                    Once you click “Update now”, Gateway will reload. Don’t
                    worry about your data, it is safely stored on the device.
                </p>
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Remind me later"
                        onClick={closeModal}
                        secondary
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Update now" onClick={update} primary />
                </div>
            </div>
        </Modal>
    );
};
