import { useAuth0 } from '@auth0/auth0-react';
import {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { db } from '../db/db';
import Loader from '../components/Loader/Loader';
import { dataFlowEventHub } from '../events/dataFlowEvents';
import { useOrders } from '../hooks/useOrders';
import { userDataDb } from '../db/userDataDb';

type ContextValue = {
    setIsAppLoading: Dispatch<SetStateAction<boolean>>;
    setIsLoaderDisplayed: Dispatch<SetStateAction<boolean>>;
    loadingPrices: boolean;
    isAppLoading: boolean;
};

const AppLoadingContext = createContext<ContextValue>({
    loadingPrices: false,
    isAppLoading: false,
    // Do nothing by default
    setIsAppLoading: () => {},
    setIsLoaderDisplayed: () => {},
});

export const AppLoadingProvider: React.FC<
    React.PropsWithChildren<Record<never, never>>
> = ({ children }) => {
    const [loadingPrices, setLoadingPrices] = useState(false);
    const [isAppLoading, setIsAppLoading] = useState(true);
    const { isAuthenticated, isLoading, user } = useAuth0();
    const { isValidatingOrders: isLoadingOrders } = useOrders();
    const [loadingError, setLoadingError] = useState<unknown>(null);
    const [isLoaderDisplayed, setIsLoaderDisplayed] = useState(true);
    const hasRestored = useRef(false);
    const isRestoring = useRef(false);

    const restoreDb = async () => {
        if (hasRestored.current || !isAuthenticated) {
            return true;
        }

        if (isRestoring.current || !user?.sub) {
            return false;
        }

        isRestoring.current = true;

        try {
            const dbExport = await userDataDb.exports.get(user.sub);
            if (dbExport) {
                console.info(
                    '[AppLoading] Restoring IndexedDb from previous user session',
                );

                // Delete previous database to make sure we can support
                // imports from older versions, running upgrades.
                // See https://github.com/dexie/Dexie.js/issues/1337#issuecomment-866687433
                await db.delete();
                const { importDB } = await import('dexie-export-import');
                const tempDb = await importDB(dbExport.data);
                tempDb.close();
                await db.open();
            }
        } catch (error) {
            setLoadingError(error);
            console.error(error);
        } finally {
            await userDataDb.exports.delete(user.sub);
        }

        hasRestored.current = true;

        return true;
    };

    const checkIsAppLoaded = async () => {
        try {
            if (isLoading) {
                return false;
            }

            if (!db.isOpen()) {
                if (!loadingError) {
                    await db.open();
                }
                return false;
            }

            if (!isAuthenticated) {
                return true;
            }

            if (isLoadingOrders) {
                return false;
            }

            const areProductsLoaded = await db.products.count(
                (count) => count > 0,
            );
            const arePortsLoaded = await db.ports.count((count) => count > 0);
            const areAssortmentsLoaded = await db.assortments.count(
                (count) => count > 0,
            );

            if (areProductsLoaded && arePortsLoaded && areAssortmentsLoaded) {
                return true;
            }

            return false;
        } catch (error) {
            setLoadingError(error);
            console.error(error);
        }
    };

    useEffect(() => {
        if (loadingError) {
            throw loadingError;
        }
    }, [loadingError]);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            if ((await checkIsAppLoaded()) && (await restoreDb())) {
                if (isAppLoading) {
                    setIsAppLoading(false);
                    dataFlowEventHub.emit('preloadData');
                }

                if (intervalId) {
                    clearInterval(intervalId);
                }
            }
        }, 1000);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isLoading, isAuthenticated, isLoadingOrders, db.isOpen()]);

    useEffect(() => {
        dataFlowEventHub.on('loadingPrices', (isLoading) => {
            setLoadingPrices(isLoading);
        });
    }, []);

    return (
        <AppLoadingContext.Provider
            value={{
                setIsAppLoading,
                loadingPrices,
                isAppLoading,
                setIsLoaderDisplayed,
            }}
        >
            {isLoaderDisplayed ? <Loader /> : children}
        </AppLoadingContext.Provider>
    );
};

export const useAppLoading = () => {
    const context = useContext(AppLoadingContext);

    return context;
};
