/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = None
 *
 * 1 = EntitySet
 *
 * 2 = ActionImport
 *
 * 3 = FunctionImport
 *
 * 4 = Singleton
 */
export enum EdmContainerElementKind {
    None = 0,
    EntitySet = 1,
    ActionImport = 2,
    FunctionImport = 3,
    Singleton = 4,
}