/**
 * This method applies global rules for formatting amount of product
 */
export const adjustAmountToUnitOfMeasure = (
    unit: string | undefined,
    amount: string | number,
) => {
    if (!unit) return amount;

    if (!unit.includes('LTR') && !unit.includes('KG')) {
        return Math.floor(Number(amount));
    }

    return amount;
};
