import { useReceivedOrder } from '../../../../hooks/useReceivedOrder';
import DetailsDataRow from '../../../DetailsDataTable/DetailsDataRow';
import DetailsDataTable from '../../../DetailsDataTable/DetailsDataTable';
import { OrderToDisplay } from '../../../../types/order.types';
import GreenCheckmarkIcon from '../../../../public/gfx/uiIcons/greenCheckmark.svg';
import RedCrossIcon from '../../../../public/gfx/uiIcons/redCross.svg';
import { supplierExperienceSurveyAnswers } from './supplierExperienceSurveyAnswers';

interface SupplierExperienceSummaryProps {
    orderToDisplay: OrderToDisplay;
}

export const SupplierExperienceSummary: React.FC<
    SupplierExperienceSummaryProps
> = ({ orderToDisplay }) => {
    const { data: receivedOrder } = useReceivedOrder(orderToDisplay.orderId);

    const getSupplierExperience = () => {
        const { experience: supplierExperience } =
            receivedOrder?.performanceReport ?? {};

        if (!supplierExperience) {
            return [];
        }

        const mappedSupplierExperience = supplierExperienceSurveyAnswers.map(
            (item) => {
                return {
                    description: item.description,
                    answer: supplierExperience[item.key],
                };
            },
        );

        return mappedSupplierExperience;
    };

    const supplierExperience = getSupplierExperience();

    return (
        <DetailsDataTable lessSpacing>
            <DetailsDataRow
                title="Supplier experience"
                uppercaseTitle
                boldTitle
            />
            {supplierExperience.map((item) => (
                <DetailsDataRow
                    key={item.description}
                    icon={item.answer ? GreenCheckmarkIcon : RedCrossIcon}
                    title={item.description}
                    boldValue
                    iconAriaLabel={`${item.description}: ${
                        item.answer ? 'Yes' : 'No'
                    }`}
                />
            ))}
        </DetailsDataTable>
    );
};
