import { User } from '@auth0/auth0-spa-js';
import { fetchInitialStocktakingReports } from '../../hooks/useStocktakingReports';
import { getCurrentMonthForStocktakingFlow } from '../../utils/getCurrentStocktakingMonth';

export const getCurrentMonthForStocktakingWithoutHooks = async (
    user: User | undefined,
) => {
    if (!user) {
        return null;
    }
    const stocktakingReports = await fetchInitialStocktakingReports();

    const stocktakingCurrentMonth = getCurrentMonthForStocktakingFlow(
        user,
        stocktakingReports ?? [],
    );

    return stocktakingCurrentMonth;
};
