import {
    dataFlowEventHub,
    SWRCacheKeys,
    swrDefaultConfig,
} from '../events/dataFlowEvents';
import useSWR, { mutate } from 'swr';
import { db } from '../db/db';

const provisionThreshold = 1999;
export const fetchProvisionProductsDbData = () =>
    db.products
        .filter(
            (product) => product.categoryLevel3?.number < provisionThreshold,
        )
        .toArray();

dataFlowEventHub.on('productsChanged', () =>
    mutate(SWRCacheKeys.products, undefined, { revalidate: true }),
);
dataFlowEventHub.on('preloadData', () =>
    mutate(SWRCacheKeys.products, undefined, { revalidate: true }),
);

export const useProvisionProducts = () => {
    const { data } = useSWR(
        SWRCacheKeys.provisionProducts,
        fetchProvisionProductsDbData,
        swrDefaultConfig,
    );

    return { data: data ?? [] };
};
