import { OrderToDisplayInOrdersList } from '../../types/order.types';
import { OrderTypes } from '../../context/OrderTypes';
import { v4 } from 'uuid';
import { CashPurchaseDto } from '../../apiClient/generated';

export const adjustCashPurchaseOrderToDisplayInOrdersList = (
    cashOrder: CashPurchaseDto,
): OrderToDisplayInOrdersList => ({
    localOrderId: v4(),
    wbPono: cashOrder.wbPoNo,
    orderId: cashOrder.id,
    type: OrderTypes.cash,
    lineItems: cashOrder.items ?? [],
    deliveryPort: {
        portName: cashOrder?.deliveryPort?.portName,
        portCode: cashOrder?.deliveryPort?.portCode,
    },
    deliveryDate: new Date(cashOrder.deliveryDate),
    lastUpdated: new Date(cashOrder.lastModified ?? ''),
    currency: cashOrder.currency,
    cashDiscount: cashOrder.cashDiscount,
    status: cashOrder.status,
    origin: cashOrder.origin,
});
