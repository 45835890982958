import { getAndUpdateEditableOrders } from '../db/editableOrders';
import { SWRCacheKeys, dataFlowEventHub } from '../events/dataFlowEvents';

import useSWR, { mutate } from 'swr';

const fetchDbData = () => getAndUpdateEditableOrders();

dataFlowEventHub.on('editableOrdersChanged', () => {
    mutate(SWRCacheKeys.editableOrders, undefined, {
        revalidate: true,
    });
});

export const useEditableOrders = () => {
    const {
        data,
        isValidating: isEditableOrderValidating,
        isLoading,
    } = useSWR(SWRCacheKeys.editableOrders, () => fetchDbData());

    return {
        data,
        isEditableOrderValidating,
        isLoading,
    };
};