import { ItemIssueProblem } from '../../../apiClient/generated';
import { claimBaseReasons } from '../../ClaimOrCondemnPreparationModal/utils/selectableOptions';
import { CondemnReasons } from '../../StocktakingProductsList/utils/stocktakingUtils';

export const getItemIssueLabel = (
    itemIssueProblem: ItemIssueProblem | CondemnReasons | null,
) => {
    if (!itemIssueProblem) return 'Select issue type';

    const label = claimBaseReasons.find(
        (reason) => reason.itemIssueProblem === itemIssueProblem,
    )?.text;

    if (!label) {
        console.error('label is not defined');
        return 'Other';
    }

    return label;
};
