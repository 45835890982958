import { useOrder } from './useOrder';
import { useEditableOrder } from './useEditableOrder';
import { adjustOrderToDisplay } from '../components/utils/adjustOrderToDisplay';
import { usePrices } from './usePrices';
import useOrderType from './useOrderType';
import { OrderToDisplay } from '../types/order.types';

export const useOrderToDisplay = () => {
    const { orderIdFromQuery } = useOrderType();

    const { data: order } = useOrder(orderIdFromQuery);

    const {
        data: editableOrder,
        saveDraft,
        changeEditableOrderItemStatus,
    } = useEditableOrder(orderIdFromQuery);

    const getOrderToDisplay = (): OrderToDisplay | undefined => {
        if (editableOrder) {
            return editableOrder;
        }
        if (order) {
            return adjustOrderToDisplay(order);
        }
    };

    usePrices(editableOrder);

    return {
        orderToDisplay: getOrderToDisplay(),
        changeEditableOrderItemStatus,
        saveEditableOrderDraft: saveDraft,
    };
};
