import {
    EditableOrder,
    OrderToDisplayInOrdersList,
} from '../../types/order.types';

export const adjustEditableOrderToDisplayInOrdersList = (
    editableOrder: EditableOrder,
): OrderToDisplayInOrdersList => ({
    localOrderId: editableOrder.localOrderId,
    type: editableOrder.type,
    lineItems: editableOrder.rfq.lineItems ?? [],
    deliveryPort: editableOrder.rfq.deliveryPort ?? {
        portName: '',
        portCode: '',
    },
    deliveryDate: new Date(editableOrder.rfq.deliveryDate),
    status: editableOrder.status,
    lastUpdated: new Date(editableOrder.rfq.created ?? ''),
    isSending: editableOrder.isSending,
    draftName: editableOrder.draftName,
    currency: editableOrder.rfq.contractCurrency,
    orderId: editableOrder.orderId ?? undefined
});
