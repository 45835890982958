// Business formula: https://dev.azure.com/wristohoi-setapp/Wrist%20Generate/_wiki/wikis/Wrist-Generate.wiki/28/Business-formulas

import {
    LineItem,
    OrderToDisplay,
    Product,
    RFQ,
} from '../../types/order.types';
import { LineItemWithProductDetails } from '../../db/utils/products';
import { currencyFormatter } from './numberFormat';
import { VesselMetadataResponse } from '../../apiClient/generated';

interface NutitionGroup {
    meatAndSeafood: number;
    fruitsAndVegetables: number;
    pastaAndRice: number;
}

export interface OrderSummaryCalculated {
    nutritionsPerPersonPerDay: NutitionGroup;
    nutritionsSum: NutitionGroup;
    nutritionsMax: NutitionGroup;
    orderBudget: number;
    orderTotal: number;
    orderBudgetReservedForFreshTopUps: number;
}

export interface KgAmountSummary {
    meatAndSeafood: number;
    fruitsAndVegetables: number;
    pastaAndRice: number;
}

export const getAmountInKg = (
    nutritionArr: string[],
    orderedProducts:
        | LineItemWithProductDetails[]
        | { lineItem: LineItem; product: Product }[],
) => {
    const productsOfNutrition = orderedProducts.map((item) =>
        nutritionArr.includes(item.product.categoryLevel2.text) &&
        item.product.unitOfMeasure.toLowerCase() === 'kg'
            ? item.lineItem.quantity
            : 0,
    );
    const result = productsOfNutrition.reduce((prev, curr) => prev + curr, 0);

    return result;
};

const getNutritionMax = (
    consumptionRateClaim: number,
    order: Pick<
        RFQ,
        'coveringDays' | 'manning' | 'deliveryDate' | 'deliveryPort'
    >,
) => {
    return consumptionRateClaim * order.manning * order.coveringDays;
};

export const parseLineItemsToLineItemsWithProductDetails = (
    lineItems: LineItem[],
    products: Product[],
): LineItemWithProductDetails[] => {
    const lineItemWithProductDetails =
        lineItems.map((item) => {
            const product = products.find(
                (p) => p.itemNumber === item.itemNumber,
            );

            if (!product) {
                return null;
            }

            return {
                product: product,
                lineItem: item,
            };
        }) ?? [];

    const result = lineItemWithProductDetails.filter(
        (item) => item !== null,
    ) as LineItemWithProductDetails[];

    return result;
};

export const getLineItemsWithProductDetails = (
    order: OrderToDisplay | undefined | null,
    products: Product[] | undefined,
): LineItemWithProductDetails[] => {
    if (!order || !products || !order.rfq?.lineItems) return [];

    return parseLineItemsToLineItemsWithProductDetails(
        order.rfq?.lineItems,
        products,
    );
};

export const calculateSummary = (
    products: Product[],
    orderToDisplay: OrderToDisplay,
    vesselMetadata: VesselMetadataResponse,
): OrderSummaryCalculated => {
    const orderDetails = orderToDisplay.rfq;

    const parsedClaims = {
        consumptionRateOfFruitsAndVeggies:
            vesselMetadata.dayAllowanceCategory1Amount,
        consumptionRateOfMeatAndSeafood:
            vesselMetadata.dayAllowanceCategory2Amount,
        consumptionRateOfPastaAndRice:
            vesselMetadata.dayAllowanceCategory3Amount,
    };

    const lineItemsWithProductDetails = getLineItemsWithProductDetails(
        orderToDisplay,
        products,
    );

    const orderTotalValue = lineItemsWithProductDetails.reduce(
        (previous, current) => {
            const { lineItem } = current;

            const price =
                lineItem.confirmedPrice ?? lineItem?.estimatedPrice ?? 0;

            const totalPriceOfCurrentLineItem = lineItem.quantity * price;
            const orderTotalValue = previous + totalPriceOfCurrentLineItem;

            return orderTotalValue;
        },
        0,
    );

    const nutritionsSumsInKg: KgAmountSummary = {
        meatAndSeafood: getAmountInKg(
            ['Meat', 'Seafood', 'Poultry'],
            lineItemsWithProductDetails,
        ),
        fruitsAndVegetables: getAmountInKg(
            ['Fruit and Vegetables'],
            lineItemsWithProductDetails,
        ),
        pastaAndRice: getAmountInKg(
            ['Pasta and Noodles', 'Rice and Oats'],
            lineItemsWithProductDetails,
        ),
    };

    const nutritionsMax: KgAmountSummary = {
        meatAndSeafood: getNutritionMax(
            parsedClaims.consumptionRateOfMeatAndSeafood,
            orderDetails,
        ),
        fruitsAndVegetables: getNutritionMax(
            parsedClaims.consumptionRateOfFruitsAndVeggies,
            orderDetails,
        ),
        pastaAndRice: getNutritionMax(
            parsedClaims.consumptionRateOfPastaAndRice,
            orderDetails,
        ),
    };

    const getNutritionsPerPersonPerDay = () => {
        const nutritionsPerPersonPerDay: KgAmountSummary = {
            meatAndSeafood: 0,
            fruitsAndVegetables: 0,
            pastaAndRice: 0,
        };

        for (const [key, value] of Object.entries(nutritionsSumsInKg)) {
            nutritionsPerPersonPerDay[key as keyof KgAmountSummary] =
                value / orderDetails.manning / orderDetails.coveringDays;
        }

        return nutritionsPerPersonPerDay;
    };

    const getBudget = () => {
        const reserveForDiscrepancies = 0.95; // 5% of budget reserved for discrepancies in pack sizes etc.
        const freshTopUpsThresholdInDays = 30; // If orders are over 30 days, we reserve 30% of the budget beyond the 30 days for Fresh Top Ups)

        const orderBudget =
            vesselMetadata.dailyBudgetRateInVesselCurrency *
            orderDetails.manning *
            orderDetails.coveringDays *
            reserveForDiscrepancies;

        const shouldReserveBudgetForFresTopUps =
            orderDetails.coveringDays > freshTopUpsThresholdInDays;

        if (shouldReserveBudgetForFresTopUps) {
            const reserveForFreshTopUps = 0.3;

            const orderBudgetReservedForFreshTopUps =
                vesselMetadata.dailyBudgetRateInVesselCurrency *
                orderDetails.manning *
                (orderDetails.coveringDays - freshTopUpsThresholdInDays) *
                reserveForDiscrepancies *
                reserveForFreshTopUps;

            const reducedOrderBudget =
                orderBudget - orderBudgetReservedForFreshTopUps;

            return {
                orderBudget: reducedOrderBudget,
                orderBudgetReservedForFreshTopUps:
                    orderBudgetReservedForFreshTopUps,
            };
        }
        return {
            orderBudget,
            orderBudgetReservedForFreshTopUps: 0,
        };
    };

    const { orderBudget, orderBudgetReservedForFreshTopUps } = getBudget();

    const result = {
        orderTotal: orderTotalValue,
        nutritionsPerPersonPerDay: getNutritionsPerPersonPerDay(),
        nutritionsSum: nutritionsSumsInKg,
        nutritionsMax,
        orderBudget,
        orderBudgetReservedForFreshTopUps,
    };

    return result;
};

export const getOrderTotalFromCompletedOrder = (
    rfq: RFQ,
    vesselCurrency: string,
) => {
    const totals: number[] = [];
    rfq?.lineItems.forEach((item) => {
        if (item.confirmedPrice)
            totals.push(item.quantity * item.confirmedPrice);
    });
    const total = totals.reduce((previous, current) => previous + current, 0);
    return currencyFormatter(total, vesselCurrency);
};
