import { useState } from 'react';
import { OrderToDisplay } from '../types/order.types';

export enum FailedRequestModalType {
    claim = 'claim',
    provisionOrder = 'order',
}

export type FailedRequestModalMetadata = null | {
    orderToDisplay?: OrderToDisplay;
    claimDetails?: null;
    isUnavailableItemModal?: boolean;
    unavailableItemModalMessage?: string;
    unavailableItemButtonText?: string;
};

export const useFailedRequestModal = () => {
    const [failedRequestModalMetadata, setFailedRequestModalMetadata] =
        useState<FailedRequestModalMetadata>(null);

    const putFailedRequestModalMetadata = (
        metadata?: FailedRequestModalMetadata,
    ) => {
        if (!metadata) {
            setFailedRequestModalMetadata(null);
            return;
        }
        setFailedRequestModalMetadata(metadata);
    };

    return { putFailedRequestModalMetadata, failedRequestModalMetadata };
};
