import React from 'react';
import { ModalType, useModalContext } from '../../context/ModalContext';
import AddIcon from '../../public/gfx/uiIcons/add.svg';
import Button from '../Button/Button';

interface Props {
    blue?: boolean;
    transparent?: boolean;
    onClick?: () => void;
}
export const NewOrderButton: React.FC<Props> = ({ blue, transparent, onClick }) => {
    const { openGlobalModal } = useModalContext();

    const iconColor = [blue ? '#fff' : '', transparent ? 'black' : ''].join(' ');

    return (
        <Button
            onClick={() => {
                if (onClick) {
                    onClick();
                }
                openGlobalModal({
                    modalType: ModalType.orderPreparation,
                });
            }}
            text="New order"
            smallRounded
            blueCta={blue}
            transparentBlack={transparent}
            leftIcon={<AddIcon width="12" height="12" stroke={iconColor} />}
        />
    );
};
