/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Gateway
 *
 * 1 = AX
 */
export enum CashPurchaseOrigin {
    Gateway = 0,
    AX = 1,
}