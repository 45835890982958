import { ItemStatus } from './itemStatuses.types';

export interface Meal {
    id: number;
    name: string;
}

export interface AttendeeLog {
    manningTypeId: number;
    value: number;
}

export interface MannigMealLog {
    mealId: number;
    attendees: AttendeeLog[];
    comment?: string;
}

export interface ManningDayLog {
    date: string; // YYYY-MM-DD
    comment?: string;
    meals: MannigMealLog[];
}

export interface ManningOptions {
    manningLabel: string;
    manningTypeId: number;
    meals: number[];
    commentMandatory: boolean;
}

export interface ManningReport {
    id?: number; // from API
    monthAndYear: string; //  'YYYY-MM'
    state: ItemStatus;
    days: ManningDayLog[];
    manningOptions: ManningOptions[]; // todo: propably it will exist only if manningReport.state === draft
    localId: string;
    lastModified: Date;
    comment?: string;
    previousState?: ItemStatus;
}

export interface Attendee {
    manningLabel: string;
    manningTypeId: number;
    value: number;
    commentMandatory: boolean;
}

export interface MealLogAdjustedToUI {
    mealName: string;
    id: number;
    attendees: Attendee[];
    comment?: string;
}

export const hardcodedMealTypes: Meal[] = [
    { id: 0, name: 'Breakfast' },
    { id: 1, name: 'Lunch' },
    { id: 2, name: 'Dinner' },
    { id: 3, name: 'Night Meal' },
];
