import styles from '../CommentButton/CommentButton.module.css';
import FlagIcon from '../../public/gfx/uiIcons/flag.svg';
import ActiveFlagIcon from '../../public/gfx/uiIcons/activeFlag.svg';

interface Props {
    onClick?: () => void;
    isActive: boolean;
}

export const FlagButton: React.FC<Props> = ({ onClick, isActive }) => {
    return (
        <button
            className={[styles.commentButton, !onClick && styles.disabled].join(
                ' ',
            )}
            onClick={onClick}
        >
            {isActive ? (
                <ActiveFlagIcon width={19} height={18} />
            ) : (
                <FlagIcon width={16} height={15} />
            )}
        </button>
    );
};

export default FlagButton;
