import React, { useMemo } from 'react';
import Button from '../../Button/Button';
import { OrderToDisplay } from '../../../types/order.types';
import { List } from '../../List/List';
import { Field } from '../../List/Field';
import { getShortWbPono } from '../../utils/wbPono';
import { adjustOrderToDisplay } from '../../utils/adjustOrderToDisplay';
import moment from 'moment';
import modalStyles from '../../Modal/Modal.module.css';
import claimOrCondemnPreparationModalStyles from '../ClaimOrCondemnPreparationModal.module.css';
import styles from './ClaimsOrderSelectionStep.module.css';
import { getIssueNameHeaderText } from '../utils/itemIssueHelpers';
import {
    ItemIssueDetails,
    UpdateItemIssue,
    ClaimOrder,
} from '../ClaimOrCondemnPreparationModal';
import CountryFlag from '../../Flags/CountryFlag';
import { useOrders } from '../../../hooks/useOrders';
import Tooltip from '../../Tooltips/Tooltip';
import InfoIcon from '../../../public/gfx/uiIcons/infoIconBlack.svg';
import InfoIconGrey from '../../../public/gfx/uiIcons/infoIcon.svg';
import { useVesselMetadata } from '../../../hooks/useVesselMetadata';
import { useItemsAvailableToClaim } from '../../../hooks/useItemsAvailableToClaim';

interface CantFindOrderTooltipProps {
    seaStarEmail?: string;
    onSwitchToCondemn?: () => void;
}

interface ClaimIsAlreadySubmittedProps extends CantFindOrderTooltipProps {
    itemName: string | null;
    itemNumber?: number;
    itemOrderNumber?: number;
}
interface ClaimsOrderSelectionStepProps {
    onBack: () => void;
    updateClaim: UpdateItemIssue;
    claimDetails: ItemIssueDetails;
    onContinue: () => void;
    onSwitchToCondemn?: () => void;
}

const CantFindOrderTooltip: React.FC<CantFindOrderTooltipProps> = ({
    seaStarEmail,
    onSwitchToCondemn,
}) => {
    return (
        <div className={styles.tooltipContainer}>
            <Tooltip
                clickable
                tooltipTitle="The order might be too old to open a claim"
                tooltipMessage={
                    <>
                        <p>
                            We only display orders from the last 6 months that
                            contain the item.
                        </p>
                        <p>
                            Items delivered more than 6 months ago can only be
                            condemned. You can continue to report this product
                            as condemn item.
                        </p>
                        <button
                            onClick={onSwitchToCondemn}
                            className={styles.tooltipLink}
                        >
                            Switch to condemn
                        </button>
                        <p>
                            If you need help please contact{' '}
                            <strong>{seaStarEmail ?? ''}</strong>
                        </p>
                    </>
                }
            >
                <div className={styles.tooltipChildren}>
                    <InfoIcon
                        className={styles.tooltipInfoIcon}
                        width="16"
                        height="16"
                    />
                    <strong>Can't find the order number? </strong>
                </div>
            </Tooltip>
        </div>
    );
};

const ClaimIsAlreadySubmittedTooltip: React.FC<
    ClaimIsAlreadySubmittedProps
> = ({ itemName, seaStarEmail }) => {
    return (
        <div className={styles.tooltipContainer}>
            <Tooltip
                clickable
                tooltipTitle="You've already submitted a claim for this item"
                tooltipMessage={
                    <>
                        <p>
                            {`The item ${
                                itemName ?? ''
                            } has been claimed on this order previously. You cannot
                            claim it again so this item has been removed.`}
                        </p>

                        <p>
                            If you need help please contact{' '}
                            <strong>{seaStarEmail ?? ''}</strong>
                        </p>
                    </>
                }
            >
                <div className={styles.tooltipChildren}>
                    <InfoIconGrey
                        className={styles.tooltipInfoIcon}
                        width="16"
                        height="16"
                    />
                </div>
            </Tooltip>
        </div>
    );
};

const ClaimsOrderSelectionStep: React.FC<ClaimsOrderSelectionStepProps> = ({
    onBack,
    claimDetails,
    updateClaim,
    onContinue,
    onSwitchToCondemn,
}) => {
    const {
        order: claimOrder,
        selectedProduct: { itemNumber: reportedItemId, name },
    } = claimDetails;
    const { data: orders } = useOrders();

    const { itemsAvailableToClaim } = useItemsAvailableToClaim();
    const selectedItemOrders = itemsAvailableToClaim?.find(
        (item) => item.itemNumber === reportedItemId,
    )?.orders;

    const claimedItemOrders: ClaimOrder[] = useMemo(() => {
        const claimedItemOrders: ClaimOrder[] = [];
        const convertedOrders = orders?.map(adjustOrderToDisplay) ?? [];

        selectedItemOrders?.map((selectedItemOrder) => {
            const foundOrder = convertedOrders?.find(
                (order) => order.orderId === selectedItemOrder.orderId,
            );
            if (foundOrder)
                claimedItemOrders.push({
                    ...foundOrder,
                    orderedQuantity: selectedItemOrder.orderedQuantity ?? 0,
                    claimedQuantity: selectedItemOrder.claimedQuantity ?? 0,
                });
        });
        return claimedItemOrders;
    }, [orders, selectedItemOrders]);

    const issueNameHeaderText = getIssueNameHeaderText(claimDetails);

    const getParsedCreationDate = (createdAt: Date) => {
        const wasCreatedToday = moment(createdAt).isSame(moment(), 'day');

        if (wasCreatedToday) {
            return 'Today';
        } else {
            return moment(createdAt).format('DD.MM.YYYY');
        }
    };

    const {
        data: { seaStarEmail },
    } = useVesselMetadata();

    const isButtonActive = Boolean(claimOrder);

    const isRowDisabled = (claimOrder: ClaimOrder | null) => {
        if (claimOrder)
            return claimOrder.claimedQuantity === claimOrder.orderedQuantity;
        else return false;
    };

    return (
        <>
            <div
                className={claimOrCondemnPreparationModalStyles.headerContainer}
            >
                <p>{`Claim: ${issueNameHeaderText}`}</p>
                <h1>Which order was this from?</h1>
            </div>
            <div
                data-testid="orderSelectionStep"
                className={styles.minHeightContentContainer}
            >
                <div
                    className={`${claimOrCondemnPreparationModalStyles.contentContainer} ${claimOrCondemnPreparationModalStyles.slimFit}`}
                >
                    <List
                        data={claimedItemOrders}
                        idSource="orderId"
                        onRowClick={(record) => {
                            updateClaim('order', record);
                        }}
                        getIsRowActive={(record) =>
                            record.orderId === claimOrder?.orderId
                        }
                        applySelectableRowStyle
                        getIsRowDisabled={(record) => isRowDisabled(record)}
                        endingRowIcon={(record) => {
                            if (isRowDisabled(record)) {
                                return (
                                    <ClaimIsAlreadySubmittedTooltip
                                        itemName={name}
                                        seaStarEmail={seaStarEmail}
                                    />
                                );
                            } else return <></>;
                        }}
                    >
                        <Field<OrderToDisplay>
                            title="Delivery port"
                            source=""
                            renderFunction={({}, orderToDisplay) => {
                                const { portName, portCode } =
                                    orderToDisplay?.rfq.deliveryPort ?? {};

                                return (
                                    <span className={styles.cell}>
                                        <CountryFlag portCode={portCode} />
                                        &nbsp;
                                        {portName ?? 'N/A'}
                                    </span>
                                );
                            }}
                            smallFont
                        />
                        <Field<OrderToDisplay>
                            title="Delivery date"
                            source=""
                            renderFunction={({}, orderToDisplay) => (
                                <span className={styles.cell}>
                                    {orderToDisplay?.rfq.deliveryDate
                                        ? getParsedCreationDate(
                                              new Date(
                                                  orderToDisplay.rfq.deliveryDate,
                                              ),
                                          )
                                        : 'N/A'}
                                </span>
                            )}
                            grey
                            smallFont
                        />
                        <Field<OrderToDisplay>
                            source=""
                            renderFunction={({}, orderToDisplay) => {
                                if (orderToDisplay?.rfq.wbPono) {
                                    return (
                                        <span className={styles.cell}>
                                            {getShortWbPono(
                                                orderToDisplay.rfq.wbPono,
                                            )}
                                        </span>
                                    );
                                }
                                return <>pending</>;
                            }}
                            title="Order"
                            grey
                            smallFont
                        />
                        <Field<OrderToDisplay>
                            title="Supplier"
                            source=""
                            renderFunction={({}, orderToDisplay) => (
                                <span className={styles.cell}>
                                    {orderToDisplay?.rfq.supplier?.name
                                        ? orderToDisplay.rfq.supplier.name
                                        : 'N/A'}
                                </span>
                            )}
                            grey
                            smallFont
                        />
                    </List>
                    <CantFindOrderTooltip
                        seaStarEmail={seaStarEmail}
                        onSwitchToCondemn={onSwitchToCondemn}
                    />
                </div>
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Continue"
                        onClick={onContinue}
                        disabled={!isButtonActive}
                        primary={isButtonActive}
                    />
                </div>
            </div>
        </>
    );
};

export default ClaimsOrderSelectionStep;
