import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import modalStyles from '../../components/Modal/Modal.module.css';
import styles from './ProductDetailsModal.module.css';
import Button from '../../components/Button/Button';
import { currencyFormatter } from '../utils/numberFormat';
import useOrderType from '../../hooks/useOrderType';
import { adjustLineItemValueToPackSizeAndUnit } from '../utils/lineItemUtils';
import mixpanel from 'mixpanel-browser';
import { trackingEvents } from '../../context/Tracking';
import DecimalInput from '../DecimalInput/DecimalInput';
import {
    putCommentToLineItemInEditableOrder,
    updateLineItemQuantity,
} from '../../db/editableOrders';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { AddCommentToLineItem } from '../AddCommentToLineItem/AddCommentToLineItem';
import { useProductDetails } from '../../context/ProductDetailsContext';
import { useEditableOrder } from '../../hooks/useEditableOrder';
import CommentButton from '../CommentButton/CommentButton';
import { Portal } from '@radix-ui/react-portal';
import { dataFlowEventHub } from '../../events/dataFlowEvents';
import { OrderStatus } from '../../apiClient/generated';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import { useFeatureFlags } from '../../context/useFeatureFlags';

const ProductDetailsModal = () => {
    const productDetails = useProductDetails();
    const { featureFlags } = useFeatureFlags();
    const { activeOrderType } = useOrderType();
    const { orderToDisplay, isOpen, product, isDefaultCommentView, lineItem } =
        productDetails.modal;
    const { isLocked } = parseOrderStatus(orderToDisplay);

    const price = lineItem?.confirmedPrice ?? product?.estimatedPrice;
    const packSize = lineItem?.confirmedPackSize ?? product?.estimatedPackSize;
    const unitOfMeasure = lineItem?.unitOfMeasure ?? product?.unitOfMeasure ?? '';

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const [isCommentView, setIsCommentView] = useState(isDefaultCommentView);

    useEffect(() => {
        setIsCommentView(isDefaultCommentView);
    }, [isDefaultCommentView]);

    const [commentContent, setCommentContent] = useState('');

    const [productAmount, setProductAmount] = useState<string | number>(lineItem?.quantity ?? 0);

    const { orderIdFromQuery } = useOrderType();

    const { data: editableOrder } = useEditableOrder(orderIdFromQuery);

    const closeCommentView = () => {
        setIsCommentView(false);
    };
    const openCommentView = () => {
        if (Number(productAmount) > 0) {
            setIsCommentView(true);
        }
    };

    useEffect(() => {
        setCommentContent(lineItem?.lastCommentFromRfqFlow?.comment ?? lineItem?.comment ?? '');
        setProductAmount(lineItem?.quantity ?? 0);
    }, [isOpen, product?.itemNumber, isDefaultCommentView, lineItem?.quantity, lineItem?.comment]);

    const addComment = async () => {
        if (!product?.itemNumber || !activeOrderType || !editableOrder) {
            productDetails.closeModal();
            return;
        }

        if (!lineItem) {
            const newLineItem = {
                itemNumber: product.itemNumber,
                comment: commentContent,
                quantity: Number(productAmount),
                itemName: product.itemName,
                unitOfMeasure: product.unitOfMeasure,
            };

            editableOrder.rfq.lineItems.push(newLineItem);
        } else {
            await putCommentToLineItemInEditableOrder(
                editableOrder,
                product.itemNumber,
                commentContent,
            );
        }

        mixpanel.track(trackingEvents.remarkItemAdded, {
            itemNumber: product.itemNumber,
        });
        closeCommentView();
    };

    const updateProduct = async () => {
        if (!activeOrderType) return;

        if (product && editableOrder) {
            dataFlowEventHub.emit('editableOrderQuantityChanged', {
                itemNumber: product.itemNumber,
                newValue: Number(productAmount),
            });

            await updateLineItemQuantity(editableOrder, product, Number(productAmount));
        }

        productDetails.closeModal();
    };

    const adjustQuantityToPackSize = (value: string | number) => {
        if (!product && !lineItem) {
            return value;
        }

        if (isNaN(Number(productAmount))) {
            return value;
        }

        return adjustLineItemValueToPackSizeAndUnit(Number(productAmount), packSize, unitOfMeasure);
    };

    const isCommentViewOnly =
        Boolean(orderToDisplay?.receival) ||
        Boolean(lineItem?.lastCommentFromRfqFlow) ||
        !orderToDisplay ||
        ![OrderStatus.Blank, OrderStatus.OrderForReview].includes(orderToDisplay?.status);

    const commentView = (
        <AddCommentToLineItem
            commentContent={commentContent}
            setCommentContent={setCommentContent}
            addComment={addComment}
            inputTitle="Please describe your request"
            viewOnly={isCommentViewOnly}
            onBack={!isLocked ? closeCommentView : undefined}
            closeCommentView={productDetails.closeModal}
        />
    );

    const productDescriptionView = (
        <>
            <div className={modalStyles.modalContent} data-testid="productDescriptionView">
                <h2 className={styles.headerText}>{product?.itemName || lineItem?.itemName}</h2>
                <p className={styles.productId}>{product?.itemNumber || lineItem?.itemNumber}</p>
                <div className={styles.categories}>
                    <p className={styles.categoryBadge}>{product?.categoryLevel3?.text ?? ''}</p>
                </div>
                <div className={styles.propertyRow}>
                    {packSize ? (
                        <>
                            <p className={styles.label}>{unitOfMeasure} / Pack</p>
                            <p className={styles.value}>{packSize}</p>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                {!featureFlags?.hidePrice && (
                    <div className={styles.propertyRow}>
                        <p className={styles.label}>Price</p>
                        <p className={styles.value}>
                            {price
                                ? packSize
                                    ? currencyFormatter(price * packSize, vesselCurrency)
                                    : currencyFormatter(price, vesselCurrency)
                                : '-'}
                        </p>
                    </div>
                )}
                <div className={styles.propertyRow}>
                    <p className={`${styles.label} ${styles.quantityLabel}`}>Quantity</p>
                    <div className={styles.quantity}>
                        <DecimalInput
                            value={productAmount}
                            onChange={setProductAmount}
                            onBlurValueConverter={adjustQuantityToPackSize}
                            disabled={isLocked}
                        />
                        <p className={styles.unitOfMeasure}>&nbsp;{unitOfMeasure}</p>
                    </div>
                </div>
                {!featureFlags?.hidePrice && (
                    <div className={styles.propertyRow}>
                        <p className={styles.label}>Total</p>
                        <p className={styles.value}>
                            {price && !isNaN(Number(productAmount))
                                ? currencyFormatter(price * Number(productAmount), vesselCurrency)
                                : '-'}
                        </p>
                    </div>
                )}
                <div className={styles.propertyRow}>
                    <p className={styles.label}>Comment</p>
                    <CommentButton
                        onClick={openCommentView}
                        hasComment={Boolean(commentContent.length)}
                        isActive={Boolean(Number(productAmount) > 0)}
                        withBackground
                    />
                </div>
            </div>
            <div className={modalStyles.squareActionButton}>
                <Button
                    text={isLocked ? 'Close' : 'Add to order'}
                    onClick={updateProduct}
                    primary
                />
            </div>
        </>
    );

    return (
        <Portal>
            <Modal
                isOpen={isOpen}
                onClose={productDetails.closeModal}
                onOutsideOfModalClick={productDetails.closeModal}
            >
                {isCommentView ? commentView : productDescriptionView}
            </Modal>
        </Portal>
    );
};

export default ProductDetailsModal;
