import { Order } from '../../types/order.types';

export const sortOrders = (arrayOfOrders: Order[]) => {
    const arrayOfOrdersWithDateType = arrayOfOrders.map((order) => {
        return {
            ...order,
            created: new Date(order.created),
        };
    });
    arrayOfOrdersWithDateType.sort(
        (orderA, orderB) => orderB.created.getTime() - orderA.created.getTime(),
    );
    return arrayOfOrdersWithDateType;
};
