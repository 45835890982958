import React from 'react';
import Image from 'next/image';
import styles from './Error404.module.css';
import Layout from '../Layout/Layout';
import Link from 'next/link';

const Error404 = ({ noLayout }: { noLayout?: boolean }) => {
    const content404 = (
        <>
            <div className={styles.pageContainer}>
                <Image
                    src="/gfx/error404pic.svg"
                    alt=""
                    width={324}
                    height={181}
                />
                <div className={styles.header}>
                    Looks like we’re in deep water, Captain
                </div>
                <p className={styles.text}>
                    We can’t seem to find the page that you are looking for. If
                    you entered a web address, please check that it was correct.
                </p>
                <Link href="/dashboard">
                    <a className={`${styles.button} ${styles.buttonText}`}>
                        Go to Dashboard
                    </a>
                </Link>
            </div>
        </>
    );

    if (noLayout) {
        return content404;
    }

    return <Layout>{content404}</Layout>;
};

export default Error404;
