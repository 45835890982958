import React, { ReactNode, useEffect } from 'react';
import styles from './SideModal.module.css';
import modalStyles from './Modal.module.css';
import Button from '../Button/Button';
import CloseButton from '../../public/gfx/uiIcons/close.svg';

interface Props {
    isOpen: boolean;
    onOutsideOfModalClick?: () => void;
    confirmButtonText?: string;
    onConfirm?: () => void;
    children: ReactNode;
    buttonPrimary?: boolean;
    isLoading?: boolean;
    noPaddings?: boolean;
}

export const SideModal = ({
    isOpen,
    onOutsideOfModalClick,
    confirmButtonText,
    onConfirm,
    children,
    buttonPrimary,
    isLoading,
    noPaddings,
}: Props) => {
    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <div
                    className={modalStyles.overlay}
                    onClick={!isLoading ? onOutsideOfModalClick : undefined}
                />
            )}
            <div
                className={`${styles.container} ${
                    !isOpen ? styles.modalClosed : ''
                }`}
            >
                {onOutsideOfModalClick && (
                    <button
                        className={[
                            'blackCloseButton',
                            styles.blackCloseButton,
                            !isOpen ? styles.blackCloseButtonClosed : '',
                        ].join(' ')}
                        data-testid="closeSideModalBlackButton"
                        onClick={() => onOutsideOfModalClick()}
                    >
                        <CloseButton width={14} height={14} />
                    </button>
                )}
                <div
                    className={[
                        styles.modalContent,
                        !noPaddings ? styles.paddings : '',
                    ].join(' ')}
                >
                    {children}
                </div>
                {onConfirm && confirmButtonText && (
                    <div className={modalStyles.squareActionButton}>
                        <Button
                            text={confirmButtonText}
                            onClick={onConfirm}
                            primary={buttonPrimary}
                            disabled={isLoading}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default SideModal;
