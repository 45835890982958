import { useState } from 'react';

export enum CashPurchasePreparationModalStep {
    orderDetails = 'orderDetails',
    orderReason = 'orderReason',
    orderReceipt = 'orderReceipt',
}

const cashStepsInOrder = [
    CashPurchasePreparationModalStep.orderDetails,
    CashPurchasePreparationModalStep.orderReason,
    CashPurchasePreparationModalStep.orderReceipt,
];

export const useCashStepsInOrderNavigator = ({
    startingPoint,
}: {
    startingPoint?: CashPurchasePreparationModalStep;
}) => {
    const [step, setStep] = useState<CashPurchasePreparationModalStep>(
        startingPoint ?? CashPurchasePreparationModalStep.orderDetails,
    );

    const currentIndex = cashStepsInOrder.indexOf(step);

    const isItLastStep = currentIndex === cashStepsInOrder.length - 1;
    const isItFirstStep = currentIndex === 0;

    const next = isItLastStep
        ? cashStepsInOrder[currentIndex]
        : cashStepsInOrder[currentIndex + 1];

    const previous = isItFirstStep
        ? cashStepsInOrder[currentIndex]
        : cashStepsInOrder[currentIndex - 1];

    const nextStep = () => {
        setStep(next);
    };

    const previousStep = () => {
        setStep(previous);
    };

    return {
        nextStep,
        previousStep,
        step,
        currentIndex,
        length: cashStepsInOrder.length,
    };
};
