import {
    EditableOrder,
    Order,
    OrderToDisplay,
    OrderToDisplayInOrdersList,
} from '../../types/order.types';

export const getUniqueOrdersList = (
    orders: OrderToDisplay[],
    offlineOrders: EditableOrder[],
) => {
    const localOfflineOrders: OrderToDisplay[] = [];
    const offlineOrdersWithOrderId: OrderToDisplay[] = [];

    offlineOrders.forEach((item) => {
        if (item.orderId) {
            offlineOrdersWithOrderId.push(item);
        } else {
            localOfflineOrders.push(item);
        }
    });

    const ordersWithIdsList = [...offlineOrdersWithOrderId, ...orders];

    const uniqueList: OrderToDisplay[] = [];
    const map = new Map();
    for (const item of ordersWithIdsList) {
        if (!map.has(item.orderId)) {
            map.set(item.orderId, true);
            uniqueList.push({
                orderId: item.orderId,
                localOrderId: item.localOrderId,
                type: item.type,
                status: item.status,
                rfq: item.rfq,
                lastModified: item.lastModified,
            });
        }
    }
    const result = [...localOfflineOrders, ...uniqueList];

    return result;
};

export const sortOrdersToDisplayInListByDate = (
    orders: OrderToDisplayInOrdersList[],
) => {
    return orders.sort((a, b) =>
        a.lastUpdated > b.lastUpdated
            ? -1
            : b.lastUpdated > a.lastUpdated
            ? 1
            : 0,
    );
};

export const getOrderById = (orderId: number, orders: Order[]) => {
    return orders.find((order) => order.orderId === orderId);
};
