import React from 'react';
import Modal from '../Modal/Modal';
import Heading from './Heading';
import { CashOrderDetailsStepContent } from './Steps/CashOrderDetailsStepContent';
import {
    CashPurchasePreparationModalStep,
    useCashStepsInOrderNavigator,
} from './useStepsNavigator';
import CashOrderReasonStepContent from './Steps/CashOrderReasonStepContent';
import CashOrderReceiptStepContent from './Steps/CashOrderReceiptStepContent';
import { useRouter } from 'next/router';
import { Portal } from '@radix-ui/react-portal';
import { LineItemWithProductDetails } from '../../db/utils/products';
import { LeavingPreparationOfCashPurchaseOrderModal } from '../CashPurchaseInfoModals/LeavingPreparationOfCashPurchaseOrderModal';

interface CashOrderPreparationModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    openRemovedLineItemsChangelogModal: (
        removedLineItemsWithProductDetails: LineItemWithProductDetails[],
    ) => void;
    detailsEditMode?: boolean;
    startingPoint?: CashPurchasePreparationModalStep;
}

export const CashOrderPreparationModal: React.FC<
    CashOrderPreparationModalProps
> = ({ isOpen, setIsOpen, detailsEditMode, startingPoint }) => {
    const { push } = useRouter();

    const { nextStep, previousStep, step } = useCashStepsInOrderNavigator({
        startingPoint: startingPoint,
    });

    const onCompletePreparationModal = () => {
        setIsOpen(false);
    };

    const onLeavingPreparationModal = () => {
        push('/order');
    };

    const renderStepContent = () => {
        switch (step) {
            case CashPurchasePreparationModalStep.orderDetails:
                return (
                    <CashOrderDetailsStepContent
                        onAction={nextStep}
                        onBack={onLeavingPreparationModal}
                        editMode={detailsEditMode}
                        setIsOpen={setIsOpen}
                    />
                );
            case CashPurchasePreparationModalStep.orderReason:
                return (
                    <CashOrderReasonStepContent
                        onAction={nextStep}
                        onBack={previousStep}
                    />
                );
            case CashPurchasePreparationModalStep.orderReceipt:
                return (
                    <CashOrderReceiptStepContent
                        onBack={previousStep}
                        onContinue={onCompletePreparationModal}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Portal>
                <LeavingPreparationOfCashPurchaseOrderModal />
            </Portal>
            <Modal
                onClose={detailsEditMode ? undefined : () => push('/order')}
                noHeader
                isOpen={isOpen}
                onNavigateBack={previousStep}
            >
                <Heading step={step} />
                {renderStepContent()}
            </Modal>
        </>
    );
};
