import Compressor from 'compressorjs';

export const compressedImageMaxWidthDefault = 500;
export const compressedImageMaxHeightDefault = 500;

export const compressImages = async ({
    files,
    compressedImageMaxWidth = compressedImageMaxWidthDefault,
    compressedImageMaxHeight = compressedImageMaxHeightDefault,
}: {
    files: File[];
    compressedImageMaxWidth?: number;
    compressedImageMaxHeight?: number;
}): Promise<File[]> => {
    const compressedImages = files.map(
        (file) =>
            new Promise(
                (resolve) =>
                    new Compressor(file, {
                        maxWidth: compressedImageMaxWidth,
                        maxHeight: compressedImageMaxHeight,
                        quality: 0.6,
                        success: resolve,
                        error: () => resolve(null),
                    }),
            ),
    );

    const results = await Promise.all(compressedImages);
    const compressedFiles = results
        .map((item, index) => {
            if (item instanceof Blob) {
                return new File([item], files[index].name);
            }

            return item as File;
        })
        .filter((result) => result !== null);

    return compressedFiles;
};
