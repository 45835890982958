import { db } from '../db/db';
import { apiClient } from '../apiClient/apiClient';
import useSWR from 'swr';
import { IExtendedItemIssueDto } from '../db/overrideTypes';
import { SWRCacheKeys } from '../events/dataFlowEvents';
import parsePort from '../db/utils/ports';
import { ItemIssueDto } from '../apiClient/generated';

const claimToExtendedClaim = (claim: ItemIssueDto) => {
    const { portNameWithCode, portNameWithCodeAndCountry } = parsePort(
        claim.deliveryPort?.name ?? '',
        claim.deliveryPort?.code ?? '',
    );

    const extendedClaim: IExtendedItemIssueDto = {
        ...claim,
        deliveryPort: {
            ...(claim.deliveryPort ?? {}),
            portNameWithCode,
            portNameWithCodeAndCountry,
        },
    };

    return extendedClaim;
};

const fetchClaims = async () => {
    const claims = await apiClient.getItemIssues();

    const extendedClaims = claims.map(claimToExtendedClaim);

    await updateClaims(extendedClaims);

    return (await db.claims.toCollection().sortBy('created')).reverse();
};

const updateClaims = async (claimsArray: IExtendedItemIssueDto[]) => {
    for (const claim of claimsArray) {
        if (!claim.id) {
            console.error('Claim id is missing');
            continue;
        }

        const existingClaim = await db.claims.get(claim.id);

        const isInitial =
            existingClaim &&
            claim.lastModified === null &&
            existingClaim.lastModified === null;

        const isClaimUpToDate =
            isInitial ||
            (existingClaim &&
                existingClaim?.lastModified?.toString() ===
                    claim?.lastModified?.toString());

        if (isClaimUpToDate) {
            continue;
        }

        const apiClaim = await apiClient.getItemIssuesById(claim.id);

        await db.claims.put(claimToExtendedClaim(apiClaim));
    }
};

export const getClaimByLocalId = (localId: string) => {
    return db.claims.where('localId').equals(localId).first();
};

export const useClaims = () => {
    const {
        data: claims,
        mutate,
        isValidating,
        isLoading,
    } = useSWR(SWRCacheKeys.claims, fetchClaims);

    return {
        data: claims,
        mutate,
        isValidating,
        isLoading,
    };
};
