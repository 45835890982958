import React from 'react';
import styles from './DetailsDataTable.module.css';

const DetailsDataTable: React.FC<
    React.PropsWithChildren<{ lessSpacing?: boolean }>
> = ({ children, lessSpacing }) => {
    return (
        <div
            className={[
                styles.tableContainer,
                lessSpacing ? styles.lessSpacing : '',
            ].join(' ')}
        >
            {children}
        </div>
    );
};

export default DetailsDataTable;
