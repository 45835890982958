/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Stocktaking
 *
 * 1 = Manning
 *
 * 2 = Condemned
 */
export enum ReOpenSource {
    Stocktaking = 0,
    Manning = 1,
    Condemned = 2,
}