import Modal from '../Modal/Modal';
import OrderDetailsStepContent from '../OrderPreparationModal/Steps/OrderDetailsStepContent';
import { useState } from 'react';
import { OrderPreparationModalDisplayData } from '../OrderPreparationModal/OrderPreparationModal';
import { ModalHeader } from '../ModalHeader/ModalHeader';

export interface OrderOptionsModalMetadata {
    isOpen: boolean;
    shouldRecalculateAmount?: boolean;
    orderIdToEdit?: string;
}

interface OrderOptionsModalProps {
    modal: OrderOptionsModalMetadata;
    closeModal: () => void;
}

const OrderOptionsModal: React.FC<OrderOptionsModalProps> = ({
    modal,
    closeModal,
}) => {
    const [displayData, setDisplayData] =
        useState<OrderPreparationModalDisplayData>({});

    return (
        <div>
            <Modal isOpen={modal.isOpen} onClose={() => closeModal()} noHeader>
                <ModalHeader subTitle="Order details" />

                {modal.isOpen && (
                    <OrderDetailsStepContent
                        onAction={() => closeModal()}
                        actionName="Confirm"
                        shouldRecalculateAmounts={modal.shouldRecalculateAmount}
                        editOrderDisplayData={displayData}
                        setEditOrderDisplayData={setDisplayData}
                        orderIdToEdit={modal.orderIdToEdit}
                    />
                )}
            </Modal>
        </div>
    );
};

export default OrderOptionsModal;
