import useSWR from 'swr';
import { db } from '../db/db';
import { offlineRequestDatabase } from '../worker/db/offlineRequestsDb';
import { SWRCacheKeys } from '../events/dataFlowEvents';
import { PendingCashPurchaseOrder } from '../types/cashPurchaseOrders.types';

export const filterSendingCashPurchaseOrders = async () => {
    const offlineRequestsQueue =
        await offlineRequestDatabase.requests.toArray();
    const sendingCashOrders = await db.sendingCashPurchaseOrders.toArray();

    const filteredOrders: PendingCashPurchaseOrder[] = [];
    offlineRequestsQueue.forEach((request) => {
        sendingCashOrders.forEach((order) => {
            if (order.localId === request.localId) {
                filteredOrders.push(order);
            }
        });
    });
    return filteredOrders;
};

const updateSendingCashPurchaseOrders = async () => {
    const filteredOrders = await filterSendingCashPurchaseOrders();
    await db.sendingCashPurchaseOrders.clear();
    await db.sendingCashPurchaseOrders.bulkPut(filteredOrders);
    return filteredOrders;
};

export const useSendingCashPurchaseOrders = () => {
    const {
        data: sendingCashPurchaseOrders,
        mutate: revalidateSendingCashPurchaseOrders,
        isValidating: isSendingCashPurchaseOrdersValidating,
    } = useSWR(
        SWRCacheKeys.sendingCashPurchaseOrders,
        updateSendingCashPurchaseOrders,
    );

    const addOrderToSendingCashPurchaseOrders = async (
        orderToAdd: PendingCashPurchaseOrder,
    ) => {
        await db.sendingCashPurchaseOrders.put(orderToAdd);
    };

    return {
        sendingCashPurchaseOrders,
        isSendingCashPurchaseOrdersValidating,
        revalidateSendingCashPurchaseOrders,
        addOrderToSendingCashPurchaseOrders,
    };
};
