import React, { forwardRef, useMemo } from 'react';
import { EditableOrder, Order, OrderToDisplay } from '../../types/order.types';
import styles from './OrderPDF.module.css';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import { currencyFormatter } from '../utils/numberFormat';
import { OrderTypes } from '../../context/OrderTypes';
import {
    CashPurchaseDto,
    CashPurchaseItemDto,
    CashPurchaseOrigin,
} from '../../apiClient/generated';
import { useRouter } from 'next/router';
import { useCashPurchaseOrder } from '../../hooks/useCashPurchaseOrder';
import {
    PendingCashPurchaseItem,
    PendingCashPurchaseOrder,
} from '../../types/cashPurchaseOrders.types';
import { usePrintHeaderDetails } from '../../hooks/usePrintHeaderDetails';
import { getShortWbPono } from '../utils/wbPono';
import moment from 'moment';
import useFilters from '../../hooks/useFilters';
import {
    getProductsSortedByCategories,
    OrderItemCategoriesReMapped,
} from '../ProductsList/utils/productsListParser';
import { getProductsListIncludingItemsRemovedDuringRfqFlow } from '../utils/getProductsForOrderSummaryScreen';
import ProductsList from '../ProductsList/ProductsList';
import { useProducts } from '../../hooks/useProducts';
import { useOrderByLocalOrderIdFromQuery } from '../../hooks/useOrderByLocalOrderId';
import useChangelog from '../../hooks/useChangelog';
import { useOrderToDisplay } from '../../hooks/useOrderToDisplay';
import { usePendingCashPurchaseOrder } from '../../hooks/usePendingCashPurchaseOrder';
import { isCashPurchaseItemDto } from '../CashPurchaseItemsOverview/CashPurchaseItemsList/CashPurchaseItemsList';
import OrderPDFCash from './OrderPDFCash';
import OrderPDFCategoriesLoop from './OrderPDFCategoriesLoop';
import { useFeatureFlags } from '../../context/useFeatureFlags';

interface OrdersProps {
    draftOrders?: EditableOrder;
    historyOrders?: Order;
    cashOrders?: CashPurchaseItemDto[];
    orderToDisplay?: OrderToDisplay;
    orderToDisplayCash?: CashPurchaseDto | PendingCashPurchaseOrder;
}

const OrderPDF = forwardRef<HTMLDivElement, OrdersProps>(
    ({ historyOrders, draftOrders, cashOrders, orderToDisplay, orderToDisplayCash }, ref) => {
        const getLineItems = historyOrders
            ? historyOrders.rfqs[0].lineItems
            : draftOrders?.rfq.lineItems;

        const { cashPurchaseOrder } = useCashPurchaseOrder(
            Number(useRouter().query.cashPurchaseId),
        );

        const getLineItemsCashOrder = cashOrders;

        const { vesselName, imoNo, company, vesselCurrency } = useVesselMetadata().data;

        const orderToDisplayDetails = usePrintHeaderDetails({ orderToDisplay, orderToDisplayCash });

        const {
            currency,
            shortWbPoNoOrTitle: title,
            displayDeliveryDate,
            numberOfProducts,
            products,
            orderType,
        } = orderToDisplayDetails;

        const formattedDeliveryDate = moment(displayDeliveryDate).format('YYYY-MM-DD');

        const PDFSummaryDetails: React.FC = () => {
            return (
                <>
                    <div style={{ display: 'none' }}>
                        {
                            (document.title =
                                `${orderType ?? ''} Order ${title ?? ''}`.trim() || 'Gateway')
                        }
                    </div>
                    <div>
                        <h2>
                            {orderType}
                            {' Order '}
                            {title}
                        </h2>
                    </div>

                    <div className={styles.tableSummary}>
                        <div className={[styles.tableRowSummary].join(' ')}>
                            <div
                                className={[styles.tableCellBorderless].join(' ')}
                            >{`Delivery Date: ${formattedDeliveryDate}`}</div>
                        </div>
                        <div className={[styles.tableRowSummary].join(' ')}>
                            <div
                                className={[styles.tableCellBorderless].join(' ')}
                            >{`Number of Products: ${numberOfProducts}`}</div>
                        </div>
                        {orderToDisplayCash && (
                            <div className={[styles.tableRowSummary].join(' ')}>
                                <div
                                    className={[styles.tableCellBorderless].join(' ')}
                                >{`Cash Discount: ${
                                    Number(orderToDisplayCash.cashDiscount) * 100
                                } %`}</div>
                            </div>
                        )}
                        {(orderToDisplayCash || !featureFlags?.hidePrice) && (
                            <div className={[styles.tableRowSummary].join(' ')}>
                                <div className={[styles.tableCellBorderless].join(' ')}>
                                    <b>
                                        {totalPrice
                                            ? `Order Value ` + getPrice({ thePrice: totalPrice })
                                            : totalPriceCashOrder
                                            ? `Order Value ` +
                                              getPrice({
                                                  thePrice: totalPriceCashOrder,
                                                  isCash: true,
                                              })
                                            : ''}
                                    </b>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            );
        };

        const totalPrice = getLineItems?.reduce((total, itemRow) => {
            const price = itemRow.confirmedPrice ?? itemRow.estimatedPrice ?? 0;
            const roundedPrice = Math.round(price * 100) / 100;
            return total + Math.round(roundedPrice * itemRow.quantity * 100) / 100;
        }, 0);

        const totalPriceCashOrder = getLineItemsCashOrder?.reduce((total, itemRow) => {
            const price = itemRow.price ?? 0;
            const roundedPrice = Math.round(price * 100) / 100;
            return total + Math.round(roundedPrice * itemRow.quantity * 100) / 100;
        }, 0);

        interface GetPriceProps {
            thePrice: number;
            isCash?: boolean;
        }

        const getPrice = ({ thePrice, isCash }: GetPriceProps) => {
            if (isCash) {
                const getPriceAfterDiscount = (
                    order?: PendingCashPurchaseOrder | CashPurchaseDto,
                ) => {
                    const isPriceDiscountedByAX = order?.origin === CashPurchaseOrigin.AX;
                    const discount = order?.cashDiscount ?? 0;
                    return thePrice * (!isPriceDiscountedByAX ? 1 - discount : 1);
                };

                return currencyFormatter(
                    getPriceAfterDiscount(orderToDisplayCash),
                    cashPurchaseOrder?.currency,
                );
            }

            return currencyFormatter(thePrice, vesselCurrency);
        };

        const { data: productsList } = useProducts();

        const { order: orderWithChangelog, isOrderValidating } = useOrderByLocalOrderIdFromQuery();
        const changelog = useChangelog(orderWithChangelog, productsList); // @todo performance

        const {
            orderToDisplay: orderToDisplay2,
            changeEditableOrderItemStatus,
            saveEditableOrderDraft,
        } = useOrderToDisplay();

        const isSsPoNumberAvailableForOrder =
            orderToDisplay?.isSsPoNumberAvailableForOrder ?? false;

        const productsListIncludingItemsRemovedDuringRfqFlow = useMemo(
            () =>
                getProductsListIncludingItemsRemovedDuringRfqFlow({
                    order: orderWithChangelog,
                    orderToDisplay,
                    changelog,
                    products: productsList,
                }),
            [orderToDisplay2, orderWithChangelog, products, changelog],
        );

        const { pendingCashPurchaseOrder } = usePendingCashPurchaseOrder();

        const mapCashPurchaseOrderToMutualCategoryType = (
            items: PendingCashPurchaseItem[] | CashPurchaseItemDto[],
        ): OrderItemCategoriesReMapped[] => {
            return items.map((item) => {
                if (isCashPurchaseItemDto(item)) {
                    return {
                        ...item,
                        categoryLevel3: {
                            text: item.category,
                            number: item.categoryLevel3,
                        },
                        isPending: false,
                    };
                } else {
                    return {
                        ...item,
                        id: Number(item.itemNumber),
                        isPending: true,
                    };
                }
            });
        };

        const cashPurchaseOrderWithMutualCategoryType = mapCashPurchaseOrderToMutualCategoryType(
            orderToDisplayCash?.items ?? pendingCashPurchaseOrder?.items ?? [],
        );

        const productsSortedByCategories = getProductsSortedByCategories(
            productsListIncludingItemsRemovedDuringRfqFlow,
        );

        const productsSortedByCategoriesCash = getProductsSortedByCategories(
            cashPurchaseOrderWithMutualCategoryType,
        );

        const productsSortedByCategoriesLengths = productsSortedByCategories.map(
            (item) => item.products.length,
        );

        const productsSortedByCategoriesCashLengths = productsSortedByCategoriesCash.map(
            (item) => item.products.length,
        );

        const { featureFlags } = useFeatureFlags();
        return (
            <div ref={ref} className={styles.container}>
                <PDFSummaryDetails />
                <div className={styles.table}>
                    <div className={[styles.tableRow, styles.tableHeader].join(' ')}>
                        <div className={[styles.tableCellHeader, styles.s1].join(' ')}>No.</div>
                        {/* <div className={[styles.tableCellHeader, styles.s2].join(' ')}>Item Number</div>  */}
                        <div className={styles.tableCellHeader}>
                            {/* <div className={styles.badge}>Item Number</div> */}
                            Item Name
                        </div>
                        <div className={[styles.tableCellHeader, styles.s2].join(' ')}>Packing</div>
                        {(orderToDisplayCash || !featureFlags?.hidePrice) && (
                            <div className={[styles.tableCellHeader, styles.s2].join(' ')}>
                                Quantity
                            </div>
                        )}
                         <div className={[styles.tableCellHeader, styles.s2].join(' ')}>
                                {!featureFlags?.hidePrice ? 'Price' : 'Quantity'}
                        </div>
                        {(orderToDisplayCash || !featureFlags?.hidePrice) && (
                            <div className={[styles.tableCellHeader, styles.s2].join(' ')}>
                                {orderToDisplayCash ? 'Discounted Total' : 'Total'}
                            </div>
                        )}
                        <div className={styles.tableCellHeader}>Comment</div>
                    </div>

                    {orderToDisplayCash &&
                        productsSortedByCategoriesCash.map((category, categoryRowNumber) => (
                            <OrderPDFCash
                                category={category}
                                key={category.categoryNumber}
                                categoryRowNumber={categoryRowNumber}
                                orderToDisplayCash={orderToDisplayCash}
                                productsSortedByCategoriesCashLengths={
                                    productsSortedByCategoriesCashLengths
                                }
                            />
                        ))}

                    {orderToDisplay &&
                        productsSortedByCategories.map((category, categoryRowNumber) => (
                            <OrderPDFCategoriesLoop
                                category={category}
                                key={category.categoryName}
                                orderToDisplay={orderToDisplay}
                                categoryRowNumber={categoryRowNumber}
                                productsSortedByCategoriesLengths={
                                    productsSortedByCategoriesLengths
                                }
                                showPrice={!featureFlags?.hidePrice}
                            />
                        ))}
                    {<div>&nbsp;</div>}
                </div>
            </div>
        );
    },
);

export default OrderPDF;
