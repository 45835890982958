import {
    dataFlowEventHub,
    SWRCacheKeys,
    swrDefaultConfig,
} from './../events/dataFlowEvents';
import useSWR, { mutate } from 'swr';
import { db } from '../db/db';

const fetchDbData = () => db.products.toArray();

dataFlowEventHub.on('productsChanged', () =>
    mutate(SWRCacheKeys.products, undefined, { revalidate: true }),
);
dataFlowEventHub.on('preloadData', () =>
    mutate(SWRCacheKeys.products, undefined, { revalidate: true }),
);

export const useProducts = () => {
    const { data, isValidating } = useSWR(
        SWRCacheKeys.products,
        fetchDbData,
        swrDefaultConfig,
    );

    return { data: data ?? [], isValidating };
};
