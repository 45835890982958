import ArrowIcon from '../../../public/gfx/uiIcons/collapsedArrow.svg';
import { ProductCategory } from '../../ProductsList/ProductsList';
import productListStyles from '../../ProductsList/ProductsList.module.css';
import tableHeadStyles from './CashPurchaseItemsList.module.css';
import { CashPurchaseSingleItemRow } from './CashPurchaseSingleItemRow';
import { PendingCashPurchaseItem } from '../../../types/cashPurchaseOrders.types';
import { usePendingCashPurchaseOrder } from '../../../hooks/usePendingCashPurchaseOrder';
import { currencyFormatter } from '../../utils/numberFormat';
import { OrderItemCategoriesReMapped } from '../../ProductsList/utils/productsListParser';
import { useItemsListOrderContext } from '../ItemsListContext';

interface CategoryRowProps {
    category: ProductCategory<
        PendingCashPurchaseItem | OrderItemCategoriesReMapped
    >;
    readOnly?: boolean;
}

export const CashPurchaseCategoryRow: React.FC<CategoryRowProps> = ({
    category,
    readOnly,
}) => {
    const { expandedRowsIds, toggleRowId } = useItemsListOrderContext();
    const isExpanded = expandedRowsIds.includes(category.categoryNumber);
    const { pendingCashPurchaseOrder } = usePendingCashPurchaseOrder();

    const handleToggleProductsVisibility = () => {
        toggleRowId(category.categoryNumber);
    };

    const getCategorySummary = () => {
        const sum = category.products.reduce(
            (prev, curr) => {
                const priceSumBeforeDiscount =
                    prev.priceSum + curr.price * curr.quantity;

                return {
                    quantitySum: prev.quantitySum + curr.quantity,
                    priceSum: priceSumBeforeDiscount,
                };
            },
            { quantitySum: 0, priceSum: 0 },
        );

        return sum;
    };

    const { quantitySum, priceSum } = getCategorySummary();
    const discount = pendingCashPurchaseOrder?.cashDiscount ?? 0;

    return (
        <>
            {readOnly ? (
                <h4 className={productListStyles.categoryTitle}>
                    {category.categoryName}
                </h4>
            ) : (
                <div
                    className={productListStyles.productCategoryRow}
                    onClick={handleToggleProductsVisibility}
                    data-testid="productsListCategoryRow"
                >
                    <div className={tableHeadStyles.tableCell}>
                        <p>{category.categoryName}</p>
                    </div>

                    <div className={tableHeadStyles.tableCell} />
                    <div className={tableHeadStyles.tableCell} />
                    <div className={tableHeadStyles.tableCell}>
                        {quantitySum}
                    </div>
                    
                    <div className={tableHeadStyles.tableCell}>
                        {currencyFormatter(
                            (priceSum * (1 - discount)).toFixed(2),
                            pendingCashPurchaseOrder?.currency,
                        )}
                    </div>
                    
                    <div className={tableHeadStyles.tableCell}>
                        <ArrowIcon
                            className={`transitionRegular ${
                                isExpanded ? 'rotated270' : 'rotated180'
                            }`}
                            width="24"
                            height="24"
                            stroke="#000"
                        />
                    </div>
                </div>
            )}
            <div
                className={
                    readOnly
                        ? [
                              productListStyles.expandedProductsList,
                              productListStyles.compact,
                          ].join(' ')
                        : ''
                }
            >
                {(isExpanded || readOnly) &&
                    category.products.map((item) => (
                        <CashPurchaseSingleItemRow
                            key={item.itemNumber}
                            product={item}
                            readOnly={readOnly}
                        />
                    ))}
            </div>
        </>
    );
};
