/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = None
 *
 * 1 = Primitive
 *
 * 2 = Entity
 *
 * 3 = Complex
 *
 * 4 = Collection
 *
 * 5 = EntityReference
 *
 * 6 = Enum
 *
 * 7 = TypeDefinition
 *
 * 8 = Untyped
 *
 * 9 = Path
 */
export enum EdmTypeKind {
    None = 0,
    Primitive = 1,
    Entity = 2,
    Complex = 3,
    Collection = 4,
    EntityReference = 5,
    Enum = 6,
    TypeDefinition = 7,
    Untyped = 8,
    Path = 9,
}