import React, {
    useRef,
    useEffect,
    useState,
    useContext,
    createContext,
} from 'react';

type ContextValue = {
    addUnsavedWork: (handleName: string) => void;
    removeUnsavedWork: (handleName: string) => void;
    hasHandleUnsavedWork: (handleName: string) => boolean;
};

const UnsavedWorkContext = createContext<ContextValue | undefined>(undefined);

export const UnsavedWorkProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const [unsavedWorkHandles, setUnsavedWorkHandles] = useState<Set<string>>(
        new Set(),
    );
    const registeredEvent = useRef<null>(null);

    const addUnsavedWork = (handleName: string) => {
        setUnsavedWorkHandles((handles) => new Set(handles.add(handleName)));
    };

    const removeUnsavedWork = (handleName: string) => {
        setUnsavedWorkHandles(
            (handles) =>
                new Set(
                    Array.from(handles).filter(
                        (existingHandle) => existingHandle !== handleName,
                    ),
                ),
        );
    };

    const hasHandleUnsavedWork = (handleName: string) =>
        unsavedWorkHandles.has(handleName);

    // Note: This only updates if the state is updated with an entirely new Set
    const hasUnsavedWork = unsavedWorkHandles.size > 0;

    const onHasUnsavedWork: OnBeforeUnloadEventHandler = (event) => {
        event.preventDefault();
        // returnValue is deprecated, but the only way to make it work in Chrome...
        event.returnValue = false;
        // This string is not shown by any browser I tested, but might be shown in the future?!
        return 'It looks like you have unsaved work. Are you sure you want to leave?';
    };

    useEffect(() => {
        // The beforeunload event listener should only be registered when the user
        // has unsaved changes, because it comes with a performance penalty.
        // See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes

        if (registeredEvent.current) {
            removeEventListener('beforeunload', onHasUnsavedWork, {
                capture: true,
            });
        }

        if (hasUnsavedWork) {
            addEventListener('beforeunload', onHasUnsavedWork, {
                capture: true,
            });
        }

        return () =>
            removeEventListener('beforeunload', onHasUnsavedWork, {
                capture: true,
            });
    }, [hasUnsavedWork]);

    return (
        <UnsavedWorkContext.Provider
            value={{
                addUnsavedWork,
                removeUnsavedWork,
                hasHandleUnsavedWork,
            }}
        >
            {children}
        </UnsavedWorkContext.Provider>
    );
};

export const useUnsavedWork = () => {
    const context = useContext(UnsavedWorkContext);
    if (context === undefined) {
        throw new Error('useUnsavedWork is out of scope');
    }

    return context;
};
