import { ProductCategory } from '../ProductsList/ProductsList';  
import { currencyFormatter } from '../utils/numberFormat';
import { OrderItemCategoriesReMapped } from '../ProductsList/utils/productsListParser';
import styles from './OrderPDF.module.css';
import { OrderToDisplay, Product } from '../../types/order.types';
import { useAppLoading } from '../../context/AppLoadingContext';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { getLineItemsWithProductDetails } from '../utils/orderSummary';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import { useMemo, useState } from 'react';
import { sumElements } from '../utils/arrayMethods'; 
import {  OrderPDFSingleProductRow } from './OrderPDFSingleProductRow';
import { OrderPDFReceivedProductRow } from './OrderPDFReceivedProductRow';

interface PropsRow {
  orderToDisplay: OrderToDisplay
  category: ProductCategory<Product>,
  categoryRowNumber: number
  productsSortedByCategoriesLengths:number[]
  showPrice?: boolean
}

const OrderPDFCategoriesLoop:React.FC<PropsRow> = ({
  category,
  orderToDisplay, 
  categoryRowNumber,
  productsSortedByCategoriesLengths,
  showPrice
}) => { 
   
  const { categoryName, products } = category;
  const { loadingPrices } = useAppLoading();
  const { isReceived } = parseOrderStatus(orderToDisplay);

  const lineItemsWithProductDetails = getLineItemsWithProductDetails(
      orderToDisplay,
      products,
  );

  const {
      data: { vesselCurrency },
  } = useVesselMetadata();

  const sortedProducts = useMemo(
      () =>
          products.sort((a, b) =>
              a.itemName > b.itemName ? 1 : b.itemName > a.itemName ? -1 : 0,
          ),
      [products],
  );
  const lineItems = orderToDisplay.rfq.lineItems;

  const getTotalPrice = () => {
      let includesEstimates = false;

      const totalPrice = lineItemsWithProductDetails.map((item) => {
          if (!item.lineItem.confirmedPrice) {
              includesEstimates = true;
          }
          const correctPrice =
              item.lineItem.confirmedPrice ||
              item.lineItem.estimatedPrice ||
              item.product.estimatedPrice ||
              0;

          return correctPrice * item.lineItem.quantity;
      });

      const categoryTotalPrice = sumElements(totalPrice);

      if (!categoryTotalPrice) {
          return '-';
      }

      return `${includesEstimates ? '≈' : ''} ${currencyFormatter(
          categoryTotalPrice,
          vesselCurrency,
      )}`;
  };

  const getTotalAmount = (): number | string => {
      const unitOfMeasureToCount = 'kg';

      const amountPerProductArr: number[] = lineItemsWithProductDetails.map(
          (item) =>
              item.lineItem.unitOfMeasure?.toLowerCase() ===
              unitOfMeasureToCount
                  ? item.lineItem.quantity
                  : 0,
      );

      const totalAmount: number = sumElements(amountPerProductArr);
      if (!totalAmount) {
          return '-';
      }

      return `${totalAmount.toFixed(2)} Kg`;
  };

  const [isProductListExpanded, setIsProductsListExpanded] = useState(false);

  const handleToggleProductsVisibility = () => {
      setIsProductsListExpanded((prev: boolean) => !prev);
  };

  const startingPointOfRowCount = productsSortedByCategoriesLengths.slice(0, categoryRowNumber).reduce((total, num) => total + num, 0)
  
  
    return (
        <> 
          <div className={styles.table}>
            <div className={styles.tableRow}>
              <div className={styles.tableCellParentCategory}>{categoryName}</div> 
            </div> 
            {
              sortedProducts.map((item, index) => (
                isReceived ? (
                  <OrderPDFReceivedProductRow
                    key={ item.itemNumber }
                    orderToDisplay={orderToDisplay}
                    lineItem={orderToDisplay.receival?.items.find(
                      (lineItem) => lineItem.itemNumber === item.itemNumber
                    )}
                    product={item} 
                    rowNumber={ index+1+startingPointOfRowCount }
                    categoryRowNumber = {categoryRowNumber}
                    sortedProductsLength = { sortedProducts.length }
                    showPrice={showPrice}
                    /> 
                  ) : (
                    <OrderPDFSingleProductRow 
                      key={ item.itemNumber }
                      rowNumber={ index+1+startingPointOfRowCount }
                      categoryRowNumber={ categoryRowNumber }
                      sortedProductsLength = { sortedProducts.length}
                      orderToDisplay={orderToDisplay}
                      product={item}
                      lineItem={lineItems.find(
                        (lineItem) =>
                          lineItem.itemNumber === item.itemNumber,
                      )}
                      showPrice={showPrice}
                    /> 
                )) 
            )} 
          </div>
        </>
    );


}

export default OrderPDFCategoriesLoop