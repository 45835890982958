import { useMemo, useState } from 'react';
import { useAssortment } from '../../../hooks/useAssortment';
import { useProducts } from '../../../hooks/useProducts';
import { OrderToDisplay } from '../../../types/order.types';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import { getLineItemsFilteredByAssortment } from '../../utils/lineItemUtils';
import { getLineItemsWithProductDetails } from '../../utils/orderSummary';
import { getShortWbPono } from '../../utils/wbPono';
import styles from './DuplicateOrderModal.module.css';
import { RemovedLineItemsChangelog } from './RemovedLineItemsChangelog';

interface DuplicateOrderModalProps {
    isOpen: boolean;
    orderToDisplay: OrderToDisplay;
    onDuplicateOrder: () => Promise<void>;
    onClose: () => void;
}

export const DuplicateOrderModal: React.FC<DuplicateOrderModalProps> = ({
    isOpen,
    orderToDisplay,
    onDuplicateOrder,
    onClose,
}) => {
    const [processing, setProcessing] = useState(false);

    const { data: assortment } = useAssortment(orderToDisplay.type);

    const { data: products } = useProducts();

    const lineItemsWithProductDetails = getLineItemsWithProductDetails(
        orderToDisplay,
        products,
    );

    const lineItemsFoundInAssortment = getLineItemsFilteredByAssortment(
        orderToDisplay?.rfq.lineItems ?? [],
        assortment ?? [],
    );

    const removedLineItemsWithProductDetails = useMemo(() => {
        const approvedLineItemNumbers = lineItemsFoundInAssortment.map(
            (lineItem) => lineItem.itemNumber,
        );

        return lineItemsWithProductDetails.filter(
            (details) =>
                !approvedLineItemNumbers.includes(details.lineItem.itemNumber),
        );
    }, [lineItemsFoundInAssortment, lineItemsWithProductDetails]);

    const hasRemovedLineItems = Boolean(
        removedLineItemsWithProductDetails.length,
    );

    return (
        <>
            <ConfirmationModal
                title="Duplicating Order"
                type="Duplicate"
                description={`${orderToDisplay.type} #${getShortWbPono(
                    orderToDisplay.rfq.wbPono,
                )}`}
                isOpen={isOpen}
                onClose={onClose}
                onComplete={async () => {
                    setProcessing(true);
                    await onDuplicateOrder();
                    setProcessing(false);
                }}
                processingText="Loading..."
                processing={processing}
                confirmOptionText="Continue"
            >
                <>
                    <p className={styles.introduction}>
                        Manning and covering days will be saved and transferred
                        to your new order.
                    </p>
                </>

                {hasRemovedLineItems && (
                    <RemovedLineItemsChangelog
                        removedLineItemsWithProductDetails={
                            removedLineItemsWithProductDetails
                        }
                    />
                )}
            </ConfirmationModal>
        </>
    );
};
