import { useState } from 'react';
import styles from './FilesSection.module.css';
import RemoveIcon from '../../public/gfx/uiIcons/removeCross.svg';
import { v4 } from 'uuid';
import PictureIcon from '../../public/gfx/uiIcons/picture.svg';
import UploadIcon from '../../public/gfx/uiIcons/uploadFiles.svg';
import PdfIcon from '../../public/gfx/uiIcons/pdf.svg';
import { CashPurchasePhotoDto } from '../../apiClient/generated';
import DetailsDataRow from '../DetailsDataTable/DetailsDataRow';
import { DropArea } from '../DropArea/DropArea';
import FilePreviewModal from './FilePreviewModal';
import axios from 'axios';
import { apiClient } from '../../apiClient/apiClient';

interface FilesSectionProps {
    files?: File[];
    handleAddFiles?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
    handleRemoveFiles?: (item: File) => void;
    compressionInProgress?: boolean;
    overviewFiles?: CashPurchasePhotoDto[];
    allowPdfs?: boolean;
    noFilesUploadedText?: string;
}

interface DropFileAreaProps {
    overview: boolean;
    handleAddFiles: FilesSectionProps['handleAddFiles'];
    allowPdfs?: boolean;
}

interface FilesListProps {
    overviewFiles?: CashPurchasePhotoDto[];
    filesToUpload?: File[];
    openFilePreviewModal: (file: File | CashPurchasePhotoDto) => void;
    handleRemoveFile?: FilesSectionProps['handleRemoveFiles'];
    noFilesUploadedText?: string;
}

export const isNotCashPurchaseDtoPhoto = (
    file: File | CashPurchasePhotoDto,
): file is File => {
    return (file as File).lastModified !== undefined;
};

export const isNotPdfFile = (file: File | CashPurchasePhotoDto) => {
    if (isNotCashPurchaseDtoPhoto(file)) {
        return file.type !== 'application/pdf';
    }
    return file.name.split('.').reverse()[0] !== 'pdf';
};

const getOrValidatePdfFileRequest = async (pdfUrl: string) => {
    const pdfId = Number(pdfUrl.split('/').reverse()[1]);
    await apiClient.getCashPurchasePhoto(pdfId);
    const pdfResponse = await axios.get(pdfUrl, {
        responseType: 'blob',
    });
    return URL.createObjectURL(pdfResponse.data);
};

const DropFileArea: React.FC<DropFileAreaProps> = ({
    overview,
    handleAddFiles,
    allowPdfs,
}) => {
    /**
     * @description
     * This method is used to clear input value, because if user will add a photo, removes it and add the same again onChange will not be triggered.
     */
    const clearInputValueBeforeChange = (
        event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    ) => {
        const element = event.target as HTMLInputElement;
        element.value = '';
    };
    return (
        <>
            {!overview && (
                <label
                    className={[styles.customInput, styles.dropFileLabel].join(
                        ' ',
                    )}
                >
                    <input
                        className={styles.fileInput}
                        type="file"
                        accept={`image/*${allowPdfs ? ', .pdf' : ''}`}
                        onChange={handleAddFiles}
                        onClick={clearInputValueBeforeChange}
                        multiple
                    />
                    <DropArea
                        icon={allowPdfs ? <UploadIcon /> : <PictureIcon />}
                        captionText={
                            allowPdfs
                                ? 'Click to add photos or PDFs'
                                : 'Select a photo to add'
                        }
                    />
                </label>
            )}
        </>
    );
};

const FilesList: React.FC<FilesListProps> = ({
    overviewFiles,
    filesToUpload,
    openFilePreviewModal,
    handleRemoveFile,
    noFilesUploadedText,
}) => {
    const filesToRender =
        (filesToUpload && filesToUpload.length > 0) ||
        (overviewFiles && overviewFiles?.length > 0);
    if (filesToRender) {
        return (
            <>
                {((filesToUpload ? filesToUpload : overviewFiles) ?? []).map(
                    (item) => {
                        const isFile = isNotCashPurchaseDtoPhoto(item);
                        return (
                            <DetailsDataRow
                                key={`${v4()}-${item.name}`}
                                title={
                                    <div
                                        onClick={() => {
                                            openFilePreviewModal(item);
                                        }}
                                        className={styles.thumbnailContainer}
                                    >
                                        {isNotPdfFile(item) ? (
                                            <img
                                                className={styles.thumbnail}
                                                src={
                                                    isFile
                                                        ? URL.createObjectURL(
                                                              item,
                                                          )
                                                        : item.url
                                                }
                                                alt="Image thumbnail"
                                            />
                                        ) : (
                                            <div
                                                className={
                                                    styles.pdfIconWrapper
                                                }
                                            >
                                                <PdfIcon />
                                            </div>
                                        )}
                                        <p className={styles.fileName}>
                                            {item.name}
                                        </p>
                                    </div>
                                }
                                value={
                                    <div
                                        className={
                                            styles.sizeAndRemoveButtonContainer
                                        }
                                    >
                                        <p className={styles.imageSize}>
                                            {(item.size / 1000000).toFixed(
                                                item.size < 5000 ? 4 : 2,
                                            )}{' '}
                                            MB
                                        </p>
                                        {handleRemoveFile && isFile && (
                                            <button
                                                className={styles.removeButton}
                                                onClick={() =>
                                                    handleRemoveFile(item)
                                                }
                                            >
                                                <RemoveIcon />
                                            </button>
                                        )}
                                    </div>
                                }
                            />
                        );
                    },
                )}
            </>
        );
    } else if (overviewFiles && overviewFiles.length === 0) {
        return (
            <DetailsDataRow
                title={noFilesUploadedText ?? 'No files attached'}
            />
        );
    }
    return <></>;
};

const FilesSection: React.FC<FilesSectionProps> = ({
    files,
    handleAddFiles,
    handleRemoveFiles,
    compressionInProgress,
    overviewFiles,
    allowPdfs,
    noFilesUploadedText,
}) => {
    const [selectedFile, setSelectedFile] = useState<
        File | CashPurchasePhotoDto | null
    >(null);
    const [fileLoading, setFileLoading] = useState(false);

    const openFileModal = (file: File | CashPurchasePhotoDto) => {
        if (!isNotCashPurchaseDtoPhoto(file) && !isNotPdfFile(file)) {
            handlePdfFile(file);
        } else {
            setSelectedFile(file);
        }
    };

    const closeFileModal = () => {
        setSelectedFile(null);
    };

    const handlePdfFile = async (pdfFile: CashPurchasePhotoDto) => {
        setFileLoading(true);
        try {
            const pdfFileUrlResponse = await getOrValidatePdfFileRequest(
                pdfFile.url,
            );
            setSelectedFile(() => {
                return {
                    ...pdfFile,
                    url: pdfFileUrlResponse,
                };
            });
        } catch {
            setSelectedFile(pdfFile);
        } finally {
            setFileLoading(false);
        }
    };

    return (
        <>
            <FilePreviewModal
                selectedFile={selectedFile}
                onClose={closeFileModal}
                pdfLoading={fileLoading}
            />
            <div>
                <DetailsDataRow
                    title={allowPdfs ? 'files/photos' : 'photos'}
                    uppercaseTitle
                />
            </div>
            <DropFileArea
                overview={Boolean(overviewFiles)}
                handleAddFiles={handleAddFiles}
                allowPdfs={allowPdfs}
            />
            {compressionInProgress && <p>Compressing..</p>}
            <FilesList
                filesToUpload={files}
                overviewFiles={overviewFiles}
                openFilePreviewModal={openFileModal}
                handleRemoveFile={handleRemoveFiles}
                noFilesUploadedText={noFilesUploadedText}
            />
        </>
    );
};

export default FilesSection;
