import { db } from './db';
import moment from 'moment';
import { ManningMetadataOption } from '../apiClient/generated';
import {
    ManningDayLog,
    ManningOptions,
    hardcodedMealTypes,
} from '../types/manning.types';

const getAvailableMeals = (manningMetadata: ManningMetadataOption) => {
    const availableMeals = [];

    if (manningMetadata.hasBreakfast) {
        const mealId = hardcodedMealTypes.find(
            (item) => item.name.toLowerCase() === 'breakfast',
        )?.id;

        if (mealId !== undefined) {
            availableMeals.push(mealId);
        }
    }

    if (manningMetadata.hasLunch) {
        const mealId = hardcodedMealTypes.find(
            (item) => item.name.toLowerCase() === 'lunch',
        )?.id;

        if (mealId !== undefined) {
            availableMeals.push(mealId);
        }
    }

    if (manningMetadata.hasDinner) {
        const mealId = hardcodedMealTypes.find(
            (item) => item.name.toLowerCase() === 'dinner',
        )?.id;

        if (mealId !== undefined) {
            availableMeals.push(mealId);
        }
    }

    if (manningMetadata.hasNightMeal) {
        const mealId = hardcodedMealTypes.find(
            (item) => item.name.toLowerCase() === 'night meal',
        )?.id;

        if (mealId !== undefined) {
            availableMeals.push(mealId);
        }
    }

    return availableMeals;
};

export const getDayLogsForEachDayOfMonth = (
    monthAndYear: string,
    manningOptions: ManningOptions[],
): ManningDayLog[] => {
    const days: ManningDayLog[] = [];
    const monthStart = moment(monthAndYear, 'YYYY-MM').startOf('month');
    const monthEnd = moment(monthAndYear, 'YYYY-MM').endOf('month');

    // Get a list of configured attendee types for each meal type
    const mealAttendeeMap: Record<number, number[]> = {};
    for (const attendeeOption of manningOptions) {
        for (const mealId of attendeeOption.meals) {
            if (mealAttendeeMap[mealId]) {
                mealAttendeeMap[mealId].push(attendeeOption.manningTypeId);
            } else {
                mealAttendeeMap[mealId] = [attendeeOption.manningTypeId];
            }
        }
    }

    for (
        let date = monthStart;
        date <= monthEnd;
        date = date.clone().add(1, 'd')
    ) {
        days.push({
            date: date.format('YYYY-MM-DD'),
            // Pre-populate meals with configured attendees,
            // leaving value + comment empty
            meals: Object.entries(mealAttendeeMap).map(
                ([mealId, attendeeIds]) => ({
                    mealId: Number(mealId),
                    attendees: attendeeIds.map((manningTypeId) => ({
                        manningTypeId,
                        value: 0,
                    })),
                    comment: '',
                }),
            ),
        });
    }

    return days;
};

export const getManningOptions = (manningMetadata: ManningMetadataOption[]) => {
    const manningOptions: ManningOptions[] = [];
    manningMetadata.forEach((item) => {
        manningOptions.push({
            manningLabel: item.manningLabel,
            manningTypeId: item.manningTypeId,
            meals: getAvailableMeals(item),
            // TODO: Integration needs to provide this property
            commentMandatory: false,
        });
    });

    return manningOptions;
};

export const getManningReport = async (monthAndYear: string) =>
    db.manningReports.where('monthAndYear').equals(monthAndYear).first();
