import useSWR from 'swr';
import { SWRCacheKeys } from '../events/dataFlowEvents';
import { fetchAndUpdateAvailableItemsToClaim } from '../db/itemsAvailableToClaim';

export const useItemsAvailableToClaim = () => {
    const {
        data: itemsAvailableToClaim,
        mutate: revalidateItemsAvailableToClaim,
    } = useSWR(SWRCacheKeys.availableItemsToClaim, () =>
        fetchAndUpdateAvailableItemsToClaim(),
    );

    return {
        itemsAvailableToClaim,
        revalidateItemsAvailableToClaim,
    };
};
