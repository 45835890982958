import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import modalStyles from '../Modal/Modal.module.css';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import styles from './BudgetExceededAlert.module.css';

const BudgetExceededAlert: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onComplete: () => void;
}> = ({ isOpen, onClose, onComplete }) => (
    <Modal isOpen={isOpen} onClose={onClose} noHeader>
        <ModalHeader
            title="Alert"
            subTitle="Your order is above budget
"
        />
        <div className={styles.contentContainer}>
            <p>
                Your estimated total is considerably above budget and we suggest
                that you review your order again before submitting. We will be
                happy to offer advice - please contact your Vessel Account
                Manager to discuss.
            </p>
            <p>Do you still wish to continue submitting your order?</p>
        </div>
        <div className={modalStyles.squareActionButton}>
            <div className={modalStyles.squareActionButtonChild}>
                <Button text="Cancel" onClick={onClose} secondary />
            </div>
            <div className={modalStyles.squareActionButtonChild}>
                <Button text="Continue" onClick={onComplete} primary />
            </div>
        </div>
    </Modal>
);

export default BudgetExceededAlert;
