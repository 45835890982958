import styles from './List.module.css';
import getValueAtObjPath from 'lodash.get';
import { CSSProperties } from 'react';

export interface FieldProps<Data> {
    source: keyof Data | '';
    title?: string | React.ReactElement;
    formatter?: (source: keyof Data, record?: Data) => string;
    renderFunction?: (
        source: keyof Data,
        record?: Data,
        data?: Data[],
    ) => React.ReactElement;
    record?: Data;
    data?: Data[];
    grey?: boolean;
    iconOnly?: boolean;
    smallFont?: boolean;
    bold?: boolean;
    customWidth?: CSSProperties['flex'];
    withPadding?: boolean;
    truncate?: boolean;
    alignRight?: boolean;
    boldTitle?: boolean;
    minWidth?: number;
}

//eslint-disable-next-line
export function Field<Data extends Record<string, any>>({
    source,
    renderFunction,
    record,
    data,
    grey,
    formatter,
    iconOnly,
    smallFont,
    bold,
    customWidth,
    truncate,
    withPadding = true,
    alignRight,
    minWidth,
}: FieldProps<Data>) {
    return (
        <span
            className={[
                styles.field,
                grey ? styles.grey : '',
                iconOnly ? styles.iconOnly : '',
                smallFont ? styles.smallFont : '',
                bold ? styles.bold : '',
                withPadding ? styles.withPadding : '',
                truncate ? styles.truncate : '',
                alignRight ? styles.alignRight : '',
            ].join(' ')}
            style={{ flex: customWidth, minWidth }}
            role="cell"
        >
            {renderFunction
                ? renderFunction(source, record, data)
                : formatter?.(source, record) ??
                  (record && getValueAtObjPath(record, source))}
        </span>
    );
}
