import { v4 } from 'uuid';
import {
    ItemIssueDetails,
    ItemIssueUIType,
} from '../../ClaimOrCondemnPreparationModal/ClaimOrCondemnPreparationModal';

export const getInitialItemIssueData = (
    itemIssueType: ItemIssueUIType,
): ItemIssueDetails => {
    return {
        searchProductInputValue: '',
        itemIssueProblem: null,
        isProblemRelatedToExpiryDate: false,
        selectedProduct: { name: null, itemNumber: null },
        affectedAmount: 0,
        comment: '',
        order: null,
        clientId: v4(),
        isProductUsable: null,
        isOffline: false,
        localId: v4(),
        itemIssueType: itemIssueType,
    };
};
