import { ModalHeader } from '../ModalHeader/ModalHeader';
import { CashPurchasePreparationModalStep } from './useStepsNavigator';

interface HeadingProps {
    step: CashPurchasePreparationModalStep;
}

const Heading = ({ step }: HeadingProps) => {
    const renderHeadingTitle = () => {
        switch (step) {
            case CashPurchasePreparationModalStep.orderDetails:
                return 'Enter order details';
            case CashPurchasePreparationModalStep.orderReason:
                return 'Select the reason';
            case CashPurchasePreparationModalStep.orderReceipt:
                return 'Upload copy of the receipt or invoice';
            default:
                return '';
        }
    };

    const title = renderHeadingTitle();

    return <ModalHeader title="Cash purchase" subTitle={title} />;
};

export default Heading;
