import React, { useEffect, useRef } from 'react';
import styles from './SearchInput.module.css';
import CloseIcon from '../../public/gfx/uiIcons/blackClose.svg';

interface SearchInputProps {
    isInputActive: boolean;
    onBlur: React.FocusEventHandler<HTMLInputElement> | undefined;
    searchQuery: string;
    handleChangeQuery: (query: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
    isInputActive,
    onBlur,
    searchQuery,
    handleChangeQuery,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeQuery(e.target.value);
    };

    const handleInputFocus = () => {
        if (inputRef && inputRef.current) {
            if (isInputActive) {
                inputRef.current.focus();
            }
        }
    };

    const handleClearInput = () => handleChangeQuery('');

    useEffect(() => {
        handleInputFocus();
    }, [isInputActive]);

    return (
        <div
            className={`${styles.searchInputContainer} ${
                isInputActive ? styles.active : ''
            }`}
        >
            <input
                ref={inputRef}
                className={`${styles.searchInput} ${
                    isInputActive ? styles.active : ''
                }`}
                value={searchQuery}
                onChange={handleChange}
                onBlur={onBlur}
            />
            <button
                className={styles.clearInputIconButton}
                onClick={handleClearInput}
            >
                <CloseIcon stroke="#acb4bb" width="20" height="20" />
            </button>
        </div>
    );
};

export default SearchInput;
