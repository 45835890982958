import { GarretsContactTooltip } from '../Tooltips/GarretsContactTooltip';
import styles from './MissingDeliveryPortHelper.module.css';

export const MissingDeliveryPortHelper = () => {
    return (
        <p className={styles.content}>
            If your delivery port is missing, please contact your{' '}
            <GarretsContactTooltip>
                <span className={styles.blue}>Vessel Account Manager</span>
            </GarretsContactTooltip>
        </p>
    );
};
