import DetailsDataRow from '../../../DetailsDataTable/DetailsDataRow';
import DetailsDataTable from '../../../DetailsDataTable/DetailsDataTable';
import { OrderToDisplay } from '../../../../types/order.types';
import { useReceivedOrder } from '../../../../hooks/useReceivedOrder';
import { getRatingKeyFriendlyName } from '../utils';
import { RatingKey } from '../../../../types/receivedOrder.types';
import { StarsRatingReadOnly } from '../../../StarRatingComponent/StarsRatingReadOnly';

interface RatingsSummaryProps {
    orderToDisplay: OrderToDisplay;
}

export const RatingsSummary: React.FC<RatingsSummaryProps> = ({
    orderToDisplay,
}) => {
    const { data: receivedOrder } = useReceivedOrder(orderToDisplay.orderId);

    const getRatings = () => {
        const { ratings } = receivedOrder?.performanceReport ?? {};
        if (!ratings) {
            return [];
        }

        const mappedRatings = Object.entries(ratings).map((item) => {
            const [key, rating] = item;

            //eslint-disable-next-line
            const isRatingKey = (key: any): key is RatingKey => {
                return Object.values(RatingKey).includes(key);
            };

            if (!isRatingKey(key)) {
                throw Error('RatingKey is not valid.');
            }

            return {
                friendlyName: getRatingKeyFriendlyName(key),
                rating: rating.rating,
                comment: rating.comment,
            };
        });

        return mappedRatings;
    };

    const ratings = getRatings();

    return (
        <DetailsDataTable lessSpacing>
            <DetailsDataRow title="Ratings" uppercaseTitle boldTitle />
            {ratings.map(({ rating, friendlyName, comment }) => (
                <DetailsDataRow
                    key={friendlyName}
                    title={friendlyName}
                    boldValue
                    value={
                        <StarsRatingReadOnly
                            rating={rating}
                            comment={comment}
                        />
                    }
                />
            ))}
        </DetailsDataTable>
    );
};
