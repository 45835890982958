import { WorkboxMessage } from '../../worker/utils/emitEventToWindow';
import {
    isImageRequest,
    isIncomingItemIssueDto,
} from '../../worker/requestQueue/typeguards';
import { db } from '../../db/db';
import { QueueItem } from '../SyncStatusContext';

export const handleClaimRequest = async (
    wbMessage: WorkboxMessage,
    onlineQueue: QueueItem[],
    offlineQueue: QueueItem[],
) => {
    if (
        isImageRequest(wbMessage.body) ||
        isIncomingItemIssueDto(wbMessage.body)
    ) {
        const mergedQueues = [...onlineQueue, ...offlineQueue];
        const { localId } = wbMessage.body;

        const claimRequests = mergedQueues.filter((req) => {
            if (isImageRequest(req) || isIncomingItemIssueDto(req)) {
                return (
                    req.requestRouteName === 'itemissue' &&
                    req.localId === wbMessage.localId
                );
            }
        });

        if (claimRequests.length <= 1) {
            if (localId !== undefined) {
                const storedClaims = await db.claims.toArray();
                const offlineClaimToBeRemoved = storedClaims.find((claim) => {
                    return claim.clientId === localId.toString();
                });
                if (offlineClaimToBeRemoved && offlineClaimToBeRemoved.id) {
                    await db.claims
                        .where('id')
                        .equals(offlineClaimToBeRemoved.id)
                        .delete();
                }
            } else {
                throw new Error(
                    'Associated Id not found - claim not removed from UI',
                );
            }
        }
    }
};
