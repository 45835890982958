import BudgetExceededAlert from '../BudgetExceededAlert/BudgetExceededAlert';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { PopupsHandlers, PopupState } from '../../hooks/usePopupsInSummaryPage';
import OrderChangelogSideModal from '../OrderChangelog/OrderChangelog';
import { RFQChangelog } from '../../hooks/useChangelog';
import ApiDowntimeErrorModal, {
    ItemType,
} from '../ErrorComponents/ApiDowntimeErrorModal';
import SideModal from '../Modal/SideModal';
import useOrderType from '../../hooks/useOrderType';
import { OrderToDisplay, Product } from '../../types/order.types';
import { DuplicateOrderModal } from './DuplicateOrderModal/DuplicateOrderModal';
import { UnavailableProductsModal } from './UnavailableProductsModal/UnavailableProductsModal';
import { AddItemsModal } from './AddItemsModal/AddItemsModal';
import { StopSendingConfirmationModal } from '../StopSendingConfirmationModal/StopSendingCofirmationModal';
import {
    UnsavedDraftConfirmationModal,
    UnsavedDraftModalPopupsMetadata,
} from '../UnsavedDraftConfirmationModal/UnsavedDraftConfirmationModal';
import UnhandledErrorModal from '../ErrorComponents/UnhandledErrorModal';

interface OrderSummaryPopupsProps {
    popupsState: PopupState | undefined;
    popupsHandlers: PopupsHandlers;
    submitOrder: () => void;
    isSubmittingInProgress: boolean;
    changelog: RFQChangelog;
    apiOnDowntimeConfirm: () => void;
    orderToDisplay: OrderToDisplay;
    onDuplicateOrder: () => Promise<void>;
    onUnavailableProductsReview: (
        unavailableProducts: Product[],
    ) => Promise<void>;
    onConfirmStopSending: () => void;
    unsavedDraftModalPopupsMetadata: UnsavedDraftModalPopupsMetadata;
}

const OrderSummaryPopups: React.FC<OrderSummaryPopupsProps> = ({
    popupsState,
    popupsHandlers,
    submitOrder,
    isSubmittingInProgress,
    changelog,
    apiOnDowntimeConfirm,
    orderToDisplay,
    onDuplicateOrder,
    onUnavailableProductsReview,
    onConfirmStopSending,
    unsavedDraftModalPopupsMetadata,
}) => {
    const { isDraft } = useOrderType();
    if (!popupsState) return null;

    const {
        confirmSubmitModal,
        orderChangelogSideModal,
        apiDowntimeErrorModal,
        budgetExceededAlert,
        confirmDuplicateModal,
        unavailableProductsModal,
        unavailableProductsModalItemNumbers,
        addItemsModal,
        confirmStopSendingModal,
        unsavedDraftModal,
        unhandledErrorModal,
    } = popupsState;

    const {
        closeConfirmSubmitModal,
        closeOrderChangelogSideModal,
        closeApiDowntimeErrorModal,
        closeBudgetExceededAlertModal,
        closeConfirmDuplicateModal,
        closeAddItemsModal,
        closeConfirmStopSendingModal,
        closeUnsavedDraftModal,
        closeUnhandledErrorModal,
    } = popupsHandlers;

    return (
        <>
            <UnsavedDraftConfirmationModal
                isOpen={unsavedDraftModal}
                onClose={closeUnsavedDraftModal}
                editableOrder={unsavedDraftModalPopupsMetadata.editableOrder}
                setUnsavedDraftModalMetadata={
                    unsavedDraftModalPopupsMetadata.setUnsavedDraftModalMetadata
                }
                unsavedDraftModalMetadata={
                    unsavedDraftModalPopupsMetadata.unsavedDraftModalMetadata
                }
            />
            <ConfirmationModal
                title="Submit Order"
                subtitle="Are you ready to confirm your order request?"
                description={`Once you press confirm your ${
                    isDraft ? '' : 'revised'
                } order will be submitted to our team and we will revert as soon as possible.`}
                isOpen={Boolean(confirmSubmitModal)}
                onClose={closeConfirmSubmitModal}
                onComplete={submitOrder}
                processing={isSubmittingInProgress}
            />
            <UnavailableProductsModal
                isOpen={unavailableProductsModal}
                orderToDisplay={orderToDisplay}
                onReview={onUnavailableProductsReview}
                unavailableItemNumbersFromApiResponse={
                    unavailableProductsModalItemNumbers
                }
            />
            <AddItemsModal
                isOpen={addItemsModal}
                orderToDisplay={orderToDisplay}
                closeAddItemsModal={closeAddItemsModal}
            />
            <DuplicateOrderModal
                isOpen={confirmDuplicateModal}
                orderToDisplay={orderToDisplay}
                onDuplicateOrder={onDuplicateOrder}
                onClose={closeConfirmDuplicateModal}
            />
            <SideModal
                isOpen={orderChangelogSideModal}
                onOutsideOfModalClick={closeOrderChangelogSideModal}
            >
                {orderChangelogSideModal && (
                    <OrderChangelogSideModal changelog={changelog} />
                )}
            </SideModal>
            <ApiDowntimeErrorModal
                itemType={ItemType.order}
                isOpen={apiDowntimeErrorModal}
                onClose={closeApiDowntimeErrorModal}
                onConfirm={apiOnDowntimeConfirm}
            />

            <StopSendingConfirmationModal
                isModalOpen={confirmStopSendingModal}
                sendingItemName="order"
                onConfirm={() => {
                    onConfirmStopSending();
                    closeConfirmStopSendingModal();
                }}
                onClose={closeConfirmStopSendingModal}
            />
            <BudgetExceededAlert
                isOpen={budgetExceededAlert}
                onClose={closeBudgetExceededAlertModal}
                onComplete={() => {
                    submitOrder();
                    closeBudgetExceededAlertModal();
                }}
            />
            <UnhandledErrorModal
                isOpen={unhandledErrorModal}
                clearingCacheAvailable={false}
                refreshingAvailable={false}
                onClose={closeUnhandledErrorModal}
            />
        </>
    );
};

export default OrderSummaryPopups;
