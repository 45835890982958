import useSWR from 'swr';
import { SWRCacheKeys } from '../events/dataFlowEvents';
import { db } from '../db/db';
import { IExtendedItemIssueDto } from '../db/overrideTypes';

const getClaimsInReceivalByReceivalId = async (receivalId: number) => {
    const claimsInReceival = await db.claimsInReceival.toArray();
    const claimsInReceivalFilteredByReceivalId = claimsInReceival.filter(
        (claimsInReceival) => claimsInReceival.orderId === receivalId,
    );
    return claimsInReceivalFilteredByReceivalId;
};

export const useClaimsInReceival = (receivalId: number) => {
    const { data, mutate } = useSWR(SWRCacheKeys.claimsInReceival, () =>
        getClaimsInReceivalByReceivalId(receivalId),
    );

    const addClaimsInReceival = async (
        claimsInReceival: IExtendedItemIssueDto,
    ) => {
        mutate((prev) => [...(prev ? prev : []), claimsInReceival], {
            revalidate: false,
        });
        db.claimsInReceival.add(claimsInReceival);
    };

    return { claimsInReceival: data, addClaimsInReceival };
};
