import { appEventHub } from './../events/appEvents';
import { ApiFetchHttpRequest } from './apiFetchHttpRequest';
import { GeneratedApiClient } from './generated';

interface ApiErrorResponse extends Error {
    failedEndpoint: string;
}

export interface ApiClientResponse<T> {
    response?: T;
    error?: ApiErrorResponse;
}

let authTokenProvider: (() => Promise<string>) | null = null;
export let redirectToLoginPage: (() => void) | null = null;

export const initializeApiClient = (
    getAuthToken: () => Promise<string>,
    loginWithRedirect: () => void,
) => {
    if (!getAuthToken) {
        return;
    }

    authTokenProvider = getAuthToken;
    redirectToLoginPage = loginWithRedirect;
    appEventHub.emit('apiInitialized');
};

const getAuthToken = async (): Promise<string> => {
    if (!authTokenProvider) {
        await new Promise((resolve, reject) => {
            try {
                appEventHub.once('apiInitialized', resolve);
            } catch {
                reject();
            }
        });
    }

    const token = await authTokenProvider!();
    return token;
};

export const apiClient = new GeneratedApiClient(
    {
        BASE: process.env.NEXT_PUBLIC_API_URL,
        TOKEN: getAuthToken,
        CREDENTIALS: 'include',
        WITH_CREDENTIALS: true,
    },
    ApiFetchHttpRequest,
).generateApp;

export const apiClientFakerScope = new GeneratedApiClient(
    {
        BASE: process.env.NEXT_PUBLIC_API_URL,
        TOKEN: getAuthToken,
    },
    ApiFetchHttpRequest,
).faker;

export const apiClientWristItScope = new GeneratedApiClient(
    {
        BASE: process.env.NEXT_PUBLIC_API_URL,
        TOKEN: getAuthToken,
    },
    ApiFetchHttpRequest,
).wristIt;
