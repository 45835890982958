import Image from 'next/image';
import styles from './OpenFiltersButton.module.css';

interface Props {
    handleOpenFiltersModal: () => void;
    hasActiveFilters: boolean;
}

const OpenFiltersButton: React.FC<Props> = ({
    handleOpenFiltersModal,
    hasActiveFilters,
}) => {
    return (
        <button
            className={[
                styles.filtersButton,
                hasActiveFilters ? styles.withBlueDot : '',
            ].join(' ')}
            onClick={handleOpenFiltersModal}
        >
            <Image
                src="/gfx/uiIcons/settingsIcon.svg"
                width={24}
                height={24}
                alt="collapsed icon"
            />
        </button>
    );
};

export default OpenFiltersButton;
