/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = None
 *
 * 1 = TypeDefinition
 *
 * 2 = Term
 *
 * 3 = Action
 *
 * 4 = EntityContainer
 *
 * 5 = Function
 */
export enum EdmSchemaElementKind {
    None = 0,
    TypeDefinition = 1,
    Term = 2,
    Action = 3,
    EntityContainer = 4,
    Function = 5,
}