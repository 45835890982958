import { OrderToDisplay } from '../types/order.types';
import { useProducts } from './useProducts';

export const useLineItemDetailsFromOrder = (
    itemNumber: string | null,
    orderToDisplay: OrderToDisplay | null,
) => {
    const { data: products } = useProducts();

    if (!itemNumber) {
        return {};
    }

    if (!orderToDisplay) {
        const product = products.find((item) => item.itemNumber === itemNumber);

        if (!product) {
            return {};
        }
        return { unitOfMeasure: product.unitOfMeasure };
    }

    const lineItem = orderToDisplay.rfq.lineItems.find(
        (lineItem) => lineItem.itemNumber === itemNumber,
    );

    return {
        quantity: lineItem?.quantity,
        unitOfMeasure: lineItem?.unitOfMeasure,
        price: lineItem?.confirmedPrice,
    };
};
