import { BaseIndicator } from '../BaseIndicator/BaseIndicator';
import ConnectionStatusCloudGreen from '../../../public/gfx/uiIcons/connectionStatusCloudGreen.svg';
import ConnectionStatusCloudRed from '../../../public/gfx/uiIcons/connectionStatusCloudRed.svg';
import ConnectionStatusCloudPending from '../../../public/gfx/uiIcons/connectionStatusCloudPending.svg';
import { useSyncStatus } from '../../../context/SyncStatusContext';

interface ConnectionStatusIndicatorProps {
    withText?: boolean;
    primary?: boolean;
}

export const ConnectionStatusIndicator: React.FC<
    ConnectionStatusIndicatorProps
> = ({ withText, primary }) => {
    const { isOnline, queueStatus } = useSyncStatus();

    const isPending = isOnline && queueStatus.onlineQueue.length > 0;

    if (primary && isPending) {
        return (
            <BaseIndicator
                icon={ConnectionStatusCloudPending}
                pending
                primary
            />
        );
    }

    if (isOnline) {
        return (
            <BaseIndicator
                icon={ConnectionStatusCloudGreen}
                text={withText ? 'Connected to cloud' : undefined}
                primary
            />
        );
    } else {
        return (
            <BaseIndicator
                icon={ConnectionStatusCloudRed}
                text={withText ? 'No connection' : undefined}
                optionalHelper={
                    withText
                        ? 'You can continue working and your changes will be sent once reconnected.'
                        : undefined
                }
                primary
            />
        );
    }
};
