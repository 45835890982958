import { portCountryCodeFromFullCode } from '../../db/utils/ports';
import flags, { FlagComponent } from 'country-flag-icons/react/3x2';
import React from 'react';
import styles from './CountryFlag.module.css';

interface Props {
    portCode?: string | null;
}

const CountryFlag: React.FC<Props> = ({ portCode }) => {
    const renderFlag = (portCode?: string | null) => {
        const countryCode = portCountryCodeFromFullCode(portCode ?? '');
        let Flag: FlagComponent | typeof React.Fragment = React.Fragment;
        if (typeof flags[countryCode as keyof typeof flags] !== 'undefined') {
            Flag = flags[countryCode as keyof typeof flags];
        }

        return <Flag />;
    };

    return <span className={styles.flag}>{renderFlag(portCode)}</span>;
};

export default CountryFlag;
