import Modal from '../Modal/Modal';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { GarretsContact } from './GarretsContact';
import modalStyles from '../Modal/Modal.module.css';
import styles from './ApiErrorModal.module.css';
import Button from '../Button/Button';
import React, { SetStateAction } from 'react';
import { useOrder } from '../../hooks/useOrder';
import { IExtendedItemIssueDto } from '../../db/overrideTypes';
import { getShortWbPono } from '../utils/wbPono';
import { getLatestRfq } from '../../db/utils/getLatestRfq';

export interface ErrorInfoModalDetails {
    title: string;
    description: string;
    claimDetails?: IExtendedItemIssueDto;
}
interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    modalDetails: ErrorInfoModalDetails;
}
export const ErrorInfoModal: React.FC<Props> = ({
    isOpen,
    setIsOpen,
    modalDetails,
}) => {
    const { data: order } = useOrder(
        modalDetails.claimDetails?.orderId
            ? modalDetails.claimDetails?.orderId
            : '',
    );
    return (
        <>
            <Modal noHeader isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalHeader subTitle={modalDetails.title} />
                <div
                    className={[
                        modalStyles.modalContent,
                        styles.container,
                    ].join(' ')}
                >
                    <div
                        className={[
                            styles.description,
                            styles.descriptionCentered,
                        ].join(' ')}
                    >
                        <p className={styles.additionalDescription}>
                            {modalDetails.claimDetails
                                ? `There was a problem with opening claim for ${
                                      modalDetails.claimDetails?.itemName
                                  } related to order #${getShortWbPono(
                                      getLatestRfq(order)?.wbPono,
                                  )}. `
                                : ''}
                        </p>
                        <p>
                            {modalDetails.description} Please try to open claim
                            again.
                        </p>
                    </div>
                    <GarretsContact />
                </div>
                <Button text="OK" onClick={() => setIsOpen(false)} primary />
            </Modal>
        </>
    );
};
