import Image from 'next/image';
import styles from './FiltersModal.module.css';

interface FilterButtonProps {
    onClick: () => void;
}

const ClearFiltersButton: React.FC<FilterButtonProps> = ({ onClick }) => {
    return (
        <button
            className={`iconButton iconButton__medium ${styles.clearBtn}`}
            onClick={onClick}
        >
            <Image
                src="/gfx/uiIcons/blackClose.svg"
                width={14}
                height={14}
                alt="close modal"
            />
        </button>
    );
};

export default ClearFiltersButton;
