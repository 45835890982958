import { apiClient } from '../apiClient/apiClient';
import { db } from './db';
import {
    ExternalCurrency,
    ExternalCurrencyExchange,
} from '../apiClient/generated';
import { ExternalCurrencyWithRate } from '../types/externalCurrencies.types';

export const updateExternalCurrenciesTable = async (
    externalCurrenciesWithRates: ExternalCurrencyWithRate[],
) => {
    db.externalCurrenciesWithRates.bulkPut(externalCurrenciesWithRates);
};

export const mapCurrenciesToRates = (
    rates: ExternalCurrencyExchange[],
    currencies: ExternalCurrency[],
): ExternalCurrencyWithRate[] => {
    return currencies.map((currency) => {
        const exchangeRate = rates.find((rate) => {
            return rate.code === currency.code;
        });

        return {
            code: currency.code,
            name: currency.name,
            rate: exchangeRate?.rate ?? 1,
        };
    });
};

export const fetchAndUpdateCurrencies = async () => {
    const vesselMetadata = await db.vesselMetadata.toCollection().first();
    const baseCurrencyCode = vesselMetadata?.vesselCurrency;
    if (!baseCurrencyCode) {
        return;
    }

    try {
        const rates = await apiClient.getExchangeRates(
            new Date().toISOString(),
            baseCurrencyCode,
        );
        const currencies = await apiClient.getCurrencies();
        const currenciesWithRates = mapCurrenciesToRates(rates, currencies);
        await updateExternalCurrenciesTable(currenciesWithRates);
    } catch {
        console.log('Rates update unavailable in offline');
    }
    return db.externalCurrenciesWithRates.toCollection().sortBy('code');
};

export const getExternalCurrencyByCode = async (code: string) => {
    return await db.externalCurrenciesWithRates
        .where('code')
        .equalsIgnoreCase(code)
        .first();
};
