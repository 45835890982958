import useSWR, { mutate } from 'swr';
import { db } from '../db/db';
import { Order } from '../types/order.types';
import {
    dataFlowEventHub,
    SWRCacheKeys,
    swrDefaultConfig,
} from '../events/dataFlowEvents';
import { getOrder } from '../db/orders';

dataFlowEventHub.on('orderChanged', (orderId) =>
    mutate([SWRCacheKeys.order, orderId], undefined, { revalidate: true }),
);

const fetchDbData = (orderId: number) => getOrder(orderId);

export const useOrder = (orderId?: number | string) => {
    /**
     * This is validation to make sure orderId from parameter is in proper type.
     * In useOrder we use only number orderId.
     */
    const isValidOrderId = (
        orderId: number | string | undefined,
    ): orderId is number => {
        return typeof orderId === 'number';
    };
    const {
        data,
        isValidating: isOrderValidating,
        mutate,
    } = useSWR(
        orderId ? [SWRCacheKeys.order, orderId] : null,
        ([, orderId]) => {
            if (!isValidOrderId(orderId)) {
                return undefined;
            }
            return fetchDbData(orderId);
        },
        swrDefaultConfig,
    );

    /**
     * This method mutates our cached order, but doesn't trigger revalidation, thus our performence doesn't depened on dexie/indexedDb.
     */
    const optimisticUpdate = async (orderClone: Order) => {
        mutate(orderClone, { revalidate: false });
        await db.orders.put(orderClone);
    };

    const upsertOrder = async (update: Order) => {
        await optimisticUpdate(update);
    };

    return {
        data,
        isOrderValidating,
        upsertOrder,
        isValidOrderId: isValidOrderId(orderId),
    };
};
