import React from 'react';
import { SmallConfirmationModal } from '../SmallConfirmationModal/SmallConfirmationModal';

interface Props {
    isModalOpen: boolean;
    sendingItemName: string;
    onConfirm: () => void;
    onClose: () => void;
}

export const StopSendingConfirmationModal: React.FC<Props> = ({
    isModalOpen,
    sendingItemName,
    onConfirm,
    onClose,
}) => {
    return (
        <SmallConfirmationModal
            isOpen={isModalOpen}
            onClose={onClose}
            onConfirm={onConfirm}
            onAbort={onClose}
            confirmBtnText="Confirm"
            cancelBtnText="Cancel"
            title={`Are you sure you want to stop sending this ${sendingItemName}?`}
            subtitle={`Once you click confirm your ${sendingItemName} will go back to draft state and you will have to submit it again.`}
        />
    );
};
