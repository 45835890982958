import { EditableOrder, Product } from '../../types/order.types';
import {
    LineItemWithProductDetails,
    createNewLineItemFromProduct,
} from '../../db/utils/products';
import { PendingCashPurchaseOrder } from '../../types/cashPurchaseOrders.types';
import { parseLineItemsToLineItemsWithProductDetails } from './orderSummary';
import { ApiError } from '../../apiClient/generated';
import { OrderSubmissionResult } from './submitOrder';
import { db } from '../../db/db';
import cloneDeep from 'lodash.clonedeep';

export const isPendingCashPurchaseOrder = (
    order: PendingCashPurchaseOrder | EditableOrder,
): order is PendingCashPurchaseOrder => {
    return !(order as PendingCashPurchaseOrder)?.items === undefined;
};

export const getCashPurchaseLineItemsWithProductDetails = (
    order: PendingCashPurchaseOrder | undefined | null,
    products: Product[] | undefined,
): LineItemWithProductDetails[] => {
    if (!order || !products || !order.items) {
        return [];
    }

    return parseLineItemsToLineItemsWithProductDetails(order.items, products);
};

export const updateLineItemQuantity = async (
    order: PendingCashPurchaseOrder,
    product: Product,
    newAmount: number,
    shouldRemoveZeroQuantityProduct = true,
) => {
    if (!product || isNaN(Number(newAmount))) {
        return;
    }

    const updatedOrder = cloneDeep(order) as PendingCashPurchaseOrder;

    const lineItemToUpdate = updatedOrder?.items?.find(
        (item) => item.itemNumber === product.itemNumber,
    );

    const addNewLineItem = () => {
        const newLineItem = createNewLineItemFromProduct(
            product,
            Number(newAmount),
        );
        const { price, comment, ...rest } = newLineItem;
        const cashPurchaseItem = {
            price: Number(price),
            comment: comment?.toString() ?? '',
            ...rest,
            ...product,
        };
        updatedOrder?.items?.push(cashPurchaseItem);
    };

    const removeEmptyLineItemFromUpdatedOrder = () => {
        updatedOrder.items = updatedOrder?.items?.filter(
            (item) => item.itemNumber !== product.itemNumber,
        );
    };

    if (newAmount > 0) {
        if (!lineItemToUpdate) {
            addNewLineItem();
        } else {
            lineItemToUpdate.quantity = newAmount;
        }
    }
    if (shouldRemoveZeroQuantityProduct) {
        removeEmptyLineItemFromUpdatedOrder();
    } else if (lineItemToUpdate) {
        lineItemToUpdate.quantity = 0;

        await db.pendingCashPurchaseOrders.put(updatedOrder);
    }
};

export const handleSubmitCashOrderErrors = (error: unknown) => {
    if ((error as ApiError).status) {
        const { status, body } = error as ApiError;

        switch (status) {
            case 502:
                return { result: OrderSubmissionResult.apiOffline };
            case 418:
                return {
                    result: OrderSubmissionResult.requestFailed,
                };
            case 400:
                if (
                    body?.includes(
                        'Some of the line product numbers do not exist',
                    )
                ) {
                    const unavailableItemNumbers: string[] = [];
                    try {
                        const parsedErrorBody = JSON.parse(body);
                        const parsedErrorMessage = JSON.parse(
                            parsedErrorBody.detail,
                        );
                        unavailableItemNumbers.push(
                            ...(parsedErrorMessage?.MissingNumbers ?? []),
                        );
                    } catch (error) {
                        console.error(error);
                    }
                    return {
                        result: OrderSubmissionResult.unavailableProducts,
                        unavailableItemNumbers,
                    };
                }
                return { result: OrderSubmissionResult.requestFailed };
            default:
                return { result: OrderSubmissionResult.requestFailed };
        }
    } else {
        return { result: OrderSubmissionResult.requestFailed };
    }
};
