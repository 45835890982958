import { OrderToDisplayInOrdersList } from '../../types/order.types';
import {
    CashPurchaseDto,
    CashPurchaseStatus,
    OrderStatus,
} from '../../apiClient/generated';
import { DisplayedStatus } from '../../context/utils/displayedStatus';
import { PendingCashPurchaseOrder } from '../../types/cashPurchaseOrders.types';
import { isCashPurchaseSubmitted } from '../ProductsList/utils/productsListParser';

const isCashPurchaseStatus = (
    item: CashPurchaseStatus | OrderStatus | undefined,
): item is CashPurchaseStatus => {
    return !((item as OrderStatus) > 2);
};

export const getCashPurchaseStatusToDisplay = (
    cashPurchaseStatus: CashPurchaseStatus | undefined,
    orderOverview?: boolean,
) => {
    if (cashPurchaseStatus === CashPurchaseStatus.Submitted && orderOverview) {
        return DisplayedStatus.updatedByGarrets;
    }
    switch (cashPurchaseStatus) {
        case CashPurchaseStatus.Created:
        case CashPurchaseStatus.Submitted:
            return DisplayedStatus.completed;
        case CashPurchaseStatus.Cancelled:
            return DisplayedStatus.cancelledByGarrets;
        default:
            return DisplayedStatus.unknownStatus;
    }
};

export const parseCashPurchaseOrderStatusToDisplayInOrdersList = (
    cashPurchaseOrderToDisplay: OrderToDisplayInOrdersList,
) => {
    const { status, isSending } = cashPurchaseOrderToDisplay;

    if (isSending) {
        return DisplayedStatus.sendingOffline;
    } else if (isCashPurchaseStatus(status)) {
        return getCashPurchaseStatusToDisplay(status);
    } else {
        return DisplayedStatus.unknownStatus;
    }
};

export const parseCashPurchaseOrderStatusToDisplayOnOverview = (
    order: CashPurchaseDto | PendingCashPurchaseOrder | undefined,
) => {
    if (!order) {
        return;
    }
    if (isCashPurchaseSubmitted(order)) {
        return getCashPurchaseStatusToDisplay(order.status, true);
    } else return 'submitted (offline)';
};
