import { apiClient } from '../apiClient/apiClient';
import { db } from './db';

export const fetchAndUpdateCondemnReports = async () => {
    const response = await apiClient.getCondemnReports();
    await db.condemnReports.bulkPut(response);
    return db.condemnReports
        .toCollection()
        .reverse()
        .sortBy('reportPeriodDate');
};

export const getCondemnReportByReportPeriod = async (reportPeriod: string) => {
    const condemnReports = await db.condemnReports
        .toCollection()
        .sortBy('reportPeriodDate');

    return condemnReports.find(
        (report) => report.reportPeriodDate === reportPeriod,
    );
};
