import lodashDeepClone from 'lodash.clonedeep';

/**
 * Context for using this function:
 * { ...spread } and Object.assign({}, object) do shallow cloning,
 * leaving sub-objects with references instead of copies.
 */
export const deepClone = <InputType>(input: InputType): InputType => {
    return lodashDeepClone(input);
};
