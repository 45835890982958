import useSWR from 'swr';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import { fetchAndUpdateCurrencies } from '../db/externalCurrenciesWithRates';

export const useExternalCurrenciesWithRates = () => {
    const fourHours = 4 * 60 * 60 * 1000;

    const {
        data: externalCurrenciesWithRates,
        isValidating: isExternalCurrenciesWithRatesValidating,
    } = useSWR(
        SWRCacheKeys.externalCurrenciesWithRates,
        fetchAndUpdateCurrencies,
        {
            ...swrDefaultConfig,
            dedupingInterval: fourHours,
            focusThrottleInterval: fourHours,
        },
    );

    return {
        externalCurrenciesWithRates,
        isExternalCurrenciesWithRatesValidating,
    };
};
