/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = None
 *
 * 1 = BinaryConstant
 *
 * 2 = BooleanConstant
 *
 * 3 = DateTimeOffsetConstant
 *
 * 4 = DecimalConstant
 *
 * 5 = FloatingConstant
 *
 * 6 = GuidConstant
 *
 * 7 = IntegerConstant
 *
 * 8 = StringConstant
 *
 * 9 = DurationConstant
 *
 * 10 = Null
 *
 * 11 = Record
 *
 * 12 = Collection
 *
 * 13 = Path
 *
 * 14 = If
 *
 * 15 = Cast
 *
 * 16 = IsType
 *
 * 17 = FunctionApplication
 *
 * 18 = LabeledExpressionReference
 *
 * 19 = Labeled
 *
 * 20 = PropertyPath
 *
 * 21 = NavigationPropertyPath
 *
 * 22 = DateConstant
 *
 * 23 = TimeOfDayConstant
 *
 * 24 = EnumMember
 *
 * 25 = AnnotationPath
 */
export enum EdmExpressionKind {
    None = 0,
    BinaryConstant = 1,
    BooleanConstant = 2,
    DateTimeOffsetConstant = 3,
    DecimalConstant = 4,
    FloatingConstant = 5,
    GuidConstant = 6,
    IntegerConstant = 7,
    StringConstant = 8,
    DurationConstant = 9,
    Null = 10,
    Record = 11,
    Collection = 12,
    Path = 13,
    If = 14,
    Cast = 15,
    IsType = 16,
    FunctionApplication = 17,
    LabeledExpressionReference = 18,
    Labeled = 19,
    PropertyPath = 20,
    NavigationPropertyPath = 21,
    DateConstant = 22,
    TimeOfDayConstant = 23,
    EnumMember = 24,
    AnnotationPath = 25,
}