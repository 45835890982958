import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ProductCategory } from '../components/ProductsList/ProductsList';
import { useRouter } from 'next/router';
import { debounce, throttle } from 'throttle-debounce';
import { StocktakingProductCategoryToDisplay } from '../components/StocktakingProductsList/utils/stocktakingUtils';
import { Product } from '../types/order.types';

export const idPrefixForCategoryRowElement = 'productCategoryRow-';
export const idPrefixForProductRowElement = 'productRow-';

export const enum Page {
    receivalChecklist = 'receivalChecklist',
    stocktakingProductsList = 'stocktakingProductsList',
}

export const useCategoriesBarScrollFunctions = (
    productsSortedByCategories:
        | ProductCategory<Product>[]
        | StocktakingProductCategoryToDisplay[],
    stickyTableHeaderHeight: number,
    page: Page,
) => {
    const productListRef = useRef<HTMLDivElement | null>(null);
    const headerRef = useRef<HTMLDivElement | null>(null);
    const stickyTableHeadRef = useRef<null | HTMLDivElement>(null);

    const [categoriesBarsScrollLeft, setCategoriesBarsScrollLeft] = useState(0);
    const [
        categoriesPositionsUpdatesCounter,
        setCategoriesPositionsUpdatesCounter,
    ] = useState(0);
    const [headerHeight, setHeaderHeight] = useState<undefined | number>(
        undefined,
    );
    const [stickyTableHeadHeight, setStickyTableHeadHeight] = useState<
        undefined | number
    >(stickyTableHeaderHeight);
    const [containerWidth, setContainerWidth] = useState<undefined | number>();
    const [isCategoriesBarSticky, setIsCategoriesBarSticky] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({
        categoryName: '',
        changedManually: false,
    });
    const [
        preventHighlightingVisibleCategory,
        setPreventHighlightingVisibleCategory,
    ] = useState(false);

    /**
     * @function updates state which is used as dependency in useEffect contains fn:selectVisibleCategory.
     * @description As we can add and remove elements from the product list, we need to update the positions of these elements. This is not the best way, but at the moment I don't know do it better.
     */
    const forceUpdateCategoriesPositions = () => {
        setCategoriesPositionsUpdatesCounter((prev) => prev + 1);
    };

    /**
     Scroll productList to selectedCategory.
     */

    const calculateAmountToScroll = (
        element: HTMLElement,
        stickyTableHeadHeight: number,
    ) => {
        if (page === Page.receivalChecklist) {
            return element.offsetTop - stickyTableHeadHeight;
        }
        if (page === Page.stocktakingProductsList) {
            if (isCategoriesBarSticky) {
                return element.offsetTop - 60 - stickyTableHeadHeight;
            }
            return element.offsetTop - 2 * stickyTableHeadHeight - 60;
        }
        return element.offsetTop;
    };
    const scrollToElement = (element: HTMLElement | null) => {
        if (!element || !productListRef.current || !stickyTableHeadHeight) {
            return;
        }

        const scrollHeight = calculateAmountToScroll(
            element,
            stickyTableHeadHeight,
        );

        productListRef.current?.scrollTo({
            top: scrollHeight,
            behavior: 'smooth',
        });
    };

    const scrollToCategory = (categoryName: string) => {
        const categoryRowEl = document.getElementById(
            `${idPrefixForCategoryRowElement}${categoryName}`,
        );
        scrollToElement(categoryRowEl);
    };

    const scrollToProduct = (itemNumber: string) => {
        const productRowEl = document.getElementById(
            `${idPrefixForProductRowElement}${itemNumber}`,
        );

        scrollToElement(productRowEl);
    };

    /**
     Select category manually. Fn will scroll productList to selectedCategory.
     */
    const selectCategory = (categoryName: string, changedManually: boolean) => {
        scrollToCategory(categoryName);
        setSelectedCategory({ categoryName, changedManually });

        // Prevent highlihting all category one by one after manual change.
        setPreventHighlightingVisibleCategory(true);
        setTimeout(() => {
            setPreventHighlightingVisibleCategory(false);
        }, 1000);
    };

    useEffect(() => {
        if (!headerHeight || productListRef.current == null) {
            return;
        }

        /**
         * After user scroll below header, we want to make categoriesBar and tableHead sticky.
         */
        const makeCategoriesBarSticky = throttle(100, () => {
            const productListScrollTop = productListRef.current?.scrollTop ?? 0;

            if (productListScrollTop > headerHeight) {
                setIsCategoriesBarSticky(true);
            }

            if (productListScrollTop + 10 < headerHeight) {
                setIsCategoriesBarSticky(false);
            }
        });

        productListRef.current.addEventListener(
            'scroll',
            makeCategoriesBarSticky,
        );

        return () => {
            if (!headerHeight || productListRef.current == null) {
                return;
            }

            productListRef.current.removeEventListener(
                'scroll',
                makeCategoriesBarSticky,
            );
        };
    }, [headerHeight]);

    useEffect(() => {
        /**
         * get track productList width and store in state.
         */
        const getContainerWidth = throttle(100, () => {
            if (productListRef.current == null) {
                return;
            }
            setContainerWidth(productListRef.current.clientWidth);
        });

        setTimeout(getContainerWidth, 100);

        window.addEventListener('resize', getContainerWidth);

        return () => {
            window.removeEventListener('resize', getContainerWidth);
        };
    }, [productListRef.current]);

    useEffect(() => {
        /**
         * get sticky elements height and store in state.
         */
        const getStickyTableHeadHeight = () => {
            if (stickyTableHeadRef.current == null) {
                return;
            }
            setStickyTableHeadHeight(stickyTableHeadRef.current.clientHeight);
        };

        getStickyTableHeadHeight();
    }, [stickyTableHeadRef.current]);

    useLayoutEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    }, [headerRef.current]);

    useEffect(() => {
        /**
         * Fn will set first available category as selected.
         * We want to make sure that we call it only once. (This is why we chcek categoryName)
         */
        const setInitialSelectedCategory = () => {
            if (
                productsSortedByCategories?.[0] &&
                selectedCategory.categoryName === ''
            ) {
                setSelectedCategory({
                    categoryName: productsSortedByCategories[0].categoryName,
                    changedManually: false,
                });
            }
        };

        setInitialSelectedCategory();
    }, [productsSortedByCategories]);

    const [productListScrolledToTheEnd, setProductListScrolledToTheEnd] =
        useState(false);

    useEffect(() => {
        if (preventHighlightingVisibleCategory) {
            return;
        }

        /**
         * @return array of categories details. Function returns array of all category containers details in the product list.
         */
        const getCategoryRowsElements = () => {
            return productsSortedByCategories
                .map((item) => {
                    const categoryRowEl = document.getElementById(
                        `${idPrefixForCategoryRowElement}${item.categoryName}`,
                    );
                    return {
                        element: categoryRowEl,
                        offset: categoryRowEl?.offsetTop,
                        categoryName: item.categoryName,
                    };
                })
                .filter((item) => item.element !== null && item.offset);
        };

        const elements = getCategoryRowsElements();

        /**
         * while scrolling productList visible categories will be highlighted. When user scrolls to the very end of list last category should be selected, that's why we setProductListScrolledToTheEnd(true);
         * @important to make it work we need to make sure categoriesPositionsUpdatesCounter will be bumped on every change in elements positions.
         */
        const selectVisibleCategory = debounce(100, () => {
            if (!productListRef.current || elements.length === 0) {
                return;
            }

            const isProductListScrolledToTheEnd =
                productListRef.current?.scrollTop +
                    productListRef.current?.offsetHeight >
                productListRef.current?.scrollHeight - 100;

            if (isProductListScrolledToTheEnd) {
                setProductListScrolledToTheEnd(true);
            } else {
                setProductListScrolledToTheEnd(false);

                const breakPoint =
                    productListRef.current?.scrollTop +
                    (stickyTableHeadHeight ?? 142);

                const closestELement = (
                    elements as {
                        element: HTMLElement;
                        offset: number;
                        categoryName: string;
                    }[]
                ).reduce((prev, curr) => {
                    return Math.abs(curr.offset - breakPoint) <
                        Math.abs(prev.offset - breakPoint)
                        ? curr
                        : prev;
                });
                setSelectedCategory({
                    categoryName: closestELement.categoryName,
                    changedManually: false,
                });
            }
        });

        /**
         * This is the part when me update categories positions.
         */
        const updateListener = () => {
            if (!productListRef.current) {
                return;
            }

            productListRef.current.removeEventListener(
                'scroll',
                selectVisibleCategory,
            );

            productListRef.current.addEventListener(
                'scroll',
                selectVisibleCategory,
            );
        };

        updateListener();

        return () => {
            if (!productListRef.current) {
                return;
            }
            productListRef.current.removeEventListener(
                'scroll',
                selectVisibleCategory,
            );
        };
    }, [
        productsSortedByCategories,
        categoriesPositionsUpdatesCounter,
        preventHighlightingVisibleCategory,
        productListScrolledToTheEnd,
    ]);

    const { query } = useRouter();

    useEffect(() => {
        const queryItemNumber = query?.itemNumber;
        if (queryItemNumber && page === Page.receivalChecklist) {
            setTimeout(() => scrollToProduct(String(queryItemNumber)), 100);
        }
        page === Page.receivalChecklist
            ? history.replaceState(null, '', `checklist`)
            : '';
    }, []);

    return {
        productListRef,
        stickyTableHeadRef,
        isCategoriesBarSticky,
        containerWidth,
        stickyTableHeadHeight,
        headerRef,
        selectCategory,
        selectedCategory,
        productListScrolledToTheEnd,
        categoriesBarsScrollLeft,
        setCategoriesBarsScrollLeft,
        forceUpdateCategoriesPositions,
    };
};
