import { useCombobox, UseComboboxStateChange } from 'downshift';
import { InputHTMLAttributes, useEffect, useState } from 'react';
import styles from './Autocomplete.module.css';
import inputStyles from '../Input/Input.module.css';
import OutsideClickHandler from 'react-outside-click-handler';

type FreeTextInputHandlers =
    | {
          handleInputValueChange: (
              changes: UseComboboxStateChange<string>,
          ) => void;
          onInputClear?: never;
      }
    | {
          handleInputValueChange?: never;
          onInputClear: () => void;
      };

interface AutocompleteProps {
    label?: string;
    helper?: React.ReactElement;
    strongLabel?: boolean;
    items: string[];
    selectedItem: string;
    handleSelectedItemChange?: (
        changes: UseComboboxStateChange<string>,
    ) => void;
    error?: string;
    maxResults?: number;
    hintsOnClickEnabled?: boolean;
    dropDownHeight?: number;
    optionalLabel?: string;
}

const Autocomplete: React.FC<
    AutocompleteProps &
        FreeTextInputHandlers &
        InputHTMLAttributes<HTMLInputElement>
> = ({
    label,
    optionalLabel,
    helper,
    items,
    selectedItem,
    handleSelectedItemChange,
    handleInputValueChange,
    strongLabel,
    error,
    onInputClear,
    maxResults = 100,
    dropDownHeight,
    hintsOnClickEnabled,
    ...inputProps
}) => {
    const [inputItems, setInputItems] = useState(items);
    const [shouldClearInput, setShouldClearInput] = useState(false);
    const [isDropdownWithHintsOpen, setIsDropdownWithHintsOpen] =
        useState(false);

    useEffect(() => {
        if (!shouldClearInput) {
            return;
        }

        setShouldClearInput(false);
        onInputClear?.();
    }, [shouldClearInput]);

    const openOrCloseHints = () => {
        if (hintsOnClickEnabled && inputItems.length === 0) {
            setInputItems(items);
        }
        if (!selectedItem) setIsDropdownWithHintsOpen(true);
        if (selectedItem) setIsDropdownWithHintsOpen(false);
    };
    const closeHints = () => {
        if (hintsOnClickEnabled && isDropdownWithHintsOpen) {
            setIsDropdownWithHintsOpen(false);
        }
    };

    const {
        isOpen,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
    } = useCombobox({
        items: inputItems,
        selectedItem,
        onSelectedItemChange: handleSelectedItemChange,
        onInputValueChange: (changes) => {
            const { inputValue } = changes;
            setInputItems(
                items
                    .filter((item: string) => {
                        if (inputValue) {
                            return item
                                .toLowerCase()
                                .startsWith(inputValue.toLowerCase());
                        } else {
                            return '';
                        }
                    })
                    .slice(0, maxResults),
            );
            handleInputValueChange?.(changes);
        },
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges;

            if (handleInputValueChange) {
                return changes;
            }

            switch (type) {
                case useCombobox.stateChangeTypes.InputBlur:
                    // We can't call onInputClear() directly here,
                    // because it's considered a render-side-effect by React
                    // and it will throw a warning
                    // see https://github.com/downshift-js/downshift/issues/962
                    setShouldClearInput(true);
                    return {
                        ...changes,
                        inputValue: '',
                    };
                default:
                    return changes;
            }
        },
    });

    const shouldListBeDisplayed =
        (isDropdownWithHintsOpen && inputItems.length > 1) || isOpen;

    const AutoCompleteContent = (
        <div className={styles.container}>
            <label
                {...getLabelProps()}
                className={
                    strongLabel
                        ? inputStyles.textInputLabelStrong
                        : inputStyles.textInputLabel
                }
            >
                {label}
                {optionalLabel && (
                <label className={inputStyles.optionalLabel}>{optionalLabel}</label>
            )}
                <div {...getComboboxProps()}>
                    <input
                        className={[
                            inputStyles.textInput,
                            error ? inputStyles.textInputWithError : '',
                        ].join(' ')}
                        onClick={openOrCloseHints}
                        {...getInputProps(inputProps)}
                    />
                    <ul
                        {...getMenuProps()}
                        className={
                            isOpen || isDropdownWithHintsOpen
                                ? styles.dropdownList
                                : ''
                        }
                        style={{ maxHeight: `${dropDownHeight}px` }}
                    >
                        {shouldListBeDisplayed &&
                            inputItems.map((item: string, index: number) => (
                                <li
                                    className={`${styles.dropdownItem} ${
                                        highlightedIndex === index
                                            ? styles.highlighted
                                            : ''
                                    }`}
                                    key={`${item}${index}`}
                                    {...getItemProps({
                                        item,
                                        index,
                                    })}
                                    data-testid="autocompleteDropdownItems"
                                >
                                    {item}
                                </li>
                            ))}
                    </ul>
                    {error && (
                        <span className={inputStyles.errorMessage}>
                            {error}
                        </span>
                    )}
                </div>
                {helper}
            </label>
        </div>
    );

    return hintsOnClickEnabled ? (
        <OutsideClickHandler onOutsideClick={closeHints}>
            {AutoCompleteContent}
        </OutsideClickHandler>
    ) : (
        AutoCompleteContent
    );
};

export default Autocomplete;
