import styles from './LinkIcon.module.css';
import Link from 'next/link';
import LinkIconSvg from '../../public/gfx/uiIcons/link.svg';

interface Props {
    link: string;
}

export const LinkIcon: React.FC<Props> = ({ link }) => {
    return (
        <Link href={link}>
            <a target="_blank" rel="noopener noreferrer">
                <div className={styles.linkIcon}>
                    <LinkIconSvg width="17" height="17" />
                </div>
            </a>
        </Link>
    );
};
