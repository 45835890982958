import { User } from '@auth0/auth0-react';
import moment from 'moment';
import { claimsNamespace } from '../context/useFeatureFlags';
import { ManningReport } from '../types/manning.types';
import { ItemStatus } from '../types/itemStatuses.types';

export const validateStringFormat = (
    query: unknown,
    expectedFormat: 'YYYY-MM' | 'YYYY-MM-DD',
) => {
    if (typeof query !== 'string') {
        return null;
    }

    const isValidDayFormat = moment(
        query as string,
        expectedFormat,
        true,
    ).isValid();

    if (isValidDayFormat) {
        return query;
    }

    return null;
};

export const parseMonthAndYearToDate = (dateString: unknown) => {
    if (typeof dateString === 'undefined') {
        return null;
    }
    const monthAndYear = validateStringFormat(dateString, 'YYYY-MM');

    if (monthAndYear) {
        return moment(monthAndYear, 'YYYY-MM');
    } else {
        return null;
    }
};

const getOpeningMonthFromAuth0 = (auth0User: User | undefined) => {
    if (!auth0User) {
        return null;
    }

    const openingMonthFromAuth0 =
        auth0User[`${claimsNamespace}/MANNING_OPENING_PERIOD`];

    return parseMonthAndYearToDate(openingMonthFromAuth0);
};

const getLatestReportMonthAndYear = (manningReports: ManningReport[]) => {
    if (!manningReports.length) {
        return null;
    }

    const sortedReports = manningReports.sort((a, b) =>
        moment(b.monthAndYear, 'YYYY-MM').diff(
            moment(a.monthAndYear, 'YYYY-MM'),
        ),
    );

    const [lastReport] = sortedReports;

    const currentMonth = moment(lastReport.monthAndYear, 'YYYY-MM');

    if (lastReport.state === ItemStatus.draft) {
        return currentMonth;
    }

    return currentMonth.add(1, 'month');
};

export const getCurrentMonthForManningFlow = (
    user: User | undefined,
    manningReports: ManningReport[],
) => {
    let currentMonth;

    if (manningReports.length > 0) {
        currentMonth = getLatestReportMonthAndYear(manningReports);
    }

    if (!currentMonth) {
        currentMonth = getOpeningMonthFromAuth0(user);
    }

    return currentMonth;
};
