import { LineItem, Order } from '../../types/order.types';
import { getPreviousRfq } from '../../hooks/useChangelog';
import { LastCommentFromRfqFlow } from '../editableOrders';
import { getLatestRfq } from './getLatestRfq';

export const hasPortChangedInLastRfq = (order: Order) => {
    const latestRfq = getLatestRfq(order);
    const previousRfq = getPreviousRfq(order);

    if (!previousRfq || !latestRfq) {
        return false;
    }

    if (
        latestRfq.deliveryPort?.portCode !== previousRfq.deliveryPort?.portCode
    ) {
        return true;
    }

    return false;
};

export const getLastComment = (
    order: Order,
    lineItem: LineItem,
): LastCommentFromRfqFlow => {
    const commentsFromEachRfq = order.rfqs.map((rfq) => ({
        comment: rfq.lineItems.find(
            (item) => item.itemNumber === lineItem?.itemNumber,
        )?.comment,
        origin: rfq.origin,
        createdAt: rfq.created,
    }));

    const filledComments = commentsFromEachRfq.filter((item) =>
        Boolean(item.comment?.length),
    );

    const lastComment = filledComments[filledComments.length - 1];

    return lastComment;
};
