import React from 'react';
import Image from 'next/image';
import styles from './SearchButton.module.css';

interface SearchButtonProps {
    onClick: () => void;
}

const SearchButton: React.FC<SearchButtonProps> = ({ onClick }) => {
    return (
        <button className={styles.buttonIconContainer} onClick={onClick}>
            <Image
                src="/gfx/uiIcons/searchIcon.svg"
                alt="searchIcon"
                width="24"
                height="24"
            />
        </button>
    );
};

export default SearchButton;
