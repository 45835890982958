import React from 'react';
import Modal from '../../Modal/Modal';
import modalStyles from '../../Modal/Modal.module.css';
import Button from '../../Button/Button';
import styles from '../../SmallConfirmationModal/SmallConfirmationModal.module.css';

interface Props {
    isModalOpen: boolean;
    onClose: () => void;
    onSelectDifferent: () => void;
    onGoToExisting: () => void;
}

const DuplicatedItemModal: React.FC<Props> = ({
    isModalOpen,
    onClose,
    onGoToExisting,
    onSelectDifferent,
}) => {
    return (
        <div className={styles.overlay}>
            <Modal isOpen={isModalOpen} onClose={onClose} small>
                <div className={styles.container}>
                    <h1>Already added</h1>
                    <p className={styles.greyText}>
                        This item already exists in your received order.
                    </p>
                </div>
                <div className={modalStyles.squareActionButton}>
                    <div className={modalStyles.squareActionButtonChild}>
                        <Button
                            text="Select Different Item"
                            onClick={onSelectDifferent}
                            secondary
                        />
                    </div>
                    <div className={modalStyles.squareActionButtonChild}>
                        <Button
                            text="Go to Existing Item"
                            onClick={onGoToExisting}
                            primary
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default DuplicatedItemModal;
