import useSWR from 'swr';
import { apiClient } from '../apiClient/apiClient';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import { useEffect } from 'react';
import {
    resetEstimatedPricesInEditableOrder,
    updatePrices,
} from '../db/pricesAndPorts';
import { EditableOrder } from '../types/order.types';

/**
 * This hook handles updating prices.
 */
export const usePrices = (editableOrder: EditableOrder | undefined) => {
    const fetchPrices = async (port: string | undefined) => {
        if (port) {
            return apiClient.getMarketsByPortCode(port);
        }
    };

    const port = editableOrder?.rfq.deliveryPort?.portCode;

    const { data } = useSWR(
        `${SWRCacheKeys.prices}-${port}`,
        async () => {
            try {
                if (port) {
                    return fetchPrices(port);
                } else {
                    throw Error;
                }
            } catch (err) {
                if (editableOrder) {
                    await resetEstimatedPricesInEditableOrder(editableOrder);
                }
            }
        },
        { ...swrDefaultConfig, revalidateOnFocus: false },
    );

    /**
     * We do not use prices directly, as they are kept in the product table.
     * By using SWR, all prices are cached, so we reduce the number of requests.
     * Every time the prices change the product list and editable order is updated.
     */
    useEffect(() => {
        updatePrices(data, editableOrder);
    }, [data]);
};
