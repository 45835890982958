import styles from './ToggleButtonWithKnob.module.css';

interface ToggleButtonWithKnobProps {
    isActive: boolean;
}

export const ToggleButtonWithKnob: React.FC<ToggleButtonWithKnobProps> = ({
    isActive,
}) => {
    return (
        <div
            className={[
                styles.toggleButton,
                isActive ? styles.active : '',
            ].join(' ')}
        >
            <div className={styles.knob} />
        </div>
    );
};
