import Modal from '../Modal/Modal';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { RemovedLineItemsChangelog } from '../OrderSummaryPopups/DuplicateOrderModal/RemovedLineItemsChangelog';
import modalStyles from '../Modal/Modal.module.css';
import { LineItemWithProductDetails } from '../../db/utils/products';
import Button from '../Button/Button';

export type RemovedLineItemsChangelogModalMetadata = {
    isOpen: boolean;
    removedLineItemsWithProductDetails: LineItemWithProductDetails[];
};

interface RemovedLineItemsChangelogModalProps {
    removedLineItemsChangelogModalMetadata: RemovedLineItemsChangelogModalMetadata;
    handleCloseRemovedLineItemsChangelogModal: () => void;
}

/**
 * Duplicated order may contain products outside of the assortment.
 * Then we want to inform the user that these products will be removed from the new order.
 */
export const RemovedLineItemsChangelogModal: React.FC<
    RemovedLineItemsChangelogModalProps
> = ({
    removedLineItemsChangelogModalMetadata,
    handleCloseRemovedLineItemsChangelogModal,
}) => {
    const { isOpen, removedLineItemsWithProductDetails } =
        removedLineItemsChangelogModalMetadata;
    return (
        <Modal isOpen={isOpen} noHeader>
            <ModalHeader title="Duplicate" subTitle="Duplicating Order" />
            <div className={modalStyles.modalContent}>
                <RemovedLineItemsChangelog
                    removedLineItemsWithProductDetails={
                        removedLineItemsWithProductDetails
                    }
                />
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Ok"
                        onClick={handleCloseRemovedLineItemsChangelogModal}
                        primary
                    />
                </div>
            </div>
        </Modal>
    );
};
