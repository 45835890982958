import { useClaimsInReceivalFlow } from '../../../context/ClaimsInReceivalFlowContext';
import SelectableIssueReason from '../../ClaimOrCondemnPreparationModal/IssuesReasonsListStep/SelectableIssueReason';
import { claimBaseReasons } from '../../ClaimOrCondemnPreparationModal/utils/selectableOptions';
import { ModalHeader } from '../../ModalHeader/ModalHeader';
import styles from '../commonStyles.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import Button from '../../Button/Button';
import { useState } from 'react';
import { ItemIssueProblem } from '../../../apiClient/generated';
import { CondemnReasons } from '../../StocktakingProductsList/utils/stocktakingUtils';

export const ReasonsList = () => {
    const {
        changeStep,
        updateItemIssue,
        claimsInReceivalModalMetadata: { itemIssueDetails },
    } = useClaimsInReceivalFlow();

    const [selectedIssueReason, setSelectedIssueReason] = useState<
        ItemIssueProblem | CondemnReasons | null | undefined
    >(itemIssueDetails?.itemIssueProblem);

    return (
        <>
            <ModalHeader
                title="What was the issue?"
                subTitle="Tell us what went wrong"
            />
            <div
                className={[styles.contentContainer, styles.lessSpacing].join(
                    ' ',
                )}
            >
                {claimBaseReasons.map((option) => (
                    <SelectableIssueReason
                        key={option.id}
                        option={option}
                        updateItemIssue={() => {
                            setSelectedIssueReason(option.itemIssueProblem);
                        }}
                        isSelected={
                            selectedIssueReason === option.itemIssueProblem
                        }
                    />
                ))}
            </div>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Cancel"
                        onClick={() => {
                            changeStep('whatIsTheIssue');
                        }}
                        secondary
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Continue"
                        onClick={() => {
                            if (!selectedIssueReason) return;
                            changeStep('whatIsTheIssue');
                            updateItemIssue(
                                'itemIssueProblem',
                                selectedIssueReason,
                            );
                        }}
                        disabled={!Boolean(selectedIssueReason)}
                        primary={Boolean(selectedIssueReason)}
                    />
                </div>
            </div>
        </>
    );
};
