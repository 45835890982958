import { BaseIndicator } from '../SyncSidebarIndicators/BaseIndicator/BaseIndicator';
import styles from './SyncStatusPopup.module.css';
import ConnectionStatusCloudRed from '../../public/gfx/uiIcons/connectionStatusCloudRed.svg';

export enum NotificationType {
    loadingPrices = 'loadingPrices',
    connectionLost = 'connectionLost',
}

interface NotificationProps {
    onClose: () => void;
    type: NotificationType;
}

export const Notification: React.FC<NotificationProps> = ({
    onClose,
    type,
}) => {
    const getNotificationDetails = () => {
        switch (type) {
            case NotificationType.connectionLost:
                return {
                    text: 'Connection lost',
                    optionalHelper:
                        'You can continue working and your changes will be sent once reconnected.',
                    pending: false,
                    withPadding: false,
                    icon: ConnectionStatusCloudRed,
                };
            case NotificationType.loadingPrices:
                return {
                    text: 'Fetching new prices',
                    optionalHelper: undefined,
                    pending: true,
                    withPadding: true,
                    icon: undefined,
                };
        }
    };
    const { text, optionalHelper, pending, withPadding, icon } =
        getNotificationDetails() ?? {};

    return (
        <div
            className={[
                styles.container,
                styles.notification,
                withPadding ? styles.withPadding : '',
            ].join(' ')}
        >
            <BaseIndicator
                primary
                icon={icon}
                pending={pending}
                text={text}
                optionalHelper={optionalHelper}
                noMargin={!optionalHelper}
                onClose={onClose}
            />
        </div>
    );
};
