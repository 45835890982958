import { EditableOrder, Order } from '../../types/order.types';

export const isEditableOrder = (order: unknown): order is EditableOrder => {
    return (
        (order as EditableOrder).rfq !== undefined ||
        (order as EditableOrder).status !== undefined ||
        (order as EditableOrder).type !== undefined
    );
};

export const isOrder = (order: unknown): order is Order => {
    return (
        (order as Order).orderId !== undefined ||
        (order as Order).rfqs !== undefined ||
        (order as Order).lastModified !== undefined
    );
};
