/**
 * This context renders modal that is used in claims in receival flow.
 * Modal is triggered by the flag-IconButton in the ReceivedProductsChecklist.
 * The flow is different from the regular claims flow, so we need a seperated Modal.
 */

import React, { createContext, useContext, useState } from 'react';
import { ClaimsInReceivalModal } from '../components/ClaimsInReceivalModal/ClaimsInReceivalModal';
import {
    ClaimOrder,
    ItemIssueDetails,
    ItemIssueUIType,
    UpdateItemIssue,
} from '../components/ClaimOrCondemnPreparationModal/ClaimOrCondemnPreparationModal';
import { getInitialItemIssueData } from '../components/ClaimsOverviewComponents/helpers/getInitialItemIssueData';
import { ReceivedOrder } from '../types/receivedOrder.types';
import { useItemsAvailableToClaim } from '../hooks/useItemsAvailableToClaim';
import {
    AvailableClaimItemDto,
    ItemIssueProblem,
} from '../apiClient/generated';
import { db } from '../db/db';
import { adjustOrderToDisplay } from '../components/utils/adjustOrderToDisplay';
import { IExtendedItemIssueDto } from '../db/overrideTypes';
import { getLatestRfq } from '../db/utils/getLatestRfq';
import { getShortWbPono } from '../components/utils/wbPono';

type SelectedStep =
    | 'whatIsTheIssue'
    | 'reasonsList'
    | 'issueDescriptionAndPhotos'
    | 'issueSummary'
    | 'issueSummaryReadOnly';

type ClaimsInReceivalModalMetadata = {
    isOpen: boolean;
    selectedStep: SelectedStep;
    itemIssueDetails?: ItemIssueDetails;
    itemIssueReadOnlySummary?: {
        productName: string;
        orderId: string;
        itemIssueProblem: ItemIssueProblem;
        affectedAmount: number;
        unitOfMeasure: string;
    };
    itemNumber?: string;
    unitOfMeasure?: string;
};

interface ContextValue {
    claimsInReceivalModalMetadata: ClaimsInReceivalModalMetadata;
    closeClaimsInReceivalModal: () => void;
    openClaimsInReceivalModal: (
        order: ReceivedOrder,
        itemNumber: string,
        createdClaim: IExtendedItemIssueDto | undefined,
    ) => void;
    changeStep: (step: SelectedStep) => void;
    updateItemIssue: UpdateItemIssue;
}

const ClaimsInReceivalFlowContext = createContext<ContextValue | undefined>(
    undefined,
);

type ClaimsInReceivalFlowProviderProps = {
    children: React.ReactNode;
};

const ClaimsInReceivalFlowProvider = ({
    children,
}: ClaimsInReceivalFlowProviderProps) => {
    const [claimsInReceivalModalMetadata, setClaimsInReceivalModalMetadata] =
        useState<ClaimsInReceivalModalMetadata>({
            isOpen: false,
            selectedStep: 'whatIsTheIssue',
        });

    const updateItemIssue: UpdateItemIssue = (field, value) => {
        setClaimsInReceivalModalMetadata((prev) => {
            if (!prev.itemIssueDetails) return prev;

            return {
                ...prev,

                itemIssueDetails: {
                    ...prev.itemIssueDetails,
                    [field]: value,
                },
            };
        });
    };

    const { itemsAvailableToClaim } = useItemsAvailableToClaim();

    const getClaimOrder = async (
        receivedOrder: ReceivedOrder,
        itemsAvailableToClaim: AvailableClaimItemDto[] | undefined,
        itemNumber: string,
    ): Promise<ClaimOrder | undefined> => {
        const order = await db.orders.get(receivedOrder.orderId);
        if (!order) return;
        const orderToDisplay = adjustOrderToDisplay(order);
        const itemToClaim = itemsAvailableToClaim?.find(
            (item) => item.itemNumber === itemNumber,
        );
        if (!itemToClaim) return;
        const orderFromClaimItem = itemToClaim.orders.find(
            (order) => order.orderId === receivedOrder.orderId,
        );
        if (!orderFromClaimItem) return;

        const claimOrder = {
            ...orderToDisplay,
            orderedQuantity: orderFromClaimItem.orderedQuantity ?? 0,
            claimedQuantity: orderFromClaimItem.claimedQuantity ?? 0,
        };

        return claimOrder;
    };

    const openClaimsInReceivalModal = async (
        order: ReceivedOrder,
        itemNumber: string,
        createdClaim: IExtendedItemIssueDto | undefined,
    ) => {
        // todo: there is a problem with itemIssueProblem mapping it is enum or string depends on the issue
        if (createdClaim) {
            const product = await db.products
                .where('itemNumber')
                .equals(itemNumber)
                .first();

            const orderFromDb = await db.orders
                .where('orderId')
                .equals(order.orderId)
                .first();

            if (
                !createdClaim ||
                !product ||
                !createdClaim.affectedAmount ||
                !createdClaim.itemIssueProblem ||
                !orderFromDb
            ) {
                return;
            }

            if (createdClaim) {
                setClaimsInReceivalModalMetadata({
                    selectedStep: 'issueSummaryReadOnly',
                    isOpen: true,
                    itemIssueReadOnlySummary: {
                        productName: product.itemName,
                        affectedAmount: createdClaim.affectedAmount,
                        itemIssueProblem: createdClaim.itemIssueProblem,
                        orderId: getShortWbPono(
                            getLatestRfq(orderFromDb)?.wbPono ?? '',
                        ),
                        unitOfMeasure: product.unitOfMeasure,
                    },
                });

                return;
            }
        }

        const claimOrder = await getClaimOrder(
            order,
            itemsAvailableToClaim,
            itemNumber,
        );

        if (!claimOrder) {
            console.error('Claim order not found');
            return;
        }

        const itemIssueDetails: ItemIssueDetails = {
            ...getInitialItemIssueData(ItemIssueUIType.Claim),
            order: claimOrder,
        };

        const unitOfMeasure = claimOrder.rfq.lineItems.find(
            (item) => item.itemNumber === itemNumber,
        )?.unitOfMeasure;

        setClaimsInReceivalModalMetadata((prev) => ({
            ...prev,
            isOpen: true,
            itemIssueDetails,
            unitOfMeasure,
            itemNumber,
        }));
    };

    const closeClaimsInReceivalModal = () => {
        setClaimsInReceivalModalMetadata({
            selectedStep: 'whatIsTheIssue',
            isOpen: false,
        });
    };

    const changeStep = (step: SelectedStep) => {
        setClaimsInReceivalModalMetadata((prev) => ({
            ...prev,
            selectedStep: step,
        }));
    };

    const contextValue: ContextValue = {
        claimsInReceivalModalMetadata,
        openClaimsInReceivalModal,
        closeClaimsInReceivalModal,
        changeStep,
        updateItemIssue,
    };
    return (
        <ClaimsInReceivalFlowContext.Provider value={contextValue}>
            <ClaimsInReceivalModal />
            {children}
        </ClaimsInReceivalFlowContext.Provider>
    );
};

export { ClaimsInReceivalFlowProvider };

export const useClaimsInReceivalFlow = () => {
    const context = useContext(ClaimsInReceivalFlowContext);
    if (context === undefined) {
        throw new Error('useClaimsInReceivalFlow context is out of scope');
    }

    return context;
};
