/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VesselAccount } from '../models/VesselAccount';
import type { VesselAccountDetails } from '../models/VesselAccountDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminUsersScope {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns VesselAccount Success
     * @throws ApiError
     */
    public apiOdataAdminUsers(): CancelablePromise<Array<VesselAccount>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminUsers',
        });
    }

    /**
     * @returns VesselAccount Success
     * @throws ApiError
     */
    public apiOdataAdminUsersCount(): CancelablePromise<Array<VesselAccount>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminUsers/$count',
        });
    }

    /**
     * @param key 
     * @returns VesselAccountDetails Success
     * @throws ApiError
     */
    public apiOdataAdminUsersKey(
key?: string,
): CancelablePromise<VesselAccountDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminUsers({key})',
            query: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @returns VesselAccountDetails Success
     * @throws ApiError
     */
    public apiOdataAdminUsersKey1(
key?: string,
): CancelablePromise<VesselAccountDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/odata/AdminUsers/{key}',
            query: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns VesselAccountDetails Success
     * @throws ApiError
     */
    public apiOdataAdminUsersKeyMetadataSetEntry(
key?: string,
requestBody?: Record<string, any>,
): CancelablePromise<VesselAccountDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminUsers({key})/Metadata.SetEntry',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns VesselAccountDetails Success
     * @throws ApiError
     */
    public apiOdataAdminUsersKeySetEntry(
key?: string,
requestBody?: Record<string, any>,
): CancelablePromise<VesselAccountDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminUsers({key})/SetEntry',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns VesselAccountDetails Success
     * @throws ApiError
     */
    public apiOdataAdminUsersKeyMetadataSetEntry1(
key?: string,
requestBody?: Record<string, any>,
): CancelablePromise<VesselAccountDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminUsers/{key}/Metadata.SetEntry',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns VesselAccountDetails Success
     * @throws ApiError
     */
    public apiOdataAdminUsersKeySetEntry1(
key?: string,
requestBody?: Record<string, any>,
): CancelablePromise<VesselAccountDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminUsers/{key}/SetEntry',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns VesselAccountDetails Success
     * @throws ApiError
     */
    public apiOdataAdminUsersKeyMetadataSetMultipleEntry(
key?: string,
requestBody?: Record<string, any>,
): CancelablePromise<VesselAccountDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminUsers({key})/Metadata.SetMultipleEntry',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns VesselAccountDetails Success
     * @throws ApiError
     */
    public apiOdataAdminUsersKeySetMultipleEntry(
key?: string,
requestBody?: Record<string, any>,
): CancelablePromise<VesselAccountDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminUsers({key})/SetMultipleEntry',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns VesselAccountDetails Success
     * @throws ApiError
     */
    public apiOdataAdminUsersKeyMetadataSetMultipleEntry1(
key?: string,
requestBody?: Record<string, any>,
): CancelablePromise<VesselAccountDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminUsers/{key}/Metadata.SetMultipleEntry',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns VesselAccountDetails Success
     * @throws ApiError
     */
    public apiOdataAdminUsersKeySetMultipleEntry1(
key?: string,
requestBody?: Record<string, any>,
): CancelablePromise<VesselAccountDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/odata/AdminUsers/{key}/SetMultipleEntry',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}