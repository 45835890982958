import styles from './SelectableCard.module.css';
import moment from 'moment/moment';
import { LinkIcon } from '../LinkIcon/LinkIcon';

interface SelectableCardProps {
    isSelected?: boolean;
    small?: boolean;
    title: string | React.ReactElement;
    onClick: () => void;
    orderDate?: Date;
    orderLink?: string;
}

const SelectableCard: React.FC<SelectableCardProps> = ({
    isSelected = false,
    small = false,
    title,
    onClick,
    orderDate,
    orderLink,
}) => {
    const date = (
        <p className={styles.dateLabel}>
            {moment(orderDate).format('DD/MM/YY')}
        </p>
    );

    return (
        <button
            onClick={onClick}
            className={`${styles.container} ${
                isSelected ? styles.selected : ''
            } ${small ? styles.small : ''}`}
        >
            <div className={styles.title}>{title}</div>
            <div className={styles.rowContainer}>
                {orderDate && <div>{date}</div>}
                {orderLink && (
                    <div onClick={(e) => e.stopPropagation()}>
                        <LinkIcon link={orderLink} />
                    </div>
                )}
            </div>
        </button>
    );
};

export default SelectableCard;
