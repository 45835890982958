import { getImagesFromDb } from '../../ClaimsOverviewComponents/helpers/dbHelpers';
import {
    parseClaimDetailsToItemIssueDto,
    parseItemIssueDetailsToClaimDto,
} from './parsers';
import {
    ClaimPhotosSubmissionResult,
    ClaimSubmissionResult,
    submitClaimDataPart,
    submitClaimPhotosPart,
} from './submitClaim';
import { db } from '../../../db/db';
import { ToastType, useToast } from '../../../context/ToastContext';
import { ItemIssueDetails } from '../ClaimOrCondemnPreparationModal';
import { useState } from 'react';
import { useClaims } from '../../../hooks/useClaims';
import { ModalType, useModalContext } from '../../../context/ModalContext';

export const useClaimSubmitting = () => {
    const { showToast } = useToast();

    const { openGlobalModal } = useModalContext();

    const { mutate: refreshClaims } = useClaims();

    const [processing, setProcessing] = useState(false);

    const [isClaimOfflineModalOpen, setIsClaimOfflineModalOpen] =
        useState(false);

    const claimValidationModalTitle = 'Your claim request failed';
    let claimValidationModalDescription;

    const onSuccessfulSubmitDataPart = async (claim: ItemIssueDetails) => {
        const claimPhotosPartResponse = await submitClaimPhotosPart(
            claim.clientId,
            claim.localId,
        );
        if (claimPhotosPartResponse === ClaimPhotosSubmissionResult.success) {
            if (claim.localId)
                db.pendingItemIssues
                    .where('localId')
                    .equals(claim.localId)
                    .delete();

            await refreshClaims();
            showToast({
                type: ToastType.success,
                text: 'Claim opened',
            });
        }
    };

    const onApiOffline = async (claim: ItemIssueDetails) => {
        await submitClaimPhotosPart(claim.clientId, claim.localId);
        setIsClaimOfflineModalOpen(true);
        if (claim.localId) {
            const parsedClaim = parseClaimDetailsToItemIssueDto(claim, true);
            await db.claims.put(parsedClaim);
            await refreshClaims();
        }
    };

    const onFailedSubmitDataPart = (
        claimDataPartResponse: ClaimSubmissionResult,
    ) => {
        switch (claimDataPartResponse) {
            case ClaimSubmissionResult.toHighQuantity:
                claimValidationModalDescription =
                    'Claimed quantity exceeds the ordered quantity.';
                break;
            case ClaimSubmissionResult.claimingProductDoesNotExist:
                claimValidationModalDescription =
                    'Claimed product does not exist on the order.';
                break;
            case ClaimSubmissionResult.productNotFound:
                claimValidationModalDescription =
                    'Claimed product was not found.';
                break;
            case ClaimSubmissionResult.orderNotFound:
                claimValidationModalDescription =
                    'The order in which you claimed the product does not exist.';
                break;
            case ClaimSubmissionResult.portRequired:
                claimValidationModalDescription =
                    'Port is required for provided problem.';
                break;
            default:
                claimValidationModalDescription = 'Something went wrong.';
        }

        openGlobalModal({
            modalType: ModalType.claimValidationModal,
            claimValidationModalDetails: {
                title: claimValidationModalTitle,
                description: claimValidationModalDescription,
            },
        });
    };

    const submitClaim = async (claim: ItemIssueDetails) => {
        const claimImages = await getImagesFromDb();
        const parsedClaim = parseItemIssueDetailsToClaimDto(
            claim,
            claimImages?.length,
        );

        setProcessing(true);
        const claimDataPartResponse = await submitClaimDataPart(parsedClaim);

        if (claimDataPartResponse === ClaimSubmissionResult.success) {
            await onSuccessfulSubmitDataPart(claim);
        } else if (claimDataPartResponse === ClaimSubmissionResult.apiOffline) {
            await onApiOffline(claim);
        } else {
            onFailedSubmitDataPart(claimDataPartResponse);
        }

        setProcessing(false);
    };
    return {
        submitClaim,
        processing,
        isClaimOfflineModalOpen,
        setIsClaimOfflineModalOpen,
    };
};
