/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Other
 *
 * 1 = BadQualityItemDelivered
 *
 * 2 = IncorrectItemDelivered
 *
 * 3 = DamagedOnDelivery
 *
 * 4 = ExpiredOnDelivery
 *
 * 5 = ExpiredOnBoard
 *
 * 6 = RemovedFromStock
 *
 * 7 = DamagedOnBoard
 */
export enum ItemIssueProblem {
    Other = 0,
    BadQualityItemDelivered = 1,
    IncorrectItemDelivered = 2,
    DamagedOnDelivery = 3,
    ExpiredOnDelivery = 4,
    ExpiredOnBoard = 5,
    RemovedFromStock = 6,
    DamagedOnBoard = 7,
}