const isDeviceDesktop = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return false;
    } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua,
        )
    ) {
        return false;
    }
    return true;
};

export const isTouchDevice = () => {
    return (
        (isDeviceDesktop() && 'ontouchstart' in window) ||
        navigator.maxTouchPoints > 0
    );
};
