import Button from '../../Button/Button';
import DetailsDataRow from '../../DetailsDataTable/DetailsDataRow';
import FilesSection from '../../FilesSection/FilesSection';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import { LimitedTextArea } from '../../LimitedTextArea/LimitedTextArea';
import { ModalHeader } from '../../ModalHeader/ModalHeader';
import { Question } from '../../Question/Question';
import styles from '../commonStyles.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import { db } from '../../../db/db';
import { useClaimsInReceivalFlow } from '../../../context/ClaimsInReceivalFlowContext';
import { useState } from 'react';
import { compressImages } from '../../ClaimOrCondemnPreparationModal/utils/compressionHelper';

export const ClaimDescriptionAndPhotos = () => {
    const { changeStep, updateItemIssue, claimsInReceivalModalMetadata } =
        useClaimsInReceivalFlow();

    const [files, setFiles] = useState<File[]>(
        claimsInReceivalModalMetadata.itemIssueDetails?.images?.map((file) => {
            return file.image;
        }) ?? [],
    );
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [compressionInProgress, setCompressionInProgress] = useState(false);

    const handleAddFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        return await addFilesFromFileList(e.target.files);
    };

    const addFilesFromFileList = async (fileList: FileList) => {
        setUploadError(null);
        const filesListArray = Array.from(fileList);

        if (filesListArray.length === 0) {
            return;
        }

        const filesAdjustedToSend: File[] = [];
        filesListArray.forEach((file) => {
            switch (file.type) {
                case 'application/pdf': {
                    return filesAdjustedToSend.push(file);
                }
                default: {
                    return setCompressionInProgress(true);
                }
            }
        });

        const compressedImages = await compressImages({
            files: filesListArray,
        });

        setCompressionInProgress(false);

        const maxSizeInBytes = 5000000;

        filesAdjustedToSend.push(...compressedImages);

        const isAnyOfTheFilesTooBig = filesAdjustedToSend.some(
            (file) => file.size > maxSizeInBytes,
        );

        if (isAnyOfTheFilesTooBig) {
            setUploadError(
                "The filesize of the attachment you're trying to upload is too large. Please upload a file smaller than 5 MB.",
            );
            console.error('File size is too big');

            return;
        }
        mapAndUpdateFiles(filesAdjustedToSend);
    };

    const mapAndUpdateFiles = (filesToSend: File[]) => {
        setFiles((prev) => {
            const files = [...(prev ? prev : []), ...filesToSend];
            const reMappedFiles = files.map((file) => {
                return {
                    id: file.name,
                    image: file,
                };
            });
            updateItemIssue('images', reMappedFiles);
            return files;
        });
    };

    const handleRemoveFile = (item: File) => {
        setFiles((prev) => {
            const reducedFiles = prev
                ? prev.filter(
                      (file) =>
                          !(
                              file.name === item.name &&
                              file.lastModified === item.lastModified
                          ),
                  )
                : [];

            const reMappedFiles = reducedFiles.map((file) => {
                return {
                    id: file.name,
                    image: file,
                };
            });
            updateItemIssue('images', reMappedFiles);
            return reducedFiles;
        });
    };

    return (
        <>
            <ModalHeader
                title="Claim step 2 of 3"
                subTitle="Enter details on the issue."
                description="Providing details and a photo of the issue helps us resolve claims faster."
            />
            <div className={styles.contentContainer}>
                <DetailsDataRow
                    boldTitle
                    title={'Describe the issue'}
                    lessSpacing
                />
                <LimitedTextArea
                    name="commentInput"
                    placeholder={
                        'Additional notes that would help us process your request.'
                    }
                    className={[
                        modalStyles.commentInput,
                        modalStyles.small,
                    ].join(' ')}
                    value={
                        claimsInReceivalModalMetadata.itemIssueDetails
                            ?.comment ?? ''
                    }
                    onChange={(value) => {
                        updateItemIssue('comment', value ?? '');
                    }}
                    characterLimit={db.defaults.axOrderLineStringFieldLimit}
                />
                <div className={styles.question}>
                    <Question
                        question="Is the product still usable?"
                        answer={
                            claimsInReceivalModalMetadata.itemIssueDetails
                                ?.isProductUsable
                        }
                        experienceKey="isUsable"
                        setAnswer={(key, answer) => {
                            updateItemIssue('isProductUsable', answer);
                        }}
                        remark="This determines whether we should remove the item from your stock."
                    />
                </div>
                <FilesSection
                    allowPdfs
                    files={files}
                    handleAddFiles={handleAddFiles}
                    handleRemoveFiles={handleRemoveFile}
                    compressionInProgress={compressionInProgress}
                />
                <p>{uploadError}</p>
            </div>

            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Back"
                        onClick={() => changeStep('whatIsTheIssue')}
                        secondary
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Next"
                        onClick={() => changeStep('issueSummary')}
                        disabled={
                            !claimsInReceivalModalMetadata.itemIssueDetails
                                ?.comment ||
                            claimsInReceivalModalMetadata.itemIssueDetails
                                ?.isProductUsable === null
                        }
                        primary={Boolean(
                            claimsInReceivalModalMetadata.itemIssueDetails
                                ?.comment &&
                                claimsInReceivalModalMetadata.itemIssueDetails
                                    ?.isProductUsable !== null,
                        )}
                    />
                </div>
            </div>
        </>
    );
};
