import { ChangeEvent } from 'react';
import styles from './Question.module.css';

interface QuestionProps<TKey> {
    question: string;
    experienceKey: TKey;
    answer: boolean | null | undefined;
    remark?: string;
    setAnswer: (key: TKey, answer: boolean) => void;
}

export const Question = <TKey,>({
    question,
    experienceKey,
    setAnswer,
    answer,
    remark,
}: QuestionProps<TKey>) => {
    const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Boolean(Number(e.target.value));
        setAnswer(experienceKey, value);
    };

    const isAnyAnswerSelected = answer !== null;

    return (
        <>
            <div className={styles.questionRowContainer}>
                <p className={styles.questionText}>{question}</p>
                <form
                    className={[
                        styles.radioButtonsContainer,
                        !isAnyAnswerSelected ? styles.noAnswerSelected : '',
                    ].join(' ')}
                    onSubmit={(e) => e.preventDefault()}
                >
                    <input
                        name="question"
                        id={`questionRow-no-${experienceKey}`}
                        className={styles.radioButton}
                        type="radio"
                        value={0}
                        checked={isAnyAnswerSelected && !answer}
                        onChange={handleSelect}
                    />
                    <label
                        className={[styles.radioLabel, styles.no].join(' ')}
                        htmlFor={`questionRow-no-${experienceKey}`}
                    >
                        No
                    </label>
                    <input
                        id={`questionRow-yes-${experienceKey}`}
                        className={styles.radioButton}
                        type="radio"
                        name="question"
                        value={1}
                        checked={isAnyAnswerSelected && answer}
                        onChange={handleSelect}
                    />
                    <label
                        className={[styles.radioLabel, styles.yes].join(' ')}
                        htmlFor={`questionRow-yes-${experienceKey}`}
                    >
                        Yes
                    </label>
                </form>
            </div>
            {remark && <p className={styles.remark}>{remark}</p>}
        </>
    );
};
