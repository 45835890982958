import React, { ReactElement } from 'react';
import styles from './DetailsDataTable.module.css';
import inputStyles from '../Input/Input.module.css';

interface Props {
    title?: string | number | ReactElement;
    optionalTitle?: string;
    value?: string | number | ReactElement;
    uppercaseTitle?: boolean;
    boldTitle?: boolean;
    boldValue?: boolean;
    withoutSeparator?: boolean;
    icon?: React.VFC<React.SVGProps<SVGSVGElement>>;
    edgeIcon?: boolean;
    dark?: boolean;
    onClick?: () => void;
    iconAriaLabel?: string;
    withMarginBottom?: boolean;
    lessSpacing?: boolean;
}

const DetailsDataRow: React.FC<Props> = ({
    title,
    optionalTitle,
    value,
    uppercaseTitle,
    boldValue,
    boldTitle,
    withoutSeparator,
    icon,
    edgeIcon,
    dark,
    onClick,
    iconAriaLabel,
    withMarginBottom,
    lessSpacing,
}) => {
    const Icon = icon;

    const row = (
        <>
            {typeof title === 'string' || typeof title === 'number' ? (
                <p
                    className={`${
                        uppercaseTitle ? styles.sectionTitle : styles.rowTitle
                    } ${boldTitle ? styles.boldedRowTitle : ''} ${
                        uppercaseTitle ? styles.uppercaseText : ''
                    }`}
                >
                    {Icon && (
                        <span className={styles.iconContainer}>
                            <Icon aria-label={iconAriaLabel} />
                        </span>
                    )}
                    {title}
                    {optionalTitle && (
                        <label className={inputStyles.optionalLabel}>
                            {optionalTitle}
                        </label>
                    )}
                </p>
            ) : (
                <>{title}</>
            )}
            {typeof value === 'string' || typeof value === 'number' ? (
                <p
                    className={`${
                        boldValue ? styles.boldedRowValue : styles.rowValue
                    }`}
                    data-testid={`detailsDataTableRow${title}`}
                >
                    {value}
                </p>
            ) : (
                <>{value}</>
            )}
        </>
    );

    const classNames = [
        styles.rowContainer,
        lessSpacing ? styles.lessSpacing : '',
        withMarginBottom ? styles.withMarginBottom : '',
        onClick ? styles.button : '',
        withoutSeparator ? styles.withoutSeparator : '',
        dark ? styles.dark : '',
        edgeIcon ? styles.edgeIcon : '',
    ];

    return onClick ? (
        <button
            type="button"
            className={classNames.join(' ')}
            onClick={onClick}
        >
            {row}
        </button>
    ) : (
        <div className={classNames.join(' ')}>{row}</div>
    );
};

export default DetailsDataRow;
