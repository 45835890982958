// This file is temporary to avoid creating breaking changes
// in the models and just map generated types to the types we already use
import {
    GetManningReportsQueryResponse,
    LineItemDto,
    ManningReportDayDto,
    ManningReportDto,
    ManningReportMealAttendingTypeDto,
    ManningReportMealDto,
    OrderDto,
    OrderRfqDto,
    OrderSummaryDto,
    ProductItemDto,
    StocktakingDto,
} from './generated';
import {
    LineItem,
    Order,
    OrderSummary,
    Product,
    RFQ,
} from '../types/order.types';
import { getOrderType } from '../context/OrderTypes';
import {
    AttendeeLog,
    MannigMealLog,
    ManningDayLog,
    ManningReport,
    hardcodedMealTypes,
} from '../types/manning.types';
import moment from 'moment';
import { StocktakingItem, StocktakingReport } from '../types/stocktaking.types';
import { ItemStatus } from '../types/itemStatuses.types';
import { ItemType, getLocalId } from '../db/utils/getLocalId';
import { getManningOptions } from '../db/manningReports';

export const mapToProduct = (product: ProductItemDto): Product => ({
    itemNumber: product.itemNumber,
    itemName: product.itemName,
    area: product.area,
    categoryLevel1: product.categoryLevel1,
    categoryLevel2: product.categoryLevel2,
    categoryLevel3: product.categoryLevel3,
    nutritionGroup: product.nutritionGroup,
    unitOfMeasure: product.unitOfMeasure,
    // initial values
    estimatedPrice: 0,
    estimatedPackSize: 0,
});

export const mapToLineItem = (lineItem: LineItemDto): LineItem => ({
    itemNumber: lineItem.itemNumber,
    quantity: lineItem.quantity,
    comment: lineItem.comment ?? undefined,
    unitOfMeasure: lineItem.unitOfMeasure ?? undefined,
    confirmedPackSize: lineItem.packSize,
    confirmedPrice: lineItem.price,
    itemName: lineItem.itemName,
});

const mapToRFQ = (rfq: OrderRfqDto): RFQ => ({
    id: rfq.id,
    wbPono: rfq.wbPono,
    coveringDays: rfq.coveringDays,
    contractCurrency: rfq.contractCurrency,
    manning: rfq.manning,
    comment: rfq.comment,
    lineItems: rfq.lineItems.map((item) => mapToLineItem(item)),
    deliveryPort: {
        portCode: rfq.deliveryPort.portCode ?? '',
        portName: rfq.deliveryPort.portName ?? '',
    },
    nextPort: {
        portCode: rfq.nextPort.portCode ?? '',
        portName: rfq.nextPort.portName ?? '',
    },
    deliveryDate: new Date(rfq.deliveryDate),
    departureDate: rfq.departureDate ? new Date(rfq.departureDate) : new Date(),
    agent: rfq.agent,
    supplier: {
        name: rfq.supplier.name ?? undefined,
        phone: rfq.supplier.phone ?? undefined,
        email: rfq.supplier.email ?? undefined,
        city: rfq.supplier.city ?? undefined,
    },
    created: new Date(rfq.created),
    origin: rfq.origin,
    isSsPoNumberAvailableForOrder: rfq.isSsPoNumberAvailableForOrder,
});

export const mapToOrder = (order: OrderDto): Order => ({
    orderId: order.orderId,
    systemRfqId: order.systemRfqId ?? '',
    status: order.status,
    vesselImo: order.vesselImo,
    type: getOrderType(order.type),
    created: new Date(order.created),
    lastModified: new Date(order.lastModified ?? ''),
    receival: order.receivals,
    rfqs: order.rfqs.map((item): RFQ => mapToRFQ(item)),
    isSsPoNumberAvailableForOrder: order.isSsPoNumberAvailableForOrder,
    isPerformanceReportCreated: order.isPerformanceReportCreated,
});

export const mapToOrderSummary = (summary: OrderSummaryDto): OrderSummary => ({
    orderId: summary.orderId,
    type: getOrderType(summary.type),
    status: summary.status,
    created: new Date(summary.created),
    lastModified: new Date(summary.lastModified ?? ''),
    isSsPoNumberAvailableForOrder: summary.isSsPoNumberAvailableForOrder,
});

export const mapToManningReport = (
    manningReport: GetManningReportsQueryResponse,
): ManningReport => ({
    id: manningReport.id,
    days: manningReport.days.map((day) =>
        mapToManningDayLog(day, manningReport.reportMonth),
    ),
    manningOptions: getManningOptions(manningReport.options),
    monthAndYear: manningReport.reportMonth,
    // todo: as soon as the backend is updated, we need to adjust the item state mapping. (related PBI 2723: https://dev.azure.com/wristohoi-setapp/Wrist%20Generate/_workitems/edit/2723)
    state: ItemStatus.submitted,
    localId: getLocalId({
        itemType: ItemType.manning,
        monthAndYear: manningReport.reportMonth,
    }),
    comment: manningReport.headerComment ?? '',
    lastModified: new Date(manningReport.lastModified ?? ''),
});

export const mapToManningDayLog = (
    manningDayLog: ManningReportDayDto,
    monthAndYear: string,
): ManningDayLog => {
    const dayInMonth =
        manningDayLog.dayInMonth < 10
            ? `0${manningDayLog.dayInMonth}`
            : manningDayLog.dayInMonth.toString();

    return {
        date: `${monthAndYear}-${dayInMonth}`,
        meals: manningDayLog.meals.map(mapToManningMeal),
        comment: manningDayLog.comment,
    };
};

export const mapToManningMeal = (
    manningMeal: ManningReportMealDto,
): MannigMealLog => ({
    mealId: manningMeal.mealType,
    comment: manningMeal.comment,
    attendees: manningMeal.attendingTypes.map(mapToAttendee),
});

export const mapToAttendee = (
    manningAttendee: ManningReportMealAttendingTypeDto,
): AttendeeLog => ({
    manningTypeId: manningAttendee.typeId,
    value: manningAttendee.numberOfPeople,
});

const breakfastId = hardcodedMealTypes.find(
    (item) => item.name === 'Breakfast',
)!.id;
const lunchId = hardcodedMealTypes.find((item) => item.name === 'Lunch')!.id;
const dinnerId = hardcodedMealTypes.find((item) => item.name === 'Dinner')!.id;
const nightMealId = hardcodedMealTypes.find(
    (item) => item.name === 'Night Meal',
)!.id;
export const mapManningReportToRequest = (
    manningReport: ManningReport,
): ManningReportDto => ({
    captain: 'N/A',
    chiefCook: 'N/A',
    headerComment: manningReport.comment ?? '',
    days: manningReport.days.map((day) => ({
        comment: day.comment ?? '',
        dayInMonth: Number(moment(day.date).format('DD')),
        meals:
            day?.meals?.map((meal) => ({
                mealType: meal.mealId,
                comment: meal.comment ?? '',
                attendingTypes:
                    meal.attendees?.map((attendee) => ({
                        typeId: attendee.manningTypeId,
                        numberOfPeople: attendee.value,
                    })) ?? [],
            })) ?? [],
    })),
    reportMonth: manningReport.monthAndYear,
    localId: manningReport.localId,
    options: manningReport.manningOptions.map((item) => ({
        manningTypeId: item.manningTypeId,
        manningLabel: item.manningLabel,
        hasBreakfast:
            item.meals.find((mealId) => mealId === breakfastId) !== undefined,
        hasLunch: item.meals.find((mealId) => mealId === lunchId) !== undefined,
        hasDinner:
            item.meals.find((mealId) => mealId === dinnerId) !== undefined,
        hasNightMeal:
            item.meals.find((mealId) => mealId === nightMealId) !== undefined,
    })),
});

export const mapToStocktakingReport = (
    apiStocktaking: StocktakingDto,
): StocktakingReport => ({
    reportMonth: apiStocktaking.reportMonth,
    // todo: as soon as the backend is updated, we need to adjust the item state mapping. (related PBI 2723: https://dev.azure.com/wristohoi-setapp/Wrist%20Generate/_workitems/edit/2712)
    state: ItemStatus.submitted,
    date: new Date(apiStocktaking.submitDate),
    items: apiStocktaking.items.map(
        (item): StocktakingItem => ({
            id: item.id,
            itemNumber: item.itemNumber,
            itemName: item.itemName,
            category: item.category,
            quantity: item.quantity,
            unitPrice: item.unitPrice,
            unitOfMeasure: item.unitOfMeasure,
            comment: item.comment ?? '',
            condemned: item.condemned ?? [],
        }),
    ),
});
