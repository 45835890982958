/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Created
 *
 * 1 = Updated
 *
 * 2 = Cancelled
 */
export enum CashPurchaseNotificationStatus {
    Created = 0,
    Updated = 1,
    Cancelled = 2,
}