import { ModalHeader } from '../../../ModalHeader/ModalHeader';
import StepsProgressBar from '../../../StepsProgressBar/StepsProgressBar';
import { Question } from '../../../Question/Question';
import { questions } from './questions';
import { useState } from 'react';
import { FlexSpacer } from '../../../FlexSpacer/FlexSpacer';
import styles from '../../../Receival/ReceivalModals/Receival.module.css';
import modalStyles from '../../../Modal/Modal.module.css';
import Button from '../../../Button/Button';
import useOrderType from '../../../../hooks/useOrderType';
import { useReceivedOrder } from '../../../../hooks/useReceivedOrder';
import { ExperienceKey } from '../../../../types/receivedOrder.types';

interface SupplierExperienceProps {
    steps: number;
    onBack: () => void;
    onContinue: () => void;
}

export const SupplierExperience: React.FC<SupplierExperienceProps> = ({
    steps,
    onBack,
    onContinue,
}) => {
    const { orderIdFromQuery } = useOrderType();
    const { updatePerformanceReport, data: receivedOrder } = useReceivedOrder(
        Number(orderIdFromQuery),
    );

    const createSurveyResultsObject = () => {
        if (receivedOrder?.performanceReport?.experience) {
            return receivedOrder.performanceReport.experience;
        }
        const surveyResults: Partial<Record<ExperienceKey, boolean | null>> =
            {};

        questions.forEach((item) => {
            surveyResults[item.key] = null;
        });
        return surveyResults;
    };

    const [surveyResults, setSurveyResults] = useState(
        createSurveyResultsObject(),
    );

    const setAnswer = (key: ExperienceKey, answer: boolean) => {
        setSurveyResults((prev) => ({ ...prev, [key]: answer }));
    };

    const hasAllAnswers = (
        //eslint-disable-next-line
        surveyResults: any,
    ): surveyResults is Partial<Record<ExperienceKey, boolean>> => {
        return !Object.values(surveyResults).includes(null);
    };

    const handleContinue = async () => {
        if (!hasAllAnswers(surveyResults)) {
            return;
        }

        await updatePerformanceReport({ experience: surveyResults });
        onContinue();
    };

    return (
        <>
            <div className={[styles.header, styles.withMarginBottom].join(' ')}>
                <ModalHeader
                    title={
                        <div className={styles.stepsProgressBar}>
                            <StepsProgressBar
                                currentStep={8}
                                totalStepsLength={steps}
                                parentRemounts
                            />
                        </div>
                    }
                    subTitle="Supplier experience"
                    description="Please answer the following questions about the supplier."
                />
            </div>
            {questions.map((item) => (
                <div className={styles.question} key={item.key}>
                    <Question
                        question={item.question}
                        answer={surveyResults[item.key]}
                        experienceKey={item.key}
                        setAnswer={setAnswer}
                    />
                </div>
            ))}
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Continue"
                        onClick={handleContinue}
                        disabled={!hasAllAnswers(surveyResults)}
                        primary={hasAllAnswers(surveyResults)}
                    />
                </div>
            </div>
        </>
    );
};
