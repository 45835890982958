import { createContext, useContext, useState } from 'react';

import ToastComponent from '../components/Toast/Toast';
import { sleep } from '../utils/sleep';

export enum ToastType {
    success = 'success',
    error = 'error',
}

export interface ToastMetadata {
    isOpen: boolean;
    type: ToastType;
    text: string;
}

type ContextValue = {
    showToast: ({ type, text }: Omit<ToastMetadata, 'isOpen'>) => void;
};

const ToastContext = createContext<ContextValue>({
    showToast: () => {},
});

export const ToastProvider: React.FC<
    React.PropsWithChildren<Record<never, never>>
> = ({ children }) => {
    const [toastMetadata, setToastMetadata] = useState<
        ToastMetadata | undefined
    >(undefined);

    const showToast = ({ type, text }: Omit<ToastMetadata, 'isOpen'>) => {
        setToastMetadata({ type, text, isOpen: true });
    };

    const hideToast = async () => {
        setToastMetadata((prev) => {
            if (prev) {
                return { ...prev, isOpen: false };
            }
            return prev;
        });
        await sleep(300);
        setToastMetadata(undefined);
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            <>
                {children}
                <ToastComponent
                    toastMetadata={toastMetadata}
                    hideToast={hideToast}
                />
            </>
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    const context = useContext(ToastContext);

    if (context === undefined) {
        throw new Error('useToast context is out of scope');
    }

    return context;
};
