import { db } from './db';

type SupportedDataAreas = 'Products';

export interface DataVersions {
    area: SupportedDataAreas;
    version: BigInt;
}

export const setDataVersion = async (
    area: SupportedDataAreas,
    version: BigInt,
) => {
    await db.dataVersions.put({ area, version });
};

export const getDataVersion = async (
    area: SupportedDataAreas,
): Promise<BigInt | undefined> => {
    const dataVersion = await db.dataVersions.get(area);

    return dataVersion?.version;
};
