import styles from './ChatBubble.module.css';
import UserProfile from '../../public/gfx/menuIcons/userProfile.svg';
import moment from 'moment';
import Image from 'next/image';

interface Props {
    displayName?: string;
    createdAt?: string | Date;
    avatarUrl?: string;
}

const ChatMessageHeader = ({ displayName, createdAt, avatarUrl }: Props) => (
    <>
        <span className={styles.senderAvatarWrapper}>
            {avatarUrl ? (
                <Image
                    src={avatarUrl}
                    alt=""
                    width={24}
                    height={24}
                    objectFit="contain"
                />
            ) : (
                <UserProfile className={styles.genericUserProfileAvatar} />
            )}
        </span>
        <span className={styles.senderDetails}>
            <span className={styles.senderName}>{displayName}</span>
            <time
                dateTime={createdAt ? moment(createdAt).toISOString() : ''}
                className={styles.sentAt}
            >
                {createdAt ? moment(createdAt).format('L') : ''}
            </time>
        </span>
    </>
);

export default ChatMessageHeader;
