import {
    CashPurchaseDto,
    CashPurchaseItemDto,
    Category,
} from '../../../apiClient/generated';
import {
    PendingCashPurchaseItem,
    PendingCashPurchaseOrder,
} from '../../../types/cashPurchaseOrders.types';
import { Product } from '../../../types/order.types';
import { ProductCategory } from '../ProductsList';

export const isCashPurchaseInViewOnly = (
    item: PendingCashPurchaseItem | OrderItemCategoriesReMapped,
): item is OrderItemCategoriesReMapped => {
    return !(item as OrderItemCategoriesReMapped).isPending;
};

export const isCashPurchaseSubmitted = (
    item: CashPurchaseDto | PendingCashPurchaseOrder | undefined,
): item is CashPurchaseDto => {
    return Boolean((item as CashPurchaseDto).wbPoNo);
};

export interface OrderItemCategoriesReMapped
    extends Omit<CashPurchaseItemDto, 'categoryLevel3' | 'category'> {
    categoryLevel3: Category;
    isPending: boolean;
}

export const getProductsSortedByCategories = <
    T extends Product | PendingCashPurchaseItem | OrderItemCategoriesReMapped,
>(
    products: T[],
): ProductCategory<T>[] => {
    if (!products) {
        return [];
    }

    const sortedCategories: Category[] = [];
    for (const product of products) {
        const categoryWithTheSameNameFromArray = sortedCategories.find(
            (item) =>
                item.text.toLowerCase() ===
                product.categoryLevel3?.text.toLowerCase(),
        );

        if (!categoryWithTheSameNameFromArray) {
            sortedCategories.push(product.categoryLevel3);
        } else {
            const hasCurrentCategoryHigherNumber =
                categoryWithTheSameNameFromArray.number >
                product.categoryLevel3.number;

            if (hasCurrentCategoryHigherNumber) {
                const index = sortedCategories.indexOf(
                    categoryWithTheSameNameFromArray,
                );

                sortedCategories[index] = product.categoryLevel3;
            }
        }
    }

    const productsPerCategoryArr = sortedCategories.map((category) => {
        const productsInCategory = products.filter(
            (item) =>
                item.categoryLevel3.text.toLowerCase() ===
                category.text.toLowerCase(),
        );

        return {
            categoryName: category.text.toUpperCase(),
            categoryNumber: category.number,
            products: productsInCategory,
        };
    });

    productsPerCategoryArr.sort((a, b) =>
        a.categoryNumber > b.categoryNumber
            ? 1
            : b.categoryNumber > a.categoryNumber
            ? -1
            : 0,
    );

    return productsPerCategoryArr;
};
