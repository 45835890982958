import { useAuth0 } from '@auth0/auth0-react';
import useSWR from 'swr';
import { apiClient } from '../apiClient/apiClient';
import { mapToStocktakingReport } from '../apiClient/apiClientMappers';
import { db } from '../db/db';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import { ItemStatus } from '../types/itemStatuses.types';
import { getCurrentMonthForStocktakingFlow } from '../utils/getCurrentStocktakingMonth';
import { StocktakingReport } from '../types/stocktaking.types';
import { ReOpenSource } from '../apiClient/generated';
import { getStocktakingReport } from '../db/stocktakingReports';
import { ItemType, getLocalId } from '../db/utils/getLocalId';
import { useFeatureFlags } from '../context/useFeatureFlags';
import { useMemo } from 'react';

const getFetcher = (withReopens?: boolean) => async () => {
    const response = await apiClient.getStocktakingReports();
    const reports = response.map(mapToStocktakingReport);

    const reOpenRequests = withReopens ? await apiClient.getReopens() : [];

    const stocktakingReopenRequests =
        reOpenRequests?.filter(
            (request) => request.source === ReOpenSource.Stocktaking,
        ) ?? [];

    for (const report of reports) {
        const localReport = await getStocktakingReport(report.reportMonth);

        const reopenRequest = stocktakingReopenRequests?.find(
            (reOpenRequest) => reOpenRequest.reportMonth === report.reportMonth,
        );

        if (reopenRequest) {
            report.state = ItemStatus.awaitingAction;
            report.items = report.items.map((item) => ({
                ...item,
                counted: false,
                lastCount: item.quantity,
            }));
            // A localId is needed for offline submission support
            report.localId = getLocalId({
                itemType: ItemType.stocktaking,
                reportMonth: report.reportMonth,
            });

            const skipableStates = [
                ItemStatus.awaitingAction,
                ItemStatus.sending,
            ];
            const skipUpdating =
                localReport && skipableStates.includes(localReport.state);

            if (skipUpdating) {
                // Don't update reports that are currently on the re-open list
                continue;
            }
        }

        await db.stocktakingReports.put(report);
    }

    return db.stocktakingReports.orderBy('reportMonth').toArray();
};

export const fetchInitialStocktakingReports = getFetcher();

export const useStocktakingReports = () => {
    const { user } = useAuth0();

    const { featureFlags } = useFeatureFlags();

    const fetchAndUpdateStocktakingReports = useMemo(
        () => getFetcher(featureFlags?.stocktakingReopen),
        [getFetcher, featureFlags?.stocktakingReopen],
    );

    const {
        data: stocktakingReports,
        mutate: revalidateStocktakingReports,
        isLoading,
    } = useSWR(
        SWRCacheKeys.stocktakingReports,
        fetchAndUpdateStocktakingReports,
        { ...swrDefaultConfig, revalidateIfStale: true },
    );

    const hasDraftStocktakingReport = (stocktakingReports ?? []).some(
        (report) => report.state === ItemStatus.draft,
    );

    const addNewReportOptimistically = (
        stocktakingReport: StocktakingReport,
    ) => {
        db.stocktakingReports.put(stocktakingReport);
        revalidateStocktakingReports(
            [...(stocktakingReports ?? []), stocktakingReport],
            { revalidate: true },
        );
    };

    const isAnyStocktakingReportInAwaitingAction = stocktakingReports?.some(
        (report) => {
            return report.state === ItemStatus.awaitingAction;
        },
    );

    return {
        currentMonthAndYear: getCurrentMonthForStocktakingFlow(
            user,
            stocktakingReports ?? [],
        ),
        hasDraftStocktakingReport,
        stocktakingReports,
        fetchReports: fetchAndUpdateStocktakingReports,
        revalidateStocktakingReports,
        addNewReportOptimistically,
        isAnyStocktakingReportInAwaitingAction,
        isLoading,
    };
};
