import { useClaimsInReceivalFlow } from '../../../context/ClaimsInReceivalFlowContext';
import Button from '../../Button/Button';
import DetailsDataRow from '../../DetailsDataTable/DetailsDataRow';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import { ModalHeader } from '../../ModalHeader/ModalHeader';
import modalStyles from '../../Modal/Modal.module.css';
import styles from '../commonStyles.module.css';

export const ClaimSummaryReadOnly = () => {
    const { closeClaimsInReceivalModal, claimsInReceivalModalMetadata } =
        useClaimsInReceivalFlow();

    const { itemIssueReadOnlySummary } = claimsInReceivalModalMetadata;

    if (!itemIssueReadOnlySummary) {
        console.error('itemIssueDetails is not defined');
        return null;
    }

    return (
        <>
            <ModalHeader
                subTitle="Claim Summary"
                description="Thanks for reporting an issue. We will review and confirm whether you’re eligible for a credit or discount."
            />
            <div className={styles.contentContainer}>
                <DetailsDataRow
                    boldTitle
                    title={itemIssueReadOnlySummary.productName}
                />
                <DetailsDataRow
                    title={'Order #'}
                    value={itemIssueReadOnlySummary.productName}
                />
                <DetailsDataRow
                    title={'Issue'}
                    value={itemIssueReadOnlySummary.itemIssueProblem}
                />
                <DetailsDataRow
                    title={'Quantity affected'}
                    value={
                        <span
                            className={styles.redValue}
                        >{`${itemIssueReadOnlySummary.affectedAmount} ${itemIssueReadOnlySummary.unitOfMeasure}`}</span>
                    }
                />
            </div>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                {claimsInReceivalModalMetadata.selectedStep ===
                    'issueSummaryReadOnly' && (
                    <div className={modalStyles.squareActionButtonChild}>
                        <Button
                            text="Back"
                            onClick={closeClaimsInReceivalModal}
                            secondary
                        />
                    </div>
                )}
            </div>
        </>
    );
};
