import React, { useState } from 'react';
import { SmallConfirmationModal } from '../SmallConfirmationModal/SmallConfirmationModal';
import { useRouter } from 'next/router';
import { usePendingCashPurchaseOrder } from '../../hooks/usePendingCashPurchaseOrder';
import { usePreventWindowClosing } from '../../hooks/usePreventWindowClosing';
import { useModalContext } from '../../context/ModalContext';

export interface LeavingPreparationOfCashPurchaseOrderModalMetadata {
    isOpen: boolean;
    redirectTo?: string;
}

export const LeavingPreparationOfCashPurchaseOrderModal = () => {
    const [
        leavingPreparationOfCashPurchaseOrderModalMetadata,
        setLeavingPreparationOfCashPurchaseOrderModalMetadata,
    ] = useState<LeavingPreparationOfCashPurchaseOrderModalMetadata>({
        isOpen: false,
    });

    const router = useRouter();

    const { closeGlobalModal } = useModalContext();

    const { pendingCashPurchaseOrder, deletePendingCashPurchaseOrder } =
        usePendingCashPurchaseOrder();

    const routeChangeEventHandler = (route: string) => {
        const isSummaryPage = route.includes('/?tab=history'); // user is redirected to order?tab=history after successful submission
        const isReloading = route === router.asPath;

        const shouldInterceptRouting =
            pendingCashPurchaseOrder && !isReloading && !isSummaryPage;

        if (shouldInterceptRouting) {
            setLeavingPreparationOfCashPurchaseOrderModalMetadata({
                isOpen: true,
                redirectTo: route,
            });
            throw 'route intercepted';
        }
    };

    usePreventWindowClosing({
        routeChangeEventHandler,
        shouldPreventClose: Boolean(pendingCashPurchaseOrder),
    });

    const onDiscardOrder = async () => {
        await deletePendingCashPurchaseOrder();

        setLeavingPreparationOfCashPurchaseOrderModalMetadata({
            isOpen: false,
        });

        closeGlobalModal();
        await router.replace(
            leavingPreparationOfCashPurchaseOrderModalMetadata.redirectTo ??
                '#',
        );
    };

    return (
        <SmallConfirmationModal
            isOpen={leavingPreparationOfCashPurchaseOrderModalMetadata.isOpen}
            onClose={() =>
                setLeavingPreparationOfCashPurchaseOrderModalMetadata({
                    isOpen: false,
                })
            }
            confirmBtnText="Discard Order"
            cancelBtnText="Keep editing"
            onConfirm={onDiscardOrder}
            onAbort={() =>
                setLeavingPreparationOfCashPurchaseOrderModalMetadata({
                    isOpen: false,
                })
            }
            title="Discard Cash Purchase Order?"
            subtitle="If you exit this this window, your progress will be lost and you will have to create this Cash Purchase Order again."
            additionalSubtitle="Are you sure you wish to discard this order?"
        />
    );
};
