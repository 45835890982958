import { useAuth0 } from '@auth0/auth0-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useAppLoading } from '../../context/AppLoadingContext';
import { db } from '../../db/db';
import { offlineRequestDatabase } from '../../worker/db/offlineRequestsDb';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { ActionToConfirm } from './AppSettingsModal';
import { apiClient } from '../../apiClient/apiClient';
import { userDataDb } from '../../db/userDataDb';
import { CacheContentsSummary } from './CacheContentsSummary';
import { datadogRum } from '@datadog/browser-rum';

interface Props {
    confirmationStep: ActionToConfirm;
    setConfirmationStep: Dispatch<SetStateAction<ActionToConfirm | null>>;
    closeParentModal?: () => void;
    /**
     * Set this to `true` to skip rendering inaccessible data hooks that require
     * the component to render deeper in the app's tree to be accessible.
     * Example: When used in the UnhandledErrorBoundary
     */
    outsideApp?: boolean;
}

const ConfirmationStep: React.FC<Props> = ({
    confirmationStep,
    setConfirmationStep,
    closeParentModal,
    outsideApp,
}) => {
    const { logout, user } = useAuth0();
    const { setIsAppLoading } = useAppLoading();
    // Fallback for when the AppLoadingContext is not available (e.g. in ErrorBoundary)
    const [isProcessing, setIsProcessing] = useState(false);

    const clearCache = async () => {
        setIsProcessing(true);
        setIsAppLoading(true);
        try {
            datadogRum.clearUser();
            await apiClient.logout();
            await db.delete();
            await offlineRequestDatabase.delete();
            localStorage.clear();
        } finally {
            logout({ returnTo: window.location.origin });
        }
    };

    const closeConfirmationStep = () => setConfirmationStep(null);

    const transferDraftsToLocalStorageAndClearCache = async () => {
        if (!user?.sub) {
            return;
        }

        setIsProcessing(true);
        setIsAppLoading(true);

        // This module is client-side-only. To allow our build to pass,
        // we need to import it at runtime
        await import('dexie-export-import');
        // Create a binary export of the main IndexedDb (`db`)
        const data = await db.export();
        // Put the binary export into a second database, associated by user id
        await userDataDb.exports.put({ userId: user.sub, data });

        closeConfirmationStep();
        closeParentModal?.();
        await clearCache();
    };

    const clearCookies = () => {
        setIsProcessing(true);
        setIsAppLoading(true);

        document.cookie = '';

        logout({ returnTo: window.location.origin });
    };

    const getModalProps = () => {
        switch (confirmationStep) {
            case ActionToConfirm.clearCache:
                return {
                    headline: 'Clearing Gateway data on your device',
                    description:
                        'Are you sure you want to reset Gateway on your device and log out?',
                    action: async () => await clearCache(),
                    destructive: true,
                };
            case ActionToConfirm.clearCookies:
                return {
                    headline: 'Clearing cookies on your device',
                    description:
                        'Are you sure you want to remove cookies on your device and log out?',
                    action: clearCookies,
                    destructive: true,
                };
            case ActionToConfirm.logout:
                return {
                    headline: 'Log out',
                    description: 'Are you sure you want to log out?',
                    action: async () =>
                        await transferDraftsToLocalStorageAndClearCache(),
                    destructive: false,
                };
        }
    };

    const { headline, description, action, destructive } = getModalProps();

    return (
        <ConfirmationModal
            isOpen={true}
            onClose={closeConfirmationStep}
            onComplete={action}
            title={headline}
            description={description}
            processing={isProcessing}
            processingText="Processing..."
            type="Please confirm"
            red={destructive}
        >
            {destructive && !outsideApp && <CacheContentsSummary />}
        </ConfirmationModal>
    );
};

export default ConfirmationStep;
