import { GenerateDexieClass } from '../db';

export const onUpgradeTo19 = async (db: GenerateDexieClass) => {
    enum OrderTypes {
        provision = 'Provision',
        bonded = 'Bonded',
        nonFood = 'Nonfood',
    }

    type Agent = {
        name: string;
        city: string;
        phone: string;
        email: string;
    };

    type OrderPort = {
        portName?: string | null;
        portCode?: string | null;
    };

    enum OrderRfqOrigin {
        App = 0,
        WristIntegration = 1,
    }

    enum OrderStatus {
        Blank = 0,
        Created = 1,
        RfqSent = 2,
        OrderForReview = 3,
        FinalOrder = 4,
        OrderForConfirmation = 5,
        ReceiptInProgress = 6,
        Receipt = 7,
        CancelledByVessel = 8,
        CancelledByGarrets = 9,
    }

    type SupplierInfo = {
        name?: string | null;
        city?: string | null;
        phone?: string | null;
        email?: string | null;
    };

    interface RFQ {
        id?: number;
        wbPono?: string;
        coveringDays: number;
        contractCurrency?: string;
        manning: number;
        comment?: string;
        lineItems: LineItem[];
        deliveryPort?: OrderPort;
        nextPort: OrderPort;
        deliveryDate: Date;
        departureDate: Date;
        agent?: Agent;
        supplier?: SupplierInfo;
        created?: Date;
        origin?: OrderRfqOrigin;
    }

    interface LineItem {
        id?: number;
        itemNumber: string;
        quantity: number;
        comment?: string;
        unitOfMeasure?: string;
        confirmedPackSize?: number;
        confirmedPrice?: number;
        estimatedPrice?: number;
        estimatedPackSize?: number;
    }

    interface EditableOrder {
        localOrderId?: number;
        type: OrderTypes;
        orderId?: number;
        status: OrderStatus;
        rfq: RFQ;
    }

    const migrateTable = async (tableName: string) => {
        const dbTable = db.table<EditableOrder>(tableName);
        const editableOrdersToUpdate = await dbTable.toArray();

        for (const order of editableOrdersToUpdate) {
            order.rfq.lineItems.forEach(
                (item) => (item.itemNumber = item.itemNumber.toString()),
            );
        }

        await dbTable.bulkPut(editableOrdersToUpdate);
    };

    await migrateTable('editableOrders');
    await migrateTable('offlineOrders');
};
