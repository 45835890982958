import { Dispatch, SetStateAction } from 'react';
import { ReceivedOrder } from '../../../types/receivedOrder.types';
import { ToggleButtonWithKnob } from '../../ToggleButtonWithKnob/ToggleButtonWithKnob';
import productsListsStyles from '../ProductsList.module.css';
import styles from './TableHead.module.css';

interface TableHeadProps {
    setShowConfirmed: Dispatch<SetStateAction<boolean>>;
    showConfirmed: boolean;
    receivedOrder: ReceivedOrder | undefined;
}

export const TableHead: React.FC<TableHeadProps> = ({
    setShowConfirmed,
    showConfirmed,
    receivedOrder,
}) => {
    const confirmedItemsNumber = () => {
        return Object.values(receivedOrder?.receivedLineItems ?? {}).filter(
            (item) => item.isReceived,
        ).length;
    };
    return (
        <div className={productsListsStyles.tableHead}>
            <p className={productsListsStyles.tableCell}>Product name</p>
            <p className={productsListsStyles.tableCell}>Ordered</p>
            <p className={productsListsStyles.tableCell}>Received</p>
            <button
                className={[
                    productsListsStyles.tableCell,
                    productsListsStyles.asButton,
                ].join(' ')}
                onClick={() => {
                    setShowConfirmed((prev) => !prev);
                }}
            >
                <span className={styles.toggleButtonLabel}>
                    Show Confirmed
                    {confirmedItemsNumber() > 0
                        ? ` (${confirmedItemsNumber()})`
                        : ''}
                </span>
                <ToggleButtonWithKnob isActive={showConfirmed} />
            </button>
        </div>
    );
};
