import { OrderToDisplay } from '../../types/order.types';
import { OrderTypes } from '../../context/OrderTypes';
import { db } from '../../db/db';
import useOrderType from '../../hooks/useOrderType';
import styles from '../../styles/pages/Order.module.css';
import BudgetFigure from '../BudgetFigure/BudgetFigure';
import { currencyFormatter } from '../utils/numberFormat';
import { OrderSummaryCalculated } from '../utils/orderSummary';
import { useMemo } from 'react';
import { sumElements } from '../utils/arrayMethods';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import { useFeatureFlags } from '../../context/useFeatureFlags';

interface OrderSummaryCardsSectionProps {
    orderToDisplay: OrderToDisplay;
    orderSummary?: OrderSummaryCalculated;
}

const OrderBudgetFigures = ({
    orderToDisplay,
    orderSummary,
}: OrderSummaryCardsSectionProps) => {
    const { activeOrderType } = useOrderType();

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const isProvision = activeOrderType === OrderTypes.provision;

    const figureFormatter = (value: string | number) =>
        currencyFormatter(value, vesselCurrency);

    return (
        <>
            {isProvision && (
                <BudgetFigure
                    figure={orderSummary?.orderBudget ?? 0}
                    caption={`Order budget (${
                        orderToDisplay?.rfq.coveringDays ??
                        db.defaults.coveringDays
                    } days)`}
                    formatter={figureFormatter}
                    inverted
                />
            )}
            <BudgetFigure
                figure={orderSummary?.orderTotal ?? 0}
                caption="Estimated order value"
                formatter={figureFormatter}
                inverted={!isProvision}
            />
            {isProvision && (
                <>
                    <BudgetFigure
                        figure={
                            orderSummary?.orderBudgetReservedForFreshTopUps ?? 0
                        }
                        caption="Reserved budget (fresh food)"
                        formatter={figureFormatter}
                    />
                    <BudgetFigure
                        figure={
                            ((orderSummary?.orderTotal ?? 1) /
                                (orderSummary?.orderBudget ?? 1)) *
                            100
                        }
                        caption="Estimated % of budget used"
                        formatter={(value: string | number) => (
                            <>
                                {Number(value).toFixed(2)}
                                &thinsp;%
                            </>
                        )}
                    />
                </>
            )}
        </>
    );
};

const ReceivedOrderBudgetFigures = ({
    orderToDisplay,
    orderSummary,
}: OrderSummaryCardsSectionProps) => {
    const {
        data: { vesselCurrency },
    } = useVesselMetadata();
    const receivedTotal = useMemo(
        () =>
            sumElements(
                orderToDisplay.receival?.items?.map(
                    (item) => item.price * item.quantity,
                ) ?? [],
            ),
        [orderToDisplay],
    );

    const figureFormatter = (value: string | number) =>
        currencyFormatter(value, vesselCurrency);

    return (
        <>
            <BudgetFigure
                figure={receivedTotal}
                caption="Received total"
                formatter={figureFormatter}
                inverted
            />
            <BudgetFigure
                figure={orderSummary?.orderTotal ?? 0}
                caption="Order total"
                formatter={figureFormatter}
            />
        </>
    );
};

const OrderSummaryCardsSection: React.FC<OrderSummaryCardsSectionProps> = ({
    orderToDisplay,
    orderSummary,
}) => {
    const { activeOrderType } = useOrderType();
    const { featureFlags } = useFeatureFlags();
    const { isReceived } = parseOrderStatus(orderToDisplay);
    const isProvision = activeOrderType === OrderTypes.provision;

    return (
        <section className={styles.contentContainer}>
            {!featureFlags?.hidePrice && (
                <h3 className={styles.sectionHeading}>
                    {isProvision ? <>Budget</> : <>Total</>}
                </h3>
            )}
            <article className={styles.budgetFigures}>
                {isReceived ? (
                    <ReceivedOrderBudgetFigures
                        orderToDisplay={orderToDisplay}
                        orderSummary={orderSummary}
                    />
                ) : !featureFlags?.hidePrice ? (
                    <OrderBudgetFigures
                        orderToDisplay={orderToDisplay}
                        orderSummary={orderSummary}
                    />
                ) : null}
            </article>
        </section>
    );
};

export default OrderSummaryCardsSection;
