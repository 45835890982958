import {
    dataFlowEventHub,
    SWRCacheKeys,
    swrDefaultConfig,
} from './../events/dataFlowEvents';
import useSWR, { mutate } from 'swr';
import { db } from '../db/db';

const fetchDbData = () => db.ports.toArray();

dataFlowEventHub.on('portsChanged', () =>
    mutate(SWRCacheKeys.ports, undefined, { revalidate: true }),
);
dataFlowEventHub.on('preloadData', () => {
    mutate(SWRCacheKeys.ports, undefined, { revalidate: true });
});

export const usePorts = () => {
    const { data } = useSWR(SWRCacheKeys.ports, fetchDbData, swrDefaultConfig);

    return data ?? [];
};
