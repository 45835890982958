import React, { useMemo, useState } from 'react';
import styles from './Receival.module.css';
import searchStyles from '../../ClaimOrCondemnPreparationModal/SearchProductsWithIssuesStep/SearchProductsWithIssuesStep.module.css';
import SearchProductsBox from '../../SearchProductsBox/SearchProductsBox';
import modalStyles from '../../Modal/Modal.module.css';
import Button from '../../Button/Button';
import { List } from '../../List/List';
import { LineItem, Product } from '../../../types/order.types';
import { Field } from '../../List/Field';
import { useRouter } from 'next/router';
import { useReceivedOrder } from '../../../hooks/useReceivedOrder';
import { ReceivedLineItem } from '../../../types/receivedOrder.types';
import useOrderType from '../../../hooks/useOrderType';
import DuplicatedItemModal from './DuplicatedItemModal';
import { ModalHeader } from '../../ModalHeader/ModalHeader';
import { useProductSearch } from '../../../hooks/useProductSearch';
import useSWR from 'swr';
import { throttle } from 'throttle-debounce';
import { SWRCacheKeys } from '../../../events/dataFlowEvents';
import { useProvisionProducts } from '../../../hooks/useProvisionProducts';
import { useSWRCachedResult } from '../../../hooks/useSWRCachedResult';

interface Props {
    selectedOrderId: number;
}

const MissingItemSearchModal: React.FC<Props> = ({ selectedOrderId }) => {
    const { push } = useRouter();

    const [isDuplicatedItemModalOpen, setIsDuplicatedModalOpen] =
        useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [productSelectedInList, setProductSelectedInList] =
        useState<Product>();
    const { data: products } = useProvisionProducts();
    const { data: receivedOrder, upsertReceivedOrder } =
        useReceivedOrder(selectedOrderId);

    const { orderIdFromQuery, activeOrderType } = useOrderType();

    const { search } = useProductSearch(products);

    const { data, isValidating: isSearching } = useSWR(
        searchValue
            ? [SWRCacheKeys.searchReceivalMissingItem, searchValue]
            : null,
        async ([, searchValue]) => await search(searchValue),
    );
    const searchedProductsToDisplay = useSWRCachedResult(data);

    const throttleSetSearchValue = throttle(
        500,
        (value: string) => setSearchValue(value),
        { noLeading: true },
    );

    const selectProduct = (product: Product) => {
        setProductSelectedInList(product);
    };

    const addItem = async (selectedProduct: Product) => {
        if (receivedOrder) {
            let selectedLineItem: Record<
                LineItem['itemNumber'],
                ReceivedLineItem
            > = {};

            selectedLineItem = {
                [selectedProduct.itemNumber]: {
                    itemNumber: selectedProduct.itemNumber,
                    isReceived: false,
                    receivedQuantity: 0,
                    comment: '',
                    isFromOrder: false,
                },
            };

            const receivedLineItems: Record<
                LineItem['itemNumber'],
                ReceivedLineItem
            > = receivedOrder?.receivedLineItems;

            const allLineItems: Record<
                LineItem['itemNumber'],
                ReceivedLineItem
            > = {
                ...receivedLineItems,
                ...selectedLineItem,
            };

            let isItemDoubled = false;
            for (const item of Object.entries(receivedLineItems)) {
                if (item[0].includes(selectedProduct.itemNumber.toString())) {
                    setIsDuplicatedModalOpen(true);
                    isItemDoubled = true;
                }
            }
            if (!isItemDoubled) {
                const receivedOrderCopy = {
                    ...receivedOrder,
                    receivedLineItems: allLineItems,
                };

                await upsertReceivedOrder(receivedOrderCopy);

                push(
                    `/order/${activeOrderType.toLowerCase()}/${orderIdFromQuery}/receival/checklist?itemNumber=${
                        productSelectedInList?.itemNumber
                    }`,
                );
            }
        }
    };

    const onCancel = () => {
        push(
            `/order/${activeOrderType.toLowerCase()}/${orderIdFromQuery}/receival/checklist`,
        );
    };

    const listOfSearchedProducts = useMemo(
        () => (
            <List<Product>
                data={searchedProductsToDisplay}
                idSource="itemNumber"
                onRowClick={selectProduct}
                getIsRowActive={(record) =>
                    record.itemNumber === productSelectedInList?.itemNumber
                }
                applySelectableRowStyle
                emptyState={
                    <div className={searchStyles.centeredTextArea}>
                        <p>
                            <span className={searchStyles.blackText}>
                                No results found
                            </span>
                        </p>
                        <p className={styles.greyText}>
                            Sorry, we couldn’t find any item with that name. If
                            it’s not possible to find the correct item, please
                            try to select something similar.
                        </p>
                    </div>
                }
            >
                <Field<Product> source="itemName" title="Name" bold smallFont />
                <Field<Product>
                    source=""
                    title="Product Category"
                    smallFont
                    renderFunction={({}, product) => {
                        return <>{product?.categoryLevel3.text} </>;
                    }}
                />
            </List>
        ),
        [searchedProductsToDisplay, productSelectedInList],
    );

    const placeholder = (
        <div className={searchStyles.centeredTextArea}>
            <p>
                <span className={searchStyles.blackText}>
                    Search for a product
                </span>
            </p>
            <p className={styles.greyText}>
                Begin typing to find the product you wish to add.
            </p>
        </div>
    );

    return (
        <>
            <ModalHeader title="Receival" subTitle={`Add item`} />
            {searchValue.length === 0 ? (
                placeholder
            ) : (
                <div className={searchStyles.listContainer}>
                    {listOfSearchedProducts}
                    <div className={styles.bottomSpacer}></div>
                </div>
            )}
            <SearchProductsBox
                initialValue=""
                onChange={throttleSetSearchValue}
                isProductSelected
                isLoading={isSearching}
            />
            <div className={searchStyles.button}>
                <div className={modalStyles.squareActionButton}>
                    <div className={modalStyles.squareActionButtonChild}>
                        <Button text="Cancel" onClick={onCancel} secondary />
                    </div>
                    {productSelectedInList && (
                        <div className={modalStyles.squareActionButtonChild}>
                            <Button
                                text="Add Item"
                                onClick={() => addItem(productSelectedInList)}
                                primary
                            />
                        </div>
                    )}
                </div>
            </div>
            <DuplicatedItemModal
                isModalOpen={isDuplicatedItemModalOpen}
                onClose={() => setIsDuplicatedModalOpen(false)}
                onGoToExisting={() =>
                    push(
                        `/order/${activeOrderType.toLowerCase()}/${orderIdFromQuery}/receival/checklist?itemNumber=${
                            productSelectedInList?.itemNumber
                        }`,
                    )
                }
                onSelectDifferent={() => setIsDuplicatedModalOpen(false)}
            />
        </>
    );
};

export default MissingItemSearchModal;
