import { OrderToDisplay, Product } from '../../types/order.types';
import styles from './ProductsList.module.css';
import PricePerPack from './PricePerPack';
import { currencyFormatter } from '../utils/numberFormat';
import { useProductDetails } from '../../context/ProductDetailsContext';
import { ProductRowChangelogStatus } from './SingleProductRow';
import React, { useMemo } from 'react';
import { ReceivalItemDto } from '../../apiClient/generated';
import CommentButton from '../CommentButton/CommentButton';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';

interface SingleProductRowProps {
    product: Product;
    orderToDisplay: OrderToDisplay;
    lineItem?: ReceivalItemDto;
    showPrice?: boolean;
}

const ReceivedProductRow: React.FC<SingleProductRowProps> = ({
    product,
    lineItem,
    orderToDisplay,
    showPrice
}) => {
    const rfqLineItem = useMemo(
        () =>
            orderToDisplay.rfq.lineItems.find(
                (item) => item.itemNumber === lineItem?.itemNumber,
            ),
        [orderToDisplay],
    );

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const productDetails = useProductDetails();

    const receivedTotal = (lineItem?.price ?? 0) * (lineItem?.quantity ?? 0);

    const cellStyle = [styles.tableCell, styles.singleProductCell].join(' ');

    if (!lineItem) {
        return <></>;
    }

    const openCommentModal = () => {
        productDetails.openModal(orderToDisplay, lineItem.itemNumber, true);
    };

    return (
        <div className={`${styles.singleProductRow} ${styles.compact}`}>
            <div className={cellStyle}></div>
            <div className={cellStyle}>
                <p className={styles.primary}>
                    {product.itemName || lineItem.itemName}
                </p>
                <p className={styles.secondary}>{lineItem.itemNumber}</p>
            </div>
            <div className={cellStyle}>
                <p className={styles.primary}>{rfqLineItem?.quantity ?? ''}</p>
                <p className={styles.secondary}>
                    {rfqLineItem?.unitOfMeasure ?? ''}
                </p>
            </div>
            <div className={cellStyle}>
                <p className={styles.primary}>{lineItem?.quantity}</p>
                <p className={styles.secondary}>{lineItem?.unitOfMeasure}</p>
            </div>
            {
                showPrice &&
                <div className={cellStyle}>
                    <PricePerPack
                        unitOfMeasure={lineItem.unitOfMeasure ?? ''}
                        price={lineItem.price}
                        isEstimatedPrice={false}
                        currency={vesselCurrency}
                    />
                </div>
            }
            {
                showPrice &&
                <div className={cellStyle}>
                    <p className={styles.primary}>
                        {currencyFormatter(receivedTotal, vesselCurrency)}
                    </p>
                </div>
            }
            <div className={cellStyle}>
                {lineItem && (
                    <CommentButton
                        onClick={openCommentModal}
                        isActive={Boolean(lineItem?.quantity)}
                        hasComment={Boolean(lineItem?.comment)}
                        status={
                            Boolean(lineItem?.comment)
                                ? ProductRowChangelogStatus.default
                                : ProductRowChangelogStatus.locked
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default ReceivedProductRow;
