import { ReactNode, useEffect, useState, useCallback, useRef } from 'react';
import styles from './Modal.module.css';
import Image from 'next/image';
import CloseIcon from '../../public/gfx/uiIcons/closeButton.svg';

interface Props {
    children?: ReactNode;
    isOpen: boolean;
    onClose?: () => void;
    onNavigateBack?: () => void;
    onOutsideOfModalClick?: () => void;
    noHeader?: boolean;
    introModal?: boolean;
    small?: boolean;
    large?: boolean;
    calendar?: boolean;
    calendarWithCheckBox?: boolean;
}

const Modal = ({
    children,
    isOpen,
    onClose,
    onNavigateBack,
    onOutsideOfModalClick,
    noHeader,
    introModal,
    small,
    large,
    calendar,
    calendarWithCheckBox,
}: Props) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        document.body.style.overflow = isVisible ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isVisible]);

    useEffect(() => {
        if (isOpen) {
            setIsVisible(true);
        }
    }, [isOpen]);

    const [isContentScrollable, setIsScrollable] = useState(false);

    const observerRef = useRef<ResizeObserver | null>(null);
    const contentRef = useCallback(
        (node: HTMLElement | null) => {
            if (node !== null) {
                if (!observerRef.current) {
                    observerRef.current = new ResizeObserver(() => {
                        const isScrollable = node.scrollHeight > node.offsetHeight;
                        if (isScrollable !== isContentScrollable) {
                            setIsScrollable(isScrollable);
                        }
                    });
                }

                observerRef.current.observe(node);
            }

            return () => {
                if (observerRef.current && node) {
                    observerRef.current.disconnect();
                }
            };
        },
        [isContentScrollable],
    );

    const modalContent = (
        <>
            <div
                className={[
                    styles.overlay,
                    small ? styles.smallOverlay : '',
                    large ? styles.large : '',
                    calendar ? styles.smallOverlay : '',
                    calendarWithCheckBox ? styles.smallOverlay : '',
                ].join(' ')}
                onAnimationEnd={() => {
                    if (!isOpen) {
                        setIsVisible(false);
                    }
                }}
                onClick={onOutsideOfModalClick}
            >
                {onClose && !small && !introModal && (
                    <button
                        className={[
                            'blackCloseButton',
                            styles.blackCloseButton,
                            !isOpen ? styles.blackCloseButtonOnClose : '',
                            calendar || calendarWithCheckBox
                                ? styles.calendarBlackCloseButton
                                : '',
                            calendarWithCheckBox ? styles.withCheckBox : '',
                        ].join(' ')}
                        data-testid="closeModalBlackButton"
                        onClick={() => onClose()}
                    >
                        <Image
                            src="/gfx/uiIcons/close.svg"
                            width={14}
                            height={14}
                            alt="close modal"
                        />
                    </button>
                )}
            </div>

            <div
                ref={contentRef}
                className={[
                    !small ? styles.container : styles.smallContainer,
                    calendar || calendarWithCheckBox
                        ? styles.calendarContainer
                        : '',
                    calendarWithCheckBox ? styles.withCheckBox : '',
                    introModal ? styles.introModalContainer : '',
                    large ? styles.large : '',
                    !isOpen
                        ? calendar || calendarWithCheckBox
                            ? styles.modalClosedCalendar
                            : styles.modalClosed
                        : '',
                    isContentScrollable && isOpen ? styles.withFading : '',
                ].join(' ')}
                aria-hidden={!isOpen}
                data-testid="modal"
                onAnimationEnd={() => {
                    if (!isOpen) {
                        setIsVisible(false);
                    }
                }}
            >
                {(small || introModal) && onClose && (
                    <div className={styles.smallModalCloseButtonContainer}>
                        <button
                            className={styles.smallModalButton}
                            onClick={() => onClose()}
                        >
                            <CloseIcon
                                width={14}
                                height={14}
                                stroke={small ? '#6F6F6F' : '#000'}
                            />
                        </button>
                    </div>
                )}
                {!noHeader && (
                    <div className={`${!small ? styles.header : ''}`}>
                        {onNavigateBack ? (
                            <button
                                className="iconButton iconButton__big"
                                onClick={() => onNavigateBack()}
                            >
                                <Image
                                    src="/gfx/uiIcons/arrowLeft.svg"
                                    width={14}
                                    height={14}
                                    alt="navigate back"
                                />
                            </button>
                        ) : (
                            <div className={`${!small ? styles.spacer : ''}`} />
                        )}
                    </div>
                )}
                {children}
            </div>
        </>
    );

    return isVisible ? modalContent : <></>;
};

export default Modal;
