import useSWR from 'swr';
import { db } from '../db/db';
import { SWRCacheKeys } from '../events/dataFlowEvents';
import { apiClient } from '../apiClient/apiClient';
import { updateCashPurchaseOrdersTable } from '../db/cashPurchaseOrders';

export const fetchAndUpdateCashPurchaseOrders = async () => {
    const response = await apiClient.getCashPurchases();
    await updateCashPurchaseOrdersTable(response);
    return (
        await db.cashPurchaseOrders.toCollection().sortBy('deliveryDate')
    ).reverse();
};

export const useCashPurchaseOrders = () => {
    const {
        data: cashPurchaseOrders,
        isValidating: isCashPurchaseOrdersValidating,
        mutate: revalidateCashPurchaseOrders,
    } = useSWR(SWRCacheKeys.cashPurchaseOrders, () =>
        fetchAndUpdateCashPurchaseOrders(),
    );

    return {
        cashPurchaseOrders,
        isCashPurchaseOrdersValidating,
        revalidateCashPurchaseOrders,
    };
};
