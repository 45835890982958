import { KeyboardEventHandler } from 'react';
import styles from './OptionButton.module.css';

interface Props {
    onClick: () => void;
    isSelected?: boolean;
    onKeyDown?: KeyboardEventHandler<HTMLButtonElement> | undefined;
    extraClass?: string;
    id?: string;
}

export const OptionButton = ({
    children,
    onClick,
    isSelected,
    onKeyDown,
    extraClass,
    id,
}: React.PropsWithChildren<Props>) => {
    return (
        <button
            id={id}
            className={[
                extraClass,
                styles.container,
                isSelected ? styles.selected : '',
            ].join(' ')}
            type="button"
            onClick={() => {
                onClick();
            }}
            onKeyDown={onKeyDown}
        >
            {children}
        </button>
    );
};
