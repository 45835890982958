import React, { SetStateAction, useState } from 'react';
import { SmallConfirmationModal } from '../SmallConfirmationModal/SmallConfirmationModal';
import { usePreventWindowClosing } from '../../hooks/usePreventWindowClosing';
import { useEditableOrder } from '../../hooks/useEditableOrder';
import { EditableOrder } from '../../types/order.types';
import { useRouter } from 'next/router';
import { ModalType, useModalContext } from '../../context/ModalContext';
import { OrderStatus } from '../../apiClient/generated';
import { ToastType, useToast } from '../../context/ToastContext';

export interface UnsavedDraftModalMetadata {
    isOpen: boolean;
    redirectTo?: string;
}

export interface UnsavedDraftModalPopupsMetadata {
    editableOrder: EditableOrder;
    setUnsavedDraftModalMetadata: React.Dispatch<
        SetStateAction<UnsavedDraftModalMetadata>
    >;
    unsavedDraftModalMetadata: UnsavedDraftModalMetadata;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    editableOrder: EditableOrder;
    setUnsavedDraftModalMetadata: React.Dispatch<
        SetStateAction<UnsavedDraftModalMetadata>
    >;
    unsavedDraftModalMetadata: UnsavedDraftModalMetadata;
}

export const UnsavedDraftConfirmationModal: React.FC<Props> = ({
    isOpen,
    onClose,
    editableOrder,
    setUnsavedDraftModalMetadata,
    unsavedDraftModalMetadata,
}) => {
    const router = useRouter();
    const { showToast } = useToast();
    const { hasChanges, removeUnsavedDraft, saveDraft } = useEditableOrder(
        editableOrder.localOrderId,
    );

    const [isSavingDraftInProgress, setIsSavingDraftInProgress] =
        useState(false);

    const routeChangeEventHandler = (route: string) => {
        const isSummaryPage = route.includes('/summary');
        const isPreparationPage = route.includes('/preparation');
        const isReloading = route === router.asPath;

        const shouldInterceptRouting =
            hasChanges && !isSummaryPage && !isPreparationPage && !isReloading;

        if (shouldInterceptRouting) {
            setUnsavedDraftModalMetadata({ isOpen: true, redirectTo: route });
            throw 'route intercepted';
        }
    };

    usePreventWindowClosing({
        routeChangeEventHandler,
        shouldPreventClose: hasChanges,
    });

    const { openGlobalModal } = useModalContext();
    const onSave = async () => {
        /**
         * If order is in awaiting action status (orderForReview) or already has a draftName we want to
         * save changes without opening saveDraftModal.
         */

        try {
            setIsSavingDraftInProgress(true);
            if (
                editableOrder.status === OrderStatus.OrderForReview ||
                editableOrder.draftName
            ) {
                /**
                 * This setTimeout is used as a workaround for the issue with validating input value on blur.
                 * DecimalInput(components/DecimalInput.tsx) in products tab has onBlur method that adjusts input value to packSize.
                 * Mentioned method is debounced for performance reasons.
                 * It makes race-condition between saving draft and adjusting inputValue.
                 */
                await new Promise((resolve) => setTimeout(resolve, 1000));
                await saveDraft(editableOrder);

                setTimeout(() => {
                    showToast({
                        type: ToastType.success,
                        text: 'Changes saved',
                    });
                    setIsSavingDraftInProgress(false);
                }, 1000);

                router.replace(unsavedDraftModalMetadata.redirectTo ?? '#');
            } else {
                openGlobalModal({
                    modalType: ModalType.saveDraft,
                    orderId: editableOrder?.localOrderId,
                });
                await router.replace(
                    unsavedDraftModalMetadata.redirectTo ?? '#',
                );
            }
        } finally {
            setIsSavingDraftInProgress(false);
            setUnsavedDraftModalMetadata({ isOpen: false });
        }
    };

    const onDiscard = async () => {
        await removeUnsavedDraft(editableOrder);
        router.replace(unsavedDraftModalMetadata.redirectTo ?? '#');
        setUnsavedDraftModalMetadata({ isOpen: false });
    };

    return (
        <SmallConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            confirmBtnText={
                isSavingDraftInProgress ? 'Saving...' : 'Save changes'
            }
            cancelBtnText="Discard & exit"
            title="Save your changes"
            onAbort={onDiscard}
            onConfirm={onSave}
            subtitle="Don’t lose your changes. Save changes to return later and complete any awaiting action items for this order. Note your order isn’t completed until you resubmit."
        />
    );
};
