import { OrderPreparationModalStep } from '../../types/order.types';
import styles from './Heading.module.css';
import StepsProgressBar from '../StepsProgressBar/StepsProgressBar';
import { ModalHeader } from '../ModalHeader/ModalHeader';

interface HeadingProps {
    currentIndex: number;
    length: number;
    step: OrderPreparationModalStep;
}

const Heading = ({ currentIndex, length, step }: HeadingProps) => {
    const renderHeadingTitle = () => {
        switch (step) {
            case OrderPreparationModalStep.orderType:
                return 'Select Order Type';
            case OrderPreparationModalStep.orderDetails:
                return 'Enter Order Details';
            case OrderPreparationModalStep.startingPoint:
                return 'Select Starting Point';
            default:
                return '';
        }
    };

    const renderHeadingDescription = () => {
        switch (step) {
            case OrderPreparationModalStep.orderDetails:
                return 'This information is used to show estimated item prices based on port data and your cost per head budget.';
            default:
                return '';
        }
    };

    const title = renderHeadingTitle();
    const description = renderHeadingDescription();

    return (
        <div className={styles.container}>
            <div className={styles.stepProgressBar}>
                <StepsProgressBar
                    currentStep={currentIndex + 1}
                    totalStepsLength={length}
                />
            </div>
            <ModalHeader subTitle={title} description={description} />
        </div>
    );
};

export default Heading;
