import React, { useState } from 'react';
import modalStyles from '../../Modal/Modal.module.css';
import reasonStepContentStyles from './CashOrderReasonStepContent.module.css';
import UploadIcon from '../../../public/gfx/uiIcons/uploadFiles.svg';
import Button from '../../Button/Button';
import { compressImages } from '../../ClaimOrCondemnPreparationModal/utils/compressionHelper';
import { FullscreenDropArea } from '../../DropArea/FullscreenDropArea';
import { usePendingCashPurchaseOrder } from '../../../hooks/usePendingCashPurchaseOrder';
import FilesSection from '../../FilesSection/FilesSection';

interface AddCommentAndPhotosStepProps {
    onBack: () => void;
    onContinue: () => void;
}

const CashOrderReceiptStepContent: React.FC<AddCommentAndPhotosStepProps> = ({
    onContinue,
    onBack,
}) => {
    const [files, setFiles] = useState<File[]>([]);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [compressionInProgress, setCompressionInProgress] = useState(false);
    const { pendingCashPurchaseOrder, updatePendingCashPurchaseOrder } =
        usePendingCashPurchaseOrder();

    const handleAddFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        return await addFilesFromFileList(e.target.files);
    };

    const addFilesFromFileList = async (fileList: FileList) => {
        setUploadError(null);
        const filesListArray = Array.from(fileList);

        if (filesListArray.length === 0) {
            return;
        }

        const filesAdjustedToSend: File[] = [];
        filesListArray.forEach((file) => {
            switch (file.type) {
                case 'application/pdf': {
                    return filesAdjustedToSend.push(file);
                }
                default: {
                    return setCompressionInProgress(true);
                }
            }
        });

        const compressedImages = await compressImages({
            files: filesListArray,
        });

        setCompressionInProgress(false);

        const maxSizeInBytes = 5000000;

        filesAdjustedToSend.push(...compressedImages);

        const isAnyOfTheFilesTooBig = filesAdjustedToSend.some(
            (file) => file.size > maxSizeInBytes,
        );

        if (isAnyOfTheFilesTooBig) {
            setUploadError(
                "The filesize of the attachment you're trying to upload is too large. Please upload a file smaller than 5 MB.",
            );
            console.error('File size is too big');

            return;
        }
        mapAndUpdateFiles(filesAdjustedToSend);
    };

    const mapAndUpdateFiles = (filesToSend: File[]) => {
        setFiles((prev) => {
            const files = [...prev, ...filesToSend];
            const reMappedFiles = files.map((file) => {
                return {
                    filename: file.name,
                    file: file,
                };
            });
            updateCashPurchaseOrder(reMappedFiles);
            return files;
        });
    };

    const handleRemoveFile = (item: File) => {
        setFiles((prev) => {
            const reducedFiles = prev.filter(
                (file) =>
                    !(
                        file.name === item.name &&
                        file.lastModified === item.lastModified
                    ),
            );
            const reMappedFiles = reducedFiles.map((file) => {
                return {
                    filename: file.name,
                    file: file,
                };
            });
            updateCashPurchaseOrder(reMappedFiles);
            return reducedFiles;
        });
    };

    const updateCashPurchaseOrder = (
        reMappedFiles: {
            filename: string;
            file: File;
        }[],
    ) => {
        pendingCashPurchaseOrder &&
            updatePendingCashPurchaseOrder({
                ...pendingCashPurchaseOrder,
                files: reMappedFiles,
            });
    };

    return (
        <>
            <div className={reasonStepContentStyles.contentContainer}>
                <FilesSection
                    allowPdfs
                    files={files}
                    handleAddFiles={handleAddFiles}
                    handleRemoveFiles={handleRemoveFile}
                    compressionInProgress={compressionInProgress}
                />
                <FullscreenDropArea
                    onFilesDrop={addFilesFromFileList}
                    icon={<UploadIcon />}
                    captionText="Drop your file anywhere to add it"
                />
                <p className={reasonStepContentStyles.uploadError}>
                    {uploadError}
                </p>
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={'Continue'}
                        primary
                        disabled={files.length === 0}
                        onClick={onContinue}
                    />
                </div>
            </div>
        </>
    );
};

export default CashOrderReceiptStepContent;
