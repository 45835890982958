import { redirectToLoginPage } from './apiClient';
import { BaseHttpRequest, CancelablePromise, OpenAPIConfig } from './generated';
import { ApiRequestOptions } from './generated/core/ApiRequestOptions';
import { request as __request } from './generated/core/request';

export class ApiFetchHttpRequest extends BaseHttpRequest {
    constructor(config: OpenAPIConfig) {
        super(config);
    }

    /**
     * Request method
     * @param options The request options from the service
     * @returns CancelablePromise<T>
     * @throws ApiError
     */
    public override request<T>(
        options: ApiRequestOptions,
    ): CancelablePromise<T> {
        const cancellablePromise = __request<T>(this.config, options);
        cancellablePromise.catch((err: any) => {
            if (err?.status === 401 && redirectToLoginPage) {
                redirectToLoginPage();
            }
        });
        return cancellablePromise;
    }
}
