import { Order, OrderToDisplayInOrdersList } from '../../types/order.types';
import { v4 } from 'uuid';
import { getLatestRfq } from '../../db/utils/getLatestRfq';

export const adjustOrderToDisplayInOrdersList = (
    order: Order,
): OrderToDisplayInOrdersList => ({
    localOrderId: v4(),
    orderId: order.orderId,
    type: order.type,
    lineItems: getLatestRfq(order)?.lineItems ?? [],
    deliveryPort: getLatestRfq(order)?.deliveryPort ?? {
        portName: '',
        portCode: '',
    },
    deliveryDate: new Date(getLatestRfq(order)?.deliveryDate ?? ''),
    status: order.status,
    lastUpdated: new Date(order.lastModified),
    wbPono: getLatestRfq(order)?.wbPono,
    currency: getLatestRfq(order)?.contractCurrency,
});
