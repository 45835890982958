import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import modalStyles from '../Modal/Modal.module.css';
import styles from './AppSettingsModal.module.css';
import SettingRow from './SettingRow';
import { useState } from 'react';
import ConfirmationStep from './ConfirmationStep';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import { useFeatureFlags } from '../../context/useFeatureFlags';
import { useRouter } from 'next/router';
import { db } from '../../db/db';
import { exportIdbContents } from '../utils/exportIdbContents';
import { importIdbContents } from '../utils/importIdbContents';

interface Props {
    isOpen: boolean;
    closeAppSettingsModal: () => void;
}

export enum ActionToConfirm {
    clearCache = 'clearCache',
    clearCookies = 'clearCookies',
    logout = 'logout',
}

const AppSettingsModal: React.FC<Props> = ({
    isOpen,
    closeAppSettingsModal,
}) => {
    const [isImporting, setIsImporting] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [importFile, setImportFile] = useState<File>();

    const sanitizeFileInput = (
        event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    ) => {
        const element = event.target as HTMLInputElement;
        element.value = '';
        setImportFile(undefined);
    };

    const onAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        return addFile(e.target.files);
    };

    const addFile = (fileList: FileList) => {
        const files = Array.from(fileList);

        if (files.length === 0) {
            return;
        }
        if (files.length > 1) {
            console.log(
                'Multiple files detected, please make sure that only one relevant file is attached!',
            );
            return;
        }
        const [selectedFile] = files;

        setImportFile(selectedFile);
    };
    const {
        data: { imoNo, vesselName, company, vesselCurrency },
    } = useVesselMetadata();
    const { featureFlags } = useFeatureFlags();
    const { push } = useRouter();

    const isOnline = window.navigator.onLine;

    const [confirmationStep, setConfirmationStep] =
        useState<ActionToConfirm | null>(null);

    if (confirmationStep) {
        return (
            <ConfirmationStep
                confirmationStep={confirmationStep}
                setConfirmationStep={setConfirmationStep}
                closeParentModal={closeAppSettingsModal}
            />
        );
    } else {
        return (
            <Modal isOpen={isOpen} onClose={closeAppSettingsModal}>
                <div className={modalStyles.modalContent}>
                    <div className={styles.section}>
                        <p className={styles.title}>Vessel Settings</p>
                        <p className={styles.subtitle}>IMO: {imoNo}</p>
                    </div>
                    <div className={styles.section}>
                        <p className={styles.sectionTitle}>VESSEL PROFILE</p>
                        <SettingRow
                            name="Vessel Name"
                            value={vesselName ?? ''}
                        />
                        <SettingRow
                            name="Vessel Company"
                            value={company ?? ''}
                        />
                        <SettingRow name="Currency" value={vesselCurrency} />
                    </div>
                    <div className={styles.section}>
                        <p>SETTINGS</p>
                        {isOnline ? (
                            <SettingRow
                                name="App Data"
                                value="Remove application data and log out"
                                action={() =>
                                    setConfirmationStep(
                                        ActionToConfirm.clearCache,
                                    )
                                }
                            />
                        ) : (
                            <SettingRow
                                name="Offline Support"
                                value="You are offline. Reconnect to clear cache."
                                disabled
                            />
                        )}
                        <SettingRow
                            name="Export data"
                            value={
                                isExporting
                                    ? 'Exporting data...'
                                    : 'Export local vessel data for support'
                            }
                            action={async () => {
                                setIsExporting(true);
                                try {
                                    await exportIdbContents(db, imoNo);
                                } catch (err) {
                                    console.log(
                                        `export failed, reason: ${err}`,
                                    );
                                }
                                setIsExporting(false);
                            }}
                        />

                        {Boolean(featureFlags?.queueDebugMode) && (
                            <>
                                <SettingRow
                                    name="Import data"
                                    value={
                                        isImporting
                                            ? 'Importing data...'
                                            : 'Import local vessel data for support'
                                    }
                                    action={async () => {
                                        if (!importFile) {
                                            console.log(
                                                'please attach valid file',
                                            );
                                            return;
                                        }
                                        setIsImporting(true);
                                        try {
                                            await importIdbContents(
                                                db,
                                                importFile,
                                            );
                                        } catch (err) {
                                            console.log(
                                                `import failed, reason: ${err}`,
                                            );
                                        }

                                        setIsImporting(false);
                                    }}
                                />
                                <input
                                    className={styles.fileInput}
                                    type="file"
                                    accept="text/json"
                                    onChange={onAddFile}
                                    onClick={sanitizeFileInput}
                                />

                                <SettingRow
                                    name="Testing"
                                    value="Open test page"
                                    action={() => push('/testPage')}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className={modalStyles.squareActionButton}>
                    <Button
                        text="Log out"
                        onClick={() =>
                            setConfirmationStep(ActionToConfirm.logout)
                        }
                        secondary
                    />
                </div>
            </Modal>
        );
    }
};

export default AppSettingsModal;
