import { User } from '@auth0/auth0-react';
import moment from 'moment';
import { parseMonthAndYearToDate } from './getCurrentMonthForManningFlow';
import { StocktakingReport } from '../types/stocktaking.types';
import { ItemStatus } from '../types/itemStatuses.types';
import { claimsNamespace } from '../context/useFeatureFlags';

const getLatestReportMonthAndYear = (
    stocktakingReports: StocktakingReport[],
) => {
    if (!stocktakingReports.length) {
        return null;
    }

    const sortedReports = stocktakingReports.sort((a, b) =>
        moment(b.reportMonth, 'YYYY-MM').diff(moment(a.reportMonth, 'YYYY-MM')),
    );

    const [lastReport] = sortedReports;

    const currentMonth = moment(lastReport.reportMonth, 'YYYY-MM');

    if (
        [ItemStatus.draft, ItemStatus.awaitingAction].includes(lastReport.state)
    ) {
        return currentMonth;
    }

    return currentMonth.add(1, 'month');
};

export const getCurrentMonthForStocktakingFlow = (
    user: User | undefined,
    stocktakingReports: StocktakingReport[],
) => {
    let currentMonth;

    if (stocktakingReports.length > 0) {
        currentMonth = getLatestReportMonthAndYear(stocktakingReports);
    }

    if (!currentMonth) {
        currentMonth = getStocktakingOpeningMonthFromAuth0(user);
    }

    if (!moment.isMoment(currentMonth)) {
        return null;
    }

    return currentMonth.format('YYYY-MM');
};

export const getStocktakingOpeningMonthFromAuth0 = (
    auth0User: User | undefined,
) => {
    if (!auth0User) {
        return;
    }

    const openingMonthFromAuth0 =
        auth0User[`${claimsNamespace}/STOCKTAKING_OPENING_PERIOD`];

    return parseMonthAndYearToDate(openingMonthFromAuth0);
};
