import useSWR from 'swr';
import { db } from '../db/db';
import { ISendingCondemnReport } from '../db/overrideTypes';
import { SWRCacheKeys } from '../events/dataFlowEvents';
import { offlineRequestDatabase } from '../worker/db/offlineRequestsDb';
import { swMessages } from '../worker/types';

const filterSendingCondemnReports = async () => {
    const offlineRequestsQueue =
        await offlineRequestDatabase.requests.toArray();
    const sendingCondemnReports = await db.sendingCondemnReports.toArray();

    const filteredCondemnReports: ISendingCondemnReport[] = [];
    offlineRequestsQueue.forEach((request) => {
        sendingCondemnReports.forEach((report) => {
            if (report.localId === request.localId) {
                filteredCondemnReports.push(report);
            }
        });
    });
    return filteredCondemnReports;
};

const updateSendingCondemnReports = async () => {
    const filteredCondemnReports = await filterSendingCondemnReports();
    await db.sendingCondemnReports.clear();
    await db.sendingCondemnReports.bulkPut(filteredCondemnReports);
    return filteredCondemnReports;
};

export const useSendingCondemnReports = () => {
    const {
        data: sendingCondemnReports,
        mutate: revalidateSendingCondemnReports,
    } = useSWR(SWRCacheKeys.sendingCondemnReports, updateSendingCondemnReports);

    const addReportToSendingCondemnReports = async (
        reportToAdd: ISendingCondemnReport,
    ) => {
        await db.sendingCondemnReports.put(reportToAdd);
        await revalidateSendingCondemnReports();
    };

    const deleteReportFromSendingCondemnReports = async (
        reportToDeleteId: number,
    ) => {
        const sendingCondemnReports = await db.sendingCondemnReports.toArray();
        const reportToDelete = sendingCondemnReports.find(
            (report) => report.id === reportToDeleteId,
        );

        if (reportToDelete) {
            await offlineRequestDatabase.requests
                .where('localId')
                .equals(reportToDelete.localId)
                .delete();
            await window.workbox.messageSW({
                command: swMessages.removeRequestFromOfflineQueueByLocalId,
                payload: reportToDelete.localId,
            });
            await revalidateSendingCondemnReports();
        }
    };

    return {
        sendingCondemnReports,
        addReportToSendingCondemnReports,
        deleteReportFromSendingCondemnReports,
        revalidateSendingCondemnReports,
    };
};
